import {IGlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {useStore} from '@services/hooks/useStore';
import {ISelectedUserFiltersStore} from '@stores-mobx/UserFiltersStore/models/SelectedUserFiltersStore';
import {useMemo} from 'react';

export const useMainParams = () => {
  const {userFiltersStore, globalFiltersStore, medDRAFiltersStore, studyDrugFilterStore} =
    useStore();
  const selectedFilters: ISelectedUserFiltersStore = userFiltersStore.selectedFiltersStore;
  const selectedGlobalFilters: IGlobalFiltersStore = globalFiltersStore;
  const medDRASelectedParams: {[key: string]: string} =
    medDRAFiltersStore.medDRASelectedFilters.selectedParamsWithSemicolons;
  const studyDrugFilterParams: {[key: string]: string} = studyDrugFilterStore.getAllFilters;

  return useMemo(
    () => ({
      ...selectedFilters.selectedFiltersParams,
      ...selectedGlobalFilters.selectedGlobalFiltersParams,
      ...medDRASelectedParams,
      ...studyDrugFilterParams
    }),
    [
      medDRASelectedParams,
      selectedFilters.selectedFiltersParams,
      selectedGlobalFilters.selectedGlobalFiltersParams,
      studyDrugFilterParams
    ]
  );
};
