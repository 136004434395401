import React from 'react';
import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';

import {EmptyData, FiltersWithPercentage} from '../../../../../../components';

import {DemographicsPieChartContainer} from './components/DemographicsPieChart';
import {DemographicsControlsContainer} from './components/DemographicsControls';
import s from './Demographics.module.css';

interface Props {
  ageGroupsData: IFilterDataItem[];
  selectedAgeGroups: IFilterCodeData[];
  countriesData: IFilterDataItem[];
  selectedCountries: IFilterCodeData[];
  setUserFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearUserFilter: (filterName: SelectedUserFilters, filterData: string | number) => void;
  withoutScroll?: boolean;
}

const Demographics: React.FC<Props> = ({
  ageGroupsData,
  selectedAgeGroups,
  countriesData,
  selectedCountries,
  setUserFilter,
  clearUserFilter,
  withoutScroll
}) => {
  return (
    <>
      <DemographicsControlsContainer />
      {!ageGroupsData.length && !countriesData.length ? (
        <EmptyData text="No filter data" className={s.emptyData} />
      ) : (
        <div className={s.filtersContainer}>
          <FiltersWithPercentage
            className={s.grid}
            filterData={ageGroupsData}
            selectedFilters={selectedAgeGroups}
            selectFilter={setUserFilter}
            clearFilter={clearUserFilter}
            filterName={SelectedUserFilters.AgeGroups}
            withoutScroll={withoutScroll}
          />
          <DemographicsPieChartContainer className={s.gridMiddle} />
          <FiltersWithPercentage
            controlHeight={24}
            className={s.grid}
            filterData={countriesData}
            selectedFilters={selectedCountries}
            selectFilter={setUserFilter}
            clearFilter={clearUserFilter}
            filterName={SelectedUserFilters.Countries}
            withoutScroll={withoutScroll}
          />
        </div>
      )}
    </>
  );
};

export default Demographics;
