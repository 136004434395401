import {IDatePickerProps} from '@models/DatePicker/DatePicker.types';
import {IDropdownProps} from '@models/Dropdown/Dropdown';
import {IDropdownFilterProps} from '@models/Dropdown/DropdownFilter';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import ReportsPage from './ReportsPage';

const ReportsPageContainer: React.FC<{}> = () => {
  const {userFiltersStore, medDRAFiltersStore, globalFiltersStore} = useStore();
  const {caseStatStore} = userFiltersStore;

  const getDropdownProps = (): IDropdownProps => {
    const {
      targetDrugs,
      dataSources,
      drugClasses,
      updateGlobalFilterOptions,
      selectAllGlobalFilterOptions
    } = globalFiltersStore;

    return {
      targetDrugs,
      dataSources,
      drugClasses,
      updateGlobalFilterOptions,
      selectAllGlobalFilterOptions,
      isFetching: caseStatStore.isFetching
    };
  };

  const getDropdownButtonProps = (): IDropdownFilterProps => {
    const {selectedFiltersStore} = userFiltersStore;
    const {medDRASelectedFilters} = medDRAFiltersStore;

    const {selectedFiltersChipsOptions, clearUserFilter, clearAllUserFilters} =
      selectedFiltersStore;

    const {selectedMedDraFiltersChipsOptions, clearMedDRAFilter, clearAllMedDRAFilters} =
      medDRASelectedFilters;

    return {
      userFiltersOptions: selectedFiltersChipsOptions,
      clearUserFilter,
      clearAllUserFilters,
      isUserFetching: caseStatStore.isFetching,
      medDraOptions: selectedMedDraFiltersChipsOptions,
      clearMedDRAFilter,
      clearAllMedDRAFilters,
      isMedDraFetching: medDRAFiltersStore.isFetching
    };
  };

  const getDatePickerProps = (): IDatePickerProps => {
    const {startDate, endDate} = globalFiltersStore;

    const setStartDate = () => {};
    const setEndDate = () => {};

    return {
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      disabled: false,
      dark: true
    };
  };

  // useEffect(() => {
  //   caseStatStore.fetchCaseVolumeData();
  //   reportsStore.fetchReportsData();
  //   reportsStore.fetchReportByPt();
  // }, [caseStatStore, reportsStore]);

  return (
    <div className="page-content">
      <ReportsPage
        dropdownButtonProps={getDropdownButtonProps()}
        dropdownProps={getDropdownProps()}
        datePickerProps={getDatePickerProps()}
      />
    </div>
  );
};

export default observer(ReportsPageContainer);
