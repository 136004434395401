import {RoutePaths} from '@services/constants/router.constants';
import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {NotFound} from './components';
import {ProtectedRoute} from './components/ProtectedRoute';
import {ResearchPageContainer, DetailPageContainer, ReportsPageContainer} from './scenes';

interface Props {
  isAllowed: boolean;
  onLogin: () => void;
}

const AppRouter: React.FC<Props> = (props: Props) => {
  const {onLogin, isAllowed} = props;

  return (
    <Switch>
      <Redirect exact from={RoutePaths.welcome} to={RoutePaths.research} />
      <ProtectedRoute
        exact
        path={RoutePaths.research}
        component={ResearchPageContainer}
        isAllowed={isAllowed}
        redirectToLoginIn={onLogin}
      />
      <ProtectedRoute
        exact
        path={RoutePaths.detail}
        component={DetailPageContainer}
        isAllowed={isAllowed}
        redirectToLoginIn={onLogin}
      />
      <ProtectedRoute
        exact
        path={RoutePaths.reports}
        component={ReportsPageContainer}
        isAllowed={isAllowed}
        redirectToLoginIn={onLogin}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
