import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

const ReportsByMedicalHistoryContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {medHistories: selectedMedHistories} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;

  const data: IFilterDataItem[] = caseStatData.medHistories;

  const emptyColors = {};
  const formattedOptions = data
    .map((item) => ({
      ...item,
      name: item.name
    }))
    .filter(({name, code}) => {
      if (selectedMedHistories.length) {
        if (code === null || code === undefined) {
          return selectedMedHistories.findIndex(({name: _name}) => _name === name) > -1;
        } else {
          return selectedMedHistories.findIndex(({code: _code}) => _code === code) > -1;
        }
      }
      return name;
    })
    .slice(0, 18)
    .reverse();

  const chartOptions = getChartOptions({options: formattedOptions});
  const {t} = useTranslation();
  return (
    <Report
      title={t('reportByMedHistory')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={emptyColors}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      contentHeight={400}
    />
  );
};

export default observer(ReportsByMedicalHistoryContainer);
