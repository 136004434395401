import {IChartFiltersData, IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {SeriesLineType} from '@models/Chart/chart.types';
import {ALL_CLASSES, colorGrayOpacity, colorGreen} from '@services/constants/chart.constants';
import {
  getReportClassesColorCollection,
  getReportClassesWithAll,
  lineChartLegendFormatted
} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import echarts from 'echarts/lib/echarts';
import {EChartsFiltersUtils} from '@services/utils/Charts/EchartsFilters.utils';
import merge from 'lodash/merge';

export function getChartOptions(
  chartData: IChartFiltersData,
  reportClasses: IFilterDataItem[]
): echarts.EChartOption {
  const chartOptions = EChartsFiltersUtils.getChartOptions();
  const legendOptions: echarts.EChartOption.Legend = EChartsFiltersUtils.getLegendOptions();
  const series: SeriesLineType[] = [];

  const {timeData, caseVolumeChartData, splitByClasses} = chartData;
  const isDataEmpty = Object.keys(caseVolumeChartData).length === 0;

  const caseVolumeChartDataLabels: string[] = Object.keys(caseVolumeChartData).map((key) =>
    key === 'all' ? ALL_CLASSES : key
  );
  const formattedReportClasses: IFilterDataItem[] = getReportClassesWithAll(reportClasses);
  const reportClassesWithColors = getReportClassesColorCollection(reportClasses);

  if (!isDataEmpty) {
    Object.entries(caseVolumeChartData).forEach(([key, caseVolumeChart], idx) => {
      const color = splitByClasses ? reportClassesWithColors[key] : colorGreen;
      const seriesOption: SeriesLineType = {
        data: caseVolumeChart,
        name: caseVolumeChartDataLabels[idx],
        lineStyle: {
          color
        },
        itemStyle: {
          color,
          borderWidth: 4
        },
        ...(splitByClasses && {
          areaStyle: {
            color: 'transparent'
          }
        })
      };
      series.push(seriesOption);
    });
  }
  legendOptions.data = caseVolumeChartDataLabels;
  legendOptions.formatter = lineChartLegendFormatted(formattedReportClasses);

  const chartModifiedOptions = {
    xAxis: {
      ...EChartsFiltersUtils.getXAxisOptions(),
      data: timeData,
      ...(isDataEmpty && {
        splitArea: {
          show: true,
          areaStyle: {
            color: [colorGrayOpacity, colorGrayOpacity],
            opacity: 0.5
          }
        }
      })
    },
    series: series.slice(0, 6), //FIXME rewrite merge with chart options
    legend: legendOptions
  };
  return merge({}, chartOptions, chartModifiedOptions);
}
