import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@services/hooks/useStore';
import {Button} from '@components/UIKit';
import {ArrowLeftIcon} from 'src/static/icons';
import {useTranslation} from 'react-i18next';

import {DemoModalHeader} from './components/DemoModalHeader';
import {DemoModalTable} from './components/DemoModalTable';
import s from './DemoModal.module.css';
import {DemoModalHeaderFilter} from './components/DemoModalHeaderFilter';

const DemoModal = () => {
  const {demoStore} = useStore();
  const {t} = useTranslation();
  const {
    modal,
    name,
    closeDemoModal,
    isFetching,
    filteredDiseaseData,
    literatureList,
    openPtReviewModal
  } = demoStore;

  const diseasesData = useMemo(() => {
    return filteredDiseaseData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDiseaseData, literatureList.length]);

  return (
    <>
      {modal.isOpened && (
        <>
          <div className={s.containerModal} />
          <div className={s.containerContent}>
            <div className={s.containerContentWrapper}>
              <div className={s.containerContentWrapperHeader}>
                <div className={s.containerContentWrapperHeaderButtons}>
                  <Button type="button" variant="secondary" onClick={closeDemoModal}>
                    <ArrowLeftIcon className={s.arrowLeft} />
                  </Button>
                </div>
                <div className={s.containerContentWrapperHeaderText}>
                  <p className={s.title}>{t('literatureWithName', {name})}</p>
                </div>
              </div>
              <div className={s.containerContentWrapperItems}>
                <DemoModalHeader />
                <DemoModalHeaderFilter />
                <DemoModalTable
                  literatureList={diseasesData}
                  isFetching={isFetching}
                  onClickRow={openPtReviewModal}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(DemoModal);
