import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router';

interface Props extends RouteProps {
  isAllowed: boolean;
  redirectToLoginIn: () => void;
}

const ProtectedRoute: React.FC<Props> = ({isAllowed, path, ...props}) => {
  const {redirectToLoginIn} = props;

  if (redirectToLoginIn && !isAllowed) {
    redirectToLoginIn();
    return null;
  }

  return isAllowed ? <Route {...props} /> : <Redirect to="/" />;
};

export default ProtectedRoute;
