import {RoutePaths} from '@services/constants/router.constants';
import {useLocation} from 'react-router-dom';

const usePagesPathInfo = (): {isResearchPage: boolean; isWelcomePage: boolean} => {
  const location = useLocation();

  const pathname = location.pathname
    .slice(1)
    .split('/')
    .filter((path) => path !== '');
  const isResearchPage: boolean =
    pathname.length === 1 && pathname[0] === RoutePaths.research.slice(1);
  const isWelcomePage: boolean = pathname.length === 0;

  return {isResearchPage, isWelcomePage};
};

export {usePagesPathInfo};
