import {SeriesScatterType} from '@models/Chart/chart.types';
import echarts from 'echarts/lib/echarts';
import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
  DEFAULT_SERIES_OPTIONS,
  DEFAULT_TOOLTIP_OPTIONS
} from '@services/constants/scatterChart.constants';

export class ScatterChart {
  static getChartOptions = (): echarts.EChartOption => DEFAULT_CHART_OPTIONS;

  static getXAxisOptions = (): echarts.EChartOption.XAxis => DEFAULT_XAXIS_OPTIONS;

  static getYAxisOptions = (): echarts.EChartOption.YAxis => DEFAULT_YAXIS_OPTIONS;

  static getTooltipOptions = (): echarts.EChartOption.Tooltip => DEFAULT_TOOLTIP_OPTIONS;

  static getSeriesOptions = (): SeriesScatterType[] => DEFAULT_SERIES_OPTIONS;
}
