import {colorPurple600} from '@services/constants/chart.constants';
import {getItemStyleColor} from '@services/utils/DetailCaseCharts/LabsChart.helpers';
import {ICaseDetailStore} from '@stores-mobx/CaseDetailStore';
import merge from 'lodash/merge';
import echarts from 'echarts/lib/echarts';
import {
  DEFAULT_LINE_CHARTS_COUNT,
  DEFAULT_SIDEBAR_WIDTH,
  SeriesLineType,
  SeriesScatterType
} from '@models/Chart/chart.types';
import {EChartsUtils} from '@services/utils/Charts/EchartsDetail.utils';
import {pipe} from '@services/utils/Functions.utils';

interface IChartModifiedOptions {
  xAxis: echarts.EChartOption.XAxis[];
  yAxis: echarts.EChartOption.YAxis[];
  series: (SeriesScatterType | SeriesLineType)[];
  grid: echarts.EChartOption.Grid[];
  dataZoom: echarts.EChartOption.DataZoom[];
}

interface IFnPipeProps {
  chartModifiedOptions: IChartModifiedOptions;
  chartData: ICaseDetailStore & {lineChartYOffset: number};
}

const initialChartModifiedOptions: IChartModifiedOptions = {
  xAxis: [],
  yAxis: [],
  series: [],
  grid: [],
  dataZoom: []
};

function setChartTimeline({chartModifiedOptions, chartData}: IFnPipeProps): IFnPipeProps {
  const {timeData} = chartData;
  const formattedChartOptions = {
    xAxis: [
      ...chartModifiedOptions.xAxis,
      {
        ...EChartsUtils.getXAxisOptions()[0],
        data: timeData
      }
    ],
    yAxis: [
      ...chartModifiedOptions.yAxis,
      {
        ...EChartsUtils.getYAxisOptions()[0],
        data: []
      }
    ],
    series: [
      ...chartModifiedOptions.series,
      {
        ...EChartsUtils.getSeriesOptions()[0],
        data: []
      }
    ],
    grid: [
      ...chartModifiedOptions.grid,
      {
        ...EChartsUtils.getGridOptions()[0]
      }
    ],
    dataZoom: [...EChartsUtils.getDataZoomOptions()]
  };
  return {chartModifiedOptions: formattedChartOptions, chartData};
}

function setDrugsChartData({chartModifiedOptions, chartData}: IFnPipeProps): IFnPipeProps {
  const {timeData, drugsLabels, drugsData, chartSettings, markLineDrugsData} = chartData;
  const formattedChartOptions = {
    xAxis: [
      ...chartModifiedOptions.xAxis,
      {
        ...EChartsUtils.getXAxisOptions()[1],
        data: timeData
      }
    ],
    yAxis: [
      ...chartModifiedOptions.yAxis,
      {
        ...EChartsUtils.getYAxisOptions()[1],
        data: Object.keys(drugsLabels)
      }
    ],
    series: [
      ...chartModifiedOptions.series,
      {
        ...EChartsUtils.getSeriesOptions()[1],
        data: drugsData,
        markLine: {
          data: markLineDrugsData
        }
      }
    ],
    grid: [
      ...chartModifiedOptions.grid,
      {
        ...EChartsUtils.getGridOptions()[1],
        top: chartSettings.topOffset,
        height: chartSettings.firstChartHeight
      }
    ],
    dataZoom: [...chartModifiedOptions.dataZoom]
  };
  return {chartModifiedOptions: formattedChartOptions, chartData};
}

function setEventsChartData({chartModifiedOptions, chartData}: IFnPipeProps): IFnPipeProps {
  const {timeData, eventsLabels, eventsData, markLineEventsData, chartSettings} = chartData;
  const formattedChartOptions = {
    xAxis: [
      ...chartModifiedOptions.xAxis,
      {
        ...EChartsUtils.getXAxisOptions()[2],
        data: timeData
      }
    ],
    yAxis: [
      ...chartModifiedOptions.yAxis,
      {
        ...EChartsUtils.getYAxisOptions()[2],
        data: Object.keys(eventsLabels)
      }
    ],
    series: [
      ...chartModifiedOptions.series,
      {
        ...EChartsUtils.getSeriesOptions()[2],
        data: eventsData,
        markLine: {
          data: markLineEventsData
        }
      }
    ],
    grid: [
      ...chartModifiedOptions.grid,
      {
        ...EChartsUtils.getGridOptions()[2],
        top: chartSettings.secondChartTopOffset,
        height: chartSettings.secondChartHeight
      }
    ],
    dataZoom: [...chartModifiedOptions.dataZoom]
  };
  return {chartModifiedOptions: formattedChartOptions, chartData};
}

function setLabsChartData({chartModifiedOptions, chartData}: IFnPipeProps): IFnPipeProps {
  const {timeData, chartSettings, lineChartYOffset, labsData} = chartData;
  const {thirdChartLabels} = chartSettings;

  const formattedChartOptions = {
    xAxis: [...chartModifiedOptions.xAxis],
    yAxis: [...chartModifiedOptions.yAxis],
    series: [...chartModifiedOptions.series],
    grid: [...chartModifiedOptions.grid],
    dataZoom: [...chartModifiedOptions.dataZoom]
  };

  const lineChartsCount =
    thirdChartLabels.length === 0 ? DEFAULT_LINE_CHARTS_COUNT : thirdChartLabels.length;

  for (let i = 0, gridIndex = 2; i < lineChartsCount; i++) {
    gridIndex++;

    const isLabsDataExists: boolean = labsData[i] ? labsData[i].length > 0 : false;

    formattedChartOptions.xAxis.push({
      ...EChartsUtils.getXAxisOptions()[3],
      ...(!isLabsDataExists && {
        splitArea: {
          show: true,
          areaStyle: {
            color: [colorPurple600, colorPurple600],
            opacity: 0.1
          }
        }
      }),
      data: timeData,
      gridIndex
    });

    formattedChartOptions.yAxis.push({
      ...EChartsUtils.getYAxisOptions()[3],
      offset: lineChartYOffset,
      ...(thirdChartLabels.length && {
        max: thirdChartLabels[i].maxFloor,
        interval: thirdChartLabels[i].interval
      }),
      gridIndex
    });
    // @ts-ignore
    formattedChartOptions.series.push({
      ...EChartsUtils.getSeriesOptions()[3],
      data: labsData[i],
      itemStyle: {
        color: getItemStyleColor(i, thirdChartLabels)
      },
      xAxisIndex: gridIndex,
      yAxisIndex: gridIndex
    });

    formattedChartOptions.grid.push({
      ...EChartsUtils.getGridOptions()[3],
      top: chartSettings.thirdChartTopOffsetsCollection[i],
      height: chartSettings.thirdChartDefaultHeight
    });
  }
  return {chartModifiedOptions: formattedChartOptions, chartData};
}

function setDataZoom({chartModifiedOptions, chartData}: IFnPipeProps): IFnPipeProps {
  const defaultXAxisIndex = chartModifiedOptions.series.reduce<number[]>((acc, item, idx) => {
    acc.push(idx);
    return acc;
  }, []);

  const formattedChartOptions = {
    xAxis: [...chartModifiedOptions.xAxis],
    yAxis: [...chartModifiedOptions.yAxis],
    series: [...chartModifiedOptions.series],
    grid: [...chartModifiedOptions.grid],
    dataZoom: [
      {
        ...chartModifiedOptions.dataZoom[0],
        xAxisIndex: defaultXAxisIndex
      },
      {
        ...chartModifiedOptions.dataZoom[1],
        xAxisIndex: defaultXAxisIndex
      }
    ]
  };

  return {chartModifiedOptions: formattedChartOptions, chartData};
}

export function getChartOptions(chartData: ICaseDetailStore, width: number): echarts.EChartOption {
  const chartOptions = EChartsUtils.getChartOptions();
  const lineChartYOffset = -width + DEFAULT_SIDEBAR_WIDTH;

  const generateChartData = pipe(
    setChartTimeline,
    setDrugsChartData,
    setEventsChartData,
    setLabsChartData,
    setDataZoom
  );

  const {chartModifiedOptions} = generateChartData({
    chartModifiedOptions: initialChartModifiedOptions,
    chartData: {...chartData, lineChartYOffset}
  });
  return merge({}, chartOptions, chartModifiedOptions);
}
