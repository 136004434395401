import React, {FunctionComponent, useRef, useEffect, ReactNode} from 'react';
import cn from 'classnames';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useClickOutside} from '@services/hooks/useClickOutside';

import s from './ModalWindow.module.css';

interface IProps {
  onClose: () => void;
  onClickOutside?: () => void;
  children?: ReactNode;
  className?: string;
}

const ModalWindow: FunctionComponent<IProps> = ({children, onClose, onClickOutside, className}) => {
  const modalWindowRef = useRef(null);
  useClickOutside(modalWindowRef, onClickOutside ? onClickOutside : onClose);

  const onEscPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEscPress, false);
    return () => {
      document.removeEventListener('keydown', onEscPress, false);
    };
  }, [onEscPress]);

  return (
    <TransitionGroup>
      {children && (
        <CSSTransition classNames="modal" timeout={{enter: 200, exit: 200}}>
          <div className={s.overlay}>
            <div ref={modalWindowRef} className={cn(s.modalWindow, className)}>
              {children}
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default ModalWindow;
