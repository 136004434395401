import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';
import React from 'react';
import {FiltersWithPercentage} from 'src/components/FiltersWithPercentage';
import {EmptyData} from 'src/components/UIKit';

import s from './CaseProperties.module.css';
import {CasePropertiesControlsContainer} from './CasePropertiesControlsContainer';
import {CasePropertiesPieChartContainer} from './CasePropertiesPieChart';

interface Props {
  outcomesData: IFilterDataItem[];
  selectedOutcomes: IFilterCodeData[];
  setUserFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearUserFilter: (filterName: SelectedUserFilters, filterData: string | number) => void;
  withoutScroll?: boolean;
}

const CaseProperties: React.FC<Props> = ({
  outcomesData,
  selectedOutcomes,
  setUserFilter,
  clearUserFilter,
  withoutScroll
}) => (
  <>
    <CasePropertiesControlsContainer />
    {!outcomesData.length ? (
      <EmptyData text="No filter data" className={s.emptyData} />
    ) : (
      <div className={s.filtersContainer}>
        <FiltersWithPercentage
          className={s.gridFirst}
          filterData={outcomesData}
          selectedFilters={selectedOutcomes}
          selectFilter={setUserFilter}
          clearFilter={clearUserFilter}
          filterName={SelectedUserFilters.Outcomes}
          withoutScroll={withoutScroll}
        />
        <CasePropertiesPieChartContainer className={s.gridLast} />
      </div>
    )}
  </>
);

export default CaseProperties;
