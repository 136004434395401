import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ChartColorCollectionType} from '@models/Chart/chart.types';
import {BarChartHorizontalUtils} from '@services/utils/Charts/BarChartHorizontal.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

export function getChartOptions({
  options,
  colors
}: {
  options: IFilterDataItem[];
  colors: ChartColorCollectionType;
}): echarts.EChartOption {
  const chartOptions = BarChartHorizontalUtils.getChartOptions();
  const xAxisOptions = BarChartHorizontalUtils.getXAxisOptions();
  const seriesOptions = BarChartHorizontalUtils.getSeriesOptions();

  const series: echarts.EChartOption.SeriesBar[] = options.map(({name, count}, idx) => ({
    ...seriesOptions[idx],
    name,
    data: [count],
    itemStyle: {
      color: colors[name]
    }
  }));

  const maxCount: number = Math.max(...options.map((rc) => rc.count));

  const getInterval = () => {
    const DEFAULT_SPLIT_NUMBER = 3;
    return Math.floor(maxCount / DEFAULT_SPLIT_NUMBER / 100) * 100 + 100;
  };

  const chartModifiedOptions = {
    xAxis: {
      ...xAxisOptions,
      interval: getInterval()
    },
    series: series.slice(0, 3) //FIXME rewrite merge with chart options
  };
  return merge({}, chartOptions, chartModifiedOptions);
}
