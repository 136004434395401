import React from 'react';

import s from './Checkbox.module.css';

interface Props {
  itemName: string;
  dataKey: string;
  checked?: boolean;
  onChecked: (checked: boolean) => void;
  className?: string;
  disableCheckbox?: boolean;
}

const CheckboxItem: React.FC<Props> = ({
  itemName,
  dataKey,
  checked,
  onChecked,
  className,
  disableCheckbox
}) => (
  <>
    <input
      type="checkbox"
      className={s.customCheckbox}
      id={dataKey}
      name={dataKey}
      checked={checked}
      onChange={() => onChecked(!checked)}
      disabled={disableCheckbox}
    />
    <label htmlFor={dataKey} className={className}>
      <span>{itemName}</span>
    </label>
  </>
);

export default CheckboxItem;
