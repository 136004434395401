import {types, flow} from 'mobx-state-tree';
import {getBaseUrl} from '@config/api.config';
import {ENotification} from '@models/Notification/Notification.types';
import {getRootStore} from '@services/utils/Mst.utils';
import axios, {AxiosRequestConfig, CancelTokenSource} from 'axios';
import {keycloak} from '@services/keycloak';
import {ELang, i18n} from '@services/i18n';
import {Session} from '@stores-mobx/AppStore/Session';
import {storage} from '@services/utils/storage.utils';

enum RequestStatuses {
  Pending = 'pending',
  Done = 'done',
  Error = 'error'
}

export type RequestStateType = keyof typeof RequestStatuses;
const baseUrl = getBaseUrl();

const REQUEST_TIMEOUT = 60000;
const INITIAL_STATE = [RequestStatuses.Pending, RequestStatuses.Done, RequestStatuses.Error];

const Request = types
  .model('Request', {
    state: types.maybeNull(types.enumeration<RequestStatuses>(INITIAL_STATE)),
    session: types.optional(Session, {}),
    fileName: types.maybeNull(types.string)
    // lastUrl: '',
    // lastParam: types.optional(types.frozen<object>(), {}),
    // cacheData: types.optional(types.frozen<object>(), {})
  })
  .actions((self) => ({
    handleError(url: string, message: string) {
      const notificationStore = getRootStore(self).notification;
      notificationStore.open(ENotification.Error, {type: ENotification.Error, message, url});
    }
  }))
  .actions((self) => {
    let cancel: CancelTokenSource | null = null;

    return {
      send: flow(function* (
        url: string,
        reqOps: AxiosRequestConfig = {},
        mockData?
        // useCache = false
      ) {
        if (mockData) {
          self.state = RequestStatuses.Pending;
          const result = yield new Promise((resolve) => setTimeout(resolve, 500, mockData));
          self.state = RequestStatuses.Done;
          return result;
        }
        try {
          if (cancel) {
            cancel.cancel('Request canceled');
          }

          const requestValidAccessToken = flow(function* () {
            if (!keycloak.token) {
              return null;
            }
            if (keycloak.isTokenExpired(10)) {
              yield self.session.updateToken();
            }

            return keycloak.token;
          });

          self.state = RequestStatuses.Pending;
          self.fileName = null;
          cancel = axios.CancelToken.source();
          const lang = i18n.language || localStorage.getItem('i18nextLng');

          const accessToken = yield requestValidAccessToken();

          if (accessToken) {
            reqOps.headers = {common: {}};
            reqOps.headers.common.Authorization = `Bearer ${accessToken}`;
          }

          // if (useCache && self.lastUrl === url && isEqual(self.lastParam, reqOps)) {
          //   console.log('Return cached data', self.lastUrl, self.lastParam, self.cacheData);
          //   return self.cacheData;
          // }
          //
          // if (useCache) {
          //   self.lastUrl = url;
          //   self.lastParam = reqOps;
          // }

          const response = yield axios({
            ...reqOps,
            url,
            timeout: REQUEST_TIMEOUT,
            cancelToken: cancel?.token,
            headers: {
              locale: lang ? lang.split('-')[0] : ELang.En,
              ...reqOps.headers
              // you can put here common headers for all requests
            },
            validateStatus: (status) => status <= 401
          });

          // unauthorised, update tokenF
          if (response.status === 401) {
            storage.clearStorage();
            keycloak.login();
          }

          self.state = RequestStatuses.Done;
          // if (useCache) self.cacheData = response.data;
          // console.log('Return non cached data', self.lastUrl, self.lastParam, response.data);
          const disposition = response.headers['content-disposition'];
          const filename = disposition && disposition.split('filename=')[1];

          if (filename) {
            self.fileName = filename;
          }

          return response.data;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          if (axios.isCancel(error)) {
            cancel = null;
          } else {
            const cutUrl = url.slice(baseUrl?.length || 0);

            self.state = RequestStatuses.Error;
            self.handleError(cutUrl, error.message);
          }
          self.fileName = null;
        } finally {
          if (cancel) {
            cancel = null;
            self.state = RequestStatuses.Done;
          }
        }
      })
    };
  })
  .views((self) => ({
    get isPending() {
      return self.state === RequestStatuses.Pending;
    },
    get isDone() {
      return self.state === RequestStatuses.Done;
    },
    get isError() {
      return self.state === RequestStatuses.Error;
    },
    get isNotSend() {
      return self.state === null;
    },
    get responseFileName() {
      return self.fileName;
    }
  }));

export {Request};
