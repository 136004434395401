import React from 'react';

export const LogoTextIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="113" height="24" viewBox="0 0 113 24">
    <g fill="#FFF" fillRule="nonzero">
      <path d="M9.603 16.43a6.379 6.379 0 0 0 3.857-1.187 6.05 6.05 0 0 0 2.226-3.467l2.865.609a8.393 8.393 0 0 1-3.193 5.005 9.37 9.37 0 0 1-5.755 1.819 9.603 9.603 0 1 1 0-19.207 9.416 9.416 0 0 1 5.723 1.788 8.33 8.33 0 0 1 3.225 4.817l-2.865.828a6.05 6.05 0 0 0-2.226-3.467 6.73 6.73 0 0 0-8.588.781 7.12 7.12 0 0 0 0 9.713 6.316 6.316 0 0 0 4.731 1.967zM34.736 5.179v13.64H31.88v-1.562a6.582 6.582 0 0 1-4.958 1.952 6.48 6.48 0 0 1-4.77-2.022 7.69 7.69 0 0 1 0-10.361 6.441 6.441 0 0 1 4.754-2.038 6.582 6.582 0 0 1 4.958 1.952V5.18h2.873zm-7.347 11.5a4.357 4.357 0 0 0 3.123-1.311 4.88 4.88 0 0 0 0-6.738 4.513 4.513 0 0 0-6.332 0 5.2 5.2 0 0 0 0 6.8 4.318 4.318 0 0 0 3.17 1.25h.04zM46.167 4.788a6.457 6.457 0 0 1 4.762 2.038 7.714 7.714 0 0 1 0 10.36 6.949 6.949 0 0 1-9.728.071v6.246h-2.858V5.18h2.858V6.74a6.558 6.558 0 0 1 4.966-1.952zm-.461 11.891a4.302 4.302 0 0 0 3.185-1.28 5.2 5.2 0 0 0 0-6.8 4.302 4.302 0 0 0-3.185-1.281 4.357 4.357 0 0 0-3.123 1.312 4.88 4.88 0 0 0 0 6.738 4.357 4.357 0 0 0 3.123 1.311z" />
      <path d="M68.754 5.179v13.64h-2.858v-1.562a6.949 6.949 0 0 1-9.728-.07 7.714 7.714 0 0 1 0-10.361 6.473 6.473 0 0 1 4.763-2.038 6.558 6.558 0 0 1 4.95 1.952V5.18h2.873zm-7.34 11.5a4.357 4.357 0 0 0 3.124-1.311 4.88 4.88 0 0 0 0-6.738 4.357 4.357 0 0 0-3.123-1.312 4.31 4.31 0 0 0-3.193 1.28 5.2 5.2 0 0 0 0 6.8 4.31 4.31 0 0 0 3.193 1.281zM80.481 5.007c.324-.01.648.03.96.117l-.195 2.748h-.78a4.685 4.685 0 0 0-3.85 1.64 7.027 7.027 0 0 0-1.32 4.528v4.81h-2.865V5.179h2.866V8.09a5.793 5.793 0 0 1 5.184-3.084zM90.881 4.788a5.192 5.192 0 0 1 3.958 1.609 5.864 5.864 0 0 1 1.515 4.169v8.253h-2.889v-7.675a3.904 3.904 0 0 0-.898-2.694 2.96 2.96 0 0 0-2.342-1.015 3.693 3.693 0 0 0-2.982 1.288 5.77 5.77 0 0 0-1.086 3.795v6.3h-2.865V5.179h2.865V6.85a5.84 5.84 0 0 1 4.724-2.06zM113 5.179v13.64h-2.865v-1.562a6.55 6.55 0 0 1-4.95 1.952 6.48 6.48 0 0 1-4.77-2.022 7.698 7.698 0 0 1 0-10.361 6.473 6.473 0 0 1 4.762-2.038 6.55 6.55 0 0 1 4.95 1.952V5.18H113zm-7.347 11.5a4.357 4.357 0 0 0 3.123-1.311 4.895 4.895 0 0 0 0-6.738 4.357 4.357 0 0 0-3.123-1.312 4.31 4.31 0 0 0-3.193 1.28 5.2 5.2 0 0 0 0 6.8 4.31 4.31 0 0 0 3.193 1.281z" />
    </g>
  </svg>
);
