import {flow, Instance, types} from 'mobx-state-tree';
import {Store} from '@models/base/Store';
import {Request} from '@models/base/Request';
import {getRootStore} from '@services/utils/Mst.utils';
import {IGlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {IDispropFetchParams, PeriodType} from '@models/DispropScore/DispropScore.types';

const DispropScoreCSVStore = types.compose(
  Store,
  types
    .model('DispropScoreCSV', {
      requestCSV: types.optional(Request, {})
    })
    .actions((self) => {
      const actions = {
        loadCSV: flow(function* ({
          periodType,
          pt,
          filters,
          medraFilters,
          finalDrugsRequestTarget
        }: IDispropFetchParams & {periodType: PeriodType}) {
          const selectedGlobalFilters: IGlobalFiltersStore = getRootStore(self).globalFiltersStore;

          if (finalDrugsRequestTarget[0] !== undefined) {
            return yield self.requestCSV.send(
              getApiUrl(ApiVersions.v11, 'disp.score.list.pageable.analytics.csv'),
              {
                method: 'post',
                data: {
                  therapy: {
                    components: finalDrugsRequestTarget,
                    type: 'target'
                  },
                  filter: {
                    ...filters,
                    time_period: {
                      start_date: selectedGlobalFilters?.startDate,
                      end_date: selectedGlobalFilters?.endDate,
                      ds_period_label: periodType?.toLowerCase()
                    }
                  },
                  meddra_filter: medraFilters,
                  client: 'string2',
                  pt
                }
              }
            );
          } else return null;
        })
      };
      return actions;
    })
    .views((self) => ({
      get isFetchingCSV(): boolean {
        return self.requestCSV.isPending;
      }
    }))
);

export interface IDispropScoreCSVStore extends Instance<typeof DispropScoreCSVStore> {}

export {DispropScoreCSVStore};
