import {
  Genders,
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters,
  SeriousNameFormatted
} from '@models/CaseStat/CaseStat.types';
import {IStyleProps, SeriesPieDataObjectType, SeriesPieType} from '@models/Chart/PieChart.types';
import {shadowBlur, shadowColor} from '@services/constants/chart.constants';
import {pieChartLegendFormatter} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {PieChartUtils} from '@services/utils/Charts/PieChart.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

const defaultSexesLegends = {
  [Genders.Male]: true,
  [Genders.Female]: true,
  [Genders.Unknown]: true
};

const defaultSeriousnessLegends = {
  [SeriousNameFormatted.Serious]: true,
  [SeriousNameFormatted.NotSerious]: true
};

interface IChartModifiedOptions {
  series: SeriesPieType[];
  legend: echarts.EChartOption.Legend;
}

interface Props {
  chartData: IFilterDataItem[];
  sumOfCases: string;
  selectedFilters: IFilterCodeData[];
  colorCollection: IStyleProps;
  filterName: SelectedUserFilters;
}

export function getChartOptions({
  chartData,
  sumOfCases,
  selectedFilters,
  colorCollection,
  filterName
}: Props): echarts.EChartOption {
  const chartOptions = PieChartUtils.getChartOptions();
  const legendOptions = PieChartUtils.getLegendOptions();
  const seriesOptions = PieChartUtils.getSeriesOptions();

  const data: SeriesPieDataObjectType[] = chartData.map(({name, code, count, percent}) => ({
    code,
    name,
    itemStyle: {
      color: colorCollection[name],
      shadowColor,
      shadowBlur
    },
    value: [count, percent]
  }));

  let selectedLegends = chartData.reduce<{[key: string]: boolean}>((acc, {name, code}) => {
    acc[name] = selectedFilters.findIndex(({code: _code}) => _code === code) > -1;
    return acc;
  }, {});
  const legendsUnselected: boolean = Object.values(selectedLegends).every((legend) => !legend);

  if (filterName === SelectedUserFilters.Sexes && legendsUnselected) {
    selectedLegends = defaultSexesLegends;
  }
  if (filterName === SelectedUserFilters.Seriousness && legendsUnselected) {
    selectedLegends = defaultSeriousnessLegends;
  }

  const chartModifiedOptions: IChartModifiedOptions = {
    legend: {
      ...legendOptions,
      selected: selectedLegends,
      formatter: pieChartLegendFormatter(data)
    },
    series: [
      {
        ...seriesOptions[0],
        data
      },
      {
        ...seriesOptions[1]
      },
      {
        ...seriesOptions[2],
        data: [
          {
            label: {
              formatter: () => `{a|${sumOfCases}}\n{b|Cases}`
            }
          }
        ]
      }
    ]
  };
  return merge({}, chartOptions, chartModifiedOptions);
}
