import React, {ButtonHTMLAttributes, PropsWithChildren, ReactElement} from 'react';
import cn from 'classnames';

import s from './Button.module.css';

type TVariant = 'primary' | 'default' | 'secondary' | 'basic';
type TSize = 'short' | 'wide' | 'small' | 'large';

interface ButtonProps {
  variant?: TVariant;
  size?: TSize;
  loading?: boolean;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, PropsWithChildren<ButtonProps> {}

const Button: React.FC<Props> = ({
  children,
  type,
  variant = 'default',
  size = 'short',
  onClick,
  className,
  disabled,
  loading
}): ReactElement => (
  <button
    type={type}
    disabled={disabled}
    className={cn(s.btn, s[variant], s[size], className, {
      [s.disabled]: disabled,
      [s.loading]: loading
    })}
    onClick={onClick}
  >
    <span className={s.btnText}>{children}</span>
    {/*{loading && (*/}
    {/*  <LoadingIcon />*/}
    {/*)}*/}
  </button>
);

export default Button;
