import React, {useCallback, useState} from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IDatePickerProps} from '@models/DatePicker/DatePicker.types';
import {IDropdownFilterProps} from '@models/Dropdown/DropdownFilter';
import {Logo} from '@components/index';
import {ExitIcon} from 'src/static/icons/ExitIcon';
import {DOCIcon, FiltersIcon, LoadingIcon, BellIcon, SettingsIcon} from 'src/static/icons';
import {
  ContainedButton,
  DropdownFilter,
  DropdownButton,
  IDropdownButtonOptions
} from 'src/components/UIKit';
import {keycloak} from '@services/keycloak';
import {GlobalDates} from '@models/GlobalFilters/GlobalFilters.types';
import {useStore} from '@services/hooks/useStore';
import {Tooltip} from '@components/UIKit';

import {DatePickerRange} from '../DatePickerRange';
import {ClickOutside} from '../ClickOutside';
import {ChangePasswordIcon} from '../../static/icons/ChangePasswordIcon';

import HeaderResetButton from './HeaderResetButton';
import s from './Header.module.css';

interface IProps {
  count?: number;
  dropdownButtonProps?: IDropdownFilterProps;
  datePickerProps: IDatePickerProps;
  isResearchPage: boolean;
  isReportsFetching: boolean;
  isReportGenerating: boolean;
  reportOptions: IDropdownButtonOptions[];
  onLogout: () => void;
  cumulativeDates: GlobalDates;
}

const Header: React.FC<IProps> = ({
  dropdownButtonProps,
  datePickerProps,
  count,
  isResearchPage,
  isReportsFetching,
  isReportGenerating,
  reportOptions,
  cumulativeDates,
  onLogout
}) => {
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const {dateFrom, dateTo} = cumulativeDates;
  const {t} = useTranslation();
  const {studyDrugFilterStore} = useStore();
  const {getReportIsDisabled} = studyDrugFilterStore;
  const handleClickOutside = () => {
    if (isUserMenuOpen) {
      setUserMenuOpen(false);
    }
  };

  const handleChangePassword = useCallback(() => {
    keycloak.login({
      action: 'UPDATE_PASSWORD'
    });
  }, []);

  return (
    <header className={cn(s.header, {[s.headerSticky]: isResearchPage})}>
      <div className={s.headerLogo}>
        <Logo />
      </div>
      <div className={s.headerTop}>
        <div className={s.container}>
          <div className={s.headerTopMenu}>
            <nav className={s.headerTopNav}>
              <div className={cn(s.navItem, s.active)}>
                <Link className={s.navItemLink} to="/">
                  {t('header.navTabOne')}
                </Link>
              </div>
              <div className={cn(s.navItem)}>
                <a className={s.navItemLink} href="https://israelgutierrez.onspring.com/Dashboard">
                  {t('header.navTabTwo')}
                </a>
              </div>
              <div className={cn(s.navItem, s.disabled)}>
                <Link className={s.navItemLink} to="/">
                  {t('header.navTabThree')}
                </Link>
              </div>
              <div className={cn(s.navItem, s.disabled)}>
                <Link className={s.navItemLink} to="/">
                  {t('header.navBiologit')}
                </Link>
              </div>
            </nav>
            <div className={s.menuItemIcon}>
              <BellIcon />
            </div>
            <div className={s.userMenuWrapper}>
              <button onClick={() => setUserMenuOpen(!isUserMenuOpen)} className={s.avatar}>
                MD
              </button>
              <ClickOutside handleClickOutside={handleClickOutside}>
                <div className={cn(s.userMenu, {[s.open]: isUserMenuOpen})}>
                  <button className={cn(s.userMenuItem, s.disabled)}>
                    <SettingsIcon className={s.userMenuItemIcon} />
                    <span className={s.userMenuItemName}>Settings</span>
                  </button>
                  <button className={cn(s.userMenuItem)} onClick={handleChangePassword}>
                    <ChangePasswordIcon className={s.userMenuItemIcon} />
                    <span className={s.userMenuItemName}>Change password</span>
                  </button>
                  <button className={s.userMenuItem} onClick={onLogout} title="Sign Out">
                    <ExitIcon className={s.userMenuItemIcon} />
                    <span className={s.userMenuItemName}>Sign Out</span>
                  </button>
                </div>
              </ClickOutside>
            </div>
          </div>
        </div>
      </div>
      <div className={s.headerBottom}>
        <div className={s.btnGroup}>
          <div className={s.cumulativeDates}>
            <span className={s.title}>{t('header.dates')}</span>
            {dateFrom && dateTo && (
              <span>
                {dateFrom} — {dateTo}
              </span>
            )}
          </div>
          <DatePickerRange {...datePickerProps} />
          {/*<ContainedButton title="Settings" Icon={SettingsIcon} disabled active />*/}
          {isResearchPage && dropdownButtonProps ? (
            <DropdownFilter count={count} {...dropdownButtonProps} />
          ) : (
            <ContainedButton
              title={t('filters')}
              Icon={FiltersIcon}
              count={count}
              disabled={!isResearchPage}
              active
            />
          )}
          <Tooltip
            text={t('cantGenerateReport')}
            position="top"
            trigger={getReportIsDisabled ? 'none' : 'hover'}
          >
            <DropdownButton
              title={t('header.exportReport')}
              options={reportOptions}
              disabled={isReportsFetching || getReportIsDisabled}
              classNames={s.btnGroupLastBtn}
            >
              {isReportGenerating ? (
                <LoadingIcon className={s.reportIcon} />
              ) : (
                <DOCIcon className={s.reportIcon} />
              )}
            </DropdownButton>
          </Tooltip>
          <HeaderResetButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
