import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

interface Props {
  withoutOffsets?: boolean;
}

const ReportByCountriesContainer: React.FC<Props> = ({withoutOffsets}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {countries: selectedCountries} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;

  const {t} = useTranslation();

  const data: IFilterDataItem[] = caseStatData.countries;

  const emptyColors = {};
  const formattedOptions = data
    .map((item) => ({
      ...item,
      name: item.name
    }))
    .filter(({name, code}) => {
      if (selectedCountries.length) {
        return code ? selectedCountries.findIndex(({code: _code}) => _code === code) > -1 : false;
      }
      return name;
    })
    .slice(0, 15);

  const chartOptions = getChartOptions({options: formattedOptions});

  return (
    <Report
      title={t('ReportByCountries')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={emptyColors}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      contentHeight={400}
      withoutOffsets={withoutOffsets}
      leftOffset
    />
  );
};

export default observer(ReportByCountriesContainer);
