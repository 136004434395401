import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ChartColorCollectionType} from '@models/Chart/chart.types';
import {BarChartVerticalUtils} from '@services/utils/Charts/BarChartVertical.utils';
import {convertNameToCapitalize} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

const convertNameWithLineBreak = (name: string) => {
  if (name.includes('/')) {
    return name.replace('/', '/\n');
  }
  if (name.includes(' ')) {
    const indexOfSpace = name.indexOf(' ');
    return `${name.slice(0, indexOfSpace)} \n${name.slice(indexOfSpace + 1)}`;
  }
  return name;
};

const getInterval = (countData: number[]) => {
  const maxCount = Math.max(...countData);
  const splitNumber = 4;

  const topOffset = () => {
    if (maxCount > 1000) {
      return 2000;
    }
    if (maxCount > 500) {
      return 500;
    }
    if (maxCount < 10) {
      return 8;
    }
    return 100;
  };

  const maxFloorValue = maxCount - (maxCount % 100) + topOffset();
  const interval = Math.floor(maxFloorValue / splitNumber);

  return {maxFloorValue, interval};
};

export function getChartOptions({
  options,
  colors
}: {
  options: IFilterDataItem[];
  colors: ChartColorCollectionType;
}): echarts.EChartOption {
  const chartOptions = BarChartVerticalUtils.getChartOptions();
  const seriesOptions = BarChartVerticalUtils.getSeriesOptions();
  const xAxisOptions = BarChartVerticalUtils.getXAxisOptions();
  const yAxisOptions = BarChartVerticalUtils.getYAxisOptions();
  const gridOptions = BarChartVerticalUtils.getGridOptions();

  const xAxisData = options.map(({name}) => {
    const nameToCapitalize = convertNameToCapitalize(name);
    return convertNameWithLineBreak(nameToCapitalize);
  });
  const seriesData = options.map(({count, name}) => ({
    name,
    value: count,
    itemStyle: {
      color: colors[name]
    }
  }));
  const countData = options.map(({count}) => count);
  const {interval, maxFloorValue} = getInterval(countData);

  const chartModifiedOptions = {
    series: [
      {
        ...seriesOptions[0],
        label: {
          normal: {
            show: true
          }
        },
        data: seriesData
      }
    ],
    xAxis: {
      ...xAxisOptions,
      data: xAxisData,
      axisLabel: {
        rotate: seriesData.length > 5 ? 50 : 0
      }
    },
    yAxis: {
      ...yAxisOptions,
      interval,
      max: maxFloorValue
    },
    grid: {
      ...gridOptions,
      bottom: 15
    }
  };

  return merge({}, chartOptions, chartModifiedOptions);
}
