import React from 'react';
import cn from 'classnames';

import s from './EmptyData.module.css';

interface Props {
  text: string;
  className?: string;
}

const EmptyData: React.FC<Props> = ({text, className}) => {
  return (
    <div className={cn(s.contentCenter, className)}>
      <span className="primaryTextEmpty">{text}</span>
    </div>
  );
};

export default EmptyData;
