import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

const ReportByPTContainer: React.FC<{}> = () => {
  const {reportsStore} = useStore();
  const {reportByPtData, isReportByPtFetching} = reportsStore;

  const formattedOptions =
    reportByPtData && reportByPtData.length ? [...reportByPtData].reverse() : [];
  const chartOptions = getChartOptions({options: formattedOptions});

  const {t} = useTranslation();

  return (
    <Report
      title={t('ReportByPT')}
      isFetching={isReportByPtFetching}
      options={formattedOptions}
      colors={{}}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      contentHeight={400}
      leftOffset
    />
  );
};

export default observer(ReportByPTContainer);
