import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConfirmationDialog, ModalWindow} from '@components/index';
import {useStore} from '@services/hooks/useStore';

import s from './StudyDrugEmptyClassModal.module.css';

const StudyDrugEmptyClassModal: React.FC = () => {
  const {studyDrugFilterStore} = useStore();
  const {closeConfirmation, setModalOpen, modal, updateSelected, setShowAll} = studyDrugFilterStore;
  const {t} = useTranslation();

  const handleClickConfirm = () => {
    updateSelected();
    setShowAll(true);
    setModalOpen(false);
    closeConfirmation();
  };

  return (
    <>
      <ModalWindow onClose={closeConfirmation} className={s.modalConfirmation}>
        {modal.isOpened && (
          <ConfirmationDialog
            title={t('continueWithoutFilters')}
            description={t('youHaveToChoose')}
            onCancel={closeConfirmation}
            onSubmit={handleClickConfirm}
          />
        )}
      </ModalWindow>
    </>
  );
};

export default observer(StudyDrugEmptyClassModal);
