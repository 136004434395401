import React, {FC, memo} from 'react';
import cn from 'classnames';

import NoDataSectionPicture from './assets/NoDataSectionPicture.png';
import s from './NoDataSection.module.css';

interface IProps {
  title: string;
  description: string;
  className?: string;
}

const NoDataSection: FC<IProps> = ({title, description, className = ''}) => {
  return (
    <div className={cn(s.wrapper, className)}>
      <img alt={title} src={NoDataSectionPicture} width="326" height="286" className={s.image} />
      <span className={s.title}>{title}</span>
      <span className={s.text}>{description}</span>
    </div>
  );
};

export default memo(NoDataSection);
