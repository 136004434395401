import {ReportsChartFormattedDataType} from '@models/Reports/Reports.types';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {ScatterChart} from '@services/utils/Charts/ScatterChart.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

export function getChartOptions({
  scatterOptions = [],
  labels = [],
  timeline = []
}: {
  scatterOptions: ReportsChartFormattedDataType;
  labels: string[];
  timeline: number[];
}): echarts.EChartOption {
  const chartOptions = ScatterChart.getChartOptions();
  const xAxisOptions = ScatterChart.getXAxisOptions();
  const yAxisOptions = ScatterChart.getYAxisOptions();
  const seriesOptions = ScatterChart.getSeriesOptions();
  const tooltipOptions = ScatterChart.getTooltipOptions();

  const optionValues = scatterOptions.map((option) => option[2]);
  const maxValue = Math.max(...optionValues);

  const chartModifiedOptions = {
    xAxis: {
      ...xAxisOptions,
      data: labels,
      axisLabel: {
        rotate: labels.length > 6 ? 30 : 0
      }
    },
    yAxis: {
      ...yAxisOptions,
      type: 'value',
      data: timeline
    },
    tooltip: {
      ...tooltipOptions,
      formatter: (
        params: echarts.EChartOption.Tooltip.Format | echarts.EChartOption.Tooltip.Format[]
      ) => formatterTooltip({params, isBoxSpot: true, width: '60px'})
    },
    series: [
      {
        ...seriesOptions,
        name: 'BoxPlot',
        symbol: (value: [number, number, number]) => {
          return value[2] >= 1 ? 'rect' : 'circle';
        },
        symbolSize: (val: [number, number, number]) => {
          const value = val[2];
          const MAX_WIDTH = 1000 / labels.length;

          const scale = maxValue / MAX_WIDTH;

          if (value === 0) return 0;
          if (value >= 1 && value < 10) {
            return [4, 3];
          }
          if (value >= 10 && value < 100) {
            return [value / 5, 3];
          }
          if (value >= 100 && value < 500) {
            return [value / 10, 4];
          }
          return [value / scale, 4];
        },
        data: scatterOptions,
        animation: false
      }
    ],
    animation: false
  };
  return merge({}, chartOptions, chartModifiedOptions);
}
