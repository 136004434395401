import React from 'react';

export const LogoLargeIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="184" height="80" viewBox="0 0 184 80">
    <g fill="#FFF" fillRule="nonzero">
      <path d="M71.107 5.342v16.463l12.538 12.538 8.232 8.232 4.255-4.255 3.977-3.977.117-.117-8.35-8.115L71.108 5.342zm41.539 0-17.68 17.68 4.036 4.036 4.196 4.196 9.448-9.448V5.342zM71.107 28.983c-1.31 17.316.278 15.927 16.464 15.927l-8.5-7.963-7.964-7.964zM112.646 28.983c1.31 17.316-.278 15.927-16.464 15.927l8.5-7.963 7.964-7.964zM19.438 64.675v.537h19.249v-.537zM145.066 64.675v.537h19.25v-.537z" />
      <g>
        <path d="M55.754 71h2.16l-.702-12.132h-2.88l-1.674 4.86a59.222 59.222 0 0 0-1.206 4.23h-.054a61.233 61.233 0 0 0-1.116-4.212l-1.584-4.878h-2.916L44.972 71h2.07l.27-4.878c.09-1.656.162-3.582.216-5.166h.036a60.728 60.728 0 0 0 1.224 4.716l1.62 5.184H52.1l1.782-5.256a64.841 64.841 0 0 0 1.44-4.644h.054c-.018 1.638.072 3.528.144 5.094l.234 4.95zM66.95 63.818h-4.572v-3.132h4.842v-1.818h-7.056V71h7.326v-1.818h-5.112v-3.564h4.572zM69.434 70.964c.738.09 1.71.162 2.988.162 2.358 0 4.284-.558 5.49-1.656 1.152-1.044 1.89-2.646 1.89-4.824 0-2.052-.72-3.51-1.908-4.464-1.116-.936-2.664-1.404-4.932-1.404-1.314 0-2.538.108-3.528.252v11.934zm2.214-10.332c.324-.072.81-.126 1.512-.126 2.772 0 4.32 1.548 4.302 4.212 0 3.078-1.71 4.68-4.59 4.662-.45 0-.918 0-1.224-.054v-8.694zM81.188 70.424c.666.414 1.998.774 3.294.774 3.114 0 4.59-1.692 4.59-3.654 0-1.746-1.008-2.808-3.114-3.6-1.62-.63-2.322-1.062-2.322-1.998 0-.72.594-1.458 1.998-1.458 1.134 0 1.98.342 2.43.576l.522-1.782c-.63-.324-1.584-.612-2.898-.612-2.646 0-4.302 1.494-4.302 3.492 0 1.746 1.278 2.808 3.294 3.528 1.53.558 2.142 1.098 2.142 2.016 0 .99-.792 1.656-2.196 1.656-1.134 0-2.232-.36-2.934-.774l-.504 1.836zM91.07 58.868V71h2.214V58.868zM105.542 64.304h-4.284v1.746h2.142v3.042c-.288.126-.864.234-1.674.234-2.52 0-4.212-1.638-4.212-4.392 0-2.826 1.818-4.356 4.41-4.356 1.278 0 2.088.216 2.754.522l.486-1.782c-.558-.27-1.728-.576-3.222-.576-4.014 0-6.732 2.448-6.75 6.3 0 1.836.612 3.438 1.692 4.464 1.152 1.098 2.718 1.62 4.77 1.62 1.602 0 3.114-.396 3.888-.684v-6.138zM109.844 71v-4.338c0-1.998-.036-3.6-.126-5.166l.072-.018c.594 1.368 1.386 2.844 2.16 4.176L115.118 71h2.304V58.868h-2.052v4.23c0 1.854.036 3.42.198 5.04h-.036a36.144 36.144 0 0 0-2.07-4.068l-3.132-5.202h-2.538V71h2.052zM126.242 67.562 127.34 71h2.394l-3.942-12.132h-2.826L119.078 71h2.286l1.044-3.438h3.834zm-3.474-1.674.936-2.988c.198-.72.396-1.566.576-2.268h.036c.18.702.378 1.53.612 2.268l.954 2.988h-3.114zM131.426 71h7.2v-1.854h-4.986V58.868h-2.214z" />
      </g>
    </g>
  </svg>
);
