import React from 'react';

export const BellIcon: React.FC<{className?: string}> = ({className}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="currentColor" fillRule="evenodd">
      <g transform="translate(-1328 -20) translate(1320 12)">
        <path
          fill="#currentColor"
          fillRule="nonzero"
          d="M12.075 20.593c.61 0 1.15-.14 1.621-.422.472-.281.846-.653 1.12-1.116.237-.397.378-.82.425-1.268l.014-.208h3.464c.41 0 .734-.102.971-.304.238-.202.356-.47.356-.804 0-.434-.168-.843-.505-1.226-.337-.384-.69-.76-1.06-1.13-.192-.187-.342-.433-.447-.738-.106-.305-.185-.636-.238-.993-.052-.358-.09-.715-.114-1.072-.035-1.061-.162-2.001-.382-2.822-.22-.82-.557-1.504-1.01-2.052-.455-.548-1.048-.954-1.78-1.217-.147-.551-.436-1.013-.866-1.385-.431-.372-.954-.558-1.57-.558-.62 0-1.146.186-1.577.558-.43.372-.72.834-.866 1.385-.732.263-1.325.67-1.78 1.217-.454.548-.792 1.232-1.015 2.052-.222.82-.348 1.761-.378 2.822-.029.357-.068.714-.118 1.072-.05.357-.128.688-.233.993-.106.305-.255.55-.448.738-.37.37-.722.746-1.06 1.13-.336.383-.505.792-.505 1.226 0 .334.119.602.356.804.237.202.561.303.971.303l3.464.001.017.208c.051.448.194.869.43 1.263.276.46.648.832 1.117 1.117.468.284 1.01.426 1.626.426zm6.126-4.43H5.922v-.105c.07-.077.164-.176.282-.3l.395-.408c.147-.15.287-.306.422-.47.146-.17.275-.366.387-.589.111-.223.203-.476.277-.76.073-.284.131-.598.175-.94.044-.343.078-.714.101-1.112.024-1.16.15-2.082.378-2.765.229-.682.526-1.192.892-1.529.367-.337.781-.57 1.244-.699.1-.023.177-.063.233-.118.056-.056.09-.14.101-.25.018-.429.138-.774.36-1.038.223-.264.525-.395.906-.395.375 0 .674.131.896.395.223.264.343.61.36 1.037.012.112.046.195.102.25.055.056.133.096.233.12.462.128.877.361 1.243.698.366.337.662.847.888 1.53.225.682.356 1.603.391 2.764.012.398.041.769.088 1.111.047.343.108.657.184.94.077.285.169.538.277.761.109.223.233.419.374.589.14.164.284.32.43.47.147.15.277.286.392.409.114.123.203.222.268.299v.105zm-6.126 3.173c-.492 0-.897-.16-1.213-.48-.277-.278-.444-.644-.501-1.096l-.016-.181h3.45l-.015.18c-.057.453-.224.819-.501 1.098-.317.32-.718.479-1.204.479z"
          transform="translate(8 8)"
        />
      </g>
    </g>
  </svg>
);
