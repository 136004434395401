import React from 'react';

import s from './CaseVolume.module.css';
import {ReportClassesContainer, CaseVolumeChartContainer} from './components';
import {CaseVolumeControlsContainer} from './components/CaseVolumeControls';

interface Props {}

const CaseVolume: React.FC<Props> = () => (
  <div>
    <CaseVolumeControlsContainer />

    <div className={s.caseVolumeData}>
      <CaseVolumeChartContainer />
      <ReportClassesContainer />
    </div>
  </div>
);

export default CaseVolume;
