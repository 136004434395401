import {Request} from '@models/base/Request';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {
  EMedDraType,
  IMedDraDictionariesData,
  MedDRADictType,
  MedDRAPtTypes
} from '@models/MedDRAFilters/MedDRAFilters.types';
import {initialPageParam, IPageParams} from '@models/Page/Page.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getSelectedData} from '@services/utils/MedDRAFilters.utils';
import {getRootStore} from '@services/utils/Mst.utils';
import {IMedDRASearchValuesModel} from '@stores-mobx/MedDRAFiltersStore/models/MedDRASearchValue';
import debounce from 'lodash/debounce';
import {flow, IJsonPatch, Instance, onPatch, types} from 'mobx-state-tree';
import {ISelectOptions} from '@models/Dropdown/Dropdown.types';

interface IMeddraDictParams {
  page: number;
  dictType?: string;
  filter?: string;
  ptType?: string;
  mainDrug?: string;
}
const INITIAL_STATE: IPageParams<IMedDraDictionariesData> = initialPageParam;

const MedDraDictionary = types
  .model('MedDraDictionary', {
    medDraType: types.frozen<EMedDraType>(),
    data: types.frozen<IPageParams<IMedDraDictionariesData>>(INITIAL_STATE),
    page: types.optional(types.number, 0),
    request: types.optional(Request, {}),
    ptType: types.optional(
      types.enumeration<MedDRAPtTypes>('MedDRAPtTypes', Object.values(MedDRAPtTypes)),
      MedDRAPtTypes.Default
    ),
    lastRequestParam: types.optional(types.string, '')
  })
  .actions((self) => ({
    load: flow(function* (data: IParamsPagination) {
      const {medDRASelectedFilters} = getRootStore(self).medDRAFiltersStore;
      const searchValues: IMedDRASearchValuesModel =
        getRootStore(self).medDRAFiltersStore.medDRASearchValues;
      const medDRASelectedParams: {[key: string]: string} =
        getRootStore(self).medDRAFiltersStore.medDRASelectedFilters.selectedParamsWithSemicolons;
      const mainDrug: string = getRootStore(self).studyDrugFilterStore.getMainDrugName;
      // eslint-disable-next-line no-unused-vars
      const {[self.medDraType]: thisTypeNoSend, ...medDRAQueryParams} = medDRASelectedParams;

      self.page = data.page || 0;

      const params: IMeddraDictParams = {
        page: self.page,
        dictType: MedDRADictType[self.medDraType],
        filter: searchValues[self.medDraType],
        ptType: self.medDraType === EMedDraType.PT ? self.ptType : '',
        ...medDRAQueryParams
      };

      if (mainDrug) {
        params.mainDrug = mainDrug;
      }

      const response: IPageParams<IMedDraDictionariesData> = yield self.request.send(
        getApiUrl(ApiVersions.v11, 'meddra.dict'),
        {
          method: 'get',
          params
        }
      );
      if (response) {
        const {content, ...pagination} = response;
        const updatedContent = self.page === 0 ? content : [...self.data.content, ...content];

        self.data = {
          content: getSelectedData({
            medDRASelectedFilters: medDRASelectedFilters[self.medDraType],
            medDraDictionaryData: updatedContent
          }),
          ...pagination
        };
      }
    })
  }))
  .actions((self) => {
    return {
      afterCreate() {
        const searchQuery = debounce((patch: IJsonPatch) => {
          if (patch.path === `/${self.medDraType}`) {
            self.load({});
          }
        }, 200);

        const selfFiltersQuery = debounce(() => {
          self.load({});
        }, 10);

        onPatch(getRootStore(self).medDRAFiltersStore.medDRASearchValues, searchQuery);
        onPatch(getRootStore(self).medDRAFiltersStore.medDRASelectedFilters, selfFiltersQuery);
      },
      setPtType(type: MedDRAPtTypes) {
        self.ptType = type;
        self.load({});
      }
    };
  })
  .views((self) => ({
    get isFetching() {
      return self.request.isPending;
    },
    get fetchDictionaryData() {
      return self.load;
    },
    get getPtTypeOptions(): ISelectOptions[] {
      return [
        {
          dataKey: MedDRAPtTypes.Broad,
          name: 'Broad',
          isVisible: self.ptType === MedDRAPtTypes.Broad || self.ptType === MedDRAPtTypes.Default,
          disableCheckbox: self.ptType === MedDRAPtTypes.Broad
        },
        {
          dataKey: MedDRAPtTypes.Narrow,
          name: 'Narrow',
          isVisible: self.ptType === MedDRAPtTypes.Narrow || self.ptType === MedDRAPtTypes.Default,
          disableCheckbox: self.ptType === MedDRAPtTypes.Narrow
        }
      ];
    }
  }));
export interface IMedDraDictionaryModel extends Instance<typeof MedDraDictionary> {}

export {MedDraDictionary};
