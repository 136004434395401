import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useMainParams} from '@services/hooksParams/useMainParams';

import ResearchPage from './ResearchPage';

const ResearchPageContainer = () => {
  const {reportsStore, studyDrugFilterStore} = useStore();
  const {getShowWidgets: showWidgets} = studyDrugFilterStore;

  const params = useMainParams();

  useEffect(() => {
    reportsStore.loadBoxPlotData(params);
    reportsStore.loadReportByPt(params);
  }, [reportsStore, params]);

  return <ResearchPage showWidgets={showWidgets} />;
};

export default observer(ResearchPageContainer);
