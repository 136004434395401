import React from 'react';

export const LogoSmallIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
    <g fillRule="nonzero" fill="none">
      <path
        d="M9.187 19.367c-1.12 1.106-3.46 2.993-2.747 5.573.713 2.58 2.88 3.473 4.42 3.38 1.547-.087 2.553-.767 5.187-3.36 2.633-2.593 6.08-5.627 7.193-7.493 1.113-1.867.14-3.874-.367-4.48-.5-.607-1.08-.414-1.08-.414s-6.353 6.514-7.273 7.274c-2.133 1.766-5.333-.48-5.333-.48z"
        fill="#FFF"
      />
      <path
        d="M25.067 3.367S21.16.207 14.987.727C6.893 1.4 1.793 8.653.953 11.733c-.84 3.08-.413 6.547 1.16 10.574 1.574 4.026 6.347 6.026 6.347 6.026s-2.153-3-2.153-4.106c0-2.56 2.886-5.127 2.886-5.127s-1.52-1.747-1.106-4.467c.3-2.013 9.84-10.506 9.84-10.506s1.98-1.04 3.253-.84c1.267.193 3.887.08 3.887.08z"
        fill="#FFF"
      />
      <path
        d="m15.78 19.833 6.593-7.446a4.497 4.497 0 0 0-6.36-.04L8.607 19.66c1.746 1.767 5.406 1.92 7.173.173z"
        fill="#FFF"
      />
      <path
        d="m22.373 12.387 1.554-1.534a4.497 4.497 0 1 0-6.32-6.4l-8.38 8.28a4.497 4.497 0 0 0-.04 6.36l6.826-6.746a4.497 4.497 0 0 1 6.36.04z"
        fill="#2B3977"
      />
      <path
        d="M8.78 19.427a5.08 5.08 0 0 1 .04-7.174l8.38-8.28a5.028 5.028 0 0 1 3.567-1.466h.033a5.03 5.03 0 0 1 3.58 1.506c1.987 2.014 2 5.227.033 7.174l-1.573 1.56-.807-.814 1.574-1.56c1.52-1.5 1.5-3.986-.047-5.546a3.902 3.902 0 0 0-2.767-1.167 3.884 3.884 0 0 0-2.78 1.133l-8.386 8.274a3.925 3.925 0 0 0-.034 5.546l-.813.814z"
        fill="#FFF"
      />
      <path
        d="M15.76 1.153c8.073 0 14.613 6.54 14.613 14.614 0 8.073-6.54 14.613-14.613 14.613S1.147 23.833 1.147 15.76 7.687 1.153 15.76 1.153m0-1.153C7.067 0 0 7.073 0 15.76s7.073 15.76 15.76 15.76 15.76-7.067 15.76-15.76S24.453 0 15.76 0z"
        fill="#FFF"
      />
      <path
        d="m10.567 18.593-.86-.873 5.946-5.887a5.086 5.086 0 0 1 3.594-1.48h.033a5.066 5.066 0 0 1 3.607 1.52l-.874.867a3.844 3.844 0 0 0-2.74-1.153 3.866 3.866 0 0 0-2.753 1.12l-5.953 5.886z"
        fill="#2B3977"
      />
      <path
        d="M10.8 28.94c-1.32 0-2.64-.507-3.64-1.52a5.062 5.062 0 0 1-1.473-3.627 5.066 5.066 0 0 1 1.52-3.606l3.033-2.994.413.627c.427.647 1.307 1.127 2.247 1.227.547.06 1.593.026 2.453-.82l7.04-6.7.427.426a5.128 5.128 0 0 1-.04 7.234l-8.387 8.28A5.105 5.105 0 0 1 10.8 28.94zm-.707-9.873-2.026 2a3.844 3.844 0 0 0-1.154 2.74 3.866 3.866 0 0 0 1.12 2.753 3.891 3.891 0 0 0 5.494.033l8.38-8.28a3.888 3.888 0 0 0 .42-5.033L16.2 19.107c-.893.886-2.12 1.3-3.44 1.16-1.027-.107-1.993-.554-2.667-1.2z"
        fill="#2B3977"
      />
      <path
        d="M22.327 13.28s-.187-.26-.374-.487c-.22-.266-.413-.453-.413-.453l1.093-1.013.807.82-1.113 1.133z"
        fill="#FFF"
      />
    </g>
  </svg>
);
