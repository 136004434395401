import {
  colorGray,
  colorGray250,
  colorGrayOpacity,
  colorPurple600,
  colorWhite,
  tooltipSettings
} from '@services/constants/chart.constants';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {integerWithSpaces} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';

const defaultSeriesOptions: echarts.EChartOption.SeriesBar & {
  showBackground: boolean;
  backgroundStyle: object;
} = {
  type: 'bar',
  data: [],
  barMinHeight: 10,
  itemStyle: {
    color: colorPurple600
  },
  barWidth: 30,
  barMaxWidth: 30,
  showBackground: true,
  backgroundStyle: {
    color: colorGrayOpacity,
    opacity: 0.5
  }
};

export const DEFAULT_LEGEND_OPTIONS: echarts.EChartOption.Legend = {
  show: false,
  orient: 'horizontal',
  bottom: 0,
  data: [],
  icon: 'circle',
  selectedMode: false
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  show: true,
  trigger: 'item',
  axisPointer: {
    type: 'shadow'
  },
  position: 'top',
  formatter: (params) => formatterTooltip({params}),
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid = {
  left: 10,
  bottom: 60,
  top: 15,
  right: 20,
  containLabel: true
};

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  type: 'value',
  boundaryGap: [0, '11%'],
  axisLine: {
    // bottom line x
    show: true,
    lineStyle: {
      color: colorGray,
      opacity: 0.5
    }
  },
  axisTick: {
    show: false
  },
  splitArea: {
    show: false,
    areaStyle: {
      color: [colorGrayOpacity, colorWhite],
      opacity: 0.5
    }
  },
  splitLine: {
    show: false
  },
  interval: 4500,
  splitNumber: 4,
  axisLabel: {
    align: 'right'
  }
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis[] = [
  {
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: colorGray,
        opacity: 0.5
      }
    },
    axisTick: {
      show: false
    },
    axisPointer: {
      show: false
    }
  },
  {
    zlevel: 2,
    inverse: true,
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    data: [], // data of counts: number[]
    axisLabel: {
      inside: true,
      interval: 0,
      align: 'right',
      verticalAlign: 'middle',
      lineHeight: 20,
      fontSize: 13,
      formatter: (value: string) => {
        return `{a|${integerWithSpaces(+value)}}`;
      },
      rich: {
        a: {
          color: colorGray250,
          lineHeight: 12
        }
      }
    }
  }
];

export const DEFAULT_SERIES_OPTIONS: echarts.EChartOption.SeriesBar[] = [
  {
    ...defaultSeriesOptions
  },
  {
    ...defaultSeriesOptions
  },
  {
    ...defaultSeriesOptions
  }
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  legend: DEFAULT_LEGEND_OPTIONS,
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  series: DEFAULT_SERIES_OPTIONS
};
