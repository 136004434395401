import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';
import {Instance, types} from 'mobx-state-tree';

const MedDRASearchValues = types
  .model('MedDRASearchValuesStore', {
    bodySystems: types.optional(types.string, ''),
    hlgt: types.optional(types.string, ''),
    hlt: types.optional(types.string, ''),
    pt: types.optional(types.string, ''),
    smq: types.optional(types.string, '')
  })
  .actions((self) => ({
    handleSearchValue(filterName: EMedDraType, searchValue: string) {
      self[filterName] = searchValue;
    }
  }));

export interface IMedDRASearchValuesModel extends Instance<typeof MedDRASearchValues> {}
export {MedDRASearchValues};
