import {flow, Instance, types} from 'mobx-state-tree';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {Store} from '@models/base/Store';
import {Request} from '@models/base/Request';
import {formatDate, toISOString} from '@services/utils/Date.utils';
import {GlobalDates} from '@models/GlobalFilters/GlobalFilters.types';

const GlobalDateRangeStore = types.compose(
  Store,
  types
    .model('GlobalDateRangeStore', {
      dateFrom: types.optional(types.string, '2015-01'),
      dateTo: types.optional(types.string, '2023-01'),
      request: types.optional(Request, {})
    })
    .actions((self) => {
      return {
        loadDateRange: flow(function* () {
          const response = yield self.request.send(getApiUrl(ApiVersions.v11, 'case.range'), {
            method: 'get'
          });

          if (response) {
            self.dateFrom = toISOString(new Date(response.dateFrom));
            self.dateTo = toISOString(new Date(response.dateTo));
          }
        })
      };
    })
    .actions((self) => {
      return {
        afterCreate() {
          self.loadDateRange();
        }
      };
    })
    .views((self) => ({
      get getCumulativeDates(): GlobalDates {
        return {
          dateFrom: formatDate(new Date(self.dateFrom), 'yyyy-MM'),
          dateTo: formatDate(new Date(self.dateTo), 'yyyy-MM')
        };
      }
    }))
);

export interface IGlobalDateRangeStore extends Instance<typeof GlobalDateRangeStore> {}

export {GlobalDateRangeStore};
