import echarts from 'echarts/lib/echarts';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {
  colorGray200,
  colorGray300,
  colorPurple100,
  colorPurple600,
  tooltipSettings
} from '@services/constants/chart.constants';
import {SeriesScatterType} from '@models/Chart/chart.types';

const xAxisSettings = {
  splitAreaOpacity: 0.1,
  splitAreaColor: [colorPurple600, colorPurple100],
  splitLineColor: colorGray200,
  axisLineColor: colorGray300,
  zlevel: 2
};

const yAxisSettings = {
  splitAreaColor: [colorPurple100],
  offset: 100
};

const axisPointerSettings: echarts.EChartOption.AxisPointer = {
  show: true,
  type: 'line',
  lineStyle: {
    width: tooltipSettings.axisPointerLineStyleWidth,
    type: 'dashed',
    color: tooltipSettings.axisPointerLineStyleColor
  }
};

const seriesSettings = {
  symbolSize: (val: [number, number, number]) => {
    return val[2] !== 0 ? 8 : 0;
  },
  itemOpacity: 1,
  animationDelay: (idx: number) => idx * 5,
  itemColor: colorPurple600,
  itemStyleColor: colorPurple600
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  position: 'top',
  trigger: 'item',
  formatter: (params) => formatterTooltip({params}),
  axisPointer: {
    type: 'cross',
    label: {
      show: false
    }
  },
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid = {
  left: 10,
  bottom: 15,
  top: 15,
  right: 20,
  containLabel: true
};

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  type: 'category',
  data: [],
  boundaryGap: true,
  splitArea: {
    show: true,
    areaStyle: {
      color: xAxisSettings.splitAreaColor,
      opacity: xAxisSettings.splitAreaOpacity
    }
  },
  splitLine: {
    // vertical
    show: false,
    lineStyle: {
      color: xAxisSettings.splitLineColor,
      type: 'dashed'
    }
  },
  axisTick: {
    show: false
  },
  axisLine: {
    // bottom line x
    show: false,
    lineStyle: {
      color: colorGray200
    }
  },
  axisPointer: axisPointerSettings
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis = {
  type: 'category',
  data: [],
  boundaryGap: true,
  splitArea: {
    show: true,
    areaStyle: {
      color: yAxisSettings.splitAreaColor
    }
  },
  splitLine: {
    show: true
  },
  axisLine: {
    show: false,
    lineStyle: {
      color: colorGray200
    }
  },
  axisTick: {
    show: false
  },
  axisPointer: {
    show: false
  }
};

export const DEFAULT_SERIES_OPTIONS: SeriesScatterType[] = [
  {
    name: 'Drugs',
    type: 'scatter',
    symbolSize: seriesSettings.symbolSize,
    data: [],
    animationDelay: seriesSettings.animationDelay,
    hoverAnimation: true, // hover
    cursor: 'pointer',
    large: true,
    itemStyle: {
      color: seriesSettings.itemColor,
      opacity: seriesSettings.itemOpacity
    },
    markLine: {
      silent: true,
      symbolSize: 1,
      lineStyle: {
        color: colorPurple600,
        width: 2,
        type: 'solid'
      },
      data: []
    }
  }
];

const DEFAULT_AXIS_POINTER_OPTIONS: echarts.EChartOption.AxisPointer = {
  link: [{xAxisIndex: 'all'}]
};

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  // @ts-ignore
  series: DEFAULT_SERIES_OPTIONS,
  axisPointer: DEFAULT_AXIS_POINTER_OPTIONS
};
