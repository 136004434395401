import {types, flow, cast, Instance} from 'mobx-state-tree';
import {Request} from '@models/base/Request';
import {Store} from '@services/models/base/Store';
import {Modal} from '@models/Modal';
import {SortDirection} from 'react-virtualized';
import {SortDirectionType} from 'react-virtualized/dist/es/Table';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {sortByProperty} from '@services/utils/UserFilters.utils';
import {CheckedDataType, ISelectOptions} from '@services/models/Dropdown/Dropdown.types';

import {
  tagFilter,
  transformData,
  searchedDiseases,
  objToSelectOptions,
  filteredByWorkStatus,
  filteredDiseasesDataByName
} from '../utils/Filter.utils';
import {IDemoListItem, ReviewStatus} from '../models/Demo.types';
import jsonData from '../mocks/data_for_front_2299cases_07_12_2022_cut2.json';

const EntitiesLabels: Record<string, string> = {
  DRUG: 'Drug',
  DEMO_NAME: 'Demo',
  DEMO_GEO: 'Demo',
  PT_DISORDER: 'Medical History',
  PT_DISORDER_AE: 'Adverse Event',
  PT_PROCEDURE: 'Test',
  PT_INVESTIGATION: 'Test',
  PT_INJURY: 'Medical History',
  PT_SOCIAL_CIRC: 'Demo',
  PT_PRODUCT_ISSUE: 'Medical History',
  PT_PREG_COND: 'Demo',
  PT_NEOPLASM: 'Medical History'
};

const DemoStore = types.compose(
  Store,
  types
    .model('DemoStore', {
      modal: types.optional(Modal, {}),
      tagList: types.frozen<ISelectOptions[]>([]),
      searchValue: types.optional(types.string, ''),
      ptReviewModal: types.optional(Modal, {}),
      sortBy: types.optional(types.string, 'rank'),
      literatureList: types.optional(types.array(types.frozen<IDemoListItem>()), []),
      literatureForReview: types.maybeNull(types.frozen<IDemoListItem>()),
      cashLiteratureList: types.optional(types.array(types.frozen<IDemoListItem>()), []),
      sortDirection: types.frozen<SortDirectionType>(SortDirection.ASC),
      workStatus: types.optional(
        types.enumeration('ReviewStatus', Object.values(ReviewStatus)),
        ReviewStatus.All
      ),
      request: types.optional(Request, {}),
      name: '',
      drugName: ''
    })
    .actions((self) => {
      return {
        load: flow(function* () {
          try {
            const response = yield self.request.send(
              getApiUrl(ApiVersions.v11, 'case.time.to.onset'),
              {
                method: 'get',
                responseType: 'json'
              },
              jsonData
            );

            let result = response;

            if (self.name) {
              self.workStatus = ReviewStatus.All;
              const filteredDiseases = filteredDiseasesDataByName(
                response,
                self.drugName,
                self.name
              );
              self.tagList = objToSelectOptions(filteredDiseases);
              result = transformData(filteredDiseases, self.drugName, self.name);

              self.literatureList = cast(result);
            } else {
              self.cashLiteratureList = cast(result);
            }
          } catch (error) {
            console.error('Failed to fetch diseases', error);
          }
        }),
        // search
        setListDiseasesSearch(searchValue: string) {
          self.searchValue = searchValue;
        },
        setSort({sortBy, sortDirection}: {sortBy: string; sortDirection: SortDirectionType}) {
          self.sortBy = cast(sortBy || self.sortBy);
          self.sortDirection = cast(sortDirection || self.sortDirection);
        },
        openDemoModal(name: string, drugName: string): void {
          self.name = name;
          self.drugName = drugName;
          this.load();
          self.modal.open();
        },
        closeDemoModal() {
          self.modal.close();
        },
        updatedDiseasesList(data: CheckedDataType) {
          const [key, checked] = data;

          self.tagList = self.tagList.map((item) => {
            return item.dataKey === key ? {...item, isVisible: checked} : item;
          });
        },
        selectAllDiseasesInList: (isVisible: boolean) => {
          self.tagList = self.tagList.map((item) => {
            return {
              ...item,
              isVisible
            };
          });
        },
        setWorkStatus(workStatus: ReviewStatus) {
          if (self.literatureForReview) {
            self.literatureForReview = {
              ...self.literatureForReview,
              workStatus
            };
          }
          self.workStatus = workStatus;
        },
        openPtReviewModal(pmid: string): void {
          const selectedItem = self.literatureList.find((el) => el.article_data.pmid === pmid);
          if (selectedItem) {
            const newParsedEntities = selectedItem.parsed_entites.filter((elem, index) => {
              return (
                index ===
                selectedItem.parsed_entites.findIndex((e) => {
                  return e.start_char === elem.start_char;
                })
              );
            });
            self.literatureForReview = {...selectedItem, parsed_entites: newParsedEntities};

            self.ptReviewModal.open();
          }
        },
        closePtReviewModal() {
          self.ptReviewModal.close();
        },
        removeEntitiesFromSelectedLiterature(index: number) {
          if (!self.literatureForReview) return;
          const start = self.literatureForReview.parsed_entites.slice(0, index) ?? [];
          const end = self.literatureForReview.parsed_entites.slice(index + 1) ?? [];

          self.literatureForReview = {
            ...self.literatureForReview,
            parsed_entites: [...start, ...end]
          };
        },
        setReviewedStatus(value: string) {
          if (self.literatureForReview)
            self.literatureForReview = {
              ...self.literatureForReview,
              workStatus: value
            };
        }
      };
    })
    .views((self) => ({
      get isFetching(): boolean {
        return self.request.isPending;
      },
      get selectedOptions(): ISelectOptions[] {
        return self.tagList;
      },
      get filteredDiseaseData(): IDemoListItem[] {
        const searchValue = searchedDiseases(self.literatureList, self.searchValue);
        const filterByTag = tagFilter(searchValue, self.tagList);
        const sortRank = [...filterByTag].sort(sortByProperty(self.sortBy, self.sortDirection));
        return filteredByWorkStatus(sortRank, self.workStatus);
      },
      get getSelectedForReviewLiterature() {
        return self.literatureForReview;
      },
      get getCurrentReviewStatus() {
        return self.literatureForReview?.workStatus ?? '';
      },
      get getStatusOptions() {
        return Object.values(ReviewStatus)
          .filter((el) => el !== 'All')
          .map((el) => ({
            dataKey: el,
            name: el,
            isVisible: true,
            disableCheckbox: false
          }));
      },
      get getEntitiesLabels() {
        return EntitiesLabels;
      }
    }))
);

export interface IDemoStore extends Instance<typeof DemoStore> {}

export {DemoStore};
