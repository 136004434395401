import {useStore} from '@services/hooks/useStore';
import {getUnknownFilterCount} from '@services/utils/UserFilters.utils';
import {observer} from 'mobx-react-lite';
import React from 'react';

import CasePropertiesControls from './CasePropertiesControls';

const CasePropertiesControlsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  return (
    <CasePropertiesControls
      unknownOutcomesCount={getUnknownFilterCount(caseStatData.outcomes)}
      unknownSeriousCount={getUnknownFilterCount(caseStatData.serious)}
    />
  );
};

export default observer(CasePropertiesControlsContainer);
