import {EMedDraType, IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import s from './FiltersList.module.css';
import FiltersList from './FiltersList';

const FiltersListContainer: React.FC<{}> = () => {
  const {
    medDRAFiltersStore: {medDRASelectedFilters, medDRASearchValues},
    medDRAFiltersStore
  } = useStore();
  const {isInEditMode, setIsInEditMode} = medDRASelectedFilters.updateObserver;

  const {setMedDRAFilter, clearMedDRAFilter} = medDRASelectedFilters;

  const {handleSearchValue} = medDRASearchValues;

  const {t} = useTranslation();

  return (
    <div className={s.filterListWrapper}>
      {Object.values(EMedDraType).map((medDraType, idx) => (
        <FiltersList
          filterListIdx={idx}
          key={idx}
          title={t(`medDRAFilters.${medDraType}`)}
          filterListData={medDRAFiltersStore[medDraType].data}
          isFetching={medDRAFiltersStore[medDraType].isFetching}
          loadData={({page}) => {
            medDRAFiltersStore[medDraType].fetchDictionaryData({page});
          }}
          page={medDRAFiltersStore[medDraType].page}
          selectedFilters={medDRASelectedFilters[medDraType]}
          selectFilter={(filterData: IMedDraDictionariesData[]) => {
            setMedDRAFilter(medDraType, filterData);
          }}
          clearFilter={(filterData: number | string) => {
            clearMedDRAFilter(medDraType, filterData);
          }}
          searchValue={medDRASearchValues[medDraType]}
          handleSearch={(value: string) => handleSearchValue(medDraType, value)}
          isListCollapsed={!isInEditMode}
          setListCollapsed={(value: boolean) => {
            setIsInEditMode(!value);
          }}
        />
      ))}
    </div>
  );
};

export default observer(FiltersListContainer);
