import {IDropdownFilterOptions} from '@models/Dropdown/DropdownFilter';
import {EMedDraType, IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {getFilterProp} from '@services/utils/Functions.utils';
import {Instance, types} from 'mobx-state-tree';
import {UpdateObserver} from '@models/UpdateObserver';

const MedDRASelectedFilters = types
  .model('MedDRASelectedFilters', {
    bodySystems: types.frozen<IMedDraDictionariesData[]>([]),
    hlgt: types.frozen<IMedDraDictionariesData[]>([]),
    hlt: types.frozen<IMedDraDictionariesData[]>([]),
    pt: types.frozen<IMedDraDictionariesData[]>([]),
    smq: types.frozen<IMedDraDictionariesData[]>([]),
    updateObserver: types.optional(UpdateObserver, {})
  })
  .actions((self) => ({
    setMedDRAFilter: (filterName: EMedDraType, filterData: IMedDraDictionariesData[]) => {
      self[filterName] = filterData.map(({name, code}) => ({
        name: name?.toLowerCase(),
        code
      }));
      self.updateObserver.isContentChanged = true;
    },
    clearMedDRAFilter: (filterName: EMedDraType, filterData: string | number) => {
      self[filterName] = self[filterName]?.filter(({name, code}) =>
        typeof filterData === 'string'
          ? name?.toLowerCase() !== filterData?.toLowerCase()
          : code !== filterData
      );
      self.updateObserver.isContentChanged = true;
    },
    clearAllMedDRAFilters: (filterName: EMedDraType) => {
      self[filterName] = [];
      self.updateObserver.isContentChanged = true;
    }
  }))
  .views((self) => ({
    get selectedParams(): {[key: string]: string} {
      return {
        ...getFilterProp(self.bodySystems, EMedDraType.BodySystems),
        ...getFilterProp(self.hlgt, EMedDraType.HLGT),
        ...getFilterProp(self.hlt, EMedDraType.HLT),
        ...getFilterProp(self.pt, EMedDraType.PT, true),
        ...getFilterProp(self.smq, EMedDraType.SMQ, true)
      };
    },
    get selectedParamsFullObject(): {[key: string]: string} {
      return {
        bodySystems: '',
        hlgt: '',
        pt: '',
        hlt: '',
        smq: '',
        ...getFilterProp(self.bodySystems, EMedDraType.BodySystems),
        ...getFilterProp(self.hlgt, EMedDraType.HLGT),
        ...getFilterProp(self.hlt, EMedDraType.HLT),
        ...getFilterProp(self.pt, EMedDraType.PT, true),
        ...getFilterProp(self.smq, EMedDraType.SMQ, true)
      };
    },
    /** it's currently only for get requests */
    get selectedParamsWithSemicolons(): {[key: string]: string} {
      return {
        ...getFilterProp(self.bodySystems, EMedDraType.BodySystems, true),
        ...getFilterProp(self.hlgt, EMedDraType.HLGT, true),
        ...getFilterProp(self.hlt, EMedDraType.HLT, true),
        ...getFilterProp(self.pt, EMedDraType.PT, true),
        ...getFilterProp(self.smq, EMedDraType.SMQ, true)
      };
    },
    get selectedMedDraFiltersChipsOptions(): IDropdownFilterOptions<EMedDraType>[] {
      return Object.values(EMedDraType).reduce<IDropdownFilterOptions<EMedDraType>[]>(
        (acc, filterName) => {
          acc.push({
            category: filterName,
            // TODO
            selectedFilters: self[filterName].map(({name}) => name)
          });
          return acc;
        },
        []
      );
    },
    get selectedMedDraFiltersCount(): number {
      return Object.values(EMedDraType).reduce<number>((acc, filterName) => {
        acc += self[filterName].length;
        return acc;
      }, 0);
    }
  }));

export interface IMedDRASelectedFiltersModel extends Instance<typeof MedDRASelectedFilters> {}
export {MedDRASelectedFilters};
