import {
  DrugsChartFormattedDataType,
  IAvailablePeriods,
  IDrugsInitialData,
  IDrugsLabels,
  IPeriodDates,
  IPeriodsDates
} from '@models/CaseDetail/CaseDetail.types';
import {SeriesMarkLineDataType} from '@models/Chart/chart.types';
import {
  getPeriodDates,
  isPeriodDatesExists,
  markLineDashedStyle
} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {getRangeDates, isDateExists, isDatesEqual} from '@services/utils/Date.utils';

export function getDrugsLabels(drugs: IDrugsInitialData[]): IDrugsLabels {
  if (!drugs.length) {
    return {
      'No results': false
    };
  }

  /** sort array with drugs - first empty data */
  const sortedDrugs = drugs.reduce<IDrugsInitialData[]>((acc, drug) => {
    if (drug.drugStartDate === null && drug.drugEndDate === null) {
      acc.unshift(drug);
    } else {
      acc.push(drug);
    }
    return acc;
  }, []);

  return sortedDrugs.reduce<IDrugsLabels>((acc, drug) => {
    acc[drug.medicinalProduct] = drug.drugStartDate === null && drug.drugEndDate === null;
    return acc;
  }, {});
}

export function getDrugsChartFormattedData({
  drugs,
  drugsLabels,
  startDate,
  endDate
}: {
  drugs: IDrugsInitialData[];
  drugsLabels: IDrugsLabels;
  startDate: Date;
  endDate: Date;
}): DrugsChartFormattedDataType {
  const drugsData: DrugsChartFormattedDataType = []; // x, y, value
  const timeDataInterval = getRangeDates({startDate, endDate});

  drugs.forEach((drug) => {
    const arrStartDate: [number, number, string] = [0, 0, '0'];
    const arrEndDate: [number, number, string] = [0, 0, '0'];
    const drugsLabelsNames: string[] = Object.keys(drugsLabels);

    const yIndex = drugsLabelsNames.findIndex((drugLabel) => drugLabel === drug.medicinalProduct);
    arrStartDate[1] = yIndex;
    arrEndDate[1] = yIndex;

    const {
      drugSeparateDosageNumb,
      drugIntervalDosageUnitNumb,
      drugIntervalDosageDefinition,
      activeSubstanceName
    } = drug;

    const definition =
      drugSeparateDosageNumb || drugIntervalDosageUnitNumb || drugIntervalDosageDefinition
        ? ''.concat(
            `Active Substance Name: ${activeSubstanceName || '-'},`,
            `Separate Dosage Numb: ${drugSeparateDosageNumb || '-'},`,
            `Interval Dosage Unit Numb: ${drugIntervalDosageUnitNumb || '-'},`,
            `Interval Dosage Definition: ${drugIntervalDosageDefinition || '-'}`
          )
        : 'No results';

    if (isDateExists(drug.drugStartDate)) {
      const {drugStartDate} = drug;
      arrStartDate[0] = timeDataInterval.findIndex((date) => {
        return isDatesEqual({firstDate: date, secondDate: new Date(drugStartDate)});
      });
      arrStartDate[2] = definition;
      drugsData.push(arrStartDate);
    }
    if (isDateExists(drug.drugEndDate)) {
      const {drugEndDate} = drug;
      arrEndDate[0] = timeDataInterval.findIndex((date) => {
        return isDatesEqual({firstDate: date, secondDate: new Date(drugEndDate)});
      });
      arrEndDate[2] = definition;
      drugsData.push(arrEndDate);
    }
  });
  return drugsData;
}

function getDrugsPeriods({
  drugs,
  startDate: startDateDefault,
  endDate: endDateDefault
}: {
  drugs: IDrugsInitialData[];
  startDate: Date;
  endDate: Date;
}): IPeriodDates[] {
  const drugPeriods: IAvailablePeriods = drugs.reduce<IAvailablePeriods>((acc, drug) => {
    acc[drug.medicinalProduct] = {
      dateStart:
        acc[drug.medicinalProduct] && acc[drug.medicinalProduct].dateStart
          ? acc[drug.medicinalProduct].dateStart
          : drug.drugStartDate,
      dateEnd:
        acc[drug.medicinalProduct] && acc[drug.medicinalProduct].dateEnd
          ? acc[drug.medicinalProduct] && acc[drug.medicinalProduct].dateEnd
          : drug.drugEndDate
    };
    return acc;
  }, {});

  const periods: IPeriodsDates[] = getPeriodDates({
    periods: drugPeriods,
    startDateDefault,
    endDateDefault
  });
  return periods.filter(isPeriodDatesExists);
}

export function getMarkLineDrugsData({
  drugs,
  drugsLabels,
  startDate,
  endDate
}: {
  drugs: IDrugsInitialData[];
  drugsLabels: IDrugsLabels;
  startDate: Date;
  endDate: Date;
}): SeriesMarkLineDataType {
  const markLineData: SeriesMarkLineDataType = [];
  const periods = getDrugsPeriods({drugs, startDate, endDate});
  const timeDataInterval = getRangeDates({startDate, endDate});

  periods.forEach((period) => {
    const dateStartIndex = timeDataInterval.findIndex((date) =>
      isDatesEqual({firstDate: date, secondDate: period.dateStart})
    );
    const dateEndIndex = timeDataInterval.findIndex((date) =>
      isDatesEqual({firstDate: date, secondDate: period.dateEnd})
    );
    const yIndex = Object.keys(drugsLabels).findIndex((drugLabel) => drugLabel === period.name);

    for (let x = dateStartIndex; x < dateEndIndex; x++) {
      markLineData.push([
        {coord: [x, yIndex], ...(!period.isFullPeriodExists && markLineDashedStyle)},
        {coord: [x + 1, yIndex]}
      ]);
    }
  });
  return markLineData;
}
