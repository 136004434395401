import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import Demographics from './Demographics';

interface Props {
  withoutScroll?: boolean;
}

const DemographicsContainer: React.FC<Props> = ({withoutScroll}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {caseStatData} = caseStatStore;
  const {
    ageGroups: selectedAgeGroups,
    countries: selectedCountries,
    setUserFilter,
    clearUserFilter
  } = selectedFiltersStore;

  return (
    <Demographics
      ageGroupsData={caseStatData.ageGroups}
      selectedAgeGroups={selectedAgeGroups}
      countriesData={caseStatData.countries}
      selectedCountries={selectedCountries}
      setUserFilter={setUserFilter}
      clearUserFilter={clearUserFilter}
      withoutScroll={withoutScroll}
    />
  );
};

export default observer(DemographicsContainer);
