import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {Store} from '@models/base/Store';

import {Session} from './Session';

const AppStore = types.compose(
  Store,
  types
    .model('AppStore', {
      session: types.optional(Session, {})
    })
    .actions((self) => {
      const init = () => {
        self.session.init();
      };
      return {
        init
      };
    })
);

export interface IAppStore extends Instance<typeof AppStore> {}

export interface IAppStoreSnapshotIn extends SnapshotIn<typeof AppStore> {}

export {AppStore};
