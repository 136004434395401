import React from 'react';
import {useTranslation} from 'react-i18next';
import {FilterGroupTitle} from 'src/components';

import s from '../SeverityListedness.module.css';

interface Props {
  unknownSeverityCount: number;
  unknownListednessCount: number;
}

const SeverityListednessControls: React.FC<Props> = ({
  unknownSeverityCount,
  unknownListednessCount
}) => {
  const {t} = useTranslation();

  return (
    <div className={s.controlsContainer}>
      <FilterGroupTitle
        className={s.gridFirst}
        title="Severity (CTC AE grade)"
        subtitle={t('unknownNCases', {n: unknownSeverityCount})}
      />
      {/*<FilterGroupTitle*/}
      {/*  className={s.gridLast}*/}
      {/*  title="Listedness"*/}
      {/*  subtitle={t('unknownNCases', {n: unknownListednessCount})}*/}
      {/*/>*/}
    </div>
  );
};

export default SeverityListednessControls;
