import {types, flow, Instance} from 'mobx-state-tree';
import {keycloak} from '@services/keycloak';
import {storage} from '@services/utils/storage.utils';

const Session = types
  .model('Session', {
    isAuthorized: false
  })
  .actions((self) => {
    const actions = {
      init: flow(function* () {
        try {
          const authenticated = yield keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
          });

          // console.debug('keycloak:init', authenticated, keycloak.token);

          if (authenticated) {
            self.isAuthorized = authenticated;
          } else {
            storage.clearStorage();
            keycloak.login();
          }
        } catch (error) {
          storage.clearStorage();
          self.isAuthorized = false;
          keycloak.login();
        }
      }),
      login() {
        // console.debug('keycloak:login', keycloak.authenticated, keycloak.token);
        storage.clearStorage();
        keycloak.login();
      },
      logout() {
        /** redirect to logout since we have no home page */
        // console.debug('keycloak:logout', keycloak.authenticated, keycloak.token);
        storage.clearStorage();
        keycloak.logout();
      },
      updateToken: flow(function* () {
        try {
          const refreshed = yield keycloak.updateToken(15);
          self.isAuthorized = refreshed;

          if (refreshed) {
            console.debug('Token was successfully refreshed');
          } else {
            console.debug('Token is still valid');
          }
        } catch (error) {
          self.isAuthorized = false;
        }
      })
    };
    return actions;
  })
  .views(() => ({}));

export interface ISessionModel extends Instance<typeof Session> {}

export {Session};
