import echarts from 'echarts/lib/echarts';
import {SeriesOptionsType} from '@models/Chart/chart.types';

import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_GRID_OPTIONS,
  DEFAULT_SERIES_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
  DEFAULT_DATA_ZOOM_OPTIONS
} from '../../../scenes/Detail/components/DetailCaseCharts/chart.constants';

export class EChartsUtils {
  static getChartOptions = (): echarts.EChartOption => DEFAULT_CHART_OPTIONS;

  static getXAxisOptions = (): echarts.EChartOption.XAxis[] => DEFAULT_XAXIS_OPTIONS;

  static getYAxisOptions = (): echarts.EChartOption.YAxis[] => DEFAULT_YAXIS_OPTIONS;

  static getSeriesOptions = (): SeriesOptionsType => DEFAULT_SERIES_OPTIONS;

  static getGridOptions = (): echarts.EChartOption.Grid[] => DEFAULT_GRID_OPTIONS;

  static getDataZoomOptions = (): echarts.EChartOption.DataZoom[] => DEFAULT_DATA_ZOOM_OPTIONS;
}
