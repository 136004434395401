import {useStore} from '@services/hooks/useStore';
import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PaperWidget} from 'src/components/PaperWidget';
import {FilterTitle} from 'src/components/UIKit';
import {ClickOutside} from '@components/ClickOutside';
import {observer} from 'mobx-react-lite';

import {FiltersListContainer} from './components/FiltersList';
import s from './MedDRAFiltersCard.module.css';

const MedDRAFiltersCard: React.FC<{}> = () => {
  const {t} = useTranslation();

  const {
    medDRAFiltersStore: {fetchDictionaries, medDRASelectedFilters}
  } = useStore();

  const {updateObserver} = medDRASelectedFilters;
  const {isInEditMode, setIsInEditMode, resetObservers} = updateObserver;

  useEffect(() => {
    fetchDictionaries();
  }, [fetchDictionaries]);

  const onDestroy = useCallback(
    (event) => {
      resetObservers();
    },
    [resetObservers]
  );

  useEffect(() => {
    window.addEventListener('pagehide', onDestroy, false);
    return () => {
      window.removeEventListener('pagehide', onDestroy);
    };
  }, [onDestroy]);

  const handleClickOutside = useCallback(() => {
    if (isInEditMode) {
      setIsInEditMode(false);
    }
  }, [isInEditMode, setIsInEditMode]);

  return (
    <PaperWidget
      wrapperClassName={s.paperWidgetWrapper}
      contentClassName={s.paperWidgetContent}
      button
      onClick={() => {
        setIsInEditMode(!isInEditMode);
      }}
      buttonText={isInEditMode ? 'Confirm' : 'Edit'}
    >
      <FilterTitle title={t('medDRAFilters.title')} />
      <ClickOutside handleClickOutside={handleClickOutside}>
        <FiltersListContainer />
      </ClickOutside>
    </PaperWidget>
  );
};

export default observer(MedDRAFiltersCard);
