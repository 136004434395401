import React from 'react';

export const ArrowLeftIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 9L12 15 6 9"
      transform="rotate(90 12 12)"
    />
  </svg>
);
