import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {BarChartHorizontalUtils} from '@services/utils/Charts/BarChartHorizontal.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

type SourceType = [string | number, string | number, string][];

export function getChartOptions({options}: {options: IFilterDataItem[]}): echarts.EChartOption {
  const chartOptions = BarChartHorizontalUtils.getChartOptions();
  const {series, ...chartOptionsWithoutSeries} = chartOptions;
  const xAxisOptions = BarChartHorizontalUtils.getXAxisOptions();
  const yAxisOptions = BarChartHorizontalUtils.getYAxisOptions();
  const seriesOptions = BarChartHorizontalUtils.getSeriesOptions();
  const tooltipOptions = BarChartHorizontalUtils.getTooltipOptions();
  const gridOptions = BarChartHorizontalUtils.getGridOptions();

  const datasetSource: SourceType = options.reduce<SourceType>((acc, {name, count, percent}) => {
    if (!acc[0]) {
      acc.push(['score', 'amount', 'product']);
    }
    acc.push([percent, count, name]);
    return acc;
  }, []);
  const countData: number[] = options.map(({count}) => count).reverse();
  const maxCount: number = Math.max(...options.map((rc) => rc.count));

  const getInterval = () => {
    const DEFAULT_SPLIT_NUMBER = 3;
    return Math.floor(maxCount / DEFAULT_SPLIT_NUMBER / 100) * 100 + 100;
  };

  const {data, ...defaultSeriesOptions} = seriesOptions[0];

  const ageGroupSeries: echarts.EChartOption.SeriesBar[] = [
    {
      ...defaultSeriesOptions,
      barWidth: 'auto',
      encode: {
        // Map the "amount" column to X axis.
        x: 'amount',
        // Map the "product" column to Y axis
        y: 'product'
      }
    }
  ];

  const chartModifiedOptions: echarts.EChartOption = {
    xAxis: {
      ...xAxisOptions,
      interval: getInterval()
    },
    yAxis: [{...yAxisOptions[0]}, {...yAxisOptions[1], data: countData}],
    dataset: {
      source: datasetSource
    },
    tooltip: {
      ...tooltipOptions,
      formatter: (params) => formatterTooltip({params, isDataset: true, width: '60px'})
    },
    grid: {
      ...gridOptions,
      bottom: 15
    },
    series: ageGroupSeries
  };

  return merge({}, chartOptionsWithoutSeries, chartModifiedOptions);
}
