import {getBaseUrl} from '@config/api.config';

export enum ApiVersions {
  v1 = 'v1/',
  v11 = 'v1.1/'
}

export function getApiUrl(version: ApiVersions, endpointUrl: string) {
  const baseUrl = getBaseUrl();
  return baseUrl + version + endpointUrl;
}

export const hasToken = (): boolean => {
  return !!localStorage.getItem('token');
};

// TODO: remove all depricated auth logic
export const setToken = (): void => {
  localStorage.setItem('token', 'authorized');
};

export const removeToken = (): void => {
  localStorage.removeItem('token');
};
