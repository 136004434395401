import echarts from 'echarts/lib/echarts';

import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
  DEFAULT_SERIES_OPTIONS,
  DEFAULT_GRID_OPTIONS
} from '../../constants/barChartVertical.constants';

export class BarChartVerticalUtils {
  static getChartOptions = (): echarts.EChartOption => DEFAULT_CHART_OPTIONS;

  static getXAxisOptions = (): echarts.EChartOption.XAxis => DEFAULT_XAXIS_OPTIONS;

  static getYAxisOptions = (): echarts.EChartOption.YAxis => DEFAULT_YAXIS_OPTIONS;

  static getSeriesOptions = (): echarts.EChartOption.SeriesBar[] => DEFAULT_SERIES_OPTIONS;

  static getGridOptions = (): echarts.EChartOption.Grid => DEFAULT_GRID_OPTIONS;
}
