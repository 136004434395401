import {
  EventsChartFormattedDataType,
  IAvailablePeriods,
  IEventsInitialData,
  IEventsLabels,
  IPeriodDates,
  IPeriodsDates
} from '@models/CaseDetail/CaseDetail.types';
import {SeriesMarkLineDataType} from '@models/Chart/chart.types';
import {
  getPeriodDates,
  isPeriodDatesExists,
  markLineDashedStyle
} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {getRangeDates, isDateExists, isDatesEqual} from '@services/utils/Date.utils';

export function getEventsLabels(events: IEventsInitialData[]): IEventsLabels {
  if (!events.length) {
    return {
      'No results': false
    };
  }

  /** sort array with events - first empty data */
  const sortedDrugs = events.reduce<IEventsInitialData[]>((acc, event) => {
    if (event.startDate === null && event.endDate === null) {
      acc.unshift(event);
    } else {
      acc.push(event);
    }
    return acc;
  }, []);

  return sortedDrugs.reduce<IEventsLabels>((acc, drug) => {
    acc[drug.reactionName] = drug.startDate === null && drug.endDate === null;
    return acc;
  }, {});
}

export function getEventsChartFormattedData({
  events,
  eventsLabels,
  startDate: startDateDefault,
  endDate: endDateDefault
}: {
  events: IEventsInitialData[];
  eventsLabels: IEventsLabels;
  startDate: Date;
  endDate: Date;
}): EventsChartFormattedDataType {
  const eventsData: EventsChartFormattedDataType = []; // x, y, value, serious
  const timeDataInterval = getRangeDates({startDate: startDateDefault, endDate: endDateDefault});

  events.forEach((event) => {
    const arrStartDate: [number, number, string, boolean] = [0, 0, '0', false];
    const arrEndDate: [number, number, string, boolean] = [0, 0, '0', false];
    const eventsLabelsNames: string[] = Object.keys(eventsLabels);

    const yIndex = eventsLabelsNames.findIndex((eventLabel) => eventLabel === event.reactionName);
    arrStartDate[1] = yIndex;
    arrEndDate[1] = yIndex;

    if (isDateExists(event.startDate)) {
      const {startDate, outcome} = event;
      arrStartDate[0] = timeDataInterval.findIndex((date) => {
        return isDatesEqual({firstDate: date, secondDate: new Date(startDate)});
      });
      arrStartDate[2] = outcome || 'No results';
      eventsData.push(arrStartDate);
    }
    if (isDateExists(event.endDate)) {
      const {endDate, outcome} = event;
      arrEndDate[0] = timeDataInterval.findIndex((date) => {
        return isDatesEqual({firstDate: date, secondDate: new Date(endDate)});
      });
      arrEndDate[2] = outcome || 'No results';
      eventsData.push(arrEndDate);
    }
  });
  return eventsData;
}

function getEventsPeriods({
  events,
  startDate: startDateDefault,
  endDate: endDateDefault
}: {
  events: IEventsInitialData[];
  startDate: Date;
  endDate: Date;
}): IPeriodDates[] {
  const eventsPeriods: IAvailablePeriods = events.reduce<IAvailablePeriods>((acc, event) => {
    acc[event.reactionName] = {
      dateStart:
        acc[event.reactionName] && acc[event.reactionName].dateStart
          ? acc[event.reactionName].dateStart
          : event.startDate,
      dateEnd:
        acc[event.reactionName] && acc[event.reactionName].dateEnd
          ? acc[event.reactionName] && acc[event.reactionName].dateEnd
          : event.endDate
    };
    return acc;
  }, {});

  const periods: IPeriodsDates[] = getPeriodDates({
    periods: eventsPeriods,
    startDateDefault,
    endDateDefault
  });
  return periods.filter(isPeriodDatesExists);
}

export function getMarkLineEventsData({
  events,
  eventsLabels,
  startDate,
  endDate
}: {
  events: IEventsInitialData[];
  eventsLabels: IEventsLabels;
  startDate: Date;
  endDate: Date;
}): SeriesMarkLineDataType {
  const markLineData: SeriesMarkLineDataType = [];
  const periods = getEventsPeriods({events, startDate, endDate});
  const timeDataInterval = getRangeDates({startDate, endDate});

  periods.forEach((period) => {
    const dateStartIndex = timeDataInterval.findIndex((date) =>
      isDatesEqual({firstDate: date, secondDate: period.dateStart})
    );
    const dateEndIndex = timeDataInterval.findIndex((date) =>
      isDatesEqual({firstDate: date, secondDate: period.dateEnd})
    );

    const yIndex = Object.keys(eventsLabels).findIndex((eventLabel) => eventLabel === period.name);

    let dateStartIdx = dateStartIndex;
    let dateEndIdx = dateEndIndex;

    // switch dates for case when date start is more then date end
    if (dateStartIndex > dateEndIndex) {
      dateStartIdx = dateEndIndex;
      dateEndIdx = dateStartIndex;
    }

    for (let x = dateStartIdx; x < dateEndIdx; x++) {
      markLineData.push([
        {coord: [x, yIndex], ...(!period.isFullPeriodExists && markLineDashedStyle)},
        {coord: [x + 1, yIndex]}
      ]);
    }
    /* soft way to generate markLine
      markLineData.push([
        {coord: [dateStartIndex, yIndex], ...(serious && markLineSeriousStyle)},
        {coord: [dateEndIndex, yIndex]}
      ]);
     */
  });
  return markLineData;
}
