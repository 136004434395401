import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';
import s from './ReportByAgeGroup.module.css';

interface Props {
  contentHeight?: number;
  className?: string;
}

const ReportByAgeGroupContainer: React.FC<Props> = ({className, contentHeight}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {ageGroups: selectedAgeGroups} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;
  const {t} = useTranslation();
  const data: IFilterDataItem[] = caseStatData.ageGroups;

  const emptyColors = {};

  const formattedOptions = data
    .filter(({name, code}) => {
      if (selectedAgeGroups.length) {
        return code ? selectedAgeGroups.findIndex(({code: _code}) => _code === code) > -1 : false;
      }
      return name;
    })
    .map((item) => ({...item, name: t(item.name?.toLowerCase())}))
    .reverse();

  const chartOptions = getChartOptions({options: formattedOptions});

  return (
    <Report
      title={t('reportByAgeGroup')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={emptyColors}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      leftOffset={false}
      contentHeight={contentHeight}
      wrapperClassName={cn(s.paperWidgetWrapper, className)}
    />
  );
};

export default observer(ReportByAgeGroupContainer);
