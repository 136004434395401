import React from 'react';
import cn from 'classnames';

import s from './Switcher.module.css';

interface Props<T> {
  statTypes: T[];
  statNames: string[];
  activeStatType: T;
  setActiveStatType: (statType: T) => void;
  disabled?: boolean;
}

function Switcher<T>({
  statTypes,
  statNames,
  activeStatType,
  setActiveStatType,
  disabled
}: Props<T>) {
  return (
    <div
      className={cn(s.switcherContainer, {
        [s.switcherStatTypeDisabled]: disabled
      })}
    >
      {statTypes.map((type, idx) => (
        <span
          key={idx}
          className={cn(s.switcherStatType, {
            [s.switcherStatTypeActive]: activeStatType === type
          })}
          onClick={() => {
            if (!disabled) setActiveStatType(type);
          }}
        >
          {statNames[idx] || type}
        </span>
      ))}
    </div>
  );
}

export default Switcher;
