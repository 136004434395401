import {IChartFiltersData, IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {DEFAULT_OFFSET} from '@services/constants/chart.constants';
import React, {useRef} from 'react';
import ReactEcharts from 'echarts-for-react';
import {AutoSizer} from 'react-virtualized';

import s from './CaseVolumeChart.module.css';
import {getChartOptions} from './chartOptions';

interface Props {
  chartData: IChartFiltersData;
  reportClasses: IFilterDataItem[];
}

const CaseVolumeChart: React.FC<Props> = ({chartData, reportClasses}) => {
  const ref = useRef<ReactEcharts>(null);

  return (
    <div className={s.caseVolumeChart}>
      <AutoSizer>
        {({width, height}) => (
          <ReactEcharts
            ref={ref}
            option={getChartOptions(chartData, reportClasses)}
            style={{width: width + DEFAULT_OFFSET, height}}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default CaseVolumeChart;
