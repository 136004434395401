import {IFilterDataItem, SelectedUserFilters} from '@models/CaseStat/CaseStat.types';
import {ISelectOptions} from '@models/Dropdown/Dropdown.types';
import {GlobalFiltersNames} from '@models/GlobalFilters/GlobalFilters.types';
import {mapToSeriousName} from '@services/utils/UserFilters.utils';
import {IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';
import html2canvas from 'html2canvas';
import {IDrug} from '@stores-mobx/StudyDrugFilterStore';

const identity = <T>(arg: T) => arg;

/**
 * pipe with any amount of functions in arguments
 *
 * pipe(fn1, fn2, fn3)
 *
 */
export const pipe = <T>(...fns: ((arg: T) => T)[]) =>
  fns.reduce((acc, curr) => (values: T) => curr(acc(values)), identity);

export const setToArray = <T>(s: Set<T>): T[] => {
  return Array.from(s);
};

export const integerWithSpaces = (numberValue: number): string => {
  return numberValue?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getFilterProp = (
  filterProps: (string | IMedDraDictionariesData)[],
  queryParam: SelectedUserFilters | string,
  useSemicolon = false
): {[key: string]: string} => {
  const separator = useSemicolon ? ';' : ',';
  let filterPropsData: string[] = filterProps.map((item) => {
    if (typeof item === 'string') {
      return item;
    }
    return item?.code ? item?.code?.toString() : item?.name;
  });

  if (filterProps.length) {
    if (queryParam === SelectedUserFilters.Seriousness) {
      filterPropsData = mapToSeriousName(filterPropsData);
    }
    return {
      [queryParam]: filterPropsData.join(separator)
    };
  }
  return {};
};

export const getGlobalFilterProp = (
  filterProps: ISelectOptions[],
  queryParam: GlobalFiltersNames
) => {
  const selectedFilters = filterProps.filter((f) => f.isVisible);
  if (selectedFilters.length) {
    return {
      [queryParam]: selectedFilters.map((f) => f.name).join(',')
    };
  }
  return {};
};

export const sumOfCases = ({
  chartData,
  selectedFilters
}: {
  chartData: IFilterDataItem[];
  selectedFilters: string[];
}): number => {
  return chartData.reduce((acc, {code = 0, count}) => {
    if (selectedFilters.length && selectedFilters.includes(`${code}`)) {
      acc += count;
    }
    if (!selectedFilters.length) {
      acc += count;
    }
    return acc;
  }, 0);
};

export const convertNameToCapitalize = (name: string): string => {
  return `${name.split('')[0].toUpperCase()}${name.slice(1)}`;
};

export const downloadPng = ({node, title}: {node: HTMLElement; title: string}): void => {
  html2canvas(node)
    .then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `${title}.png`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.error('Error', error);
    });
};

export const downloadBlob = (data: Blob, fileName: string): void => {
  const downloadUrl = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getBase64FromNode = async (node: HTMLElement | null): Promise<string | null> => {
  if (!node) {
    return null;
  }
  try {
    const canvas = await html2canvas(node);
    const pngStr: string = canvas.toDataURL();
    const split = pngStr.split(',');
    return split.length === 2 ? split[1] : null;
  } catch (e) {
    return null;
  }
};

export const parseHtmlToPng = ({
  node,
  setImageUrl
}: {
  node: HTMLElement;
  setImageUrl: (imageUrl: string) => void;
}): void => {
  html2canvas(node)
    .then((canvas) => {
      const imageUrl = canvas.toDataURL('image/png');
      setImageUrl(imageUrl);
    })
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      console.error('Error', error);
    });
};

export const cutName = (name: string): string => name?.split(' ')?.slice(0, 2)?.join(' ');

export const capitalizeFirstLetter = (name: string): string => {
  return name?.charAt(0)?.toUpperCase() + name?.toLowerCase()?.slice(1);
};

export const onlyUniqueForStringArray = (
  value: string | undefined,
  index: number,
  selfArray: (string | undefined)[]
) => {
  return selfArray.indexOf(value) === index;
};

export const filterSelectedClasses = (data: IDrug[], role: string): boolean => {
  return (
    data.filter((el) => el.drugRole === role).map((el) => el.drugClass?.drugClassName).length !== 0
  );
};
