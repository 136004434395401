import React from 'react';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';

import DetailCaseChartsDates from './DetailCaseChartsDates';

const DetailCaseChartsDatesContainer: React.FC<{}> = () => {
  const {caseDetailStore} = useStore();
  const {startDate, endDate, minDate, maxDate, updateCaseChartDate} = caseDetailStore;
  const isDisabled = false;

  const setStartDate = (date: Date) => {
    updateCaseChartDate({startDate: date});
  };

  const setEndDate = (date: Date) => {
    updateCaseChartDate({endDate: date});
  };

  return (
    <DetailCaseChartsDates
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      disabled={isDisabled}
    />
  );
};

export default observer(DetailCaseChartsDatesContainer);
