import React from 'react';
import cn from 'classnames';

import s from './LightButton.module.css';

interface Props {
  title: string;
  handlerOnClick?: () => void;
  className?: string;
  disabled?: boolean;
  small?: boolean;
  icon?: React.FC<{className?: string}>;
  active?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const LightButton: React.FC<Props> = ({
  title,
  handlerOnClick,
  icon,
  className,
  small,
  disabled,
  active,
  isFirst = false,
  isLast = false
}) => {
  return (
    <button
      className={cn(
        s.btn,
        {
          [s.disabled]: disabled,
          [s.iconSmall]: small,
          [s.active]: active,
          [s.firstChild]: isFirst,
          [s.lastChild]: isLast
        },
        className
      )}
      onClick={handlerOnClick}
      disabled={disabled}
    >
      {icon && icon}
      {!small && <span>{title}</span>}
    </button>
  );
};

export default LightButton;
