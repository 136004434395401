import React from 'react';
import cn from 'classnames';
import {ArrowDownIcon} from 'src/static/icons';

import s from './FilterTitle.module.css';

interface Props {
  title: string;
  collapsible?: boolean;
  className?: string;
}

const FilterTitle: React.FC<Props> = ({title, collapsible = false, className}) => (
  <div className={s.container}>
    <span className={cn(s.title, className)}>{title}</span>
    {collapsible && <ArrowDownIcon className={s.icon} />}
  </div>
);

export default FilterTitle;
