import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

const ReportsByConmedsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {conmeds: selectedConmeds} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;

  const data: IFilterDataItem[] = caseStatData.conmeds;

  const emptyColors = {};
  const formattedOptions = data
    .map((item) => ({
      ...item,
      name: item.name
    }))
    .filter(({name, code}) => {
      if (selectedConmeds.length) {
        if (name) {
          return selectedConmeds.findIndex(({name: _name}) => _name === name) > -1;
        } else if (code) {
          return selectedConmeds.findIndex(({code: _code}) => _code === code) > -1;
        } else {
          return false;
        }
      }
      return name;
    })
    .slice(0, 15)
    .reverse();

  const chartOptions = getChartOptions({options: formattedOptions});
  const {t} = useTranslation();
  return (
    <Report
      title={t('reportByConmeds')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={emptyColors}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      contentHeight={400}
      leftOffset
    />
  );
};

export default observer(ReportsByConmedsContainer);
