import {useStore} from '@services/hooks/useStore';
import {getUnknownFilterCount} from '@services/utils/UserFilters.utils';
import {observer} from 'mobx-react-lite';
import React from 'react';

import MedicalHistoryControls from './MedicalHistoryControls';

const MedicalHistoryControlsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  return (
    <MedicalHistoryControls
      unknownMedicalHistoriesCount={getUnknownFilterCount(caseStatData.medHistories)}
      unknownConmedsCount={getUnknownFilterCount(caseStatData.conmeds)}
    />
  );
};

export default observer(MedicalHistoryControlsContainer);
