import React from 'react';
import {useTranslation} from 'react-i18next';
import {SortDirection} from 'react-virtualized';
import {ISortIndicator} from '@models/Table/Table.types';
import {ArrowDownIcon, ArrowTopIcon} from 'src/static/icons';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import cn from 'classnames';

import s from './DemoModalHeaderFilter.module.css';

const SortIndicator = ({sortDirection}: ISortIndicator): JSX.Element | null => {
  if (!sortDirection) return null;
  return sortDirection === SortDirection.ASC ? (
    <ArrowTopIcon className={s.icon} />
  ) : (
    <ArrowDownIcon className={s.icon} />
  );
};

const DemoModalHeaderFilter = () => {
  const {t} = useTranslation();

  const {demoStore} = useStore();
  const {sortDirection, sortBy, setSort} = demoStore;

  const headerTitles = [
    {name: t('rankToAE'), sortKey: 'rank', sortable: true},
    {name: t('caption'), sortKey: 'cpt', sortable: false},
    {name: t('keywords'), sortKey: 'kw', sortable: false},
    {name: t('journal'), sortKey: 'jor', sortable: false},
    {name: t('authors'), sortKey: 'aut', sortable: false},
    {name: t('publicationDate'), sortKey: 'pd', sortable: false},
    {name: t('reviewStatus'), sortKey: 'rs', sortable: false}
  ];

  const handleSort = (sortByProp: string) => {
    const sortDirectionProp =
      sortBy === sortByProp && sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;
    setSort({sortBy: sortByProp, sortDirection: sortDirectionProp});
  };

  const VisibleSortIndicator = ({sortKey}: {sortKey: string}) =>
    sortKey === sortBy ? <SortIndicator sortDirection={sortDirection} /> : null;

  return (
    <div className={s.tableRow}>
      {headerTitles?.map(({name, sortable, sortKey}) => {
        return (
          <div
            key={name}
            className={cn(s.cellHeader, {
              [s.cellRank]: sortable
            })}
            onClick={() => handleSort(sortKey)}
          >
            <span className={s.title}>{name}</span>
            {sortable && <VisibleSortIndicator sortKey={sortKey} />}
          </div>
        );
      })}
    </div>
  );
};

export default observer(DemoModalHeaderFilter);
