import {EDrugRole, IDrug} from '@stores-mobx/StudyDrugFilterStore';
import {IDispropScoreData} from '@models/DispropScore/DispropScore.types';
import {ERiskSelectOptions} from '@models/DispropScoreFilters/DispropScoreFilters.types';
import {IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {IPageParams} from '@models/Page/Page.types';

export const finalDrugsRequest = (array: IDrug[]) => {
  const newArray = array?.map((el) => ({
    drug: {name: el.name},
    drug_class: el.drugClass?.drugClassCode ? {code: el.drugClass.drugClassCode} : {},
    drug_role: el.drugRole === EDrugRole.SecondaryDrug ? 'secondary' : 'primary'
  }));
  return newArray;
};

export const searchedDispropScoreData = (data: IDispropScoreData[], searchValue: string) => {
  let searchedData: IDispropScoreData[] = data;

  if (searchValue) {
    searchedData = data.filter((val: IDispropScoreData) => {
      return val.ptName.toLowerCase().includes(searchValue.toLowerCase().trim());
    });
  }

  return searchedData;
};

/*
  risk_rsi is null -> Unlisted
  risk_rsi not null -> Listed
 */
export const checkRiskRsiByOption = (riskRsi: string | null, option: string) => {
  if (option === ERiskSelectOptions.Unlisted) {
    return riskRsi === null;
  }
  if (option === ERiskSelectOptions.Listed) {
    return riskRsi !== null;
  }
  return false;
};

export const sortSelectedData = (
  dataList: IPageParams<IDispropScoreData>,
  selectedList: IMedDraDictionariesData[]
): IPageParams<IDispropScoreData> => {
  if (!selectedList.length) {
    return dataList;
  }

  const filteredData: IDispropScoreData[] = dataList.content.filter(
    ({ptName}) =>
      ptName &&
      selectedList.findIndex(({name: _name}) => ptName.toLowerCase() === _name.toLowerCase()) !== -1
  );

  const unfilteredData: IDispropScoreData[] = dataList.content.filter(
    ({ptName}) =>
      ptName &&
      selectedList.findIndex(({name: _name}) => ptName.toLowerCase() === _name.toLowerCase()) === -1
  );
  return {...dataList, content: [...filteredData, ...unfilteredData]};
};
