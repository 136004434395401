import React from 'react';
import {useTranslation} from 'react-i18next';
import {FilterGroupTitle} from 'src/components';

import s from '../MedicalHistory.module.css';

interface Props {
  unknownMedicalHistoriesCount: number;
  unknownConmedsCount: number;
}

const MedicalHistoryControls: React.FC<Props> = ({
  unknownMedicalHistoriesCount,
  unknownConmedsCount
}) => {
  const {t} = useTranslation();

  return (
    <div className={s.controlsContainer}>
      <FilterGroupTitle
        className={s.gridFirst}
        title={t('medicalHistory')}
        subtitle={t('unknownNCases', {n: unknownMedicalHistoriesCount})}
      />
      <FilterGroupTitle
        className={s.gridLast}
        title={t('conmeds')}
        subtitle={t('unknownNCases', {n: unknownConmedsCount})}
      />
    </div>
  );
};

export default MedicalHistoryControls;
