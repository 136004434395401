export const appConstants = {
  keycloakUrl: process.env.REACT_APP_AUTH,
  keycloakRealm: process.env.REACT_APP_AUTH_REALM || 'master',
  keycloakClient: process.env.REACT_APP_AUTH_CLIENT || 'phv-api',
  reportGeneratorUrl:
    `${process.env.REACT_APP_REPORT_GENERATOR_URL}/v1/get_report` ||
    'https://dev.phv.perfectartco.com/report-generator/v1/get_report',
  storageKeys: {
    studiesFilter: 'studiesFilter',
    drugsFilter: 'drugsFilter'
  }
};

console.debug(process.env.REACT_APP_AUTH_REALM, process.env.REACT_APP_AUTH_CLIENT);
