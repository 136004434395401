import ReactEcharts from 'echarts-for-react';
import React, {useRef} from 'react';
import {AutoSizer} from 'react-virtualized';

import {Props} from './PieChart.types';
import PieChartLegend from './PieChartLegend';
import s from './PieChart.module.css';

const PieChart: React.FC<Props> = (props) => {
  const {className, options} = props;
  const ref = useRef<ReactEcharts>(null);

  return (
    <div className={className}>
      <AutoSizer>
        {({width, height}) => (
          <div style={{width, height}} className={s.pieChart}>
            <ReactEcharts ref={ref} option={options} />
            <PieChartLegend chartRef={ref} {...props} />
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export default PieChart;
