import React from 'react';

export const LabsIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <filter id="prefixLabs">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.192157 0 0 0 0 0.196078 0 0 0 0 0.211765 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      filter="url(#prefixLabs)"
    >
      <g stroke="#313236">
        <g>
          <path
            d="M10 2v6.124c2.649 1.158 4.5 3.8 4.5 6.876 0 2.454-1.178 4.632-3 6h-9c-.676-.507-1.264-1.127-1.736-1.831C.916 18.809 1 18.415 1 18h0v-7.5c.773-1.03 1.805-1.854 3-2.376V2h6z"
            transform="translate(2 1) translate(6.5 .5)"
          />
          <path
            fill="#D8D8D8"
            fillOpacity=".5"
            d="M6.59 13.811c1.74.904 3.946.035 5.7-.32.137.48.21.986.21 1.509 0 1.576-.663 2.998-1.726 4H3.226C2.163 17.999 1.5 16.577 1.5 15c0-.39.04-.772.118-1.14 1.432-.572 3.202-.969 4.972-.049z"
            transform="translate(2 1) translate(6.5 .5)"
          />
          <path d="M3 0H11V2H3z" transform="translate(2 1) translate(6.5 .5)" />
        </g>
        <g>
          <path
            d="M1 2h6v13c0 1.657-1.343 3-3 3s-3-1.343-3-3V2h0zM0 0H8V2H0z"
            transform="translate(2 1) translate(.5 3.5)"
          />
          <path
            fill="#D8D8D8"
            fillOpacity=".5"
            d="M5 8H3v7c0 .552.448 1 1 1s1-.448 1-1V8h0z"
            transform="translate(2 1) translate(.5 3.5)"
          />
        </g>
      </g>
    </g>
  </svg>
);
