import {useStore} from '@services/hooks/useStore';
import echarts from 'echarts/lib/echarts';
import {observer} from 'mobx-react-lite';
import React from 'react';
import s from 'src/components/Report/Report.module.css';
import {EmptyData} from 'src/components/UIKit';

import {getChartOptions} from './chartOptions';
import TimeToOnsetScatterLines from './TimeToOnsetScatterLines';

const TimeToOnsetScatterLinesContainer: React.FC<{}> = () => {
  const {
    reportsStore: {timeToUnsetReportData, timeToUnsetLabels, timeToOnsetTimeline, isFetching}
  } = useStore();

  const chartOptions: echarts.EChartOption = getChartOptions({
    scatterOptions: timeToUnsetReportData,
    labels: timeToUnsetLabels,
    timeline: timeToOnsetTimeline
  });

  if (timeToUnsetReportData && !timeToUnsetReportData?.length && !isFetching) {
    return (
      <div className={s.emptyDataWrapper}>
        <EmptyData text="Data is empty" />
      </div>
    );
  }

  return <TimeToOnsetScatterLines isFetching={isFetching} getChartOptions={chartOptions} />;
};

export default observer(TimeToOnsetScatterLinesContainer);
