import React, {memo, useRef, useState} from 'react';
import {ISelectOptions} from '@models/Dropdown/Dropdown.types';
import cn from 'classnames';
import {ArrowDownIcon} from 'src/static/icons';
import {ClickOutside} from 'src/components/ClickOutside';
import {Loading, Radio} from 'src/components/UIKit';

import s from './DropdownRadio.module.css';

interface Props {
  options: ISelectOptions[];
  selectName: string;
  handleDataChecked: (dataKey: string) => void;
  dark?: boolean;
  disabled?: boolean;
  isFetching?: boolean;
  selectNameInOptions?: boolean;
  selectTopClassName?: string;
  withoutIcon?: boolean;
}

const DropdownComponent: React.FC<Props> = ({
  options,
  selectName,
  handleDataChecked,
  selectNameInOptions,
  disabled,
  isFetching,
  dark,
  selectTopClassName,
  withoutIcon = false
}) => {
  const [isListVisible, toggleListVisible] = useState(false);
  const selectRef: React.MutableRefObject<null> = useRef(null);

  const handleClickOutside = () => {
    if (isListVisible) {
      toggleListVisible(false);
    }
  };

  const handleClick = (value: string) => {
    handleDataChecked(value);
    toggleListVisible(false);
  };

  const selectedNames = options
    .filter((option) => option.isVisible)
    .map(({name}) => name)
    .join(', ');

  return (
    <div className={cn(s.selectContainer, {[s.selectContainerDark]: dark})}>
      <div
        className={cn(
          s.selectTop,
          {[s.selectTopDisabled]: disabled || isFetching},
          selectTopClassName
        )}
        onClick={() => {
          toggleListVisible(!isListVisible);
        }}
      >
        <span>{selectNameInOptions && selectedNames.length > 0 ? selectedNames : selectName}</span>
        {withoutIcon ? <span className={s.icon} /> : <ArrowDownIcon className={s.icon} />}
      </div>
      {isListVisible && (
        <ClickOutside handleClickOutside={handleClickOutside}>
          <ul className={cn(s.selectList, {[s.selectListFetching]: isFetching})} ref={selectRef}>
            {selectNameInOptions && <li className={s.selectName}>{selectName}</li>}
            {options.map(({name, dataKey, isVisible, disableCheckbox}, idx) => (
              <li key={idx}>
                <Radio
                  inputName="drugClasses"
                  itemName={name}
                  dataKey={dataKey}
                  checked={name === selectName}
                  isDisabled={disableCheckbox}
                  onChange={() => handleClick(dataKey)}
                />
              </li>
            ))}
            {isFetching && <Loading />}
          </ul>
        </ClickOutside>
      )}
    </div>
  );
};

export default memo(DropdownComponent);
