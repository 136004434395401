import React from 'react';
import {useTranslation} from 'react-i18next';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ChartColorCollectionType} from '@models/Chart/chart.types';
import {IMedDRAFilterData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {integerWithSpaces} from '@services/utils/Functions.utils';

import s from './ChartLegend.module.css';

interface Props {
  options: IFilterDataItem[] & IMedDRAFilterData[];
  colorCollection: ChartColorCollectionType;
}

const ChartLegend: React.FC<Props> = ({options, colorCollection}) => {
  const {t} = useTranslation();

  return (
    <div className={s.legendGroupContainer}>
      {options.map(({name, count, percent}, idx) => (
        <div key={idx} className={s.legendGroup}>
          <span className={s.circle} style={{backgroundColor: colorCollection[name]}} />
          <span className={s.legendGroupName}>{t(name) || name}</span>
          <span className={s.legendGroupCount}>{t('nCases', {n: integerWithSpaces(count)})}</span>
          <span className={s.legendGroupCount}>{percent}%</span>
        </div>
      ))}
    </div>
  );
};

export default ChartLegend;
