import {
  IAvailablePeriods,
  ILabsChartLabels,
  IPeriodDates,
  IPeriodsDates,
  SeriesTypes
} from '@models/CaseDetail/CaseDetail.types';
import {
  ICaseVolumeChart,
  ICaseVolumeChartFormattedData,
  IFilterDataItem
} from '@models/CaseStat/CaseStat.types';
import {
  ChartColorCollectionType,
  DEFAULT_LINE_CHARTS_COUNT,
  DEFAULT_SIDEBAR_WIDTH,
  IChartLabels,
  IChartSettings
} from '@models/Chart/chart.types';
import {SeriesPieDataObjectType} from '@models/Chart/PieChart.types';
import {ALL_CLASSES, colorGreen, colorPurple600} from '@services/constants/chart.constants';
import {reportClassesColors} from '@services/constants/filters.constants';
import {getRangeDates, isDatesEqual} from '@services/utils/Date.utils';
import {convertNameToCapitalize, integerWithSpaces} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';
import {useTranslation} from 'react-i18next';

export const markLineDashedStyle = {lineStyle: {type: 'dashed', color: colorPurple600}};

export const isLabelsExists = (labels: (string | ILabsChartLabels)[]): boolean => labels.length > 0;

export const isPeriodDatesExists = (period: IPeriodsDates): period is IPeriodDates => {
  return period.dateStart !== null && period.dateEnd !== null;
};

export function getHeight(chartSettings: IChartSettings): number {
  const settings = [
    chartSettings.topOffset,
    chartSettings.firstChartHeight,
    chartSettings.bottomOffset,
    chartSettings.secondChartHeight,
    chartSettings.bottomOffset,
    chartSettings.thirdChartsHeightSum,
    chartSettings.lastChartOffset
  ];

  return settings.reduce((acc, value) => {
    acc += value;
    return acc;
  }, 0);
}

function calculateLineChartOffsets({
  chartIntervals,
  thirdChartTopOffset,
  thirdChartHeight
}: {
  chartIntervals: {[key in string]: number};
  thirdChartTopOffset: number;
  thirdChartHeight: number;
}): number[] {
  const chartsOffsets = [];
  for (let i = 0; i < chartIntervals.LINE_CHARTS_COUNT; i++) {
    if (i === 0) {
      chartsOffsets.push(thirdChartTopOffset);
    }
    if (i > 0) {
      chartsOffsets.push(chartsOffsets[i - 1] + thirdChartHeight);
    }
  }
  return chartsOffsets;
}

export function getChartSettings({
  firstChartLabels = {},
  secondChartLabels = {},
  thirdChartLabels = []
}: IChartLabels): IChartSettings {
  const chartIntervals = {
    rowHeight: 40,
    topOffset: 116,
    bottomOffset: 126,
    lastChartOffset: 70,
    chartTitleOffset: 40,
    LINE_CHARTS_COUNT: thirdChartLabels.length || DEFAULT_LINE_CHARTS_COUNT,
    LINE_CHART_ROWS: 4
  };
  const firstChartLabelsNames = Object.keys(firstChartLabels);
  const secondChartLabelsNames = Object.keys(secondChartLabels);

  const firstChartHeight = firstChartLabelsNames.length * chartIntervals.rowHeight;
  const secondChartHeight = secondChartLabelsNames.length * chartIntervals.rowHeight;

  // default height for one line chart
  const thirdChartHeight = chartIntervals.LINE_CHART_ROWS * chartIntervals.rowHeight;

  // sum of height for all line charts
  const thirdChartsHeightSum = thirdChartHeight * chartIntervals.LINE_CHARTS_COUNT;

  const secondChartTopOffset =
    firstChartLabelsNames.length * chartIntervals.rowHeight +
    chartIntervals.topOffset +
    chartIntervals.bottomOffset;

  const thirdChartTopOffset =
    secondChartLabelsNames.length * chartIntervals.rowHeight +
    secondChartTopOffset +
    chartIntervals.bottomOffset;

  // offsets for each charts
  const thirdChartTopOffsetsCollection = calculateLineChartOffsets({
    chartIntervals,
    thirdChartTopOffset,
    thirdChartHeight
  });

  return {
    gridLeftOffset: `${DEFAULT_SIDEBAR_WIDTH}px`,
    rowHeight: chartIntervals.rowHeight,
    topOffset: chartIntervals.topOffset,
    bottomOffset: chartIntervals.bottomOffset,
    lastChartOffset: chartIntervals.lastChartOffset,

    firstChartLabels,
    secondChartLabels,
    thirdChartLabels,

    secondChartTopOffset,
    thirdChartTopOffsetsCollection,

    firstChartHeight,
    secondChartHeight,
    thirdChartDefaultHeight: thirdChartHeight,
    thirdChartsHeightSum,

    firstChartTitleTop: chartIntervals.topOffset - chartIntervals.chartTitleOffset,
    secondChartTitleTop: secondChartTopOffset - chartIntervals.chartTitleOffset,
    thirdChartTitleTop: thirdChartTopOffsetsCollection[0] - chartIntervals.chartTitleOffset
  };
}

export function formatterTooltip({
  params,
  width,
  isVertical,
  isDataset,
  isBoxSpot
}: {
  params: echarts.EChartOption.Tooltip.Format | echarts.EChartOption.Tooltip.Format[];
  width?: string;
  isVertical?: boolean;
  isDataset?: boolean;
  isBoxSpot?: boolean;
}): string {
  let date: string | number | undefined = '';
  let valueSum: number | string = 0;
  const {seriesType} = Array.isArray(params) ? params[0] : params;

  // type: axis
  if (Array.isArray(params)) {
    date = params[0].axisValue;

    valueSum = params.reduce((acc, param) => {
      if (param && param.value && !Array.isArray(param.value)) {
        acc += param.value;
      }
      if (param && Array.isArray(param.value) && param.value.length === 2) {
        acc += param.value[1];
      }
      if (param && Array.isArray(param.value) && param.value.length > 2) {
        acc += param.value[2];
      }
      return acc;
    }, 0);
  }
  // type: item
  if (!Array.isArray(params)) {
    date = params.name;
    // scatter
    if (
      params &&
      Array.isArray(params.value) &&
      params.value.length > 2 &&
      seriesType === SeriesTypes.Scatter
    ) {
      const {2: paramsValue} = params.value;
      valueSum = paramsValue;
    }

    // scatter for box plot
    if (
      params &&
      Array.isArray(params.value) &&
      params.value.length > 2 &&
      seriesType === SeriesTypes.Scatter &&
      isBoxSpot
    ) {
      const {1: yValue, 2: paramsValue} = params.value;
      valueSum = `${yValue} Days, ${paramsValue} Cases`;
    }

    // bar
    if (params && seriesType === SeriesTypes.Bar && !isVertical) {
      const {seriesName, value} = params;
      date = seriesName;
      valueSum = `${integerWithSpaces(value as number)} Cases`;
    }
    if (params && seriesType === SeriesTypes.Bar && !isVertical && isDataset) {
      const {value} = params;
      const [percent, count, seriesName] = value as [number, number, string];
      date = seriesName;
      valueSum = `${integerWithSpaces(count as number)} Cases, ${percent}% `;
    }
    if (params && seriesType === SeriesTypes.Bar && isVertical) {
      const {name, value} = params;
      date = name !== undefined ? convertNameToCapitalize(name) : '';
      valueSum = `${integerWithSpaces(value as number)} Cases`;
    }

    // line chart, labs
    if (params && Array.isArray(params.value) && seriesType === SeriesTypes.Line) {
      const {1: paramsValue, 2: testUnit} = params.value;
      if (paramsValue !== null && testUnit !== null) {
        valueSum = `${paramsValue} ${testUnit}`;
      }
      if (paramsValue !== null && testUnit === null) {
        valueSum = `${paramsValue}`;
      }
      if (paramsValue === null && testUnit === null) {
        valueSum = 'No results';
      }
    }
  }

  const valueSumArr: (string | number)[] =
    typeof valueSum === 'string' ? valueSum.split(',') : [valueSum];

  const valueSumElements = valueSumArr.reduce((acc, valueItem) => {
    acc += `<div>${valueItem}</div>`;
    return acc;
  }, '');
  const minWidth = valueSumArr.length > 1 && !width ? '220px' : width;

  return `<div class="tooltip" style="min-width: ${minWidth}">
      <div class="tooltip-date">${date}</div>
      <div class="tooltip-value">${valueSumElements}</div>
    </div>`;
}

export function getPeriodDates({
  periods,
  startDateDefault,
  endDateDefault
}: {
  periods: IAvailablePeriods;
  startDateDefault: Date;
  endDateDefault: Date;
}): IPeriodsDates[] {
  return Object.entries(periods).map(([key, {dateStart, dateEnd}]) => {
    if (dateStart === null && dateEnd !== null) {
      return {
        name: key,
        dateStart: startDateDefault,
        dateEnd: new Date(dateEnd),
        isFullPeriodExists: false
      };
    }
    if (dateStart !== null && dateEnd === null) {
      return {
        name: key,
        dateStart: new Date(dateStart),
        dateEnd: endDateDefault,
        isFullPeriodExists: false
      };
    }
    if (dateStart !== null && dateEnd !== null) {
      return {
        name: key,
        dateStart: new Date(dateStart),
        dateEnd: new Date(dateEnd),
        isFullPeriodExists: true
      };
    }
    return {
      name: key,
      dateStart, // null
      dateEnd, // null
      isFullPeriodExists: false
    };
  });
}

export function getLineChartData({
  lineChart
}: {
  lineChart: ICaseVolumeChart;
}): ICaseVolumeChartFormattedData {
  if (Object.keys(lineChart).length === 0) {
    return {};
  }

  const result: ICaseVolumeChartFormattedData = {};

  Object.keys(lineChart).forEach((key) => {
    const lineChartData = lineChart[key];
    result[key] = lineChartData.map((lineChartValue, index) => {
      return [index, lineChartValue.count, ''];
    });
  });

  return result;
}

export const pieChartLegendFormatter =
  (data: SeriesPieDataObjectType[]) =>
  (legendName: string): string => {
    const legendOption: [number, number] | undefined | number = data.find(
      ({name}) => name === legendName
    )?.value;

    const casesCount = Array.isArray(legendOption)
      ? `${integerWithSpaces(legendOption[0])} Cases`
      : '';
    const casesPercent = Array.isArray(legendOption) ? `${legendOption[1]}%` : '';

    return `${legendName} \n${casesCount} \n${casesPercent}`;
  };

export const lineChartLegendFormatted = (data: IFilterDataItem[]) => (legendName: string) => {
  const chartItem: IFilterDataItem | undefined = data.find(({name}) => name === legendName);

  const {t} = useTranslation();

  if (chartItem !== undefined) {
    const chartItemCount = integerWithSpaces(chartItem.count);
    return `${legendName} \n${chartItemCount} ${t('casesFound')} \n${chartItem.percent}%`;
  } else {
    return legendName;
  }
};

export function getReportClassesWithAll(reportClasses: IFilterDataItem[]): IFilterDataItem[] {
  return reportClasses.reduce<IFilterDataItem[]>((acc, reportClassItem) => {
    acc[0] = {
      name: ALL_CLASSES,
      count: acc.length ? (acc[0].count += reportClassItem.count) : reportClassItem.count,
      percent: acc.length
        ? Math.round((acc[0].percent += reportClassItem.percent))
        : reportClassItem.percent
    };
    acc.push(reportClassItem);
    return acc;
  }, []);
}

export const getReportClassesColorCollection = (
  data: IFilterDataItem[]
): ChartColorCollectionType =>
  data.reduce<ChartColorCollectionType>((acc, {name}, idx) => {
    if (!acc[name]) {
      acc[ALL_CLASSES] = colorGreen;
    }
    acc[name] = reportClassesColors[idx];
    return acc;
  }, {});
