/* eslint-disable @typescript-eslint/camelcase */
import {flow, Instance, types} from 'mobx-state-tree';
import max from 'lodash/max';
import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {
  IDispropFetchParams,
  IDispropScoreData,
  ITimePeriod,
  PeriodType
} from '@models/DispropScore/DispropScore.types';
import {IPageParams} from '@models/Page/Page.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getDispropScoresSelectedData} from '@services/utils/MedDRAFilters.utils';
import {Modal} from '@models/Modal';
import {formatKeys} from '@services/utils/Data.utils';
import {storeState} from '@services/utils/Mst.utils';

import {DispropScoreCSVStore} from './models/DispropScoreCSVStore';
import {DispropScoreHierarchyStore} from './models/DispropScoreHierarchyStore';

const INITIAL_STATE: IPageParams<IDispropScoreData> = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const DispropScoreStore = types.compose(
  Store,
  types
    .model('DispropScoreStore', {
      modal: types.optional(Modal, {}),
      ptName: '',
      chartDataTarget: types.frozen({ds_table: []}),
      dates: types.frozen<ITimePeriod>({startDate: undefined, endDate: undefined}),
      chartDataComparator: types.frozen({ds_table: []}),
      exposureTarget: types.frozen([]),
      exposureComparator: types.frozen([]),
      dispropScoreData: types.frozen<IPageParams<IDispropScoreData>>(INITIAL_STATE),
      maxAeCount: types.optional(types.number, 0),
      periodType: types.optional(
        types.enumeration('PeriodType', Object.values(PeriodType)),
        PeriodType.TimePeriod
      ),
      request: types.optional(Request, {}),
      requestComparator: types.optional(Request, {}),
      requestRisk: types.optional(Request, {}),
      dispropScoreCSV: types.optional(DispropScoreCSVStore, {}),
      dispropScoreHierarchy: types.optional(DispropScoreHierarchyStore, {})
    })
    .actions((self) => {
      const actions = {
        openNewSignalForm(ptName: string): void {
          self.ptName = ptName;
          self.modal.open();
        },
        closeNewSignalForm(): void {
          self.modal.close();
        },
        load: flow(function* ({
          dates,
          pt,
          selectedPtValues,
          filters,
          medraFilters,
          finalDrugsRequestTarget,
          finalDrugsRequestComparator
        }: IDispropFetchParams) {
          const requestParams = {
            filter: {
              ...filters,
              time_period: {
                ...dates,
                ds_period_label: self.periodType?.toLowerCase()
              }
            },
            client: 'string2',
            pt
          };
          const thread = {
            selectedPt:
              finalDrugsRequestTarget.length > 0
                ? self.request.send(
                    getApiUrl(ApiVersions.v11, 'disp.score.list.pageable.analytics'),
                    {
                      method: 'post',
                      data: {
                        therapy: {
                          components: finalDrugsRequestTarget,
                          type: 'target'
                        },
                        ...requestParams,
                        meddra_filter: medraFilters
                      }
                    }
                  )
                : Promise.resolve(),
            selectedPtComparator:
              finalDrugsRequestComparator.length > 0
                ? self.requestComparator.send(
                    getApiUrl(ApiVersions.v11, 'disp.score.list.pageable.analytics'),
                    {
                      method: 'post',
                      data: {
                        therapy: {
                          components: finalDrugsRequestComparator,
                          type: 'comparator'
                        },
                        ...requestParams
                      }
                    }
                  )
                : Promise.resolve()
          };

          const selectedPtResponse = yield thread.selectedPt;
          const selectedPtResponseComparator = yield thread.selectedPtComparator;

          if (selectedPtResponse && selectedPtResponse !== '') {
            const {ds_table: updatedContent, ...time_period} = selectedPtResponse;

            const updatedContentWithSelectedPT = getDispropScoresSelectedData({
              dispropScoreData: updatedContent,
              selectedPT: selectedPtValues,
              selectedPtResponse:
                selectedPtResponse?.data?.content?.length > 0
                  ? selectedPtResponse?.data?.content
                  : []
            });
            self.chartDataTarget = selectedPtResponse;
            self.dates = formatKeys<Record<string, string>, ITimePeriod>(
              selectedPtResponse?.time_period
            );
            self.exposureTarget = selectedPtResponse?.ds_table?.map(
              (el: Partial<IDispropScoreData>) => ({
                exposure: el?.exposure?.toFixed(1),
                pt_name: el?.pt_name,
                rr: el?.rr?.toFixed(1),
                prr: el?.prr?.toFixed(1),
                ror: el?.ror?.toFixed(1),
                ebgm: el?.ebgm?.toFixed(1),
                ic: el?.ic?.toFixed(1),
                eb05: el?.eb05?.toFixed(1)
              })
            );
            self.dispropScoreData = {content: updatedContentWithSelectedPT, ...time_period};
            self.maxAeCount =
              selectedPtResponse?.stat?.maxAeCount ??
              max(
                selectedPtResponse.ds_table.map((val: Partial<IDispropScoreData>) => val.ae_counts)
              );
          } else {
            const updatedContentWithSelectedPT = getDispropScoresSelectedData({
              dispropScoreData: [],
              selectedPT: selectedPtValues,
              selectedPtResponse:
                selectedPtResponse?.data?.content?.length > 0
                  ? selectedPtResponse?.data?.content
                  : []
            });
            self.chartDataTarget = selectedPtResponse;
            self.dates = selectedPtResponse?.time_period;
            self.exposureTarget = [];
            // @ts-ignore
            self.dispropScoreData = {content: updatedContentWithSelectedPT};
            self.maxAeCount = selectedPtResponse?.stat?.maxAeCount ?? 0;
          }

          if (selectedPtResponseComparator && selectedPtResponseComparator !== '') {
            self.chartDataComparator = selectedPtResponseComparator;
            self.exposureComparator = selectedPtResponseComparator?.ds_table?.map(
              (el: Partial<IDispropScoreData>) => ({
                exposure: el?.exposure?.toFixed(1),
                pt_name: el?.pt_name,
                rr: el?.rr?.toFixed(1),
                prr: el?.prr?.toFixed(1),
                ror: el?.ror?.toFixed(1),
                ebgm: el?.ebgm?.toFixed(1),
                ic: el?.ic?.toFixed(1),
                eb05: el?.eb05?.toFixed(1)
              })
            );
          } else {
            // @ts-ignore
            self.chartDataComparator = [];
            self.exposureComparator = [];
          }
        }),
        sendRisk: flow(function* sendRisk() {
          const response = yield self.requestRisk.send(getApiUrl(ApiVersions.v1, 'risks'), {
            method: 'post',
            data: {
              activeStatus: 'ACTIVE',
              riskCategory: 'ADVERSE_DRUG_REACTION',
              riskDescription: '',
              riskPt: self.ptName,
              riskRsi: null,
              riskStatus: 'POTENTIAL',
              triggerEvent: ''
            }
          });
          if (response?.riskPt?.toLowerCase() === self?.ptName?.toLowerCase()) {
            self.modal.close();
          }
        })
      };
      return actions;
    })
    .actions((self) => {
      return {
        afterCreate() {
          storeState(self, ['periodType']);
        },
        setPeriodType(periodType: PeriodType) {
          self.periodType = periodType;
        }
      };
    })
    .views((self) => ({
      get isFetching(): boolean {
        return self.request.isPending || self.requestComparator.isPending;
      },
      get isFetchingRisk(): boolean {
        return self.requestRisk.isPending;
      }
    }))
);

export interface IDispropScoreStore extends Instance<typeof DispropScoreStore> {}

export {DispropScoreStore};
