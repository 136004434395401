import {useStore} from '@services/hooks/useStore';
import {INotificationOption} from '@stores-mobx/NotificationStore';
import React from 'react';
import {observer} from 'mobx-react-lite';

import s from './Notification.module.css';
import {NotificationControl} from './components';

const HEIGHT = 112;
const MARGIN_BOTTOM = 12;

const Notification: React.FC<{}> = () => {
  const {
    notification: {currentOptions, close}
  } = useStore();

  if (!currentOptions.length) return null;

  return (
    <div className={s.wrapper}>
      {currentOptions.map(({code, message, url, id}: INotificationOption, idx: number) => (
        <NotificationControl
          key={id}
          code={code}
          message={message}
          url={url}
          id={id}
          topOffset={(HEIGHT + MARGIN_BOTTOM) * idx}
          close={close}
        />
      ))}
    </div>
  );
};

export default observer(Notification);
