import React, {useEffect} from 'react';
import {ErrorIcon} from 'src/static/icons';

import s from './NotificationControl.module.css';

const TIME = 4000;

interface IProps {
  id: string;
  topOffset: number;
  code: number | null;
  message: string;
  url: string;
  close: (id: string) => void;
}

const NotificationControl: React.FC<IProps> = (props) => {
  const {id, topOffset, code, message, url, close} = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      close(id);
    }, TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, [close, id]);

  return (
    <div className={s.errorContent} style={{top: `${topOffset}px`}}>
      <ErrorIcon className={s.icon} />
      <div>
        <div className={s.title}>Error {code ? `#${code}` : ''}</div>
        {url && <div className={s.url}>{url}</div>}
        {message ? (
          <div>{message}</div>
        ) : (
          <div>
            Ooops! Something went wrong...
            <br />
            Try one more time please.
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationControl;
