import React, {memo} from 'react';
import {observer} from 'mobx-react-lite';
import {IStudies} from '@stores-mobx/StudyDrugFilterStore/model/Studies';

import s from './Options.module.css';

interface Props {
  study: IStudies;
  onRemoveStudy?: (study: IStudies) => void;
}

const StudyOption: React.FC<Props> = ({study, onRemoveStudy}) => {
  return (
    <div className={s.wrapper}>
      <div className={s.borderContent}>
        <button
          aria-label="Remove button"
          onClick={() => onRemoveStudy && onRemoveStudy(study)}
          className={s.remove}
        />
        <div className={s.selected} title={`${study.name} (${study.receiveDate})`}>
          <p className={s.overflowText}>
            {study.name} ({study.receiveDate})
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(observer(StudyOption));
