import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@services/hooks/useStore';
import {Button, DropdownRadio, SearchComponent} from '@components/UIKit';
import {ArrowLeftIcon} from 'src/static/icons';
import cn from 'classnames';

import s from './PtReviewModal.module.css';
import {TextCustomSelection} from './components/TextCustomSelection';
import {ITextElement} from './models/Demo.types';
import PtReviewList from './components/PtReviewList/PtReviewList';
import {useEntitiesParser} from './utils/useEntitiesParser';

export interface IHeaderItemProps {
  label: string;
  children: string | React.ReactNode;
  isRed?: boolean;
}

const HeaderItem: React.FC<IHeaderItemProps> = ({label, children, isRed = false}) => {
  return (
    <div className={s.rightHeaderItem}>
      <div className={s.rightHeaderLabel}>
        {label}
        <div className={s.line}></div>
      </div>
      <div className={cn(s.rightHeaderChildren, {[s.red]: isRed})}>{children}</div>
    </div>
  );
};

const PtReviewModal = () => {
  const {demoStore} = useStore();
  const {
    ptReviewModal,
    closePtReviewModal,
    getSelectedForReviewLiterature,
    removeEntitiesFromSelectedLiterature,
    getStatusOptions,
    getCurrentReviewStatus,
    setReviewedStatus,
    getEntitiesLabels
  } = demoStore;

  const [searchValue, setSearchValue] = useState<string>('');

  const [currentFilteredTextElements, setCurrentFilteredTextElements] = useState<ITextElement[]>(
    []
  );

  const [currentHoverElement, setCurrentHoverElement] = useState<number | undefined>(undefined);
  const [findElementRight, setFindElementRight] = useState<number | undefined>(undefined);
  const [findElementLeft, setFindElementLeft] = useState<number | undefined>(undefined);

  const {selectedItem, currentUniqLabels, currentTextElements} = useEntitiesParser(
    getSelectedForReviewLiterature,
    getEntitiesLabels
  );
  // useEffect(() => {
  //   console.log('currentHoverElement', currentHoverElement);
  // }, [currentHoverElement]);

  useEffect(() => {
    setCurrentFilteredTextElements(
      currentTextElements.filter((el) => el.text.includes(searchValue) || searchValue === '')
    );
  }, [currentTextElements, searchValue]);

  const removeEntities = (value: number) => {
    removeEntitiesFromSelectedLiterature(value);
  };

  return (
    <>
      {ptReviewModal.isOpened && (
        <>
          <div className={s.containerModal} />
          <div className={cn(s.containerContent)}>
            <div className={s.containerContentWrapper}>
              <div className={s.containerContentWrapperHeader}>
                <div className={s.containerContentWrapperHeaderButtons}>
                  <Button type="button" variant="secondary" onClick={closePtReviewModal}>
                    <ArrowLeftIcon className={s.arrowLeft} />
                  </Button>
                </div>
                <div className={s.containerContentWrapperHeaderText}>
                  <p className={s.title}>{selectedItem?.article_data.title}</p>
                </div>
              </div>
              <div className={s.containerContentWrapperItems}>
                <div className={s.leftWrapper}>
                  <SearchComponent
                    searchValue={searchValue}
                    handleSearch={(value) => {
                      setSearchValue(value);
                    }}
                    placeholder="Search"
                    className={s.search}
                  />
                  <div className={s.leftTitleWrapper}>
                    <div className={s.leftTitleFirst}>NER summary</div>
                    <div className={s.leftTitleSecond}>Preferred name</div>
                  </div>
                  <PtReviewList
                    textElements={currentFilteredTextElements}
                    onRemoveEntities={removeEntities}
                    uniqueLabels={currentUniqLabels}
                    entitiesLabels={getEntitiesLabels}
                    currentHoverElement={currentHoverElement}
                    setCurrentHoverElement={setCurrentHoverElement}
                    findAndShowElementIndex={findElementLeft}
                    unsetFindAndShowElement={setFindElementLeft}
                    setShowElement={setFindElementRight}
                  />
                </div>
                <div className={s.rightWrapper}>
                  <div className={s.rightHeaderWrapper}>
                    <div className={s.rightHeaderColumn}>
                      <HeaderItem label="Review status">
                        <DropdownRadio
                          handleDataChecked={(dataKey) => {
                            setReviewedStatus(dataKey);
                          }}
                          options={getStatusOptions}
                          selectName={getCurrentReviewStatus}
                        />
                      </HeaderItem>
                      <HeaderItem label="Keywords">
                        {selectedItem?.article_data.keywords.slice(0, 40)}
                      </HeaderItem>
                      <HeaderItem label="Journal">{selectedItem?.article_data.journal}</HeaderItem>
                    </div>
                    <div className={s.rightHeaderColumn}>
                      <HeaderItem label="Rank to AE" isRed>
                        {selectedItem?.rank}
                      </HeaderItem>
                      <HeaderItem label="Authors">
                        {selectedItem?.article_data.authors.slice(0, 40)}
                      </HeaderItem>
                      <HeaderItem label="Publication date">
                        {selectedItem?.article_data.pubdate}
                      </HeaderItem>
                    </div>
                  </div>
                  <div className={s.rightTitle}>{selectedItem?.article_data.title}</div>
                  <TextCustomSelection
                    textElements={currentTextElements}
                    onRemoveEntities={removeEntities}
                    currentHoverElement={currentHoverElement}
                    setCurrentHoverElement={setCurrentHoverElement}
                    findAndShowElementIndex={findElementRight}
                    unsetFindAndShowElement={setFindElementRight}
                    setShowElement={setFindElementLeft}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default observer(PtReviewModal);
