import React from 'react';
import {
  colorMagenta300,
  colorMagenta500,
  colorMagenta800,
  colorPurple600,
  colorRed300,
  colorRed500,
  colorRed800
} from '@services/constants/chart.constants';
import {isLabelsExists} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {IChartSettings} from '@models/Chart/chart.types';
import cn from 'classnames';

import s from './DetailCaseChartsLegends.module.css';

const adverseEventsLegend = [
  {name: 'Non serious', color: colorRed300},
  {name: 'Serious', color: colorPurple600}
];

const labsLegend = [
  {name: '3LLN', color: colorMagenta800},
  {name: '2LLN', color: colorMagenta500},
  {name: 'LLN', color: colorMagenta300},
  {name: 'Normal', color: colorPurple600},
  {name: 'ULN', color: colorRed300},
  {name: '2ULN', color: colorRed500},
  {name: '3ULN', color: colorRed800}
];

const topOffset = 10;

interface Props {
  chartSettings: IChartSettings;
}

const DetailCaseChartsLegends: React.FC<Props> = ({chartSettings}) => {
  const middleLabsLegendIdx = Math.floor(labsLegend.length / 2);
  const secondChartLabelsNames = Object.keys(chartSettings.secondChartLabels);
  return (
    <>
      {isLabelsExists(secondChartLabelsNames) && (
        <div className={s.eventsLegend} style={{top: chartSettings.secondChartTitleTop}}>
          {adverseEventsLegend.map(({name, color}, idx) => (
            <div key={idx}>
              <span className={s.circle} style={{backgroundColor: color}} />
              <span>{name}</span>
            </div>
          ))}
        </div>
      )}

      {isLabelsExists(chartSettings.thirdChartLabels) && (
        <div className={s.labsLegend} style={{top: chartSettings.thirdChartTitleTop - topOffset}}>
          {labsLegend.map(({name, color}, idx) => (
            <div key={idx} className={s.labsLegendGroup}>
              <span className={cn({[s.middleName]: idx === middleLabsLegendIdx})}>{name}</span>
              <span className={cn(s.circle, s.circleLabs)} style={{backgroundColor: color}} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DetailCaseChartsLegends;
