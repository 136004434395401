import React, {RefObject, useCallback, useEffect, useRef} from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from '@services/utils/Functions.utils';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {IMedDraDictionariesData, MedDRAPtTypes} from '@models/MedDRAFilters/MedDRAFilters.types';
import {IPageParams} from '@models/Page/Page.types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useStore} from '@services/hooks/useStore';
import {Dropdown, Loading, SearchComponent} from 'src/components';

import s from './FiltersList.module.css';

const DEFAULT_HEIGHT = 340;

interface Props {
  title: string;
  filterListData: IPageParams<IMedDraDictionariesData>;
  selectedFilters: IMedDraDictionariesData[];
  selectFilter: (filterData: IMedDraDictionariesData[]) => void;
  clearFilter: (filterData: number | string) => void;
  searchValue: string;
  handleSearch: (value: string) => void;
  isListCollapsed: boolean;
  setListCollapsed: (isSearchActive: boolean) => void;
  isFetching: boolean;
  loadData: (data: IParamsPagination) => void;
  page: number;
  filterListIdx: number;
}

const FiltersList: React.FC<Props> = ({
  title,
  filterListData,
  selectedFilters,
  selectFilter,
  clearFilter,
  searchValue,
  handleSearch,
  isListCollapsed,
  setListCollapsed,
  isFetching,
  loadData,
  page,
  filterListIdx
}) => {
  const scrollRef: RefObject<InfiniteScroll> = useRef(null);
  const {t} = useTranslation();

  const {
    medDRAFiltersStore: {medDRASelectedFilters, pt}
  } = useStore();

  const {ptType, setPtType, getPtTypeOptions} = pt;

  useEffect(() => {
    if (isListCollapsed) {
      scrollRef.current?.getScrollableTarget()?.scroll({top: 0});
    }
  }, [isListCollapsed]);

  const handleSelectFilter = useCallback(
    (item: IMedDraDictionariesData, isSelected: boolean, isSelectedName: boolean) => {
      if (isListCollapsed) {
        setListCollapsed(false);
      }
      const {code, name} = item;
      if (isSelected || isSelectedName) {
        clearFilter(code || name);
        return;
      }
      if (selectedFilters.find((selectedItem) => selectedItem.name === item.name)) return;
      selectFilter([...selectedFilters, item]);
    },
    [clearFilter, isListCollapsed, selectFilter, selectedFilters, setListCollapsed]
  );

  const handleLoadData = useCallback(() => {
    loadData({page: page + 1});
  }, [loadData, page]);

  const getCollapsedHeight = useCallback(() => {
    const DEFAULT_COLLAPSED_HEIGHT = 43;
    const selectedLength = selectedFilters.length;

    if (selectedLength >= 4) {
      return DEFAULT_COLLAPSED_HEIGHT * 4;
    }
    return DEFAULT_COLLAPSED_HEIGHT * selectedLength;
  }, [selectedFilters.length]);

  const getScrollHeight = () =>
    isListCollapsed && getCollapsedHeight() !== 0 ? getCollapsedHeight() : DEFAULT_HEIGHT;

  return (
    <div className={s.filterListContainer}>
      <div className={s.title}>
        <div className={s.titleHeader}>
          <div className={s.titleWrapper}>
            <h4>{title}</h4>
            {title === 'PT' && medDRASelectedFilters?.smq.length > 0 && (
              <div className={s.btnContainer} onClick={() => setListCollapsed(false)}>
                <Dropdown
                  selectName="Broad & Narrow"
                  options={getPtTypeOptions}
                  handleDataChecked={(dataChecked) => {
                    setPtType(dataChecked[0] === '1' ? MedDRAPtTypes.Broad : MedDRAPtTypes.Narrow);
                  }}
                  handleSelectAll={(checked) => {
                    if (checked) setPtType(MedDRAPtTypes.Default);
                  }}
                  disabled={false}
                  isFetching={isFetching}
                  selectNameInOptions
                  classNames={s.dropdown}
                  selectAllDisabled={ptType === MedDRAPtTypes.Default}
                  useAmpersand
                />
              </div>
            )}
          </div>
          <SearchComponent
            className={s.search}
            handleSearch={handleSearch}
            fetchData={() => {}}
            searchValue={searchValue}
            isListCollapsed={isListCollapsed}
            setListCollapsed={setListCollapsed}
            placeholder={t('searchPlaceholder')}
          />
        </div>
      </div>
      <div
        id={`filterList${filterListIdx}`}
        className={cn(s.filterList, {[s.filterListCollapsed]: isListCollapsed})}
        style={{...(isListCollapsed && {height: getCollapsedHeight()})}}
      >
        <InfiniteScroll
          ref={scrollRef}
          dataLength={filterListData?.content?.length || 0}
          hasMore
          loader={null}
          height={getScrollHeight() + 10}
          next={handleLoadData}
          scrollableTarget={`filterList${filterListIdx}`}
          className={s.hideScroll}
          style={isListCollapsed ? {overflow: 'hidden'} : {}}
        >
          {filterListData.content.map(({name, code}, idx) => {
            const nameInLowerCase = name ? name?.toLowerCase() : name;
            const isSelected = code
              ? selectedFilters.findIndex(({code: _code}) => _code === code) > -1
              : false;
            const isSelectedName = name
              ? selectedFilters.findIndex(({name: _name}) => _name === name) > -1
              : false;

            return (
              nameInLowerCase && (
                <div
                  key={code || idx}
                  className={cn(s.filterItem, {[s.filterItemActive]: isSelected || isSelectedName})}
                  onClick={() =>
                    handleSelectFilter({code, name: nameInLowerCase}, isSelected, isSelectedName)
                  }
                >
                  <span className={s.label} title={name}>
                    {capitalizeFirstLetter(nameInLowerCase)}
                  </span>
                </div>
              )
            );
          })}
          {!filterListData.content.length && <li className={s.filterItem}>No filter data</li>}
          {isFetching && !filterListData.content.length && <Loading />}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FiltersList;
