import {useStore} from '@services/hooks/useStore';
import {getUnknownFilterCount} from '@services/utils/UserFilters.utils';
import {observer} from 'mobx-react-lite';
import React from 'react';

import SeverityListednessControls from './SeverityListednessControls';

const SeverityListednessControlsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  return (
    <SeverityListednessControls
      unknownSeverityCount={getUnknownFilterCount(caseStatData.severity)}
      unknownListednessCount={getUnknownFilterCount(caseStatData.listedness)}
    />
  );
};

export default observer(SeverityListednessControlsContainer);
