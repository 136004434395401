import React from 'react';

export const ErrorIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="56" height="56" viewBox="0 0 56 56">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="4">
      <circle cx="28" cy="28" r="23" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M36.345 37l-16.8-18m.11 18l16.8-18" />
    </g>
  </svg>
);
