import {Store} from '@models/base/Store';
import {CaseStatStore} from '@stores-mobx/UserFiltersStore/models/CaseStatStore';
import {SelectedUserFiltersStore} from '@stores-mobx/UserFiltersStore/models/SelectedUserFiltersStore';
import {Instance, types} from 'mobx-state-tree';
import {storeState} from '@services/utils/Mst.utils';

const UserFiltersStore = types.compose(
  Store,
  types
    .model('UserFiltersStore', {
      caseStatStore: types.optional(CaseStatStore, {}),
      selectedFiltersStore: types.optional(SelectedUserFiltersStore, {})
    })
    .actions((self) => ({
      afterCreate() {
        storeState(self, ['selectedFiltersStore']);
      }
    }))
);

export interface IUserFiltersStore extends Instance<typeof UserFiltersStore> {}

export {UserFiltersStore};
