import {types, Instance} from 'mobx-state-tree';

const UpdateObserver = types
  .model('UpdateObserver', {
    isInEditMode: false,
    isContentChanged: false
  })
  .actions((self) => ({
    setIsInEditMode(value: boolean) {
      if (value) {
        self.isContentChanged = false;
      }
      self.isInEditMode = value;
    },
    setIsContentChanged(value: boolean) {
      self.isContentChanged = value;
    },
    resetObservers() {
      self.isInEditMode = false;
      self.isContentChanged = false;
    }
  }))
  .views((self) => ({
    get isNeedUpdate() {
      return !self.isInEditMode && self.isContentChanged;
    }
  }));

export interface IUpdateObserverModel extends Instance<typeof UpdateObserver> {}
export {UpdateObserver};
