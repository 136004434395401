import {Store} from '@models/base/Store';
import {initialSelectedFilters, EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';
import {MedDRASearchValues} from '@stores-mobx/MedDRAFiltersStore/models/MedDRASearchValue';
import {MedDRASelectedFilters} from '@stores-mobx/MedDRAFiltersStore/models/MedDRASelectedFilters';
import {Instance, types} from 'mobx-state-tree';
import {storeState} from '@services/utils/Mst.utils';
import {MedDraDictionary} from '@stores-mobx/MedDRAFiltersStore/models/MedraDictionary';

const MedDRAFiltersStore = types.compose(
  Store,
  types
    .model('MedDRAFiltersStore', {
      medDRASelectedFilters: types.optional(MedDRASelectedFilters, initialSelectedFilters),
      medDRASearchValues: types.optional(MedDRASearchValues, {}),
      bodySystems: types.optional(MedDraDictionary, {
        medDraType: EMedDraType.BodySystems
      }),
      hlgt: types.optional(MedDraDictionary, {medDraType: EMedDraType.HLGT}),
      hlt: types.optional(MedDraDictionary, {medDraType: EMedDraType.HLT}),
      pt: types.optional(MedDraDictionary, {medDraType: EMedDraType.PT}),
      smq: types.optional(MedDraDictionary, {medDraType: EMedDraType.SMQ})
    })
    .actions((self) => ({
      afterCreate() {
        storeState(self, ['medDRASelectedFilters', 'pt']);
      },
      fetchDictionaries() {
        self.bodySystems.load({});
        self.hlgt.load({});
        self.hlt.load({});
        self.pt.load({});
        self.smq.load({});
      }
    }))
    .views((self) => ({
      get isFetching() {
        return (
          self.bodySystems.isFetching &&
          self.hlgt.isFetching &&
          self.hlt.isFetching &&
          self.pt.isFetching &&
          self.smq.isFetching
        );
      }
    }))
);

export interface IMedDRAFiltersStore extends Instance<typeof MedDRAFiltersStore> {}

export {MedDRAFiltersStore};
