import React, {memo, useCallback, useEffect} from 'react';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';

import {Button} from '../UIKit';

import s from './StudyDrugFilterDialogModal.module.css';
import ModalContent from './ModalContnent/ModalContnent';

const StudyDrugFilterDialogModal = () => {
  const {t} = useTranslation();
  const {studyDrugFilterStore} = useStore();
  const {
    isModalOpen,
    setModalOpen,
    getIsTempSelected,
    updateSelected,
    updateTempSelected,
    clearTempSelected,
    openConfirmation
  } = studyDrugFilterStore;

  useEffect(() => {
    updateTempSelected();
  }, [isModalOpen, updateTempSelected]);

  const confirmClick = () => {
    if (!getIsTempSelected) {
      openConfirmation();
    } else {
      updateSelected();
      setModalOpen(false);
    }
  };

  const cancelClick = useCallback(() => {
    setModalOpen(false);
    clearTempSelected();
  }, [setModalOpen, clearTempSelected]);

  return (
    <>
      {isModalOpen && (
        <>
          <div className={s.containerModal} />
          <div className={s.containerContent}>
            <div className={s.containerContentWrapper}>
              <div className={s.containerContentWrapperHeader}>
                <div className={s.containerContentWrapperHeaderText}>
                  <p className={s.title}>{t('studiesAndDrugs')}</p>
                  <p className={s.count}>{t('studiesDrugsFilter.studiesFound', {count: 1})}</p>
                </div>

                <div className={s.containerContentWrapperHeaderButtons}>
                  <Button
                    type="button"
                    variant="primary"
                    className="buttonHeader"
                    size="small"
                    onClick={cancelClick}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    className="buttonHeader"
                    size="small"
                    onClick={confirmClick}
                  >
                    {t('confirm')}
                  </Button>
                </div>
              </div>
              <div className={s.containerContentWrapperItems}>
                <ModalContent />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(observer(StudyDrugFilterDialogModal));
