import {IRootStore} from '@stores-mobx/RootStore';
import {
  applySnapshot,
  cast,
  getParent,
  getPath,
  getRoot,
  getSnapshot,
  IAnyStateTreeNode,
  isStateTreeNode,
  onPatch
} from 'mobx-state-tree';
import {storage} from '@services/utils/storage.utils';

const hash = '83dcefb7';

export const getRootStore = (self: IAnyStateTreeNode): IRootStore => {
  return getRoot<IRootStore>(self);
};

export const getParentStore = (self: IAnyStateTreeNode): IAnyStateTreeNode => {
  return getParent<IAnyStateTreeNode>(self);
};

export const storeState = (self: IAnyStateTreeNode, observerFields: string[]) => {
  observerFields.forEach((field) => {
    const storeName = `${getPath(self)}/${field}_${hash}`;
    if (isStateTreeNode(self[field])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const storedSnapshot: any = storage.getItem(storeName);
      if (storedSnapshot) {
        applySnapshot(self[field], storedSnapshot);
      }
      onPatch(self[field], () => {
        storage.setItem(storeName, getSnapshot(self[field]));
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const storedValue: any = storage.getItem(storeName);
      if (storedValue) {
        self[field] = cast(storedValue);
      }
      onPatch(self, (patch) => {
        if (patch.path === `/${field}`) {
          storage.setItem(storeName, patch.value);
        }
      });
    }
  });
};
