import {types, Instance, SnapshotOut} from 'mobx-state-tree';

const Modal = types
  .model('Modal', {
    isOpened: false
  })
  .actions((self) => ({
    open() {
      self.isOpened = true;
    },
    close() {
      self.isOpened = false;
    }
  }))
  .views(() => ({}));

export interface IModal extends Instance<typeof Modal> {}
export interface IModalSnapshotOut extends SnapshotOut<typeof Modal> {}

export {Modal};
