import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '@services/hooks/useStore';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import s from './StudyDrugFilterList.module.css';

const StudyDrugFilterList = () => {
  const {t} = useTranslation();
  const {studyDrugFilterStore} = useStore();
  const {
    getStudies,
    getMainDrug,
    getSecondaryDrugs,
    getComparatorsDrugs,
    getComparatorsDrugNamesComma,
    getComparatorsDrugClassesComma,
    getSecondaryDrugNamesComma,
    getSecondaryDrugClassesComma
  } = studyDrugFilterStore;

  return (
    <div className={s.wrapper}>
      <div className={s.columnWrapper}>
        <p className={s.title}>
          {t('studiesDrugsFilter.studies')} ({getStudies.length})
        </p>
        {getStudies.map((el, key) => (
          <p className={s.text} key={key}>
            {el.name}
            {el.receiveDate && <span className={s.textGrey}> ({el.receiveDate})</span>}
          </p>
        ))}
      </div>
      <div className={cn(s.columnWrapper, s.middleColumn)}>
        <p className={s.title}>
          {t('studiesDrugsFilter.targetDrugs')} (
          {getMainDrug ? getSecondaryDrugs.length + 1 : getSecondaryDrugs.length})
        </p>
        <p className={cn(s.text, s.middleColumnWrapper, s.firstRow)}>
          <span className={cn(s.textGrey, s.titleColumn)}>Main drug</span>
          <span>{getMainDrug?.name}</span>
          <span className={s.textGrey}>
            {getMainDrug?.drugClass
              ? `\u00A0(${getMainDrug.drugClass.drugClassName}/${getMainDrug.drugClass.drugClassCode})`
              : ''}
          </span>
        </p>
        <p className={cn(s.text, s.middleColumnWrapper)}>
          <span className={cn(s.textGrey, s.titleColumn)}>
            {t('studiesDrugsFilter.secondaryDrug')}
          </span>
          {getSecondaryDrugNamesComma}
          <span className={s.textGrey}>
            <span className={s.textGrey}>
              {getSecondaryDrugClassesComma ? `\u00A0(${getSecondaryDrugClassesComma})` : ''}
            </span>
          </span>
        </p>
      </div>
      <div className={s.columnWrapper}>
        <p className={s.title}>
          {t('studiesDrugsFilter.comparatorDrugs')} ({getComparatorsDrugs.length})
        </p>
        <p className={s.text}>
          {getComparatorsDrugNamesComma}
          <span className={s.textGrey}>
            <span className={s.textGrey}>
              {getComparatorsDrugClassesComma ? `\u00A0(${getComparatorsDrugClassesComma})` : ''}
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default observer(StudyDrugFilterList);
