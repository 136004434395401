import React, {useRef} from 'react';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {AutoSizer} from 'react-virtualized';
import ReactEcharts from 'echarts-for-react';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ChartColorCollectionType} from '@models/Chart/chart.types';
import {IMedDRAFilterData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {downloadPng} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';
import {DownloadButton, EmptyData, Loading} from 'src/components/UIKit';
import {PaperWidget} from 'src/components/PaperWidget';

import {ChartLegend} from '../ChartLegend';

import s from './Report.module.css';

interface Props {
  title: string;
  options: IFilterDataItem[] | IMedDRAFilterData[];
  colors: ChartColorCollectionType;
  isFetching: boolean;
  getChartOptions: echarts.EChartOption;
  isLegendVisible?: boolean;
  leftOffset?: boolean;
  wrapperClassName?: string;
  contentHeight?: number;
  withoutOffsets?: boolean;
}

const Report: React.FC<Props> = ({
  title,
  options,
  colors,
  isFetching,
  getChartOptions,
  isLegendVisible = true,
  leftOffset = false,
  wrapperClassName,
  contentHeight,
  withoutOffsets
}) => {
  const {t} = useTranslation();
  const ref = useRef(null);
  const handleSaveImage = () => {
    const node = ref.current as unknown as HTMLDivElement;
    downloadPng({node, title});
  };

  return (
    <div
      className={cn(s.reportContainer, wrapperClassName, {
        [s.leftOffset]: leftOffset && !withoutOffsets
      })}
    >
      <PaperWidget
        contentClassName={s.paperWidgetContent}
        wrapperClassName={s.paperWidgetWrapper}
        //@ts-ignore
        ref={ref}
      >
        <>
          <h2 className={s.casesContainerTitle}>{title}</h2>
          <DownloadButton className={s.downloadBtn} handlerSaveImage={handleSaveImage} />
        </>
        <div className={s.casesContainer} style={contentHeight ? {height: contentHeight} : {}}>
          {isFetching && !options.length ? (
            <Loading />
          ) : (
            <>
              {!options.length ? (
                <div className={s.emptyDataWrapper}>
                  <EmptyData text={t('dataIsEmpty')} />
                </div>
              ) : (
                <>
                  <AutoSizer>
                    {({width, height}) => (
                      <ReactEcharts option={getChartOptions} style={{width, height}} />
                    )}
                  </AutoSizer>
                  {isLegendVisible && <ChartLegend options={options} colorCollection={colors} />}
                </>
              )}
            </>
          )}
        </div>
      </PaperWidget>
    </div>
  );
};

export default Report;
