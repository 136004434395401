import {useStore} from '@services/hooks/useStore';
import {useMemo} from 'react';
import {getFilterParamsArray} from '@stores-mobx/DispropScoreStore/dataMappers';
import {finalDrugsRequest} from '@services/utils/Disprop.utils';

export const useGenerateReportParams = () => {
  const {globalFiltersStore, userFiltersStore, studyDrugFilterStore, medDRAFiltersStore} =
    useStore();
  const medDRASelectedParams = medDRAFiltersStore.medDRASelectedFilters.selectedParams;
  const {ageGroups, sexes, serious} = userFiltersStore.selectedFiltersStore;
  const {getStudiesNameArray, getComparatorsDrugsWithEmptyClass, getTargetDrugsWithEmptyClass} =
    studyDrugFilterStore;
  const studyData: string[] = getStudiesNameArray;
  const {startDate, endDate} = globalFiltersStore;
  // eslint-disable-next-line @typescript-eslint/camelcase
  const meddra_filter = useMemo(() => {
    return {
      body_systems: getFilterParamsArray(medDRASelectedParams.bodySystems),
      hlgt: getFilterParamsArray(medDRASelectedParams.hlgt),
      hlt: getFilterParamsArray(medDRASelectedParams.hlt),
      pt: getFilterParamsArray(medDRASelectedParams.pt, true),
      smq: getFilterParamsArray(medDRASelectedParams.smq, true)
    };
  }, [medDRASelectedParams]);

  const finalDrugsRequestTarget = useMemo(() => {
    return finalDrugsRequest(getTargetDrugsWithEmptyClass);
  }, [getTargetDrugsWithEmptyClass]);

  const finalDrugsRequestComparator = useMemo(() => {
    return finalDrugsRequest(getComparatorsDrugsWithEmptyClass);
  }, [getComparatorsDrugsWithEmptyClass]);

  // eslint-disable-next-line @typescript-eslint/camelcase
  const therapy_description = {
    target: finalDrugsRequestTarget,
    comparator: finalDrugsRequestComparator
  };

  const filters = useMemo(() => {
    return {
      age_groups: ageGroups?.map((el) => el?.code),
      sex: sexes?.map((el) => el?.code),
      serious: serious?.map((el) => el?.code),
      study_name: studyData
    };
  }, [ageGroups, serious, sexes, studyData]);

  const dates = useMemo(
    () => ({
      start_date: startDate,
      end_date: endDate
    }),
    [startDate, endDate]
  );

  return {
    meddra_filter,
    therapy_description,
    dates,
    filters
  };
};
