import echarts from 'echarts/lib/echarts';

import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
  DEFAULT_SERIES_OPTIONS,
  DEFAULT_GRID_OPTIONS,
  DEFAULT_LEGEND_OPTIONS
} from '../../../scenes/Research/components/UserFiltersCard/components/CaseVolume/components/CaseVolumeChart/chart.constants';

export class EChartsFiltersUtils {
  static getChartOptions = (): echarts.EChartOption => DEFAULT_CHART_OPTIONS;

  static getXAxisOptions = (): echarts.EChartOption.XAxis => DEFAULT_XAXIS_OPTIONS;

  static getYAxisOptions = (): echarts.EChartOption.YAxis => DEFAULT_YAXIS_OPTIONS;
  // @ts-ignore
  static getSeriesOptions = (): echarts.EChartOption.SeriesLine[] => DEFAULT_SERIES_OPTIONS;

  static getGridOptions = (): echarts.EChartOption.Grid => DEFAULT_GRID_OPTIONS;

  static getLegendOptions = (): echarts.EChartOption.Legend => DEFAULT_LEGEND_OPTIONS;
}
