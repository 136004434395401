import React, {FC, ReactElement} from 'react';

import s from './Tooltip.module.css';

interface ITooltip {
  children: ReactElement;
  text: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  trigger?: 'hover' | 'focus' | 'none';
}

const Tooltip: FC<ITooltip> = ({children, text, position = 'bottom', trigger = 'hover'}) => {
  return (
    <div className={s.tooltip} data-tooltip={text} data-placement={position} data-trigger={trigger}>
      {children}
    </div>
  );
};

export default Tooltip;
