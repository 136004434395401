class Storage {
  setItem(key: string, value: object) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  getNotParsedItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clearStorage() {
    localStorage.clear();
  }
}

const storage = new Storage();

export {storage};
