import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CaseVolumeStatTypes} from '@services/models/CaseStat/CaseStat.types';
import {Switcher} from 'src/components/UIKit/Switcher';
import {FilterGroupTitle} from 'src/components';

import {SplitByClassesContainer} from '../SplitByClasses';

import s from './CaseVolumeControls.module.css';

interface ISwitcherData {
  statTypes: CaseVolumeStatTypes[];
  activeStatType: CaseVolumeStatTypes;
  setActiveStatType: (statType: CaseVolumeStatTypes) => void;
  disabled: boolean;
}

interface Props {
  switcherData: ISwitcherData;
}

const CaseVolumeControls: React.FC<Props> = ({switcherData}) => {
  const {t} = useTranslation();

  const data = useMemo(
    () => ({
      ...switcherData,
      statNames: switcherData.statTypes.map((val) => t(val))
    }),
    [switcherData, t]
  );

  return (
    <div className={s.caseVolumeContainer}>
      <div className={s.caseVolumeControlsWrapper}>
        <FilterGroupTitle title={t('caseVolume')} />
        <div className={s.caseVolumeControls}>
          <Switcher {...data} />
          <SplitByClassesContainer />
        </div>
      </div>
      <FilterGroupTitle title={t('reportClasses')} className={s.reportClassesTitle} />
    </div>
  );
};

export default CaseVolumeControls;
