import React, {useRef, useState} from 'react';
import {ClickOutside} from 'src/components/ClickOutside';
import {ContainedButton} from 'src/components/UIKit';
import cn from 'classnames';

import s from './DropdownButton.module.css';

export interface IDropdownButtonOptions {
  text: string;
  onClick: () => void;
}

interface IProps {
  icon?: React.FC<{className?: string}>;
  title?: string;
  disabled?: boolean;
  options: IDropdownButtonOptions[];
  classNames?: string;
  children?: React.ReactNode;
}

const DropdownButton: React.FC<IProps> = ({
  icon,
  title,
  disabled,
  options,
  classNames = '',
  children
}) => {
  const [isListVisible, toggleListVisible] = useState(false);
  const selectRef: React.MutableRefObject<null> = useRef(null);

  const handleClickOutside = () => {
    if (isListVisible) {
      toggleListVisible(false);
    }
  };

  const handleClick = (callback: () => void) => {
    callback();
    toggleListVisible(false);
  };

  return (
    <div className={cn(s.wrapper, classNames)}>
      <ContainedButton
        Icon={icon}
        title={title ? title : ''}
        onClick={() => toggleListVisible(!isListVisible)}
        disabled={disabled}
        active
        dropDownIcon
      >
        {children && children}
      </ContainedButton>
      {isListVisible && (
        <ClickOutside handleClickOutside={handleClickOutside}>
          <div className={s.selectListContainer} ref={selectRef}>
            <div className={s.selectList}>
              {options.map((el, index) => (
                <li
                  className={s.listElement}
                  key={`liKey_${index}`}
                  onClick={() => handleClick(el.onClick)}
                >
                  {el.text}
                </li>
              ))}
            </div>
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default DropdownButton;
