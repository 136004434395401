import {ICaseDetail} from '@models/CaseDetail/CaseDetail.types';

export enum AdditionalInfoKeys {
  Age = 'age',
  Gender = 'gender',
  MedicalHistory = 'medicalHistory',
  ReportClassification = 'reportClassification',
  Study = 'study',
  Indication = 'indication',
  Country = 'country'
}

interface IAdditionalInfoValue {
  [key: string]: string;
}

export const getDotPositionToSliceText = (textData: string | null, MAX_TEXT_LENGTH: number) => {
  const positions = [];
  let pos = 0;

  if (!textData) return pos;

  for (let i = 0; i < textData.length; i++) {
    const foundPos = textData.indexOf('.', pos);
    if (foundPos !== -1) {
      positions.push(foundPos);
      pos = foundPos + 1;
    }
  }
  const maxDotIndexes = positions.filter((posNum) => posNum < MAX_TEXT_LENGTH);
  return maxDotIndexes.length ? maxDotIndexes[maxDotIndexes.length - 1] + 1 : textData.length;
};

export const getAdditionalInfo = (caseDetailData: ICaseDetail) => {
  const additionalInfo: {patient: IAdditionalInfoValue[]; study: IAdditionalInfoValue[]} = {
    patient: [],
    study: []
  };
  const getValue = (value: string | null) => value || '-';

  Object.entries(caseDetailData).forEach(([key, value]) => {
    switch (key) {
      case AdditionalInfoKeys.Age:
        additionalInfo.patient.push({[key]: `Age: ${getValue(value)}`});
        break;
      case AdditionalInfoKeys.Gender:
        additionalInfo.patient.push({[key]: `Sex: ${getValue(value)}`});
        break;
      case AdditionalInfoKeys.MedicalHistory:
        additionalInfo.patient.push({[key]: `${getValue(value)}`});
        break;
      case AdditionalInfoKeys.ReportClassification:
        additionalInfo.study.push({[key]: `Class: ${getValue(value)}`});
        break;
      case AdditionalInfoKeys.Study:
        additionalInfo.study.push({[key]: `Study: ${getValue(value)}`});
        break;
      case AdditionalInfoKeys.Indication:
        additionalInfo.study.push({[key]: `Indication: ${getValue(value)}`});
        break;
      case AdditionalInfoKeys.Country:
        additionalInfo.study.push({[key]: `Country: ${getValue(value)}`});
        break;
      default:
        break;
    }
  });
  return additionalInfo;
};
