import React from 'react';

export const PngIcon: React.FC<{className?: string}> = ({className}) => (
  <svg
    className={className}
    fill="#313236"
    fillOpacity=".7"
    width="24"
    height="24"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M502,98c5.523,0,10-4.477,10-10V40c0-5.523-4.477-10-10-10h-48c-5.523,0-10,4.477-10,10v14H174.826
			c-5.523,0-10,4.477-10,10s4.477,10,10,10H444v14c0,5.523,4.477,10,10,10h14v316h-14c-5.523,0-10,4.477-10,10v48
			c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-48c0-5.523-4.477-10-10-10h-14V98H502z M492,434v28h-28v-28H492z M464,78V50
			h28v28H464z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M135.987,54h-35c-5.523,0-10,4.477-10,10s4.477,10,10,10h35c5.523,0,10-4.477,10-10S141.51,54,135.987,54z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M419.07,440.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S420.93,442.79,419.07,440.93z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M373,438H68v-14c0-5.523-4.477-10-10-10H44V98h14c5.523,0,10-4.477,10-10V40c0-5.523-4.477-10-10-10H10
			C4.477,30,0,34.477,0,40v48c0,5.523,4.477,10,10,10h14v316H10c-5.523,0-10,4.477-10,10v48c0,5.523,4.477,10,10,10h48
			c5.523,0,10-4.477,10-10v-14h305c5.523,0,10-4.477,10-10S378.523,438,373,438z M20,78V50h28v28H20z M48,462H20v-28h28V462z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M398,102h-97c-5.523,0-10,4.477-10,10s4.477,10,10,10h97c12.131,0,22,9.869,22,22v178.444l-69.559-91.025
			c-9.529-12.47-24.566-19.315-40.23-18.3c-15.661,1.011-29.695,9.729-37.542,23.319l-45.958,79.603l-24.409-37.084
			c-6.647-10.099-17.833-16.228-29.922-16.396c-12.095-0.143-23.44,5.65-30.364,15.56L92,349.709V144c0-12.131,9.869-22,22-22
			h107.333c5.523,0,10-4.477,10-10s-4.477-10-10-10H114c-23.159,0-42,18.841-42,42v224c0,23.159,18.841,42,42,42h155.993
			c0.005,0,0.011,0.001,0.016,0.001s0.009-0.001,0.014-0.001H398c23.159,0,42-18.841,42-42V144C440,120.841,421.159,102,398,102z
			 M114,390c-7.814,0-14.684-4.098-18.588-10.254l63-90.169c3.168-4.536,8.131-7.126,13.692-7.016
			c5.533,0.076,10.451,2.771,13.493,7.393L251.446,390H114z M420,368c0,12.131-9.869,22-22,22H275.39l-36.376-55.267l50.976-88.294
			c4.563-7.903,12.403-12.773,21.51-13.361c9.104-0.583,17.509,3.234,23.05,10.485L420,355.384V368z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M266.57,104.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			s4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93s2.93-4.44,2.93-7.07S268.43,106.79,266.57,104.93z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M206.826,156.345c-23.159,0-42,18.841-42,42s18.841,42,42,42s42-18.841,42-42S229.985,156.345,206.826,156.345z
			 M206.826,220.345c-12.131,0-22-9.869-22-22c0-12.131,9.869-22,22-22c12.131,0,22,9.869,22,22
			C228.826,210.476,218.957,220.345,206.826,220.345z"
        />
      </g>
    </g>
  </svg>
);
