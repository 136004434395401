import {IPageParams} from '@models/Page/Page.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getRootStore} from '@services/utils/Mst.utils';
import {types, flow, Instance} from 'mobx-state-tree';
import {Request} from '@services/models/base/Request';
import {IDrug} from '@stores-mobx/StudyDrugFilterStore';

const INITIAL_STATE: IPageParams<IDrug> = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const Drugs = types
  .model('DrugStore', {
    data: types.frozen<IPageParams<IDrug>>(INITIAL_STATE),
    request: types.optional(Request, {})
  })
  .actions((self) => ({
    load: flow(function* () {
      const study: string = getRootStore(self).studyDrugFilterStore.getStudiesTempSelectComma;
      const response = yield self.request.send(getApiUrl(ApiVersions.v11, 'drugs.filter'), {
        method: 'get',
        params: {
          study
        }
      });
      if (response) {
        const {content, ...pagination} = response;

        self.data = {
          content,
          ...pagination
        };
      }
    })
  }))
  .views((self) => ({
    get fetchDrugsData() {
      return self.load;
    },
    get isFetching(): boolean {
      return self.request.isPending;
    },
    get isEmptyCases(): boolean {
      return self.request.isDone && !self.data?.content?.length;
    }
  }));

export interface IDrugsModel extends Instance<typeof Drugs> {}

export {Drugs};
