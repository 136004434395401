import React from 'react';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';

import DetailCaseCharts from './DetailCaseCharts';

const DetailCaseChartsContainer: React.FC<{}> = () => {
  const {caseDetailStore} = useStore();
  const {timeData, chartSettings, isFetching} = caseDetailStore;

  const chartData = {
    ...caseDetailStore,
    timeData,
    chartSettings
  };

  return <DetailCaseCharts chartData={chartData} isFetching={isFetching} />;
};

export default observer(DetailCaseChartsContainer);
