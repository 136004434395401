import React, {PureComponent} from 'react';

interface State {
  error: Error | null;
}

// catch JavaScript errors anywhere in their child component tree,
// log those errors, and display a fallback UI
// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidCatch(error: Error): void {
    this.setState({error});
  }

  render(): React.ReactNode {
    const {error} = this.state;
    const {children} = this.props;

    if (error) {
      console.error(error); // eslint-disable-line
      // render fallback UI
      return <div>{error}</div>;
    }

    return children;
  }
}

export default ErrorBoundary;
