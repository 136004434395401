import React, {memo, useCallback, useEffect, useState} from 'react';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import {CollapsableSearchComponent} from 'src/components/UIKit';
import {IStudies} from '@stores-mobx/StudyDrugFilterStore/model/Studies';
import {EDrugRole, IDrug} from '@stores-mobx/StudyDrugFilterStore';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {StudyNamesList} from './StudyNamesList';
import {DrugNamesList} from './DrugNamesList';
import {StudyOption, DrugOption} from './Options';
import warning from './warning.webp';
import s from './ModalContnent.module.css';

const ModalContent = () => {
  const {t} = useTranslation();
  const [arrayStudySearch, setArrayStudySearch] = useState<IStudies[]>([]);
  const [searchValueStudies, setSearchValueStudies] = useState<string>('');
  const [arrayDrugSearch, setArrayDrugSearch] = useState<IDrug[]>([]);
  const [searchValueDrug, setSearchValueDrug] = useState<string>('');

  const [isListCollapsed, setListCollapsed] = useState<boolean>(true);

  const {studyDrugFilterStore} = useStore();
  const {
    studies,
    getStudiesDict,
    studiesTempSelect,
    setStudiesTempSelect,
    drugs,
    drugsTempSelect,
    setDrugsTempSelect,
    updateDrugsTempSelectElement,
    setDrugsTempSelectElementRoleToMain
  } = studyDrugFilterStore;
  const drugContent = drugs?.data?.content ?? [];

  const isNotSelectedStudy = useCallback(
    (study: IStudies) => {
      const result = studiesTempSelect.find((el) => el.name === study.name);
      return !result;
    },
    [studiesTempSelect]
  );

  useEffect(() => {
    studies.fetchStudiesData();
  }, [studies]);

  useEffect(() => {
    const drugsToRemove = drugsTempSelect.filter(
      (d) => !drugContent.find((ad) => ad.name === d.name)
    );
    if (drugsToRemove.length) {
      drugsToRemove.forEach((drugToRemove) => setDrugsTempSelect(drugToRemove));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugContent.length]);

  useEffect(() => {
    drugs.load();
  }, [drugs, studiesTempSelect.length]);

  useEffect(() => {
    if (drugContent?.length > 0) {
      setArrayDrugSearch(drugContent!);
    } else {
      setArrayDrugSearch([]);
    }
  }, [drugContent]);

  useEffect(() => {
    if (getStudiesDict?.length > 0) {
      setArrayStudySearch(getStudiesDict.filter((el) => isNotSelectedStudy(el)));
    } else {
      setArrayStudySearch([]);
    }
  }, [
    getStudiesDict,
    getStudiesDict.length,
    isNotSelectedStudy,
    studiesTempSelect,
    studiesTempSelect.length
  ]);

  useEffect(() => {
    if (searchValueDrug?.length > 0) {
      setArrayDrugSearch(
        drugContent?.filter((el) =>
          el?.name?.toLowerCase()?.includes(searchValueDrug?.toLowerCase())
        )
      );
    } else {
      setArrayDrugSearch(drugContent!);
    }

    if (searchValueStudies?.length > 0) {
      setArrayStudySearch(
        getStudiesDict.filter(
          (el) =>
            isNotSelectedStudy(el) &&
            el?.name?.toLowerCase()?.includes(searchValueStudies?.toLowerCase())
        )
      );
    } else {
      setArrayStudySearch(getStudiesDict.filter((el) => isNotSelectedStudy(el))!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDrug, searchValueStudies]);

  const getTargetDrugs = drugsTempSelect.filter(
    (d) => d.drugRole?.toString() !== EDrugRole.ComparatorDrug
  );
  const getComparatorDrugs = drugsTempSelect.filter(
    (d) => d.drugRole?.toString() === EDrugRole.ComparatorDrug
  );

  const BlankOption: React.FC = () => (
    <div className={s.noContent}>
      <img alt="Warning" src={warning} className={s.warning} />
      <p className={s.warnText}>{t('studiesDrugsFilter.notSingleDrugAdded')}</p>
    </div>
  );

  return (
    <>
      <div className={s.contentModalWindow}>
        <div className={s.twoSidesContent}>
          <div className={s.leftContent}>
            <div className={s.search}>
              <CollapsableSearchComponent
                className={s.search}
                handleSearch={(e: string) => setSearchValueStudies(e)}
                searchValue={searchValueStudies}
                isListCollapsed={isListCollapsed}
                setListCollapsed={setListCollapsed}
                placeholder={t('studiesDrugsFilter.searchStudy')}
                small
              />
            </div>
            <StudyNamesList
              arrayStudySearch={arrayStudySearch}
              setSelectedStudies={setStudiesTempSelect}
            />
          </div>
          <div className={s.rightContent}>
            <div className={s.searchRight}>
              <CollapsableSearchComponent
                className={s.searchRight}
                handleSearch={(e: string) => setSearchValueDrug(e)}
                searchValue={searchValueDrug}
                isListCollapsed={isListCollapsed}
                setListCollapsed={setListCollapsed}
                placeholder={t('studiesDrugsFilter.searchDrug')}
                small
              />
            </div>
            <DrugNamesList
              arrayDrugSearch={arrayDrugSearch}
              selectedDrugs={drugsTempSelect}
              setSelectedDrugs={setDrugsTempSelect}
            />
          </div>
        </div>
        <div className={s.scrollRight}>
          <div className={s.rightSideModal}>
            <div className={s.content}>
              <p className={s.title}>
                {t('studiesDrugsFilter.studies')} ({studiesTempSelect?.length})
              </p>
              <div className={s.optionWrapper}>
                {studiesTempSelect?.length > 0 ? (
                  studiesTempSelect?.map((el, key) => (
                    <StudyOption key={key} study={el} onRemoveStudy={setStudiesTempSelect} />
                  ))
                ) : (
                  <BlankOption />
                )}
              </div>
              <p className={s.title}>
                {t('studiesDrugsFilter.targetDrugs')} ({getTargetDrugs.length})
                <span className={s.titleRight}>Main drug</span>
              </p>
              <div className={cn(s.optionWrapper)}>
                {getTargetDrugs.length > 0 ? (
                  getTargetDrugs.map((el, key) => (
                    <DrugOption
                      key={key}
                      drug={el}
                      onUpdateDrugClass={updateDrugsTempSelectElement}
                      osSetDrugRoleMain={setDrugsTempSelectElementRoleToMain}
                      onRemoveDrug={setDrugsTempSelect}
                    />
                  ))
                ) : (
                  <BlankOption />
                )}
              </div>
              <p className={s.title}>
                {t('studiesDrugsFilter.comparatorDrugs')} ({getComparatorDrugs.length})
              </p>
              <div className={cn(s.targetContent, s.optionWrapper)}>
                {getComparatorDrugs.length > 0 ? (
                  getComparatorDrugs?.map((el, key) => (
                    <DrugOption
                      key={key}
                      drug={el}
                      onUpdateDrugClass={updateDrugsTempSelectElement}
                      onRemoveDrug={setDrugsTempSelect}
                      osSetDrugRoleMain={setDrugsTempSelectElementRoleToMain}
                    />
                  ))
                ) : (
                  <BlankOption />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(observer(ModalContent));
