import echarts from 'echarts/lib/echarts';
import {DEFAULT_LEGEND_OPTIONS} from '@services/constants/barChartHorizontal.constants';
import {
  colorGray200,
  colorGray300,
  colorPurple100,
  colorPurple600,
  tooltipSettings
} from '@services/constants/chart.constants';

type Format = echarts.EChartOption.Tooltip.Format;
export type SeriesBoxplotType = Omit<echarts.EChartOption.SeriesBoxplot, 'data'> & {
  data: number[][];
};

const xAxisSettings = {
  splitAreaOpacity: 0.1,
  splitAreaColor: [colorPurple600, colorPurple100],
  splitLineColor: colorGray200,
  axisLineColor: colorGray300,
  zlevel: 2
};

const yAxisSettings = {
  splitAreaColor: [colorPurple100],
  offset: 100
};

const axisPointerSettings: echarts.EChartOption.AxisPointer = {
  show: true,
  type: 'line',
  lineStyle: {
    width: tooltipSettings.axisPointerLineStyleWidth,
    type: 'dashed',
    color: tooltipSettings.axisPointerLineStyleColor
  }
};

const tooltipFormatter = (param: Format | Format[]) => {
  const isFormatType = (paramProps: Format | Format[]): paramProps is Format => {
    return !Array.isArray(paramProps);
  };

  if (isFormatType(param)) {
    return `<div class="tooltip">
        <div class="tooltip-date">${param.name}</div>
        <div class="tooltip-value">Upper: ${param.data[5]}</div>
        <div class="tooltip-value">Q3: ${param.data[4]}</div>
        <div class="tooltip-value">Median: ${param.data[3]}</div>
        <div class="tooltip-value">Q1: ${param.data[2]}</div>
        <div class="tooltip-value">Lower: ${param.data[1]}</div>
      </div>`;
  } else {
    return '';
  }
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid = {
  top: 10,
  left: 0,
  right: 0,
  bottom: 0,
  containLabel: true
};

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  type: 'category',
  boundaryGap: true,
  splitArea: {
    show: true,
    areaStyle: {
      color: xAxisSettings.splitAreaColor,
      opacity: xAxisSettings.splitAreaOpacity
    }
  },
  splitLine: {
    // vertical
    show: false,
    lineStyle: {
      color: xAxisSettings.splitLineColor,
      type: 'dashed'
    }
  },
  axisTick: {
    show: false
  },
  axisLine: {
    // bottom line x
    show: false,
    lineStyle: {
      color: colorGray200
    }
  },
  axisPointer: axisPointerSettings,
  data: []
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis = {
  type: 'value',
  boundaryGap: true,
  splitArea: {
    show: true,
    areaStyle: {
      color: yAxisSettings.splitAreaColor
    }
  },
  splitLine: {
    show: true
  },
  axisLine: {
    show: false,
    lineStyle: {
      color: colorGray200
    }
  },
  axisTick: {
    show: false
  },
  axisPointer: {
    show: false
  }
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  position: 'top',
  trigger: 'item',
  axisPointer: {
    type: 'shadow'
  },
  formatter: (params) => tooltipFormatter(params),
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_SERIES_OPTIONS: SeriesBoxplotType[] = [
  {
    name: 'boxplot',
    type: 'boxplot',
    data: [],
    itemStyle: {
      color: colorPurple100,
      borderColor: colorPurple600,
      borderWidth: 1.2
    },
    emphasis: {
      itemStyle: {
        color: colorPurple100,
        shadowBlur: 0,
        shadowColor: 'transparent'
      }
    }
  }
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  legend: DEFAULT_LEGEND_OPTIONS,
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  series: DEFAULT_SERIES_OPTIONS
};
