import {cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {Store} from '@models/base/Store';
import {Request} from '@models/base/Request';
import {getRootStore} from '@services/utils/Mst.utils';
import {IGlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {TreeMapOptions} from '@models/Chart/TreeMapChartTypes';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {IDispropFetchParams, PeriodType} from '@models/DispropScore/DispropScore.types';

const DispropScoreHierarchyStore = types.compose(
  Store,
  types
    .model('DispropScoreHierarchyStore', {
      dispropScoreHierarchyData: types.optional(types.array(types.frozen<TreeMapOptions>()), []),
      requestHierarchy: types.optional(Request, {})
    })
    .actions((self) => {
      const actions = {
        loadHierarchy: flow(function* ({
          periodType,
          pt,
          filters,
          medraFilters,
          finalDrugsRequestTarget
        }: Partial<IDispropFetchParams> & {periodType: PeriodType}) {
          const selectedGlobalFilters: IGlobalFiltersStore = getRootStore(self).globalFiltersStore;

          // tree map
          const response: TreeMapOptions[] = yield self.requestHierarchy.send(
            getApiUrl(ApiVersions.v11, 'disp.score.hierarchy.analytics'),
            {
              method: 'post',
              data: {
                therapy: {
                  components: finalDrugsRequestTarget,
                  type: 'target'
                },
                filter: {
                  ...filters,
                  time_period: {
                    start_date: selectedGlobalFilters?.startDate,
                    end_date: selectedGlobalFilters?.endDate,
                    ds_period_label: periodType?.toLowerCase()
                  }
                },
                meddra_filter: medraFilters,
                client: 'string2',
                pt
              }
            }
          );
          self.dispropScoreHierarchyData = cast(response || []);
        })
      };

      return actions;
    })
    .views((self) => ({
      get isHierarchyFetching(): boolean {
        return self.requestHierarchy.isPending;
      },
      get memoDispropScoreHierarchyData() {
        return getSnapshot(self.dispropScoreHierarchyData);
      }
    }))
);

export interface IDispropScoreHierarchyStore extends Instance<typeof DispropScoreHierarchyStore> {}

export {DispropScoreHierarchyStore};
