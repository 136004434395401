import {getFormattedReportClasses} from '@services/utils/ChartHelpers/BarChart.helpers';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';
import {getReportClassesColorCollection} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

interface Props {
  withoutOffsets?: boolean;
}

const ReportClassificationContainer: React.FC<Props> = ({withoutOffsets}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {reportClasses: selectedReportClasses} = selectedFiltersStore;
  const {caseStatData, splitByClasses, isFetching} = caseStatStore;

  const formattedReportClasses: IFilterDataItem[] = getFormattedReportClasses({
    reportClasses: caseStatData.reportClasses,
    splitByClasses,
    selectedReportClasses
  });
  const reportClassesWithColors = getReportClassesColorCollection(caseStatData.reportClasses);
  const chartOptions = getChartOptions({
    options: formattedReportClasses,
    colors: reportClassesWithColors
  });

  const {t} = useTranslation();

  return (
    <Report
      title={t('reportClassification')}
      isFetching={isFetching}
      options={formattedReportClasses.slice(0, 3)} //FIXME change layout for more than 3 bar
      colors={reportClassesWithColors}
      getChartOptions={chartOptions}
      withoutOffsets={withoutOffsets}
      leftOffset
    />
  );
};

export default observer(ReportClassificationContainer);
