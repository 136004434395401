import React, {memo, MutableRefObject, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useClickOutside} from '@services/hooks/useClickOutside';
import {EDrugRole, IDrug, IDrugClass} from '@stores-mobx/StudyDrugFilterStore';
import dropdown from 'src/static/images/dropdown.webp';
import {useTranslation} from 'react-i18next';

import {Radio} from '../../../UIKit';

import s from './Options.module.css';

interface Props {
  drug: IDrug;
  onRemoveDrug?: (drug: IDrug) => void;
  onUpdateDrugClass?: (drug: IDrug) => void;
  osSetDrugRoleMain?: (drug: IDrug) => void;
}

const DrugOption: React.FC<Props> = ({
  drug,
  onRemoveDrug,
  onUpdateDrugClass,
  osSetDrugRoleMain
}) => {
  const {t} = useTranslation();
  const [openOption, setOpenOption] = useState<boolean>(false);
  const refWrapper: MutableRefObject<HTMLDivElement | null> = useRef(null);

  useClickOutside(refWrapper, () => {
    if (openOption) setOpenOption(!openOption);
  });

  const handleSelectClass = (drugClass: IDrugClass) => {
    if (onUpdateDrugClass) {
      onUpdateDrugClass({...drug, drugClass});
    }
  };

  const handleSelectMain = () => {
    if (osSetDrugRoleMain) {
      osSetDrugRoleMain(drug);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.borderContent}>
        <button
          className={s.remove}
          aria-label="Remove button"
          onClick={() => onRemoveDrug && onRemoveDrug(drug)}
        />
        <div className={s.selected} title={drug.name}>
          <p className={s.overflowText}>{drug.name}</p>
        </div>
        <div className={s.dropdown}>
          <div
            ref={refWrapper}
            className={s.selectedOption}
            onClick={() => setOpenOption(!openOption)}
          >
            <p className={!drug.drugClass ? s.notSelected : s.selectedSearch}>
              {drug.drugClass?.drugClassName
                ? `${drug.drugClass?.drugClassName} (${drug.drugClass?.drugClassCode})`
                : t('studiesDrugsFilter.notSelectedClass')}
            </p>
            <img src={dropdown} alt="Dropdown for choosing drug class" className={s.dropdownImg} />
            <div className={openOption ? s.contentDropdown : s.contentDropdownHidden}>
              {drug.drugClassesArray?.map((el, key: number) => (
                <div className={s.hover} key={key} onClick={() => handleSelectClass(el)}>
                  {`${el.drugClassName} (${el.drugClassCode})`}
                </div>
              ))}
            </div>
          </div>
        </div>
        {drug.drugRole !== EDrugRole.ComparatorDrug && (
          <div className={s.radioWrapper}>
            <Radio
              className={s.radioInput}
              dataKey={drug.name + drug.drugRole?.toString().replace(' ', '')}
              inputName={drug.name + drug.drugRole?.toString().replace(' ', '')}
              checked={drug.drugRole === EDrugRole.MainDrug}
              onChange={handleSelectMain}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(observer(DrugOption));
