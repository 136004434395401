import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ChartColorCollectionType} from '@models/Chart/chart.types';
import {BarChartVerticalUtils} from '@services/utils/Charts/BarChartVertical.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

export function getChartOptions({
  options,
  colors
}: {
  options: IFilterDataItem[];
  colors: ChartColorCollectionType;
}): echarts.EChartOption {
  const chartOptions = BarChartVerticalUtils.getChartOptions();
  const seriesOptions = BarChartVerticalUtils.getSeriesOptions();
  const xAxisOptions = BarChartVerticalUtils.getXAxisOptions();
  const yAxisOptions = BarChartVerticalUtils.getYAxisOptions();

  const xAxisData = options.map(({name}) => name);
  const seriesData = options.map(({count, name}) => ({
    name,
    value: count,
    itemStyle: {
      color: colors[name]
    }
  }));
  const countData = options.map(({count}) => count);

  const getInterval = () => {
    const maxCount = Math.max(...countData);
    const splitNumber = 4;

    const maxFloorValue = maxCount - (maxCount % 100) + 200;
    const interval = Math.floor(maxFloorValue / splitNumber);

    return {maxFloorValue, interval};
  };

  const chartModifiedOptions = {
    series: [
      {
        ...seriesOptions[0],
        data: seriesData
      }
    ],
    xAxis: {
      ...xAxisOptions,
      data: xAxisData
    },
    yAxis: {
      ...yAxisOptions,
      interval: getInterval().interval,
      max: getInterval().maxFloorValue
    }
  };

  return merge({}, chartOptions, chartModifiedOptions);
}
