import React from 'react';

export const FiltersIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <filter id="prefixFilter">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefixFilter)" transform="translate(-8 -8)">
      <g>
        <path
          fill="#313236"
          d="M15.535 6.5H19l-4.5 6v6l-2.5 1V11l2.703-4.055c.186-.278.498-.445.832-.445z"
          opacity=".5"
          transform="translate(8 8)"
        />
        <path
          stroke="#313236"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.5 4.5h13c.552 0 1 .448 1 1 0 .216-.07.427-.2.6l-4.8 6.4h0v6l-5 2v-8L4.7 6.1c-.331-.442-.242-1.069.2-1.4.173-.13.384-.2.6-.2zM8.5 6.5L9.5 6.5M11 12.5L13 12.5M11.5 6.5L16.5 6.5"
          transform="translate(8 8)"
        />
      </g>
    </g>
  </svg>
);
