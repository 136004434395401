import React, {useCallback} from 'react';
import {removeToken} from '@services/utils/Api.utils';
import {history} from '@services/router/RouterHistory';
import {RoutePaths} from '@services/constants/router.constants';

import {LogoSmallIcon} from '../../static/icons';
import {LogoTextIcon} from '../../static/icons/LogoTextIcon';

import s from './Logo.module.css';

const Logo: React.FC = () => {
  const handleRouteToLogin = useCallback(() => {
    removeToken();
    history.push(RoutePaths.welcome);
  }, []);

  return (
    <div className={s.logoContainer} onClick={handleRouteToLogin}>
      <LogoSmallIcon className={s.logo} />
      <LogoTextIcon className={s.logoText} />
    </div>
  );
};

export default Logo;
