export enum GlobalFiltersNames {
  TargetDrugs = 'targetDrugs',
  DataSources = 'dataSources',
  DrugClasses = 'drugClasses'
}

export interface GlobalDates {
  dateFrom: string;
  dateTo: string;
}
