import {IFilterCodeData, IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {integerWithSpaces} from '@services/utils/Functions.utils';
import React from 'react';
import cn from 'classnames';
import {CountLine} from 'src/components';

import s from './ReportClasses.module.css';

interface Props {
  reportClassItem: IFilterDataItem;
  color: string;
  selectReportClass: (isReportClassSelected: boolean) => void;
  selectedReportClasses: IFilterCodeData[];
}

const ReportClassControl: React.FC<Props> = ({
  reportClassItem,
  color,
  selectReportClass,
  selectedReportClasses
}) => {
  const {name, code, count, percent} = reportClassItem;
  const isReportClassSelected: boolean =
    selectedReportClasses.findIndex(({code: _code}) => _code === code) > -1;
  const backgroundColor = isReportClassSelected ? 'white' : color;

  return (
    <div
      className={cn(s.reportClassWrapper, {[s.selectedReportClass]: isReportClassSelected})}
      onClick={() => selectReportClass(isReportClassSelected)}
    >
      <div className={s.title}>
        <h4>{name}</h4>
        <h4>{integerWithSpaces(count)}</h4>
      </div>

      <CountLine percent={percent} backgroundColor={backgroundColor} />
    </div>
  );
};

export default ReportClassControl;
