import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';
import React from 'react';
import {FiltersWithPercentage} from 'src/components/FiltersWithPercentage';
import {EmptyData} from 'src/components/UIKit';

import s from './SeverityListedness.module.css';
import {SeverityListednessControlsContainer} from './SeverityListednessControlsContainer';

interface Props {
  severityData: IFilterDataItem[];
  selectedSeverity: IFilterCodeData[];
  listednessData: IFilterDataItem[];
  selectedListedness: IFilterCodeData[];
  setUserFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearUserFilter: (filterName: SelectedUserFilters, filterData: string | number) => void;
  withoutScroll?: boolean;
}

const SeverityListedness: React.FC<Props> = ({
  severityData = [],
  selectedSeverity,
  listednessData = [],
  selectedListedness,
  setUserFilter,
  clearUserFilter,
  withoutScroll
}) => (
  <>
    <SeverityListednessControlsContainer />
    {!severityData.length ? (
      <EmptyData text="No filter data" className={s.emptyData} />
    ) : (
      <div className={s.filtersContainer}>
        <FiltersWithPercentage
          className={s.gridFirst}
          filterData={severityData.map((i) => ({
            ...i,
            name: i.name
          }))}
          selectedFilters={selectedSeverity}
          selectFilter={setUserFilter}
          clearFilter={clearUserFilter}
          filterName={SelectedUserFilters.Severity}
          withoutScroll={withoutScroll}
        />
        {/*<FiltersWithPercentage*/}
        {/*  className={s.gridFirst}*/}
        {/*  filterData={listednessData.map((i) => ({*/}
        {/*    ...i,*/}
        {/*    name: `${i.name}`*/}
        {/*  }))}*/}
        {/*  selectedFilters={selectedListedness}*/}
        {/*  selectFilter={setUserFilter}*/}
        {/*  clearFilter={clearUserFilter}*/}
        {/*  filterName={SelectedUserFilters.Listedness}*/}
        {/*  withoutScroll={withoutScroll}*/}
        {/*/>*/}
      </div>
    )}
  </>
);

export default SeverityListedness;
