import {ISearchProps} from '@models/Search/Search.types';
import {useDebounceSearch} from '@services/hooks/useDebounce';
import React, {ChangeEvent, useEffect, useState} from 'react';
import cn from 'classnames';
import {SearchIcon} from 'src/static/icons';
import {ClickOutside} from 'src/components/ClickOutside';

import s from './Search.module.css';

interface Props extends ISearchProps {
  placeholder: string;
  isListCollapsed?: boolean;
  setListCollapsed?: (isSearchActive: boolean) => void;
}

const SearchComponent: React.FC<Props> = ({
  handleSearch,
  fetchData = () => {},
  searchValue,
  isListCollapsed,
  setListCollapsed,
  className,
  placeholder
}) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const setDebounceValue = useDebounceSearch(fetchData, 500);

  useEffect(() => {
    if (isListCollapsed) {
      setSearchActive(false);
      handleSearch('');
    }
  }, [handleSearch, isListCollapsed]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
    return setDebounceValue({searchValue: e.target.value});
  };

  const handleSetListCollapsed = (isCollapsed: boolean) => {
    if (setListCollapsed !== undefined) setListCollapsed(isCollapsed);
  };

  const handleClickOutside = () => {
    if (isSearchActive && !searchValue) {
      setSearchActive(false);
    }
  };

  const handleOpenSearch = () => {
    if (!isSearchActive) {
      setSearchActive(true);
      handleSetListCollapsed(false);
    }
    if (isSearchActive && searchValue === '') {
      setSearchActive(false);
    }
  };

  return (
    <button className={cn(s.searchContainer)} onClick={handleOpenSearch}>
      <SearchIcon className={s.iconSearch} />
      <ClickOutside handleClickOutside={handleClickOutside}>
        <input
          className={cn(s.searchInput, className)}
          type="text"
          value={searchValue}
          placeholder={placeholder}
          onChange={handleOnChange}
          onFocus={() => handleSetListCollapsed(false)}
        />
      </ClickOutside>
    </button>
  );
};

export default SearchComponent;
