import {ICaseDetailStore} from '@stores-mobx/CaseDetailStore';
import React, {CSSProperties, useEffect} from 'react';
import ReactEcharts from 'echarts-for-react';
import {AutoSizer} from 'react-virtualized';
import {getHeight} from '@services/utils/ChartHelpers/ChartCommon.helpers';

import {Loading} from '../../../../components';
import {DetailCaseChartsSidebarContainer} from '../DetailCaseChartsSidebar';
import {DetailCaseChartsLegendsContainer} from '../DetailCaseChartsLegends';
import {DetailCaseChartsDatesContainer} from '../DetailCaseChartsDates';

import s from './DetailCaseCharts.module.css';
import {getChartOptions} from './chartOptions';

const timelineStyle = {
  position: 'fixed',
  left: '0',
  top: 'calc(100% - 47px)',
  bottom: '0',
  right: '0',
  padding: '0px',
  margin: '0px auto',
  minWidth: '960px',
  maxWidth: '1440px',
  zIndex: '7'
};

const chartContainerStyle: CSSProperties = {
  backgroundColor: 'white',
  borderRadius: 4,
  position: 'relative'
};

const echartsStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  flexGrow: 1
};

interface Props {
  chartData: ICaseDetailStore;
  isFetching: boolean;
}

const DetailCaseCharts: React.FC<Props> = ({chartData, isFetching}) => {
  const {chartSettings} = chartData;

  useEffect(() => {
    /** data-zoom (timeline) and xAxis timedata with dates and months are moved to separated canvases */
    const timeData = document.querySelector('[data-zr-dom-id="zr_2"]') as HTMLCanvasElement;
    const timeline = document.querySelector('[data-zr-dom-id="zr_3"]') as HTMLCanvasElement;

    /** fixed position for timeline and timedata */
    Object.entries(timelineStyle).forEach(([key, value]) => {
      if (timeline) timeline.style.setProperty(key, value);
      if (timeData) timeData.style.setProperty(key, value);
    });
    if (timeline) timeline.style.zIndex = timelineStyle.zIndex;
    if (timeData) timeData.style.zIndex = timelineStyle.zIndex;
  });

  if (isFetching) {
    return (
      <div className={s.emptyChartContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <AutoSizer>
      {({width}) => (
        <div style={{width, height: getHeight(chartData.chartSettings), ...chartContainerStyle}}>
          <DetailCaseChartsSidebarContainer />
          <DetailCaseChartsLegendsContainer />
          <DetailCaseChartsDatesContainer />

          {!!chartSettings.thirdChartLabels.length && (
            <ReactEcharts style={echartsStyle} option={getChartOptions(chartData, width)} />
          )}
          <div className={s.timeline} />
        </div>
      )}
    </AutoSizer>
  );
};

export default DetailCaseCharts;
