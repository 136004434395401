import {DispropScoreStore} from '@stores-mobx/DispropScoreStore';
import {GlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {MedDRAFiltersStore} from '@stores-mobx/MedDRAFiltersStore';
import {NotificationStore} from '@stores-mobx/NotificationStore';
import {ReportsStore} from '@stores-mobx/ReportsStore';
import {UserFiltersStore} from '@stores-mobx/UserFiltersStore';
import {types, Instance} from 'mobx-state-tree';
import {CaseDetailStore} from '@stores-mobx/CaseDetailStore';
import {CasesStore} from '@stores-mobx/CasesStore';
import {AppStore} from '@stores-mobx/AppStore';
import {GroupedCasesDsurStore} from '@stores-mobx/GroupedCasesDsurStore';
import {GroupedCasesByStudyStore} from '@stores-mobx/GroupedCasesByStudyStore';
import {StudyDrugFilterStore} from '@stores-mobx/StudyDrugFilterStore';
import {DispropScoreFiltersStore} from '@stores-mobx/DispropScoreFiltersStore';
import {DemoStore} from 'src/demo/store-mobx/DemoStore';

const RootStore = types
  .model('RootStore', {
    appStore: types.optional(AppStore, {}),
    casesStore: types.optional(CasesStore, {}),
    groupedCasesDsurStore: types.optional(GroupedCasesDsurStore, {}),
    groupedCasesByStudyStore: types.optional(GroupedCasesByStudyStore, {}),
    caseDetailStore: types.optional(CaseDetailStore, {}),
    userFiltersStore: types.optional(UserFiltersStore, {}),
    dispropScoreStore: types.optional(DispropScoreStore, {}),
    dispropScoreFiltersStore: types.optional(DispropScoreFiltersStore, {}),
    globalFiltersStore: types.optional(GlobalFiltersStore, {}),
    medDRAFiltersStore: types.optional(MedDRAFiltersStore, {}),
    reportsStore: types.optional(ReportsStore, {}),
    notification: types.optional(NotificationStore, {}),
    studyDrugFilterStore: types.optional(StudyDrugFilterStore, {}),
    demoStore: types.optional(DemoStore, {})
  })
  .actions((self) => {
    const actions = {
      afterCreate() {
        self.appStore.init();
      }
    };
    return actions;
  });

export interface IRootStore extends Instance<typeof RootStore> {
  name?: string;
}

export {RootStore};
