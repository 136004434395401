import React from 'react';
import cn from 'classnames';
import {LoadingIcon} from 'src/static/icons';

import s from './Loading.module.css';

const Loading: React.FC<{className?: string}> = ({className}) => {
  return (
    <div className={cn(s.loading, className)}>
      <LoadingIcon />
    </div>
  );
};

export default Loading;
