import React from 'react';
import cn from 'classnames';

import s from './Radio.module.css';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  itemName?: string;
  inputName: string;
  dataKey: string;
  checked?: boolean;
  className?: string;
  isDisabled?: boolean;
}

const Radio: React.FC<Props> = ({itemName, inputName, dataKey, className, isDisabled, ...rest}) => (
  <>
    <input
      type="radio"
      className={s.radioInput}
      id={dataKey}
      name={inputName}
      disabled={isDisabled}
      {...rest}
    />
    <label htmlFor={dataKey} className={cn(className, s.radioLabel)}>
      <div className={s.radioMarker} />
      <span>{itemName}</span>
    </label>
  </>
);

export default Radio;
