export enum SeriesTypes {
  Scatter = 'scatter',
  Line = 'line',
  Bar = 'bar'
}

export interface ICaseDetail {
  id: string;
  caseData: string | null;
  source: string;
  caseNumber: string;
  reportClassification: string | null;
  study: string | null;
  country: string | null;
  gender: string | null;
  age: string | null;
  ageUnit: string | null;
  medicalHistory: string | null;
  indication: string | null;
  drugs: IDrugsInitialData[];
  events: IEventsInitialData[];
  labs: ILabsInitialData;
}

/** response.drugs */
export interface IDrugsInitialData {
  drugSid: string;
  medicinalProduct: string;
  activeSubstanceName: string | null;
  drugStartDate: string | null;
  drugStartDateFormat: string | null;
  drugEndDate: string | null;
  drugEndDateFormat: string | null;
  drugIntervalDosageDefinition: string | null;
  drugIntervalDosageUnitNumb: number | null;
  drugSeparateDosageNumb: number | null;
}

/** response.events */
export interface IEventsInitialData {
  reactionName: string;
  startDate: string | null;
  startDateFormat: string | null;
  endDate: string | null;
  endDateFormat: string | null;
  outcome: string | null;
}

/** response.labs */
export interface ILabsInitialDataValue {
  highTestRange: string | null;
  lowTestRange: string | null;
  testDate: string | null;
  testName: string | null;
  testResult: number | null;
  testResultText: string | null;
  testUnit: string | null;
}

export interface ILabsInitialData {
  [key: string]: ILabsInitialDataValue[];
}

export interface ILabsDataWithDates {
  [key: string]: LabsDataValueWithDatesType[];
}

export interface IGetLabsChartData {
  labsData: ILabsDataWithDates;
  startDate: Date;
  endDate: Date;
}

export interface IDrugsLabels {
  [key: string]: boolean;
}

export interface IEventsLabels {
  [key: string]: boolean;
}

export interface ILabelNames {
  name: string;
}

export interface IDefaultChartData {
  id: string;
  name: string;
}

export interface ILabsChartLabels extends ILabelNames {
  max: number | null;
  maxFloor: number;
  min: number | null;
  interval: number;
}

export interface IPeriodDates {
  name: string;
  dateStart: Date;
  dateEnd: Date;
  isFullPeriodExists: boolean;
}

export interface IPeriodsDates {
  name: string;
  dateStart: Date | string | null;
  dateEnd: Date | string | null;
}

export interface IAvailablePeriods {
  [key: string]: {dateStart: string | null; dateEnd: string | null};
}

/** testDate not null, filtered */
export type LabsDataValueWithDatesType = Omit<ILabsInitialDataValue, 'testDate'> & {
  testDate: string;
};

export type DrugsChartFormattedDataType = [number, number, string][];
export type EventsChartFormattedDataType = [number, number, string, boolean][];

export type DateProps = {
  startDate?: Date;
  endDate?: Date;
};
