import React from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {allowedRoutes} from '@services/constants/router.constants';
import {useStore} from '@services/hooks/useStore';
import HeaderContainer from '@components/Header/HeaderContainer';
import {usePagesPathInfo} from '@services/hooks/usePagesPathInfo';

import AppRouter from './AppRouter';
import {ErrorBoundary, Layout} from './components';
import {Notification} from './components/Notification';
import {StudyDrugFilterDialogModal} from './components/StudyDrugFilterDialogModal';
import s from './App.module.css';
import {DemoModal} from './demo';
import PtReviewModal from './demo/PtReviewModal';

const App: React.FC = () => {
  const {appStore} = useStore();
  const {session} = appStore;

  const location = useLocation();
  const {isWelcomePage} = usePagesPathInfo();

  const {pathname} = location;

  /** all routes are protected, auth is required now */
  const isRouteMatch = allowedRoutes.some((route) => pathname.includes(route)) || pathname === '/';
  if (!session.isAuthorized) {
    return null;
  }

  return (
    <ErrorBoundary>
      <StudyDrugFilterDialogModal />
      <DemoModal />
      <PtReviewModal />
      <Layout>
        <main className={s.main}>
          {!isWelcomePage && isRouteMatch && (
            <>
              <HeaderContainer onLogout={session.logout} />
            </>
          )}
          <AppRouter onLogin={session.login} isAllowed={session.isAuthorized} />
          <Notification />
        </main>
      </Layout>
    </ErrorBoundary>
  );
};

export default observer(App);
