import React from 'react';

export const SettingsIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 2c.552 0 1 .448 1 1v1.252c1.43.368 2.705 1.121 3.708 2.142l1.086-.626c.479-.276 1.09-.112 1.366.366l1 1.732c.276.478.113 1.09-.366 1.366l-1.084.627c.19.681.29 1.4.29 2.141 0 .742-.1 1.46-.29 2.141l1.084.627c.479.276.642.888.366 1.366l-1 1.732c-.276.478-.887.642-1.366.366l-1.086-.626c-1.003 1.02-2.278 1.774-3.708 2.142V21c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-1.252c-1.43-.368-2.705-1.121-3.708-2.142l-1.086.626c-.479.276-1.09.112-1.366-.366l-1-1.732c-.276-.478-.113-1.09.366-1.366l1.084-.627C4.1 13.46 4 12.741 4 12c0-.742.1-1.46.29-2.141l-1.084-.627c-.479-.276-.642-.888-.366-1.366l1-1.732c.276-.478.887-.642 1.366-.366l1.086.626C7.295 5.374 8.57 4.62 10 4.252V3c0-.552.448-1 1-1h2z"
      />
      <circle
        cx="12"
        cy="12"
        r="3"
        fill="#313236"
        fillOpacity=".5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
