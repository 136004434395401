import React from 'react';

export const ChangePasswordIcon: React.FC<{className?: string}> = ({className}) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M512 577.37a40 40 0 0 0-40 40v126a40 40 0 0 0 80 0v-126a40 40 0 0 0-40-40z"
      fill="#999999"
    />
    <path
      d="M831.85 417.37H386.17a0.16 0.16 0 0 1-0.15-0.16V287.83v-1.19a126 126 0 0 1 252 0v15.82a40 40 0 1 0 80 0v-13.8C718 168.84 620 74.31 500.38 81 392 87 306 176.77 306 286.64v130.57a0.16 0.16 0 0 1-0.16 0.16H192.15A64.15 64.15 0 0 0 128 481.52v397.69a64.15 64.15 0 0 0 64.15 64.16h639.7A64.15 64.15 0 0 0 896 879.21V481.52a64.15 64.15 0 0 0-64.15-64.15zM816 863.21a0.16 0.16 0 0 1-0.15 0.16h-607.7a0.16 0.16 0 0 1-0.15-0.16V497.52a0.15 0.15 0 0 1 0.15-0.15h607.7a0.15 0.15 0 0 1 0.15 0.15z"
      fill="#999999"
    />
  </svg>
);
