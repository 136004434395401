import React, {memo} from 'react';
import {observer} from 'mobx-react-lite';
import {IStudies} from '@stores-mobx/StudyDrugFilterStore/model/Studies';

import s from './StudyNamesList.module.css';

interface Props {
  arrayStudySearch: IStudies[];
  setSelectedStudies: (value: IStudies) => void;
}

const StudyNamesList: React.FC<Props> = ({arrayStudySearch, setSelectedStudies}) => {
  return (
    <div className={s.leftContentListWrapper}>
      <ul className={s.leftContentList}>
        {arrayStudySearch.map((el, key: number) => (
          <div className={s.list} key={key} onClick={() => setSelectedStudies(el)}>
            <li className={s.item}>
              {el?.name} {el?.receiveDate ? `(${el.receiveDate})` : ''}
            </li>
            <button aria-label="Add drug class" className={s.plus} />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default memo(observer(StudyNamesList));
