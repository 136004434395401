import {IBoxPlotData} from '@models/Reports/Reports.types';
import {BoxPlotChartUtils} from '@services/utils/Charts/BoxPlotChart.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

export function getChartOptions({options}: {options: IBoxPlotData[] | null}): echarts.EChartOption {
  const chartOptions = BoxPlotChartUtils.getChartOptions();
  const seriesOptions = BoxPlotChartUtils.getSeriesOptions();
  const xAxisOptions = BoxPlotChartUtils.getXAxisOptions();

  const seriesData =
    options && options.length
      ? options.map(({lower, q1, median, q3, upper}) => {
          return [lower, q1, median, q3, upper];
        })
      : [];

  const xAxisData = options && options.length ? options.map(({pt}) => pt) : [];

  const chartModifiedOptions = {
    series: [
      {
        ...seriesOptions,
        data: seriesData
      }
    ],
    xAxis: {
      ...xAxisOptions,
      axisLabel: {
        rotate: 30
      },
      data: xAxisData
    },
    animation: false
  };

  return merge({}, chartOptions, chartModifiedOptions);
}
