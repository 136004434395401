import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../static/styles/react-datepicker-custom.css';

import s from './DetailCaseChartsDates.module.css';

const DATE_FORMAT = 'd MMM yyyy';

interface Props {
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  disabled?: boolean;
}

const DetailCaseChartsDates: React.FC<Props> = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  setStartDate,
  setEndDate,
  disabled = false
}) => {
  return (
    <div className={s.datePickerWrapper}>
      <DatePicker
        className={s.datePicker}
        selected={startDate}
        onChange={setStartDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat={DATE_FORMAT}
        fixedHeight
        disabled={disabled}
        minDate={minDate}
        maxDate={endDate}
      />
      &mdash;
      <DatePicker
        className={s.datePicker}
        selected={endDate}
        onChange={setEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        dateFormat={DATE_FORMAT}
        fixedHeight
        disabled={disabled}
        minDate={startDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default DetailCaseChartsDates;
