import React from 'react';
import cn from 'classnames';

import s from './FilterGroupTitle.module.css';

const DEFAULT_TITLE: string = 'Title';

interface Props {
  title: string;
  subtitle?: string;
  className?: string;
}

const FilterGroupTitle: React.FC<Props> = ({title = DEFAULT_TITLE, subtitle, className}) => {
  return (
    <div className={cn(s.filterGroupTitle, className)}>
      <h4 className={s.title}>{title}</h4>
      {subtitle && <div className={s.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default FilterGroupTitle;
