import React, {forwardRef} from 'react';
import cn from 'classnames';

import s from './PaperWidget.module.css';

interface IProps {
  children: [JSX.Element, JSX.Element];
  wrapperClassName?: string;
  contentClassName?: string;
  leftOffset?: boolean;
  button?: boolean;
  onClick?: () => void;
  buttonText?: string;
  isEditMode?: boolean;
}

const PaperWidget: React.FC<IProps> = forwardRef<HTMLDivElement, IProps>(
  (
    {
      children,
      leftOffset,
      wrapperClassName,
      contentClassName,
      button,
      onClick,
      buttonText,
      isEditMode = false
    },
    ref
  ) => {
    const [header, content] = React.Children.toArray(children);

    return (
      <div
        ref={ref}
        className={cn(
          s.wrapper,
          {
            [s.wrapperLeftOffset]: leftOffset,
            [s.wrapperEditable]: isEditMode
          },
          wrapperClassName
        )}
      >
        {button ? (
          <>
            <div className={s.headerWithButton}>
              <div className={s.title}>{header}</div>
              <button className={s.button} onClick={onClick}>
                {buttonText}
              </button>
            </div>
            <div className={cn(s.content, contentClassName)}>{content}</div>
          </>
        ) : (
          <>
            <div className={cn(s.header, {[s.headerEditable]: isEditMode})}>{header}</div>
            <div className={cn(s.content, contentClassName)}>{content}</div>
          </>
        )}
      </div>
    );
  }
);

export default PaperWidget;
