enum RoutePathsKeys {
  Welcome = 'welcome',
  Research = 'research',
  Detail = 'detail',
  Reports = 'reports'
}

export enum HttpResponse {
  Success = 200,
  Unauthorized = 401,
  Forbidden = 403,
  BadRequest = 400,
  NotFound = 404
}

export const RoutePaths: {[key in RoutePathsKeys]: string} = {
  welcome: '/',
  research: '/research',
  reports: '/reports',
  detail: '/research/:caseId'
};

export const allowedRoutes: string[] = ['/research', '/welcome', '/reports'];
