import {IPageParams} from '@models/Page/Page.types';
import {columnSettings} from '@services/constants/columnSettings';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {types, flow, Instance} from 'mobx-state-tree';
import {Store} from '@services/models/base/Store';
import {Request} from '@services/models/base/Request';
import {SortDirection} from 'react-virtualized';
import {SortDirectionType} from 'react-virtualized/dist/es/Table';
import {IColumnSettings} from '@models/Table/Table.types';
import {ICaseProps, IParamsPagination} from '@models/Cases/Cases.types';
import {CheckedDataType, ISelectOptions} from '@models/Dropdown/Dropdown.types';
import {formatObjectNoEmptyFields} from '@services/utils/Data.utils';

const INITIAL_STATE: IPageParams<ICaseProps> = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const CasesStore = types.compose(
  Store,
  types
    .model('CasesStore', {
      data: types.frozen<IPageParams<ICaseProps>>(INITIAL_STATE),
      page: types.optional(types.number, 0),
      sortBy: types.optional(types.string, 'source'),
      sortDirection: types.frozen<SortDirectionType>(SortDirection.ASC),
      columnSettings: types.frozen<IColumnSettings[]>(columnSettings),
      searchValue: types.optional(types.string, ''),
      request: types.optional(Request, {}),
      requestCSV: types.optional(Request, {})
    })
    .actions((self) => ({
      load: flow(function* (data: IParamsPagination, params) {
        self.page = data.page || 0;
        self.sortBy = data.sortBy || self.sortBy;
        self.sortDirection = data.sortDirection || self.sortDirection;

        const response = yield self.request.send(getApiUrl(ApiVersions.v11, 'case.list'), {
          method: 'get',
          params: {
            page: self.page,
            size: 20,
            sort: `${self.sortBy},${self.sortDirection}`,
            ...(self.searchValue && {text: self.searchValue}),
            ...formatObjectNoEmptyFields(params)
          }
        });
        if (response) {
          const {content, ...pagination} = response;
          const updatedContent = self.page === 0 ? content : [...self.data.content, ...content];

          self.data = {
            content: updatedContent,
            ...pagination
          };
        }
      }),
      updateCasesColumnSettings(data: CheckedDataType) {
        const [key, checked] = data;

        self.columnSettings = self.columnSettings.map((column) => {
          return column.dataKey === key ? {...column, isVisible: checked} : column;
        });
      },
      selectAllColumnSettings(isVisible: boolean) {
        self.columnSettings = self.columnSettings.map((column) => {
          return {
            ...column,
            isVisible
          };
        });
      },
      handleSearch(data: IParamsPagination) {
        self.searchValue = data.searchValue === undefined ? self.searchValue : data.searchValue;
      },
      loadCSV: flow(function* (params: object) {
        return yield self.request.send(getApiUrl(ApiVersions.v11, 'case.list.csv'), {
          method: 'get',
          params: {
            page: 0,
            size: 100000,
            sort: `${self.sortBy},${self.sortDirection}`,
            ...formatObjectNoEmptyFields(params)
          }
        });
      })
    }))
    .views((self) => ({
      get isFetching(): boolean {
        return self.request.isPending;
      },
      get isEmptyCases(): boolean {
        return self.request.isDone && !self.data?.content?.length;
      },
      get getDropdownData(): ISelectOptions[] {
        return self.columnSettings.map(({dataKey, name, isVisible, disableCheckbox}) => ({
          dataKey,
          name,
          isVisible,
          disableCheckbox
        }));
      },
      get isFetchingCSV(): boolean {
        return self.requestCSV.isPending;
      }
    }))
);

export interface ICasesStore extends Instance<typeof CasesStore> {}

export {CasesStore};
