import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {CollapsableSearchComponent} from 'src/components';
import {Dropdown} from '@components/UIKit';
import {useTranslation} from 'react-i18next';
import {useStore} from '@services/hooks/useStore';

import WorkStatusButtons from '../WorkStatusButtons/WorkStatusButtons';

import s from './DemoModalHeader.module.css';

const DemoModalHeader = () => {
  const [value, setValue] = useState('');
  const {
    demoStore: {
      workStatus,
      searchValue,
      setWorkStatus,
      selectedOptions,
      updatedDiseasesList,
      setListDiseasesSearch,
      selectAllDiseasesInList
    }
  } = useStore();
  const {t} = useTranslation();

  useEffect(() => {
    setListDiseasesSearch(value);
  }, [value, setListDiseasesSearch]);

  return (
    <div className={s.headerContainer}>
      <div className={s.headerRight}>
        <div className={s.headerSearchOffset}>
          <CollapsableSearchComponent
            className={s.headerSearch}
            handleSearch={(e: string) => setValue(e)}
            searchValue={searchValue}
            placeholder={t('searchPlaceholder')}
          />
        </div>
        <Dropdown
          selectName={t('allTags')}
          options={selectedOptions}
          handleDataChecked={updatedDiseasesList}
          handleSelectAll={selectAllDiseasesInList}
          selectTopClassName={s.selectTopClassName}
          selectNameInOptions
        />
      </div>
      <WorkStatusButtons workStatus={workStatus} setWorkStatus={setWorkStatus} />
    </div>
  );
};

export default observer(DemoModalHeader);
