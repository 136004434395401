import React, {useRef, useState} from 'react';
import {CheckedDataType, ISelectOptions} from '@models/Dropdown/Dropdown.types';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {ArrowDownIcon} from 'src/static/icons';

import {ClickOutside} from '../../ClickOutside';
import {Checkbox} from '../Checkbox';
import {Loading} from '../Loading';

import s from './Dropdown.module.css';

interface Props {
  options?: ISelectOptions[];
  selectName: string;
  handleDataChecked: (data: CheckedDataType) => void;
  handleSelectAll: (checked: boolean) => void;
  dark?: boolean;
  disabled?: boolean;
  isFetching?: boolean;
  selectNameInOptions?: boolean;
  selectContainerClassName?: string;
  selectTopClassName?: string;
  withoutIcon?: boolean;
  selectAllDisabled?: boolean;
  long?: boolean;
  classNames?: string;
  useAmpersand?: boolean;
}

const DropdownComponent: React.FC<Props> = ({
  options,
  selectName,
  handleDataChecked,
  handleSelectAll,
  selectNameInOptions,
  disabled,
  isFetching,
  dark,
  selectContainerClassName,
  selectTopClassName,
  withoutIcon = false,
  selectAllDisabled = false,
  long,
  classNames = '',
  useAmpersand = false
}) => {
  const [isListVisible, toggleListVisible] = useState(false);
  const {t} = useTranslation();
  const selectRef: React.MutableRefObject<null> = useRef(null);

  const isSelectAllChecked =
    options && options!.filter((o) => !o.disableCheckbox).every((option) => option?.isVisible);

  const handleClickOutside = () => {
    if (isListVisible) {
      toggleListVisible(false);
    }
  };

  const selectedNames = options
    ?.filter((option) => option?.isVisible)
    .map(({name}) => name)
    .join(useAmpersand ? ' & ' : ', ');

  return (
    <div
      className={cn(
        s.selectContainer,
        {[s.selectContainerDark]: dark},
        selectContainerClassName,
        classNames
      )}
    >
      <div
        className={cn(
          s.selectTop,
          {[s.selectTopDisabled]: disabled || isFetching},
          selectTopClassName,
          long && s.long
        )}
        onClick={() => toggleListVisible(!isListVisible)}
      >
        <span>{selectNameInOptions && selectedNames!.length > 0 ? selectedNames : selectName}</span>
        {withoutIcon ? <span className={s.icon} /> : <ArrowDownIcon className={s.icon} />}
      </div>
      {isListVisible && (
        <ClickOutside handleClickOutside={handleClickOutside} useAlternateEvent>
          <ul className={cn(s.selectList, {[s.selectListFetching]: isFetching})} ref={selectRef}>
            {selectNameInOptions && <li className={s.selectName}>{selectName}</li>}
            <li>
              <Checkbox
                className={s.selectAllLabel}
                itemName={t('selectAll')}
                dataKey="selectAll"
                checked={isSelectAllChecked}
                onChecked={(checked: boolean) => handleSelectAll(checked)}
                disableCheckbox={selectAllDisabled || options!.length <= 1}
              />
            </li>
            {options?.map(({name, dataKey, isVisible, disableCheckbox}) => (
              <li key={dataKey}>
                <Checkbox
                  itemName={name}
                  dataKey={dataKey}
                  checked={isVisible}
                  disableCheckbox={disableCheckbox}
                  onChecked={(checked: boolean) => handleDataChecked([dataKey, checked])}
                />
              </li>
            ))}
            {isFetching && <Loading />}
          </ul>
        </ClickOutside>
      )}
    </div>
  );
};

export default DropdownComponent;
