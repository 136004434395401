export enum ReviewStatus {
  All = 'All',
  ToBeReviewed = 'To_be_reviewed',
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export interface IRank {
  drug: string;
  pt: string;
  rank: number;
}

export interface IArticleData {
  title: string;
  issue: string;
  pages: string;
  abstract: string;
  journal: string;
  authors: string;
  pubdate: string;
  pmid: string;
  mesh_terms: string;
  publication_types: string;
  chemical_list: string;
  keywords: string;
  doi: string;
  references: string;
  delete: boolean;
  affiliations: string;
  pmc: string;
  other_id: string;
  medline_ta: string;
  nlm_unique_id: string;
  issn_linking: string;
  country: string;
}

export interface IMapedEntity {
  name: string;
  source: string;
  level: string;
}

interface IParsedEntities {
  text: string;
  label: string;
  long_form?: string;
  start_char: number;
  end_char: number;
  sent_id: number;
  is_ae?: boolean;
  mapped_entity: IMapedEntity[];
}

export interface IDemoListItem {
  article_data: IArticleData;
  parsed_entites: IParsedEntities[];
  rank?: IRank[] | number;
  workStatus?: string;
}

export interface ITextElement {
  text: string;
  prefName: string;
  label: string | null;
  labelText?: string;
  labelIndex?: number;
  index?: number;
  longForm?: string;
}
