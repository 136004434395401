import React from 'react';
import {useTranslation} from 'react-i18next';
import {FilterGroupTitle} from 'src/components';

import s from './DemographicsControls.module.css';

interface Props {
  unknownAgeGroupsCount: number;
  unknownCountriesCount: number;
  unknownSexCount: number;
}

const DemographicsControls: React.FC<Props> = ({
  unknownAgeGroupsCount,
  unknownSexCount,
  unknownCountriesCount
}) => {
  const {t} = useTranslation();

  return (
    <div className={s.controlsContainer}>
      <FilterGroupTitle
        className={s.grid}
        title={t('ageGroup')}
        subtitle={t('unknownNCases', {n: unknownAgeGroupsCount})}
      />
      <FilterGroupTitle
        className={s.gridMiddle}
        title={t('sex')}
        subtitle={t('unknownNCases', {n: unknownSexCount})}
      />
      <FilterGroupTitle
        className={s.grid}
        title={t('country')}
        subtitle={t('unknownNCases', {n: unknownCountriesCount})}
      />
    </div>
  );
};

export default DemographicsControls;
