import {
  colorGray,
  colorGray250,
  colorGrayOpacity,
  colorPurple600,
  colorWhite,
  tooltipSettings
} from '@services/constants/chart.constants';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {integerWithSpaces} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';

export const DEFAULT_LEGEND_OPTIONS: echarts.EChartOption.Legend = {
  show: false,
  orient: 'horizontal',
  bottom: 0,
  data: [],
  icon: 'circle',
  selectedMode: false
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  show: true,
  trigger: 'item',
  axisPointer: {
    type: 'shadow'
  },
  position: 'top',
  formatter: (params) => formatterTooltip({params, isVertical: true}),
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid = {
  left: 10,
  bottom: 60,
  top: 20,
  right: 20,
  containLabel: true
};

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  type: 'category',
  boundaryGap: ['20%', '20%'],
  axisLine: {
    // bottom line x
    show: true,
    lineStyle: {
      color: colorGray,
      opacity: 0.5
    }
  },
  axisTick: {
    show: false
  },
  splitArea: {
    show: true,
    areaStyle: {
      color: [colorGrayOpacity, colorWhite],
      opacity: 0.5
    }
  },
  splitLine: {
    show: false
  },
  data: []
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis = {
  type: 'value',
  axisLine: {
    show: true,
    lineStyle: {
      color: colorGray,
      opacity: 0.5
    }
  },
  axisTick: {
    show: false
  },
  axisPointer: {
    show: false
  }
};

export const DEFAULT_SERIES_OPTIONS: echarts.EChartOption.SeriesBar[] = [
  {
    type: 'bar',
    data: [],
    barMinHeight: 10,
    itemStyle: {
      color: colorPurple600
    },
    barWidth: 30,
    label: {
      normal: {
        show: false,
        position: 'top',
        formatter: (param: echarts.EChartOption.Tooltip.Format) => {
          if (param && param.value) {
            return integerWithSpaces(+param.value);
          }
          return param.value;
        },
        fontSize: 13,
        color: colorGray250
      }
    }
  }
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  legend: DEFAULT_LEGEND_OPTIONS,
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  series: DEFAULT_SERIES_OPTIONS
};
