import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {genderColorCollection} from '@services/constants/chart.constants';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

const ReportBySexContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {isFetching, caseStatData} = caseStatStore;
  const {sexes: selectedSexes} = selectedFiltersStore;

  const data: IFilterDataItem[] = caseStatData.sex;

  const formattedOptions = data.filter(({name, code}) => {
    if (selectedSexes.length) {
      return selectedSexes.findIndex(({code: _code}) => _code === code) > -1;
    }
    return name;
  });
  const chartOptions = getChartOptions({options: formattedOptions, colors: genderColorCollection});

  const {t} = useTranslation();

  return (
    <Report
      title={t('caseCountBySex')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={genderColorCollection}
      getChartOptions={chartOptions}
      leftOffset
    />
  );
};

export default observer(ReportBySexContainer);
