import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {IGroupedCasesData} from '@models/Cases/GroupedCases.types';
import {IPageParams} from '@models/Page/Page.types';
import {IColumnSettings} from '@models/Table/Table.types';
import {groupedColumnSettings} from '@services/constants/columnSettings';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {flow, Instance, types} from 'mobx-state-tree';
import {SortDirection} from 'react-virtualized';
import {SortDirectionType} from 'react-virtualized/dist/es/Table';
import {formatObjectNoEmptyFields} from '@services/utils/Data.utils';

const INITIAL_STATE: IPageParams<IGroupedCasesData> = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const GroupedCasesDsurStore = types.compose(
  Store,
  types
    .model('GroupedCasesDsurStore', {
      data: types.frozen<IPageParams<IGroupedCasesData>>(INITIAL_STATE),
      page: types.optional(types.number, 0),
      sortBy: types.optional(types.string, 'ptName'),
      sortDirection: types.frozen<SortDirectionType>(SortDirection.ASC),
      columnSettings: types.frozen<IColumnSettings[]>(groupedColumnSettings),
      request: types.optional(Request, {}),
      requestCSV: types.optional(Request, {})
    })
    .actions((self) => ({
      load: flow(function* (data: IParamsPagination, params: object) {
        self.page = data.page || 0;
        self.sortBy = data.sortBy || self.sortBy;
        self.sortDirection = data.sortDirection || self.sortDirection;

        const response = yield self.request.send(getApiUrl(ApiVersions.v11, 'case.grouped'), {
          method: 'get',
          params: {
            groupBy: 'ptName',
            page: self.page,
            size: 20,
            sort: `${self.sortBy},${self.sortDirection}`,
            ...formatObjectNoEmptyFields(params)
          }
        });

        if (response) {
          const {content, ...pagination} = response;
          const updatedContent = self.page === 0 ? content : [...self.data.content, ...content];

          self.data = {
            content: updatedContent,
            ...pagination
          };
        }
      }),
      loadCSV: flow(function* (params: object) {
        return yield self.requestCSV.send(getApiUrl(ApiVersions.v11, 'case.grouped.csv'), {
          method: 'get',
          params: {
            groupBy: 'ptName',
            page: 0,
            size: 100000,
            sort: `${self.sortBy},${self.sortDirection}`,
            ...formatObjectNoEmptyFields(params)
          }
        });
      })
    }))
    .views((self) => ({
      get isFetching(): boolean {
        return self.request.isPending;
      },
      get isFetchingCSV(): boolean {
        return self.requestCSV.isPending;
      }
    }))
);

export interface IGroupedCasesStore extends Instance<typeof GroupedCasesDsurStore> {}

export {GroupedCasesDsurStore};
