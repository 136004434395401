import echarts from 'echarts/lib/echarts';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {
  colorGray200,
  colorGray300,
  colorPurple100,
  colorPurple600,
  colorPurpleOpacity,
  colorRed300,
  tooltipSettings
} from '@services/constants/chart.constants';
import {DataZoomType, DEFAULT_SIDEBAR_WIDTH, SeriesOptionsType} from '@models/Chart/chart.types';

import {handleIcon} from './handleIcon';

const xAxisSettings = {
  splitAreaOpacity: 0.1,
  splitAreaColor: [colorPurple600, colorPurple100],
  splitLineColor: colorGray200,
  axisLineColor: colorGray300,
  zlevel: 2
};

const yAxisSettings = {
  splitAreaColor: [colorPurple100],
  offset: 100
};

const dataZoomSettings = {
  start: 0,
  end: 100,
  backgroundColor: colorPurpleOpacity,
  fillerColor: colorPurple600,
  timelineHeight: '6px',
  handleSize: '10px',
  topOffset: 32,
  zlevel: 3
};

const gridSettings = {
  left: `${DEFAULT_SIDEBAR_WIDTH}px`,
  right: 1,
  bottom: 0,
  top: 0,
  height: 0
};

const axisPointerSettings: echarts.EChartOption.AxisPointer = {
  show: true,
  type: 'line',
  lineStyle: {
    width: tooltipSettings.axisPointerLineStyleWidth,
    type: 'dashed',
    color: tooltipSettings.axisPointerLineStyleColor
  }
};

const seriesSettings = {
  symbolSize: 8,
  animationDelay: (idx: number) => idx * 5,
  itemColor: colorPurple600,
  itemOpacity: 1,
  itemStyleColor: (param: echarts.EChartOption.Tooltip.Format) => {
    const {value} = param;
    let isSerious: boolean = false;

    if (value && Array.isArray(value) && value.length > 3) {
      const {3: serious} = value;
      isSerious = serious;
    }
    return isSerious ? colorRed300 : colorPurple600;
  }
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  position: 'top',
  trigger: 'item',
  formatter: (params) => formatterTooltip({params}),
  axisPointer: {
    type: 'cross',
    label: {
      show: false
    }
  },
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid[] = [
  {
    right: 1,
    containLabel: false,
    left: gridSettings.left,
    bottom: gridSettings.bottom,
    top: gridSettings.top,
    height: gridSettings.height
  },
  {
    right: 1,
    containLabel: false,
    left: gridSettings.left,
    bottom: gridSettings.bottom,
    top: gridSettings.top,
    height: gridSettings.height
  },
  {
    right: 1,
    containLabel: false,
    left: gridSettings.left,
    bottom: gridSettings.bottom,
    top: gridSettings.top,
    height: gridSettings.height
  },
  {
    right: 1,
    containLabel: false,
    left: gridSettings.left,
    bottom: gridSettings.bottom,
    top: gridSettings.top,
    height: gridSettings.height
  }
];

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis[] = [
  {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: {
      // bottom line x - timeline
      show: false,
      lineStyle: {
        color: xAxisSettings.axisLineColor
      }
    },
    zlevel: xAxisSettings.zlevel
  },
  {
    gridIndex: 1,
    type: 'category',
    data: [],
    boundaryGap: true,
    splitArea: {
      show: true,
      areaStyle: {
        color: xAxisSettings.splitAreaColor,
        opacity: xAxisSettings.splitAreaOpacity
      }
    },
    splitLine: {
      // vertical
      show: false,
      lineStyle: {
        color: xAxisSettings.splitLineColor,
        type: 'dashed'
      }
    },
    axisLine: {
      // bottom line x
      show: false,
      lineStyle: {
        color: 'transparent'
      }
    },
    axisPointer: axisPointerSettings
  },
  {
    gridIndex: 2,
    type: 'category',
    data: [],
    boundaryGap: true,
    splitLine: {
      show: false,
      lineStyle: {
        color: xAxisSettings.splitLineColor,
        type: 'dashed'
      }
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: xAxisSettings.splitAreaColor,
        opacity: xAxisSettings.splitAreaOpacity
      }
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'transparent'
      }
    },
    axisPointer: axisPointerSettings
  },
  {
    gridIndex: 3,
    type: 'category',
    data: [],
    boundaryGap: true,
    splitLine: {
      show: false,
      lineStyle: {
        color: xAxisSettings.splitLineColor,
        type: 'dashed'
      }
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: xAxisSettings.splitAreaColor,
        opacity: xAxisSettings.splitAreaOpacity
      }
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'transparent'
      }
    },
    axisPointer: axisPointerSettings
  }
];

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis[] = [
  {
    type: 'category',
    data: []
  },
  {
    gridIndex: 1,
    type: 'category',
    data: [],
    boundaryGap: true,
    splitArea: {
      show: true,
      areaStyle: {
        color: yAxisSettings.splitAreaColor
      }
    },
    splitLine: {
      show: true
    },
    axisLine: {
      lineStyle: {
        color: 'transparent'
      }
    },
    offset: yAxisSettings.offset,
    axisPointer: {
      show: false
    }
  },
  {
    gridIndex: 2,
    type: 'category',
    data: [],
    boundaryGap: true,
    splitArea: {
      show: true,
      areaStyle: {
        color: yAxisSettings.splitAreaColor
      }
    },
    splitLine: {
      show: true
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: 'transparent'
      }
    },
    offset: yAxisSettings.offset,
    axisPointer: {
      show: false
    }
  },
  {
    gridIndex: 3,
    type: 'value',
    data: [],
    boundaryGap: true,
    splitArea: {
      show: true,
      areaStyle: {
        color: yAxisSettings.splitAreaColor
      }
    },
    splitLine: {
      show: true
    },
    position: 'left',
    axisLine: {
      onZero: false,
      show: false,
      lineStyle: {
        color: colorGray300
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      verticalAlign: 'bottom',
      formatter: (value: number) => value,
      showMaxLabel: false,
      margin: 5,
      padding: [1, 0]
    },
    offset: 0,
    splitNumber: 4,
    axisPointer: {
      show: false
    }
  }
];

export const DEFAULT_DATA_ZOOM_OPTIONS: DataZoomType[] = [
  {
    type: 'slider',
    show: true,
    realtime: true,
    start: dataZoomSettings.start,
    end: dataZoomSettings.end,
    xAxisIndex: [0, 1, 2, 3],
    backgroundColor: dataZoomSettings.backgroundColor,
    fillerColor: dataZoomSettings.fillerColor,
    height: dataZoomSettings.timelineHeight,
    handleSize: dataZoomSettings.handleSize,
    borderColor: 'none',
    showDetail: false,
    top: dataZoomSettings.topOffset,
    zlevel: dataZoomSettings.zlevel,
    filterMode: 'none',
    handleIcon
  },
  {
    type: 'inside',
    realtime: true,
    xAxisIndex: [0, 1, 2, 3],
    zoomLock: true,
    filterMode: 'none'
  }
];

export const DEFAULT_SERIES_OPTIONS: SeriesOptionsType = [
  {
    name: 'Timeline',
    type: 'scatter',
    data: []
  },
  {
    name: 'Drugs',
    type: 'scatter',
    symbolSize: seriesSettings.symbolSize,
    data: [],
    animationDelay: seriesSettings.animationDelay,
    xAxisIndex: 1,
    yAxisIndex: 1,
    hoverAnimation: true, // hover
    cursor: 'pointer',
    large: true,
    itemStyle: {
      color: seriesSettings.itemColor,
      opacity: seriesSettings.itemOpacity
    },
    markLine: {
      silent: true,
      symbolSize: 1,
      lineStyle: {
        color: colorPurple600,
        width: 2,
        type: 'solid'
      },
      data: []
    }
  },
  {
    name: 'Adverse events',
    type: 'scatter',
    symbolSize: seriesSettings.symbolSize,
    data: [],
    animationDelay: seriesSettings.animationDelay,
    xAxisIndex: 2,
    yAxisIndex: 2,
    itemStyle: {
      color: seriesSettings.itemStyleColor,
      opacity: seriesSettings.itemOpacity
    },
    markLine: {
      silent: true,
      symbolSize: 1,
      lineStyle: {
        color: colorPurple600,
        width: 2,
        type: 'solid'
      },
      data: []
    }
  },
  {
    name: 'Lab profile',
    type: 'line',
    data: [],
    symbolSize: 8,
    showAllSymbol: true,
    animationDelay: seriesSettings.animationDelay,
    xAxisIndex: 3,
    yAxisIndex: 3,
    itemStyle: {
      color: colorPurple600,
      borderWidth: 4
    },
    lineStyle: {
      color: colorPurple600
    },
    symbol: 'circle'
  }
];

const DEFAULT_AXIS_POINTER_OPTIONS: echarts.EChartOption.AxisPointer = {
  link: [{xAxisIndex: 'all'}]
};

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  dataZoom: DEFAULT_DATA_ZOOM_OPTIONS,
  // @ts-ignore
  series: DEFAULT_SERIES_OPTIONS,
  axisPointer: DEFAULT_AXIS_POINTER_OPTIONS
};
