import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import DetailCaseCard from './DetailCaseCard';

const DetailCaseCardContainer: React.FC<{}> = () => {
  const {caseDetailStore} = useStore();
  const {data, isFetching} = caseDetailStore;

  return <DetailCaseCard caseDetailData={data} isFetching={isFetching} />;
};

export default observer(DetailCaseCardContainer);
