import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import DetailCaseChartsSidebar from './DetailCaseChartsSidebar';

const DetailCaseChartsSidebarContainer: React.FC<{}> = () => {
  const {caseDetailStore} = useStore();
  const {chartSettings, data} = caseDetailStore;

  return <DetailCaseChartsSidebar chartSettings={chartSettings} labs={data?.labs ?? {}} />;
};

export default observer(DetailCaseChartsSidebarContainer);
