import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Loading} from 'src/components/UIKit';
import cn from 'classnames';
import {formatDate} from '@services/utils/Date.utils';

import {IDemoListItem, ReviewStatus} from '../../models/Demo.types';

import s from './DemoModalTable.module.css';

interface IProps {
  literatureList: IDemoListItem[];
  isFetching: boolean;
  onClickRow: (value: string) => void;
}

const DemoModalTable: React.FC<IProps> = ({literatureList, isFetching, onClickRow}) => {
  if (isFetching) {
    return (
      <div className={s.emptyTable}>
        <Loading />
      </div>
    );
  }
  return (
    <InfiniteScroll
      dataLength={literatureList?.length}
      hasMore={false}
      loader={isFetching}
      height="calc(100vh - 350px)"
      next={() => {}}
    >
      <div className={s.content}>
        {literatureList.map((item) => {
          const {
            article_data: {pmid, title, keywords, journal, authors, pubdate},
            rank
          } = item;

          return (
            <div key={pmid}>
              <div className={s.row} onClick={() => onClickRow(pmid)}>
                <div className={s.rowPositionCenter}>
                  {rank && typeof rank === 'number' && (
                    <div
                      className={cn(s.rank, {
                        [s.RedRank]: rank > 0.66,
                        [s.BlueRank]: rank > 0.33 && rank < 0.66
                      })}
                    >
                      {rank.toFixed(2)}
                    </div>
                  )}
                </div>
                <div className={s.textPosition}>{title}</div>
                {keywords ? (
                  <div className={s.textPosition}>{keywords}</div>
                ) : (
                  <div className={s.rowPositionCenter}> - </div>
                )}
                <div className={s.textPosition}>{journal}</div>
                <div className={s.textPosition}>{authors}</div>
                <div className={s.rowPositionCenter}>
                  {formatDate(new Date(pubdate), 'dd/mm/yy')}
                </div>
                <div
                  className={cn(s.rowPositionCenter, {
                    [s.Red]: item?.workStatus === ReviewStatus.Rejected,
                    [s.Green]: item?.workStatus === ReviewStatus.Accepted
                  })}
                >
                  {item?.workStatus}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default DemoModalTable;
