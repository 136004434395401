export enum EMedDraType {
  BodySystems = 'bodySystems',
  HLGT = 'hlgt',
  HLT = 'hlt',
  PT = 'pt',
  SMQ = 'smq'
}

export enum MedDRAPtTypes {
  Default = '',
  Narrow = '0',
  Broad = '1'
}

export const MedDRADictType: Record<EMedDraType, string> = {
  [EMedDraType.BodySystems]: 'BS',
  [EMedDraType.HLGT]: 'HLGT',
  [EMedDraType.HLT]: 'HLT',
  [EMedDraType.PT]: 'PT',
  [EMedDraType.SMQ]: 'SMQ'
};

export interface IMedDRAFilterData {
  name: string;
  count: number;
  percent: number;
}

export interface IMedDraDictionariesData {
  code?: number;
  name: string;
}

export const initialSelectedFilters: {[key in EMedDraType]: IMedDraDictionariesData[]} = {
  bodySystems: [],
  hlgt: [],
  hlt: [],
  pt: [],
  smq: []
};
