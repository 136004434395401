import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import 'src/static/styles/react-toggle-custom.css';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import s from './SplitByClasses.module.css';

interface Props {
  splitByClasses: boolean;
  setSplitByClasses: (splitByClasses: boolean) => void;
  disabled?: boolean;
}

const SplitByClasses: React.FC<Props> = ({splitByClasses, setSplitByClasses, disabled}) => {
  const {t} = useTranslation();

  return (
    <div className={cn(s.splitByClassesContainer, {[s.splitByClassesDisabled]: disabled})}>
      <label>
        <Toggle
          defaultChecked={splitByClasses}
          icons={false}
          onChange={({target}) => {
            setSplitByClasses(target.checked);
          }}
        />
        <h4>{t('splitByClasses')}</h4>
      </label>
    </div>
  );
};

export default SplitByClasses;
