import React from 'react';
import cn from 'classnames';

import s from './CountLine.module.css';

interface Props {
  percent: number;
  backgroundColor: string;
  className?: string;
}

const CountLine: React.FC<Props> = ({percent, backgroundColor, className}) => {
  const countLineStyle = {
    width: `${percent}%`,
    backgroundColor
  };

  return (
    <div className={cn(s.countLine, className)}>
      <div className={s.countLinePercentage} style={countLineStyle} />
    </div>
  );
};

export default CountLine;
