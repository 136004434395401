import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, NoDataSection} from '@components/index';

import s from './StudyDrugEmptyPage.module.css';

interface IProps {
  onClick: (value: boolean) => void;
}

const StudyDrugEmptyPage: React.FC<IProps> = ({onClick}) => {
  const {t} = useTranslation();

  const onClickHandle = () => {
    onClick(true);
  };
  return (
    <div className={s.mainWrapper}>
      <div className={s.columnWrapper}>
        <NoDataSection
          title={t('studiesDrugsFilter.nothingIsSelected')}
          description={t('studiesDrugsFilter.selectStudiesAndDrugs')}
          className={s.section}
        />
        <Button
          type="button"
          variant="primary"
          size="large"
          onClick={onClickHandle}
          className={s.btn}
        >
          <span className={s.buttonText}>{t('studiesDrugsFilter.startConfiguration')}</span>
        </Button>
      </div>
    </div>
  );
};

export default observer(StudyDrugEmptyPage);
