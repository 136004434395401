import {
  ICaseVolumeChart,
  IFilterDataItem,
  SeriousName,
  SeriousNameFormatted
} from '@models/CaseStat/CaseStat.types';
import {SortDirection} from 'react-virtualized';
import {SortDirectionType} from 'react-virtualized/dist/es/Table';

export const getUnknownFilterCount = (filterData: IFilterDataItem[]): number => {
  const unknownFilterData = filterData.find((ag) => ag?.name?.toLowerCase() === 'unknown');
  if (unknownFilterData) {
    return unknownFilterData.count;
  }
  return 0;
};

export const mapToSeriousNameFormatted = (seriousData: IFilterDataItem[]): IFilterDataItem[] =>
  seriousData.map((serious) => {
    switch (serious.name) {
      case SeriousName.Yes:
        return {
          ...serious,
          name: SeriousNameFormatted.Serious
        };
      case SeriousName.No:
        return {
          ...serious,
          name: SeriousNameFormatted.NotSerious
        };
      default:
        return serious;
    }
  });

export const mapToSeriousName = (seriousName: string[]): string[] =>
  seriousName.map((name) => {
    switch (name) {
      case SeriousNameFormatted.Serious:
        return SeriousName.Yes;
      case SeriousNameFormatted.NotSerious:
        return SeriousName.No;
      default:
        return name;
    }
  });

export const getFilteredCaseVolumeChartData = ({
  chartData,
  splitByClasses,
  selectedReportClasses
}: {
  chartData: ICaseVolumeChart;
  splitByClasses: boolean;
  selectedReportClasses: string[];
}) => {
  return Object.entries(chartData).reduce<ICaseVolumeChart>((acc, [key, value]) => {
    if (splitByClasses && selectedReportClasses.includes(key.toLowerCase())) {
      acc[key] = value;
    }
    if (!splitByClasses || !selectedReportClasses.length) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const sortByUnknownAgeGroups = (ageGroups: IFilterDataItem[]) => {
  const unknownAgeGroup: IFilterDataItem | undefined = ageGroups.find(
    (el) => el?.name?.toLowerCase() === 'unknown'
  );
  const filteredAgeGroups = ageGroups.filter(({name}) => name?.toLowerCase() !== 'unknown');
  return unknownAgeGroup !== undefined
    ? [...filteredAgeGroups, unknownAgeGroup]
    : filteredAgeGroups;
};

export const sortByProperty = (sortBy: string, sortDirection: SortDirectionType) => {
  const sortOrder = sortDirection === SortDirection.DESC ? 1 : -1;
  // @ts-ignore
  return function (a, b) {
    if (a[sortBy] && b[sortBy]) {
      // eslint-disable-next-line no-nested-ternary
      const result = a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
      return result * sortOrder;
    }

    if (a[sortBy] && !b[sortBy]) {
      return sortOrder;
    }

    if (!a[sortBy] && b[sortBy]) {
      return -1 * sortOrder;
    }

    return 1;
  };
};
