import {
  IBoxPlotData,
  IBoxPlotOnset,
  IReportData,
  ReportsChartFormattedDataType
} from '@models/Reports/Reports.types';

export const getTimeline = (reportDataProps: IBoxPlotData[]): number[] => {
  const reportDays = reportDataProps.reduce<number[]>((acc, {onset}) => {
    const daysArr = onset.map(({days}) => days);
    acc.push(...daysArr);
    return acc;
  }, []);

  const minDate = Math.min(...reportDays);
  const maxDate = Math.max(...reportDays);

  const timeline = [];
  for (let i = minDate; i <= maxDate; i++) {
    timeline.push(i);
  }
  return timeline;
};

const filterSelectedPt = ({data, selectedPt}: {data: IReportData[]; selectedPt: string[]}) => {
  return selectedPt.length ? data.filter(({name}) => selectedPt.includes(name)) : data;
};

export const getReportsLabels = ({data}: {data: IBoxPlotData[]}): string[] => {
  return data.map(({pt}) => pt);
};

export const getTimeToUnsetReportFormattedData = ({
  data,
  timeline
}: {
  data: IBoxPlotData[];
  timeline: number[];
}): ReportsChartFormattedDataType => {
  const res = data.reduce<ReportsChartFormattedDataType>((acc, timeToOnsetItem, idx) => {
    timeToOnsetItem.onset.forEach((item) => {
      const yValue = timeline.findIndex((time) => time === item.days);
      acc.push([idx, yValue, item.count]); // x, y, value
    });
    return acc;
  }, []);
  return res;
};

export const getBoxPlotReportFormattedData = ({
  boxPlotData,
  timeline,
  selectedPt
}: {
  boxPlotData: IReportData[];
  timeline: number[];
  selectedPt: string[];
}): ReportsChartFormattedDataType => {
  const filteredData = filterSelectedPt({data: boxPlotData, selectedPt});

  return filteredData.reduce<ReportsChartFormattedDataType>((acc, boxPlotItem, idx) => {
    boxPlotItem.data.forEach((item) => {
      const yValue = timeline.findIndex((time) => time === item.date);
      acc.push([idx, yValue, item.value]); // x, y, value
    });
    return acc;
  }, []);
};

export const randomValue = (from: number, to: number) =>
  Math.floor(Math.random() * (to - from) + from);

export const randomData = (data: IReportData[]) => {
  return data.reduce<IReportData[]>((acc, reportItem) => {
    const mappedData = reportItem.data.map(() => ({
      date: randomValue(0, 300),
      value: randomValue(0, 100)
    }));
    acc.push({...reportItem, data: mappedData});
    return acc;
  }, []);
};

export const reduceBoxPlotOnsetData = (data: IBoxPlotData[]) => {
  return data.map((opt) => {
    const onsetDays = opt.onset.map(({days}) => days);
    const maxDay = Math.max(...onsetDays);
    const maxOnset = opt.onset.find(({days}) => days === maxDay) as IBoxPlotOnset;
    return {
      ...opt,
      onset: [...opt.onset.slice(0, 80), maxOnset] // chart doesn't render more items, too tight
    };
  });
};
