import React, {useState} from 'react';
import {ICaseDetail} from '@models/CaseDetail/CaseDetail.types';
import {useTranslation} from 'react-i18next';
import {Loading} from 'src/components/UIKit';
import {PaperWidget} from 'src/components/PaperWidget';
import {ArrowLeftIcon} from 'src/static/icons';
import {Button} from '@components/UIKit';
import {history} from '@services/router/RouterHistory';

import {EmptyData} from '../../../../components';

import s from './DetailCaseCard.module.css';
import {AdditionalInfoKeys, getAdditionalInfo} from './DetailCaseCard.helpers';

const MAX_TEXT_LENGTH = 1200;

interface Props {
  caseDetailData: ICaseDetail;
  isFetching?: boolean;
}

const MedicalHistory: React.FC<{valueIdx: number; valueText: string}> = ({valueIdx, valueText}) => {
  const {t} = useTranslation();

  return (
    <div>
      <div>{t('medicalHistory')}:</div>
      {valueText.split(',').map((text, textIdx) => (
        <div key={`${valueIdx}-${textIdx}`}>{text}</div>
      ))}
    </div>
  );
};

const DetailCaseHeader: React.FC<{caseNumber: string | null | undefined}> = ({caseNumber}) => {
  const {t} = useTranslation();

  return (
    <div className={s.caseHeaderContainer}>
      <Button
        onClick={() => {
          history.goBack();
        }}
        className={s.icon}
      >
        <ArrowLeftIcon className={s.iconArrowLeft} />
      </Button>
      <h1>
        {t('case')} {caseNumber || null}
      </h1>
    </div>
  );
};

const DetailCaseContent: React.FC<Props> = ({caseDetailData, isFetching}) => {
  const [textHidden, setTextHidden] = useState(true);
  const [maxTextDotPosition, setMaxTextDotPosition] = useState(MAX_TEXT_LENGTH);

  const {t} = useTranslation();

  if (isFetching) {
    return <Loading />;
  }

  const {caseData} = caseDetailData;
  const additionalInfoData = Object.entries(getAdditionalInfo(caseDetailData));
  const isTextShouldBeHidden = caseData && caseData.length > MAX_TEXT_LENGTH;

  if (!caseDetailData.caseData && !additionalInfoData.length && !isFetching) {
    return <div className={s.caseContentCenter}>No case detail</div>;
  }

  const handleTextHidden = () => {
    setTextHidden(!textHidden);

    if (!textHidden) {
      setMaxTextDotPosition(MAX_TEXT_LENGTH);
    } else {
      setMaxTextDotPosition(caseData ? caseData.length : MAX_TEXT_LENGTH);
    }
  };

  return (
    <div className={s.caseContentContainer}>
      <div className={s.mainInfo}>
        <h2>Case narrative</h2>
        {caseData ? (
          <p className={s.primaryText}>
            {caseData.slice(0, maxTextDotPosition)}
            {isTextShouldBeHidden && textHidden ? '...' : null}
          </p>
        ) : (
          <EmptyData text={t('noCaseDetailText')} className={s.emptyData} />
        )}

        {isTextShouldBeHidden && (
          <div className={s.toggleTextBtn} onClick={handleTextHidden}>
            Read {textHidden ? 'more' : 'less'}
          </div>
        )}
      </div>

      <div className={s.additionalInfo}>
        {additionalInfoData.map(([key, value], idx) => {
          return (
            <div key={idx}>
              <h2>{key}</h2>
              <div className={s.secondaryText}>
                {value.map((valueData, valueIdx) => {
                  return Object.entries(valueData).map(([valueKey, valueText]) => {
                    if (valueKey === AdditionalInfoKeys.MedicalHistory) {
                      return (
                        <MedicalHistory key={valueIdx} valueIdx={valueIdx} valueText={valueText} />
                      );
                    }
                    return <div key={valueIdx}>{valueText}</div>;
                  });
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DetailCaseCard: React.FC<Props> = (props) => {
  const {caseDetailData} = props;
  return (
    <PaperWidget>
      <DetailCaseHeader caseNumber={caseDetailData?.caseNumber} />
      <DetailCaseContent {...props} />
    </PaperWidget>
  );
};

export default DetailCaseCard;
