import React from 'react';
import {SelectedUserFilters} from '@models/CaseStat/CaseStat.types';
import {
  seriousColorCollection,
  seriousBoxShadowCollection
} from '@services/constants/chart.constants';
import {useStore} from '@services/hooks/useStore';
import {integerWithSpaces, sumOfCases} from '@services/utils/Functions.utils';
import {observer} from 'mobx-react-lite';
import {getChartOptions, PieChart} from 'src/components/PieChart';

const CasePropertiesPieChartContainer: React.FC<{className: string}> = ({className}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {serious: selectedSeriousness, setUserFilter} = selectedFiltersStore;
  const chartData = caseStatStore.caseStatData.serious;

  const selectedSeriousnessCodes = selectedSeriousness.map(({code = 0}) => code?.toString());
  return (
    <PieChart
      className={className}
      chartData={chartData}
      selectedFilters={selectedSeriousness}
      setFilter={setUserFilter}
      filterName={SelectedUserFilters.Seriousness}
      colorCollection={seriousColorCollection}
      boxShadowCollection={seriousBoxShadowCollection}
      options={getChartOptions({
        chartData,
        sumOfCases: integerWithSpaces(
          sumOfCases({chartData, selectedFilters: selectedSeriousnessCodes})
        ),
        selectedFilters: selectedSeriousness,
        colorCollection: seriousColorCollection,
        filterName: SelectedUserFilters.Seriousness
      })}
    />
  );
};

export default observer(CasePropertiesPieChartContainer);
