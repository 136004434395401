import {cast, getSnapshot, Instance, types} from 'mobx-state-tree';
import {Store} from '@models/base/Store';
import {IPageParams} from '@models/Page/Page.types';
import {SortDirection} from 'react-virtualized';
import {SortDirectionType} from 'react-virtualized/dist/es/Table';
import {IDispropScoreData} from '@models/DispropScore/DispropScore.types';
import {getRootStore, storeState} from '@services/utils/Mst.utils';
import {CheckedDataType, ISelectOptions} from '@models/Dropdown/Dropdown.types';
import {mapToSelectTargetDrugsOptions} from '@services/utils/Dropdown.utils';
import {dictionaries} from '@services/mocks/mockData.json';
import {EDispScoreFiltersNames} from '@models/DispropScoreFilters/DispropScoreFilters.types';
import {sortByProperty} from '@services/utils/UserFilters.utils';
import {checkRiskRsiByOption, searchedDispropScoreData} from '@services/utils/Disprop.utils';
import {IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';

const DispropScoreFiltersStore = types.compose(
  Store,
  types
    .model('DispropScoreFiltersStore', {
      riskSelectOptions: types.optional(
        types.array(types.frozen<ISelectOptions>()),
        mapToSelectTargetDrugsOptions(dictionaries.riskSelectOptions)
      ),
      sortBy: types.optional(types.string, 'aeCounts'),
      sortDirection: types.frozen<SortDirectionType>(SortDirection.DESC),
      searchValue: types.optional(types.string, ''),
      isEditMode: types.optional(types.boolean, false),
      selectedPTList: types.frozen<IMedDraDictionariesData[]>([])
    })
    .actions((self) => ({
      setIsEditMode: (isOpen: boolean) => {
        self.isEditMode = isOpen;
      },
      setSort({sortBy, sortDirection}: {sortBy: string; sortDirection: SortDirectionType}) {
        self.sortBy = cast(sortBy || self.sortBy);
        self.sortDirection = cast(sortDirection || self.sortDirection);
      },
      // search
      setDispropScoreDataSearch(searchValue: string) {
        self.searchValue = searchValue;
      },
      setSelectedPTList: (filterData: IMedDraDictionariesData[]) => {
        self.selectedPTList = cast(filterData);
      },
      clearSelectedPTList: () => {
        self.selectedPTList = [];
      },
      updateRiskSelectOptions: (filterName: EDispScoreFiltersNames, data: CheckedDataType) => {
        const [key, checked] = data;

        const updatedSelectOptions = self[filterName].map((option) => {
          return option.dataKey === key
            ? {...option, isVisible: checked}
            : {...option, disableCheckbox: false};
        });

        self[filterName] = cast([...updatedSelectOptions]);

        const checkedFilters = updatedSelectOptions.filter(({isVisible}) => isVisible);

        if (checkedFilters.length === 1) {
          // @ts-ignore
          const defaultCheckedFilters = updatedSelectOptions.map((option) => {
            return option.dataKey === checkedFilters[0].dataKey
              ? {...option, disableCheckbox: true}
              : option;
          });
          self[filterName] = cast([...defaultCheckedFilters]);
        } else {
          // @ts-ignore
          self[filterName] = cast([...updatedSelectOptions]);
        }
      },
      selectAllRiskFilterOptions: (filterName: EDispScoreFiltersNames, isVisible: boolean) => {
        if (isVisible) {
          // @ts-ignore
          self[filterName] = self[filterName].map((option) => ({
            ...option,
            isVisible,
            disableCheckbox: false
          }));
        } else {
          // @ts-ignore
          self[filterName] = self[filterName].map((option, idx) => ({
            ...option,
            isVisible: idx === 0 ? true : isVisible,
            disableCheckbox: idx === 0
          }));
        }
      }
    }))
    .actions((self) => {
      return {
        afterCreate() {
          storeState(self, ['riskSelectOptions', 'sortBy', 'sortDirection']);
        }
      };
    })
    .views((self) => ({
      get filteredDispropScoreData(): IPageParams<IDispropScoreData> {
        const {dispropScoreData} = getRootStore(self).dispropScoreStore;

        const checkedFilters = self.riskSelectOptions.filter(({isVisible}) => isVisible);

        if (checkedFilters.length === self.riskSelectOptions.length || !checkedFilters.length) {
          const searchedData = searchedDispropScoreData(dispropScoreData.content, self.searchValue);

          return {
            ...dispropScoreData,
            content: [...searchedData].sort(sortByProperty(self.sortBy, self.sortDirection))
          };
        }

        let riskContent: IDispropScoreData[] = [];

        checkedFilters.forEach(({name}) => {
          riskContent = [
            ...riskContent,
            ...dispropScoreData.content.filter(({riskRsi}) => checkRiskRsiByOption(riskRsi, name))
          ];
        });

        const searchedData = searchedDispropScoreData(riskContent, self.searchValue);

        return {
          ...dispropScoreData,
          content: [...searchedData].sort(sortByProperty(self.sortBy, self.sortDirection))
        };
      },
      get getRiskSelectOptions() {
        return getSnapshot(self.riskSelectOptions);
      },
      get getSelectedPTCount() {
        return self.selectedPTList.length;
      }
    }))
);

export interface IDispropScoreFiltersStore extends Instance<typeof DispropScoreFiltersStore> {}

export {DispropScoreFiltersStore};
