import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {BarChartHorizontalUtils} from '@services/utils/Charts/BarChartHorizontal.utils';
import {cutName} from '@services/utils/Functions.utils';
import echarts from 'echarts/lib/echarts';
import merge from 'lodash/merge';

type SourceType = [string | number, string | number, string][];

const getInterval = (countData: number[]) => {
  const splitNumber = 4;
  const maxCount: number = Math.max(...countData);

  const topOffset = () => {
    if (maxCount > 1000) {
      return 1500;
    }
    if (maxCount > 500) {
      return 500;
    }
    if (maxCount > 100) {
      return 200;
    }
    if (maxCount < 10) {
      return 8;
    }
    return 100;
  };
  const maxFloorValue = maxCount - (maxCount % 100) + topOffset();
  const interval = Math.floor(maxFloorValue / splitNumber);

  return {maxFloorValue, interval};
};

export function getChartOptions({options}: {options: IFilterDataItem[]}): echarts.EChartOption {
  const chartOptions = BarChartHorizontalUtils.getChartOptions();
  const xAxisOptions = BarChartHorizontalUtils.getXAxisOptions();
  const yAxisOptions = BarChartHorizontalUtils.getYAxisOptions();
  const seriesOptions = BarChartHorizontalUtils.getSeriesOptions();
  const tooltipOptions = BarChartHorizontalUtils.getTooltipOptions();
  const gridOptions = BarChartHorizontalUtils.getGridOptions();
  const {series, ...chartOptionsWithoutSeries} = chartOptions;

  const datasetSource: SourceType = options.reduce<SourceType>((acc, {name, count, percent}) => {
    if (!acc[0]) {
      acc.push(['score', 'amount', 'product']);
    }
    acc.push([percent, count, cutName(name)]);
    return acc;
  }, []);
  const countData: number[] = options.map(({count}) => count).reverse();

  const {data, ...defaultSeriesOptions} = seriesOptions[0];
  const {interval, maxFloorValue} = getInterval(countData);

  const countriesSeries: echarts.EChartOption.SeriesBar[] = [
    {
      ...defaultSeriesOptions,
      barWidth: 'auto',
      encode: {
        // Map the "amount" column to X axis.
        x: 'amount',
        // Map the "product" column to Y axis
        y: 'product'
      }
    }
  ];

  const chartModifiedOptions: echarts.EChartOption = {
    xAxis: {
      ...xAxisOptions,
      interval,
      max: maxFloorValue
    },
    yAxis: [{...yAxisOptions[0]}, {...yAxisOptions[1], data: countData}],
    dataset: {
      source: datasetSource
    },
    tooltip: {
      ...tooltipOptions,
      formatter: (params) => formatterTooltip({params, isDataset: true, width: '60px'})
    },
    grid: {
      ...gridOptions,
      bottom: 15
    },
    series: countriesSeries
  };

  return merge({}, chartOptionsWithoutSeries, chartModifiedOptions);
}
