import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';

export const getCasesInterval = (filterData: IFilterDataItem[], splitNumber: number): number[] => {
  const casesInterval: number[] = [0];
  if (filterData.length) {
    const filterDataCounts = filterData.map((f) => f.count);
    let maxFilterCount = Math.max(...filterDataCounts);
    const maxPercent = filterData.find((f) => f.count === maxFilterCount)?.percent ?? 100;

    if (maxPercent) {
      // max count for 100%
      maxFilterCount = Math.floor((maxFilterCount * 100) / maxPercent);
    }
    let maxFloorValue = maxFilterCount;
    let interval: number;

    if (maxFilterCount > 100) {
      maxFloorValue = maxFilterCount - (maxFilterCount % 100);
      interval = Math.floor(maxFloorValue / splitNumber);
    } else {
      interval = maxFloorValue / splitNumber;
    }

    for (let i = 1; i < splitNumber; i++) {
      const caseIntervalNum = +(casesInterval[i - 1] + interval).toFixed(1);
      casesInterval.push(caseIntervalNum);
    }
  }
  return casesInterval;
};
