import {SeriesPieType} from '@models/Chart/PieChart.types';
import echarts from 'echarts/lib/echarts';

import {
  DEFAULT_CHART_OPTIONS,
  DEFAULT_LEGEND_OPTIONS,
  DEFAULT_SERIES_OPTIONS
} from '../../../components/PieChart/chart.constants';

export class PieChartUtils {
  static getChartOptions = (): echarts.EChartOption => DEFAULT_CHART_OPTIONS;

  static getSeriesOptions = (): SeriesPieType[] => DEFAULT_SERIES_OPTIONS;

  static getLegendOptions = (): echarts.EChartOption.Legend => DEFAULT_LEGEND_OPTIONS;
}
