import {setToken} from '@services/utils/Api.utils';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {RoutePaths} from '@services/constants/router.constants';
import {useForm} from 'react-hook-form';
import cn from 'classnames';
import {history} from '@services/router/RouterHistory';
import {useTranslation} from 'react-i18next';
import {i18n, ELang, langDictionary} from '@services/i18n';
import {Button} from 'src/components';
import {LogoLargeIcon} from 'src/static/icons/LogoLargeIcon';

import s from './WelcomePage.module.css';

const DEFAULT_VALUE = 'MedvalDemo';
const DEFAULT_FORM_VALID_VALUE = {login: true, password: true};

export const VALIDATION_TEXT = {
  req: 'Required field',
  pattern: 'Invalid value'
};

type FormData = {
  login: string;
  password: string;
};

const WelcomePage: React.FC<{}> = () => {
  const {t} = useTranslation();
  const [isFormValid, setIsFormValid] = useState(DEFAULT_FORM_VALID_VALUE);
  const {register, handleSubmit, errors, watch} = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      login: '',
      password: ''
    }
  });

  const values = watch();

  useEffect(() => {
    setIsFormValid(DEFAULT_FORM_VALID_VALUE);
  }, [values.login, values.password]);

  const checkIsLoginValid = useCallback((): boolean => {
    return values.login === DEFAULT_VALUE;
  }, [values.login]);

  const checkIsPasswordValid = useCallback((): boolean => {
    return values.password === DEFAULT_VALUE;
  }, [values.password]);

  // const {drugClassesStore} = useStore();
  // const {dataDrug, ArrayDrug} = drugClassesStore;

  const onSubmit = handleSubmit(() => {
    // drugClassesStore.fetchCasesData({...dataDrug, ArrayDrug, Open: true});

    const isValid = checkIsLoginValid() && checkIsPasswordValid();
    setIsFormValid({login: checkIsLoginValid(), password: checkIsPasswordValid()});

    if (isValid) {
      setToken();
      history.push(RoutePaths.research);
    }
  });

  return (
    <div className="page-content">
      <LogoLargeIcon className={s.logo} />

      <div className={s.info}>
        <div>{t('welcomeScene.welcomeDateUpdated')}</div>
        <div>{t('welcomeScene.welcomeDateUpdatedDescription')}</div>
      </div>

      <form onSubmit={onSubmit} className={s.welcomeBlock}>
        <span className={s.welcomeBlockTitle}>{t('welcomeScene.welcomeTitle')}</span>
        <span className={s.welcomeBlockSubtitle}>{t('welcomeScene.welcomeDescription')}</span>

        <div className={s.form}>
          <div className={s.field}>
            <input
              className={cn(s.input, {[s.error]: !!errors.login || !isFormValid.login})}
              name="login"
              type="text"
              placeholder={t('welcomeScene.welcomeLoginPlaceholder')}
              ref={register({
                required: VALIDATION_TEXT.req
              })}
            />
            {errors.login?.message && <div className={s.errorMessage}>{errors.login?.message}</div>}
            {!isFormValid.login && <div className={s.errorMessage}>{VALIDATION_TEXT.pattern}</div>}
          </div>

          <div className={s.field}>
            <input
              className={cn(s.input, {[s.error]: !!errors.password || !isFormValid.password})}
              name="password"
              type="password"
              autoComplete="off"
              placeholder={t('welcomeScene.welcomePasswordPlaceholder')}
              ref={register({
                required: VALIDATION_TEXT.req
              })}
            />
            {errors.password?.message && (
              <div className={s.errorMessage}>{errors.password?.message}</div>
            )}
            {!isFormValid.password && (
              <div className={s.errorMessage}>{VALIDATION_TEXT.pattern}</div>
            )}
          </div>
        </div>
        <Button type="submit" className={s.button}>
          {t('welcomeScene.welcomeSignInButton')}
        </Button>
      </form>
      <div className={s.languageSwitcher}>
        <form id="selectbox" name="">
          <select
            className={s.languageSelect}
            value={i18n.language}
            onChange={(event) => {
              i18n.changeLanguage(event.target.value as ELang);
            }}
          >
            {Object.values(ELang).map((lang) => (
              <option key={lang} value={lang}>
                {langDictionary.get(lang)}
              </option>
            ))}
          </select>
        </form>
      </div>
    </div>
  );
};

export default observer(WelcomePage);
