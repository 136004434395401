import {UserFiltersNames, UserFiltersNamesZh} from '@models/CaseStat/CaseStat.types';
import {IDatePickerProps} from '@models/DatePicker/DatePicker.types';
import {IDropdownProps} from '@models/Dropdown/Dropdown';
import {IDropdownFilterProps} from '@models/Dropdown/DropdownFilter';
import {RoutePaths} from '@services/constants/router.constants';
import {parseHtmlToPng} from '@services/utils/Functions.utils';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import {PDFViewer, Document, Page, View, Image, StyleSheet} from '@react-pdf/renderer';
import {useTranslation} from 'react-i18next';
import {DatePickerRange} from 'src/components/DatePickerRange';
import {PaperWidget} from 'src/components/PaperWidget';
import {Dropdown, EmptyData, Loading} from 'src/components/UIKit';
import {SelectedOptions} from 'src/components/UIKit/SelectedOptions';
import {ArrowLeftIcon} from 'src/static/icons';
import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';

import {ReportByAgeGroupContainer} from '../Research/components/Reports/ReportByAgeGroup';
import {ReportsByConmedsContainer} from '../Research/components/Reports/ReportByConmeds';
import {ReportByCountriesContainer} from '../Research/components/Reports/ReportByCountries';
import {ReportByPTContainer} from '../Research/components/Reports/ReportByPT';
import {ReportBySexContainer} from '../Research/components/Reports/ReportBySex';
import {ReportClassificationContainer} from '../Research/components/Reports/ReportClassification';
import {ReportsByMedicalHistoryContainer} from '../Research/components/Reports/ReportsByMedicalHistory';
import {ReportsByOutcomeContainer} from '../Research/components/Reports/ReportsByOutcome';
import {ReportsBySeriousnessContainer} from '../Research/components/Reports/ReportsBySeriousness';
import {
  TimeToOnsetBoxPlotContainer,
  TimeToOnsetScatterLinesContainer
} from '../Research/components/Reports/ReportTimeToOnset/components';
import {
  CasePropertiesContainer,
  CaseVolumeContainer,
  DemographicsContainer,
  MedicalHistoryContainer
} from '../Research/components/UserFiltersCard/components';

import s from './ReportsPage.module.css';

const styles = StyleSheet.create({
  document: {
    width: 1000,
    height: 1000
  },
  body: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 35,
    backgroundColor: 'white'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    marginVertical: 15,
    objectFit: 'cover'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

const ReportsHeader: React.FC<{}> = () => (
  <div className={s.headerContainer}>
    <Link to={RoutePaths.research} className={s.icon}>
      <ArrowLeftIcon className={s.iconArrowLeft} />
    </Link>
    <h1>Reports</h1>
  </div>
);

interface Props {
  dropdownButtonProps: IDropdownFilterProps;
  dropdownProps: IDropdownProps;
  datePickerProps: IDatePickerProps;
}

const ReportsPage: React.FC<Props> = (props) => {
  const [firstPage, setFirstPage] = useState('');
  const [secondPage, setSecondPage] = useState('');
  const [thirdPage, setThirdPage] = useState('');
  const [fourthPage, setFourthPage] = useState('');
  const firstRef = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement>(null);
  const thirdRef = useRef<HTMLDivElement>(null);
  const fourthRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const node1 = firstRef?.current;
      const node2 = secondRef?.current;
      const node3 = thirdRef?.current;
      const node4 = fourthRef?.current;

      if (node1) parseHtmlToPng({node: node1, setImageUrl: setFirstPage});
      if (node2) parseHtmlToPng({node: node2, setImageUrl: setSecondPage});
      if (node3) parseHtmlToPng({node: node3, setImageUrl: setThirdPage});
      if (node4) parseHtmlToPng({node: node4, setImageUrl: setFourthPage});
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const {dropdownButtonProps, dropdownProps, datePickerProps} = props;

  const {
    userFiltersOptions,
    clearUserFilter,
    clearAllUserFilters,
    medDraOptions,
    clearMedDRAFilter,
    clearAllMedDRAFilters
  } = dropdownButtonProps;

  const {targetDrugs, dataSources} = dropdownProps;

  const userOptionsEmpty = userFiltersOptions.every((o) => o.selectedFilters.length === 0);
  const medDraOptionsEmpty = medDraOptions.every((o) => o.selectedFilters.length === 0);

  const isFiltersSelected: boolean =
    (!!userFiltersOptions.length && !userOptionsEmpty) ||
    (!!medDraOptions.length && !medDraOptionsEmpty);

  const {i18n, t} = useTranslation();

  const MedDRASelectedFiltersNames: Record<EMedDraType, string> = {
    [EMedDraType.BodySystems]: t(`medDRAFilters.${EMedDraType.BodySystems}`),
    [EMedDraType.HLGT]: t(`medDRAFilters.${EMedDraType.HLGT}`),
    [EMedDraType.HLT]: t(`medDRAFilters.${EMedDraType.HLT}`),
    [EMedDraType.PT]: t(`medDRAFilters.${EMedDraType.PT}`),
    [EMedDraType.SMQ]: t(`medDRAFilters.${EMedDraType.SMQ}`)
  };

  const UserFiltersNamesValue = i18n.languages[0] === 'en' ? UserFiltersNames : UserFiltersNamesZh;

  return (
    <PaperWidget contentClassName={s.paperWidgetContent}>
      <ReportsHeader />
      <>
        <div className={s.reportsWrapper}>
          <div ref={firstRef}>
            <div className={cn(s.reportRow, s.globalFilters)}>
              <Dropdown
                selectName={t('header.sources')}
                options={dataSources}
                handleDataChecked={() => {}}
                handleSelectAll={() => {}}
                selectTopClassName={s.selectTopClassName}
                selectNameInOptions
                withoutIcon
                dark
              />
              <Dropdown
                selectName={t('header.targetDrugs')}
                options={targetDrugs}
                handleDataChecked={() => {}}
                handleSelectAll={() => {}}
                selectNameInOptions
                withoutIcon
                dark
              />
              <DatePickerRange {...datePickerProps} />
            </div>
            {isFiltersSelected ? (
              <div className={cn(s.selectList, s.filters)}>
                <SelectedOptions
                  options={medDraOptions}
                  selectedFiltersNames={MedDRASelectedFiltersNames}
                  clearAllFilters={clearAllMedDRAFilters}
                  clearFilter={clearMedDRAFilter}
                  notAllowedToClear
                  fullChipsName
                />
                <SelectedOptions
                  options={userFiltersOptions}
                  selectedFiltersNames={UserFiltersNamesValue}
                  clearAllFilters={clearAllUserFilters}
                  clearFilter={clearUserFilter}
                  notAllowedToClear
                  fullChipsName
                />
              </div>
            ) : (
              <EmptyData text="No filters selected" className={cn(s.report, s.filters)} />
            )}
            <div className={cn(s.report, s.withHeight)}>
              <CaseVolumeContainer />
            </div>
            <div className={cn(s.report, s.withHeight)}>
              <DemographicsContainer withoutScroll />
            </div>
          </div>

          <div ref={secondRef}>
            <div className={cn(s.report, s.withHeight)}>
              <MedicalHistoryContainer withoutScroll />
            </div>
            <div className={cn(s.report, s.withHeight)}>
              <CasePropertiesContainer withoutScroll />
            </div>
            <div className={cn(s.report, s.reportRow)}>
              <ReportClassificationContainer withoutOffsets />
              <ReportBySexContainer />
              <ReportsBySeriousnessContainer />
            </div>
          </div>

          <div ref={thirdRef}>
            <div className={cn(s.report, s.reportRow)}>
              <ReportByAgeGroupContainer contentHeight={400} className={s.ageGroupReport} />
              <ReportsByOutcomeContainer leftOffset />
            </div>
            <div className={cn(s.report, s.reportRow)}>
              <ReportByCountriesContainer withoutOffsets />
              <ReportsByConmedsContainer />
            </div>
          </div>

          <div ref={fourthRef}>
            <div className={cn(s.report, s.reportRow)}>
              <ReportsByMedicalHistoryContainer />
              <ReportByPTContainer />
            </div>
            <div className={cn(s.report, s.withHeight)}>
              <h2 className={s.title}>{t('timeToOnsetDaysByPt')}</h2>
              <TimeToOnsetBoxPlotContainer />
            </div>
            <div className={cn(s.report, s.reportRow, s.withHeight)}>
              <TimeToOnsetScatterLinesContainer />
            </div>
          </div>
        </div>

        <div className={s.pdfWrapper}>
          {firstPage && secondPage && thirdPage && fourthPage ? (
            <PDFViewer height="100%" width="100%">
              <Document title="Reports">
                <Page style={styles.body}>
                  <View style={styles.image}>
                    <Image style={styles.image} src={firstPage} />
                  </View>
                </Page>
                <Page style={styles.body}>
                  <View style={styles.image}>
                    <Image style={styles.image} src={secondPage} />
                  </View>
                </Page>
                <Page style={styles.body}>
                  <View style={styles.image}>
                    <Image style={styles.image} src={thirdPage} />
                  </View>
                </Page>
                <Page style={styles.body}>
                  <View style={styles.image}>
                    <Image style={styles.image} src={fourthPage} />
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          ) : (
            <Loading />
          )}
        </div>
      </>
    </PaperWidget>
  );
};

export default ReportsPage;
