import React, {useEffect} from 'react';

interface Props {
  handleClickOutside: (e?: MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
  useAlternateEvent?: boolean;
}

const ClickOutside: React.FC<Props> = ({
  handleClickOutside,
  children,
  className,
  useAlternateEvent = false
}) => {
  useEffect(() => {
    if (useAlternateEvent) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      if (useAlternateEvent) {
        window.removeEventListener('mousedown', handleClickOutside);
      } else {
        window.removeEventListener('click', handleClickOutside);
      }
    };
  });

  return useAlternateEvent ? (
    <div
      className={className}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  ) : (
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};

export default ClickOutside;
