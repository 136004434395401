import React from 'react';

export const DOCIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="-8 -8 24 24">
    <g fill="none" fillRule="evenodd" filter="url(#prefixFilter)" transform="translate(-8 -8)">
      <path
        d="M4 19v-.5h6.5a1 1 0 0 0 1-1V11h.5a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1zM16.5 6.5V7a1 1 0 0 0 1 1h3V6.5h-4z"
        fill="#FFF"
        opacity=".5"
      />
      <path
        d="M4.5 18.5V21A1.5 1.5 0 0 0 6 22.5h13a1.5 1.5 0 0 0 1.5-1.5V6.5l-5-5H6A1.5 1.5 0 0 0 4.5 3v6.5"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 1.5v4a1 1 0 0 0 1 1h4l-5-5zM11.5 15.5h6M11.5 12.5h6M8.5 9.5h9M8.5 6.5h3M2.5 17.5v-7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1z"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12 1.25 4L7 12l1.25 4 1.25-4"
      />
    </g>
  </svg>
);
