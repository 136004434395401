import {useEffect, useState} from 'react';

export function useDebounceSearch<T>(handler: (arg: T) => void, time: number) {
  const [debouncedValue, setDebouncedValue] = useState<T | null>(null);

  useEffect(() => {
    if (debouncedValue === null) {
      return;
    }
    const timeout = window.setTimeout(() => handler(debouncedValue), time);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [debouncedValue, handler, time]);

  return setDebouncedValue;
}
