import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import React from 'react';
import {AutoSizer} from 'react-virtualized';
import {Loading} from 'src/components/UIKit';

import s from './TimeToOnsetBoxPlot.module.css';

interface Props {
  isFetching: boolean;
  getChartOptions: echarts.EChartOption;
}

const TimeToOnsetBoxPlot: React.FC<Props> = ({isFetching, getChartOptions}) => {
  return (
    <div className={s.container}>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <AutoSizer>
            {({width, height}) => <ReactEcharts option={getChartOptions} style={{width, height}} />}
          </AutoSizer>
        </>
      )}
    </div>
  );
};

export default TimeToOnsetBoxPlot;
