import {ISelectOptions} from '@models/Dropdown/Dropdown.types';

export const mapToSelectOptions = (
  data: {id: string; name: string; disableCheckbox?: boolean; isVisible?: boolean}[]
): ISelectOptions[] => {
  return data.map(({name, disableCheckbox, isVisible}, idx) => ({
    dataKey: name,
    name,
    isVisible: isVisible ?? idx > 0,
    disableCheckbox: disableCheckbox ?? (idx > 0 && data.length <= 1)
  }));
};

export const mapToSelectOptionsWithId = (
  data: {id: string; name: string; disableCheckbox?: boolean; isVisible?: boolean}[]
): ISelectOptions[] => {
  return data.map(({id, name, disableCheckbox, isVisible}, idx) => ({
    dataKey: id,
    name,
    isVisible: isVisible ?? idx > 0,
    disableCheckbox: disableCheckbox ?? (idx > 0 && data.length <= 1)
  }));
};

export const mapToSelectTargetDrugsOptions = (
  data: {id: string; name: string}[]
): ISelectOptions[] => {
  return data.map(({name}, idx) =>
    idx === 0
      ? {
          dataKey: name,
          name,
          isVisible: true,
          disableCheckbox: data.length <= 1
        }
      : {
          dataKey: name,
          name,
          isVisible: true,
          disableCheckbox: false
        }
  );
};

export const mapToSingleOptions = (data: {id: string; name: string}[]): ISelectOptions[] => {
  // @ts-ignore
  return data.map(({name, atc, ...rest}) =>
    name === 'ALL'
      ? {
          ...rest,
          dataKey: atc || name,
          name,
          isVisible: true,
          disableCheckbox: data.length <= 1
        }
      : {
          ...rest,
          dataKey: atc,
          name,
          isVisible: false,
          disableCheckbox: false
        }
  );
};
