import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {
  DateProps,
  DrugsChartFormattedDataType,
  EventsChartFormattedDataType,
  ICaseDetail,
  IDrugsLabels,
  IEventsLabels,
  ILabsChartLabels
} from '@models/CaseDetail/CaseDetail.types';
import {IChartSettings, SeriesMarkLineDataType} from '@models/Chart/chart.types';
import {ILineChartFormattedData} from '@models/Chart/LineChart.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getChartSettings} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {getLocalesDates, getPrevDate} from '@services/utils/Date.utils';
import {
  getEventsChartFormattedData,
  getEventsLabels,
  getMarkLineEventsData
} from '@services/utils/DetailCaseCharts/EventsChart.helpers';
import {
  getLabsChartData,
  getLabsChartLabels,
  getLabsOnlyWithDates,
  getStartEndDates
} from '@services/utils/DetailCaseCharts/LabsChart.helpers';
import {
  getDrugsChartFormattedData,
  getDrugsLabels,
  getMarkLineDrugsData
} from '@services/utils/DetailCaseCharts/DrugsChart.helpers';
import {flow, Instance, types} from 'mobx-state-tree';

const initialData: ICaseDetail = {
  id: '',
  caseData: null,
  source: '',
  caseNumber: '',
  reportClassification: null,
  study: null,
  country: null,
  gender: null,
  age: null,
  ageUnit: null,
  medicalHistory: null,
  indication: null,
  labs: {},
  drugs: [],
  events: []
};

const CaseDetailStore = types.compose(
  Store,
  types
    .model('CaseDetailStore', {
      startDate: types.optional(types.Date, getPrevDate(new Date())),
      endDate: types.optional(types.Date, new Date()),
      minDate: types.optional(types.Date, getPrevDate(new Date())),
      maxDate: types.optional(types.Date, new Date()),

      data: types.frozen<ICaseDetail>(initialData),
      request: types.optional(Request, {}),

      markLineDrugsData: types.frozen<SeriesMarkLineDataType>([]),
      markLineEventsData: types.frozen<SeriesMarkLineDataType>([]),

      labsLabels: types.frozen<ILabsChartLabels[]>([]),
      labsData: types.frozen<ILineChartFormattedData>({}),

      drugsLabels: types.frozen<IDrugsLabels>({}),
      drugsData: types.frozen<DrugsChartFormattedDataType>([]),

      eventsLabels: types.frozen<IEventsLabels>({}),
      eventsData: types.frozen<EventsChartFormattedDataType>([])
    })
    .actions((self) => ({
      setLabsData(response: ICaseDetail) {
        if (response) {
          self.data = response;

          const labsWithDates = getLabsOnlyWithDates(response.labs);

          /** date start and date to from response data */
          const datesRange = getStartEndDates({
            labsData: labsWithDates,
            drugsData: response.drugs,
            eventsData: response.events
          });
          self.startDate = datesRange.startDate;
          self.endDate = datesRange.endDate;
          self.minDate = datesRange.startDate;
          self.maxDate = datesRange.endDate;

          self.labsData = getLabsChartData({
            labsData: labsWithDates,
            startDate: self.startDate,
            endDate: self.endDate
          });
        }
        self.labsLabels = getLabsChartLabels(response?.labs ?? {});
      },
      setDrugsData(response: ICaseDetail) {
        if (response) {
          self.drugsLabels = getDrugsLabels(response.drugs);
          self.drugsData = getDrugsChartFormattedData({
            drugs: response.drugs,
            drugsLabels: self.drugsLabels,
            startDate: self.startDate,
            endDate: self.endDate
          });
          self.markLineDrugsData = getMarkLineDrugsData({
            drugs: response.drugs,
            drugsLabels: self.drugsLabels,
            startDate: self.startDate,
            endDate: self.endDate
          });
        }
        self.drugsLabels = getDrugsLabels(response?.drugs ?? []);
      },
      setEventsData(response: ICaseDetail) {
        if (response) {
          self.eventsLabels = getEventsLabels(response.events);
          self.eventsData = getEventsChartFormattedData({
            events: response.events,
            eventsLabels: self.eventsLabels,
            startDate: self.startDate,
            endDate: self.endDate
          });
          self.markLineEventsData = getMarkLineEventsData({
            events: response.events,
            eventsLabels: self.eventsLabels,
            startDate: self.startDate,
            endDate: self.endDate
          });
        }
        self.eventsLabels = getEventsLabels(response?.events ?? []);
      }
    }))
    .actions((self) => ({
      load: flow(function* (id: string) {
        const response: ICaseDetail = yield self.request.send(
          getApiUrl(ApiVersions.v1, `case.get/${id}`),
          {
            method: 'get'
          }
        );
        self.setLabsData(response);
        self.setDrugsData(response);
        self.setEventsData(response);
      }),
      updateChartDataWithDates() {
        const labsWithDates = getLabsOnlyWithDates(self.data?.labs ?? {});
        self.labsData = getLabsChartData({
          labsData: labsWithDates,
          startDate: self.startDate,
          endDate: self.endDate
        });
      }
    }))
    .actions((self) => ({
      updateCaseChartDate(data: DateProps) {
        if (data.startDate) {
          self.startDate = data.startDate;
          self.updateChartDataWithDates();
        }
        if (data.endDate) {
          self.endDate = data.endDate;
          self.updateChartDataWithDates();
        }
      }
    }))
    .views((self) => ({
      get fetchCaseDetailData() {
        return self.load;
      },
      get isFetching() {
        return self.request.isPending;
      },
      get timeData(): string[] {
        return getLocalesDates({
          startDate: self.startDate,
          endDate: self.endDate
        });
      },
      get chartSettings(): IChartSettings {
        return getChartSettings({
          firstChartLabels: self.drugsLabels,
          secondChartLabels: self.eventsLabels,
          thirdChartLabels: self.labsLabels
        });
      }
    }))
);

export interface ICaseDetailStore extends Instance<typeof CaseDetailStore> {}

export {CaseDetailStore};
