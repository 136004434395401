import {SelectedUserFilters} from '@models/CaseStat/CaseStat.types';
import {IDropdownFilterOptions} from '@models/Dropdown/DropdownFilter';
import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';
import cn from 'classnames';
import React from 'react';
import {CloseIcon} from 'src/static/icons';

import s from './SelectedOptions.module.css';

interface Props<T extends EMedDraType | SelectedUserFilters> {
  options: IDropdownFilterOptions<T>[];
  selectedFiltersNames: Record<T, string>;
  clearAllFilters: (category: T) => void;
  clearFilter: (category: T, filterName: string) => void;
  notAllowedToClear?: boolean;
  fullChipsName?: boolean;
}

function SelectedOptions<T extends EMedDraType | SelectedUserFilters>({
  options,
  selectedFiltersNames,
  clearAllFilters,
  clearFilter,
  notAllowedToClear = false,
  fullChipsName = false
}: Props<T>) {
  return (
    <>
      {options.map(
        ({category, selectedFilters}) =>
          selectedFilters.length > 0 && (
            <div key={category} className={s.categoryBlock}>
              <div className={s.category}>
                {selectedFiltersNames[category]}
                {!notAllowedToClear && (
                  <CloseAllButton handleRemoveAll={() => clearAllFilters(category)} />
                )}
              </div>
              <div className={s.categoryList}>
                {selectedFilters.map((filterName, idx) => (
                  <div className={cn(s.chips, {[s.chipsOffset]: notAllowedToClear})} key={idx}>
                    <span className={cn(s.chipsName, {[s.chipsFullName]: fullChipsName})}>
                      {filterName}
                    </span>
                    {!notAllowedToClear && (
                      <CloseButton handleRemoveFilter={() => clearFilter(category, filterName)} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </>
  );
}

const CloseButton: React.FC<{handleRemoveFilter: () => void}> = ({handleRemoveFilter}) => (
  <button className={s.btnClose} onClick={handleRemoveFilter}>
    <CloseIcon className={s.icon} />
  </button>
);

const CloseAllButton: React.FC<{handleRemoveAll: () => void}> = ({handleRemoveAll}) => (
  <button className={s.btnClose} onClick={handleRemoveAll}>
    <CloseIcon className={cn(s.icon, s.iconCloseAll)} />
  </button>
);

export default SelectedOptions;
