import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {seriousColorCollection} from '@services/constants/chart.constants';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

const ReportsBySeriousnessContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {serious: selectedSerious} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;

  const data: IFilterDataItem[] = caseStatData.serious;

  const formattedOptions = data.filter(({name, code}) => {
    if (selectedSerious.length) {
      return selectedSerious.findIndex(({code: _code}) => _code === code) > -1;
    }
    return name;
  });

  const {t} = useTranslation();

  const chartOptions = getChartOptions({options: formattedOptions, colors: seriousColorCollection});

  return (
    <Report
      title={t('reportBySeriousness')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={seriousColorCollection}
      getChartOptions={chartOptions}
      leftOffset
    />
  );
};

export default observer(ReportsBySeriousnessContainer);
