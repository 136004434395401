import React, {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {Button} from '@components/UIKit';

import s from './ConfirmationDialog.module.css';

interface IProps {
  title: string;
  description: string;
  submitButtonText?: string;
  isLoading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const ConfirmationDialog: FC<IProps> = ({
  title,
  description,
  submitButtonText = '',
  isLoading,
  onCancel,
  onSubmit
}) => {
  const {t} = useTranslation();

  return (
    <div>
      <div className={s.inner}>
        <section>
          <div>
            <h1 className={s.title}>{title}</h1>

            <p className={s.text}>{description}</p>
          </div>
        </section>

        <footer className={s.footer}>
          <Button
            variant="default"
            size="large"
            onClick={onCancel}
            className={cn(s.action, s.actionCancel)}
          >
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            size="large"
            disabled={isLoading}
            variant="primary"
            onClick={onSubmit}
            className={cn(s.action, s.actionSubmit)}
          >
            {submitButtonText || t('confirm')}
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default memo(ConfirmationDialog);
