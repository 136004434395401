import {Genders, SeriousNameFormatted} from '@models/CaseStat/CaseStat.types';
import {IStyleProps} from '@models/Chart/PieChart.types';

export const ALL_CLASSES: string = 'allClasses';
export const DEFAULT_OFFSET = 250;

export const colorPurple100 = '#edf0f7';
export const colorPurple300 = '#878fba';
export const colorPurple600 = '#535f9c';
export const colorPurple900 = '#2d3977';
export const colorPurpleOpacity = 'rgba(83,95,156,0.50)';

export const colorPurple850 = '#424d85';
export const colorPurple840 = '#576192';
export const colorPurple830 = '#6c74a0';
export const colorPurple820 = '#8188ad';
export const colorPurple810 = '#969cbb';
export const colorPurple800 = '#abb0c9';

export const colorMagenta300 = '#c470d8';
export const colorMagenta500 = '#782e8b';
export const colorMagenta800 = '#510d62';

export const colorRed300 = '#d8707c';
export const colorRed500 = '#a22433';
export const colorRed800 = '#610913';

export const colorGray100 = '#dfdfdf';
export const colorGray200 = '#979797';
export const colorGray250 = '#7c7d7e';
export const colorGray300 = '#6d6e70';
export const colorGray400 = '#5a5b5e';
export const colorGray600 = '#313236';

export const colorGray = '#838486';
export const colorGrayOpacity = '#f2f4fa';
export const colorGrayOpacity50 = 'rgba(242,244,250,0.50)';
export const colorGreen = '#428d68';
export const colorGreen100 = 'rgba(66,141,104,0.54)';
export const colorGreenOpacity = 'rgba(66, 141, 104, 0.04)';
export const colorWhite = '#fff';

// pie chart
export const shadowColor = 'rgba(82, 93, 158, 0.7)';
export const shadowBlur = 20;

export const colorRed = '#da3634';

export const colorsCollection = [
  colorMagenta800,
  colorMagenta500,
  colorMagenta300,
  colorPurple600,
  colorRed300,
  colorRed500,
  colorRed800
];

export const tooltipSettings = {
  axisPointerLineStyleColor: colorGray600,
  axisPointerLineStyleWidth: 2,
  backgroundColor: 'white',
  borderColor: colorGray100,
  extraCssText: 'box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2); padding-right: 36px;'
};

export const genderColorCollection: IStyleProps = {
  [Genders.Male]: '#6b9bb3',
  [Genders.Female]: '#cc6688',
  [Genders.Unknown]: '#5a5b5e'
};

export const genderBoxShadowCollection: IStyleProps = {
  [Genders.Male]: '0 8px 24px 0 rgba(107, 155, 179, 0.4)',
  [Genders.Female]: '0 8px 24px 0 rgba(204, 102, 136, 0.4)',
  [Genders.Unknown]: '0 8px 24px 0 rgba(90, 91, 94, 0.4)'
};

export const seriousColorCollection: IStyleProps = {
  [SeriousNameFormatted.Serious]: colorRed,
  [SeriousNameFormatted.NotSerious]: colorPurple600
};

export const seriousBoxShadowCollection: IStyleProps = {
  [SeriousNameFormatted.Serious]: '0 8px 24px 0 rgba(218, 54, 52, 0.4)',
  [SeriousNameFormatted.NotSerious]: '0 8px 24px 0 rgba(83, 95, 156, 0.4)'
};
