import {useEffect, useState} from 'react';
import {onlyUniqueForStringArray} from '@services/utils/Functions.utils';

import {IDemoListItem, IMapedEntity, ITextElement} from '../models/Demo.types';

export const useEntitiesParser = (
  item: IDemoListItem | null,
  getEntitiesLabels: Record<string, string>
) => {
  const [selectedItem, setSelectedItem] = useState<IDemoListItem | undefined>();
  const [currentUniqLabels, setCurrentUniqLabels] = useState<string[]>([]);
  const [currentTextElements, setCurrentTextElements] = useState<ITextElement[]>([]);

  const getPreferName = (arr: IMapedEntity[]): string => {
    return arr.filter((el) => !(el.source === 'meddra' && el.level === 'llt'))[0].name;
  };

  useEffect(() => {
    if (!item?.article_data) return;
    setSelectedItem(item);

    const selections = item.parsed_entites;
    const text = item.article_data.abstract;

    const uniqLabels = selections
      .map((val) => ({...val, labelText: getEntitiesLabels[val.label]}))
      .map((el) => el.labelText)
      .filter(onlyUniqueForStringArray);
    setCurrentUniqLabels(uniqLabels);
    const getIndex = (value: string) => {
      return Object.values(getEntitiesLabels).findIndex((el) => el === value);
    };

    let lastCharIndex = 0;

    const newTextElements = selections.reduce((result: ITextElement[], el, index) => {
      const previousText = text?.substring(lastCharIndex, el.start_char);
      const prevElement: ITextElement = {
        text: previousText ?? '',
        prefName: getPreferName(el.mapped_entity),
        label: null
      };
      const currentElement: ITextElement = {
        text: el.text,
        prefName: getPreferName(el.mapped_entity),
        longForm: el.long_form,
        label: el.label,
        labelText: getEntitiesLabels[el.label] || el.label,
        labelIndex: getIndex(getEntitiesLabels[el.label] || el.label),
        index
      };

      lastCharIndex = el.end_char;
      const acc = [...result, prevElement, currentElement];
      if (index === selections.length - 1) {
        const nextText = text?.substring(el.end_char, text?.length);
        const lastElement: ITextElement = {
          text: nextText ?? '',
          prefName: getPreferName(el.mapped_entity),
          label: null
        };
        acc.push(lastElement);
      }
      return acc;
    }, []);
    setCurrentTextElements(newTextElements);
  }, [getEntitiesLabels, item]);

  return {selectedItem, currentUniqLabels, currentTextElements};
};
