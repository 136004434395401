import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
// import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
// import {isLocalLanguageMessages} from '@config/debug';

export enum ELang {
  En = 'en'
}

export const langDictionary = new Map<ELang, string>([[ELang.En, 'en']]);

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    load: 'languageOnly',
    fallbackLng: ELang.En,
    supportedLngs: ['en'],
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      // : getApiUrl(ApiVersions.v11, 'translation/labels'),
      customHeaders: () => {
        return {
          locale: i18n.language || ELang.En
        };
      }
    }
  });

export {i18n};
