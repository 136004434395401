import {IFilterCodeData, IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {ALL_CLASSES} from '@services/constants/chart.constants';
import {getReportClassesWithAll} from '@services/utils/ChartHelpers/ChartCommon.helpers';

export const getFormattedReportClasses = ({
  reportClasses,
  splitByClasses,
  selectedReportClasses
}: {
  reportClasses: IFilterDataItem[];
  splitByClasses: boolean;
  selectedReportClasses: IFilterCodeData[];
}) => {
  let formattedReportClasses = reportClasses;
  if (splitByClasses) {
    formattedReportClasses = formattedReportClasses.filter(({code}) => {
      if (selectedReportClasses.length) {
        return selectedReportClasses.findIndex(({code: _code}) => _code === code) > -1;
      }
      return code;
    });
  }
  if (!splitByClasses) {
    formattedReportClasses = getReportClassesWithAll(reportClasses).filter(({name, code}) => {
      if (selectedReportClasses.length) {
        return selectedReportClasses.findIndex(({code: _code}) => _code === code) > -1;
      }
      return name === ALL_CLASSES;
    });
  }
  return formattedReportClasses;
};
