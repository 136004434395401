import React, {useCallback} from 'react';
import {IDatePickerProps} from '@models/DatePicker/DatePicker.types';
import {IDropdownFilterProps} from '@models/Dropdown/DropdownFilter';
import {useStore} from '@services/hooks/useStore';
import {toISOString} from '@services/utils/Date.utils';
import {Header} from '@components/Header/index';
import {usePagesPathInfo} from '@services/hooks/usePagesPathInfo';
import {useGenerateReportParams} from '@services/hooksParams/useGenerateReportParams';
import {observer} from 'mobx-react-lite';
import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';
import {SelectedUserFilters} from '@models/CaseStat/CaseStat.types';

interface Props {
  onLogout: () => void;
}

const HeaderContainer: React.FC<Props> = ({onLogout}) => {
  const {reportsStore, userFiltersStore, globalFiltersStore, medDRAFiltersStore} = useStore();

  const params = useGenerateReportParams();

  const {selectedFiltersCount} = userFiltersStore.selectedFiltersStore;
  const {selectedMedDraFiltersCount} = medDRAFiltersStore.medDRASelectedFilters;

  const getDropdownButtonProps = (): IDropdownFilterProps & {
    onClearAll: () => void;
  } => {
    const {selectedFiltersStore, caseStatStore} = userFiltersStore;
    const {medDRASelectedFilters} = medDRAFiltersStore;

    const {selectedFiltersChipsOptions, clearUserFilter, clearAllUserFilters} =
      selectedFiltersStore;

    const {selectedMedDraFiltersChipsOptions, clearMedDRAFilter, clearAllMedDRAFilters} =
      medDRASelectedFilters;

    const onClearAll = () => {
      clearAllMedDRAFilters(EMedDraType.BodySystems);
      clearAllMedDRAFilters(EMedDraType.HLGT);
      clearAllMedDRAFilters(EMedDraType.HLT);
      clearAllMedDRAFilters(EMedDraType.PT);
      clearAllMedDRAFilters(EMedDraType.SMQ);

      Object.values(SelectedUserFilters).forEach((userFilterName) => {
        clearAllUserFilters(userFilterName);
      });
    };

    return {
      userFiltersOptions: selectedFiltersChipsOptions,
      clearUserFilter,
      clearAllUserFilters,
      isUserFetching: caseStatStore.isFetching,
      medDraOptions: selectedMedDraFiltersChipsOptions,
      clearMedDRAFilter,
      clearAllMedDRAFilters,
      isMedDraFetching: medDRAFiltersStore.isFetching,
      onClearAll
    };
  };

  const {isResearchPage} = usePagesPathInfo();

  const getDatePickerProps = (): IDatePickerProps => {
    const {caseStatStore} = userFiltersStore;
    const {startDate, endDate, updateStartDate, updateEndDate} = globalFiltersStore;
    const {isFetching, isDataExists} = caseStatStore;

    const setStartDate = (date: Date) => {
      updateStartDate(toISOString(date));
    };

    const setEndDate = (date: Date) => {
      updateEndDate(toISOString(date));
    };

    return {
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      disabled: isFetching || !isDataExists || !isResearchPage,
      dark: true
    };
  };
  const dropdownButtonProps = getDropdownButtonProps();

  const datePickerProps: IDatePickerProps = getDatePickerProps();

  const generateHandler = useCallback(
    (reportType: string) => {
      reportsStore.generateReport({report_type: reportType, ...params});
    },
    [reportsStore, params]
  );

  const reportOptions = ['ADR', 'DSUR', 'PSUR'].map((el) => ({
    text: el,
    onClick: () => {
      generateHandler(el);
    }
  }));

  const {getCumulativeDates: cumulativeDates} = globalFiltersStore.cumulativeDateRange;

  const props = {
    dropdownButtonProps,
    datePickerProps,
    count: selectedFiltersCount + selectedMedDraFiltersCount,
    isReportGenerating: reportsStore.reportGenerating,
    isReportsFetching: reportsStore.isFetching,
    generateReport: generateHandler,
    reportOptions,
    cumulativeDates
  };

  return <Header onLogout={onLogout} isResearchPage={isResearchPage} {...props} />;
};

export default observer(HeaderContainer);
