import {IDispropScoreData} from '@models/DispropScore/DispropScore.types';
import {IMedDraDictionariesData} from '@models/MedDRAFilters/MedDRAFilters.types';
import camelCase from 'lodash/camelCase';
import {snakeToPascal} from '@services/utils/Data.utils';

// export const getSelectedSortedData = ({
//   medDRASelectedFilters,
//   medDraData
// }: {
//   medDRASelectedFilters: IMedDRASelectedFiltersModel;
//   medDraData: MedDRAFiltersDataType;
// }): MedDRAFiltersDataType => {
//   return Object.values(EMedDraType).reduce<MedDRAFiltersDataType>(
//     (acc, filterName) => {
//       const selectedFilters = medDRASelectedFilters[filterName];
//       const sortedSelectedFilters = [...selectedFilters].sort().map((item, idx) => {
//         const selectedMedDra = medDraData[filterName].find((el) => el.name === item.name);
//         return {
//           code: selectedMedDra?.code ?? idx,
//           name: item.name
//         };
//       });
//
//       const filteredData: IMedDraDictionariesData[] = medDraData[filterName].filter(
//         ({name}) => selectedFilters.findIndex((item) => item.name === name) === -1
//       );
//       acc[filterName] = [...sortedSelectedFilters, ...filteredData];
//       return acc;
//     },
//     {
//       bodySystems: [],
//       hlgt: [],
//       hlt: [],
//       pt: [],
//       smq: []
//     }
//   );
// };

export const getSelectedData = ({
  medDRASelectedFilters,
  medDraDictionaryData
}: {
  medDRASelectedFilters: IMedDraDictionariesData[];
  medDraDictionaryData: IMedDraDictionariesData[];
}): IMedDraDictionariesData[] => {
  if (!medDRASelectedFilters.length) {
    return medDraDictionaryData;
  }

  const filteredData: IMedDraDictionariesData[] = medDraDictionaryData.filter(
    ({name}) =>
      name &&
      medDRASelectedFilters.findIndex(
        ({name: _name}) => name.toLowerCase() === _name.toLowerCase()
      ) === -1
  );
  return [...medDRASelectedFilters, ...filteredData];
};

export const getDispropScoresSelectedData = ({
  selectedPT,
  dispropScoreData,
  selectedPtResponse
}: {
  selectedPT: IMedDraDictionariesData[];
  dispropScoreData: IDispropScoreData[];
  selectedPtResponse: IDispropScoreData[];
}): IDispropScoreData[] => {
  if (!selectedPT.length && dispropScoreData) {
    return dispropScoreData.map((el: IDispropScoreData) => {
      const res: Partial<IDispropScoreData> = {};

      (Object.keys(el) as Array<keyof IDispropScoreData>).forEach((val) => {
        // @ts-ignore
        res[snakeToPascal(val)] = el[val];
      });

      return res as IDispropScoreData;
    });
  }

  const filteredData: IDispropScoreData[] = [...dispropScoreData]
    .filter(
      ({ptName}) =>
        selectedPT?.findIndex(({name}) => name?.toLowerCase() === ptName?.toLowerCase()) === -1
    )
    .map((item) => {
      const formattedScoreKeys = Object.entries(item).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [camelCase(key)]: val
        };
      }, {});
      return {
        ...item,
        ...formattedScoreKeys
      };
    });

  const emptySelected = selectedPT?.reduce<IDispropScoreData[]>((acc) => {
    // const ptInSelectedResponse = selectedPtResponse?.findIndex(
    //   ({ptName}) => ptName?.toLowerCase() === item?.name?.toLowerCase()
    // );
    // const ptInDispropScores = dispropScoreData?.findIndex(
    //   ({ptName}) => ptName?.toLowerCase() === item?.name?.toLowerCase()
    // );
    return acc;
  }, []);
  return [...emptySelected, ...selectedPtResponse, ...filteredData];
};
