import {EDrugRole, IDrugClass} from '@stores-mobx/StudyDrugFilterStore';

export enum PeriodType {
  All = 'ALL',
  TimePeriod = 'TIME_PERIOD',
  CurrentYear = 'CURRENT_YEAR',
  LastYear = 'LAST_YEAR',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
  Month = 'MONTH'
  // Year = 'YEAR',
  // Quarter = 'QUARTER',
}

export interface ITimePeriod {
  startDate?: string;
  endDate?: string;
  dsPeriodLabel?: string; // TODO
}

export interface IDispropScoreData {
  ptName: string;
  pt_name?: string;
  ptCode?: number;
  pt_code?: number;
  aeCounts: number;
  ae_counts?: number;
  eb05: number | null;
  eb05Flag: boolean;
  eb05_flag?: boolean;
  ebgm: number | null;
  ebgmFlag: boolean;
  ebgm_flag?: boolean;
  exposure?: number;
  ic: number | null;
  icFlag: boolean;
  ic_flag?: boolean;
  prr: number | null;
  prrFlag: boolean;
  prr_flag?: boolean;
  ror: number | null;
  rorFlag: boolean;
  ror_flag?: boolean;
  rr: number | null;
  rrFlag: boolean;
  rr_flag?: boolean;
  riskRsi: string;
  risk_rsi?: string;
  riskStatus: string;
  risk_status?: string;
  riskId: string | null;
  risk_id?: string | null;
}

export interface IDispropFetchParams {
  pt: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  selectedPtValues: any;
  filters: {
    age_groups: (number | undefined)[];
    sex: (number | undefined)[];
    serious: (number | undefined)[];
    study_name: string[];
  };
  medraFilters: {
    body_systems: string[];
    hlgt: string[];
    hlt: string[];
    pt: string[];
    smq: string[];
  };
  finalDrugsRequestTarget: {
    drug: {name: string};
    drug_class: {code: string | undefined} | Record<string, any>;
    drug_role: string;
  }[];
  finalDrugsRequestComparator: {
    drug: {name: string};
    drug_class: {code: string | undefined} | Record<string, any>;
    drug_role: string;
  }[];
  dates: {
    start_date: string;
    end_date: string;
  };
}
