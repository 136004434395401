import {Instance, types} from 'mobx-state-tree';
import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {Modal} from '@models/Modal';
import {CheckedDataType, ISelectOptions} from '@models/Dropdown/Dropdown.types';
import {GlobalDates, GlobalFiltersNames} from '@models/GlobalFilters/GlobalFilters.types';
import {toISOString} from '@services/utils/Date.utils';
import {mapToSelectOptions, mapToSelectOptionsWithId} from '@services/utils/Dropdown.utils';
import {getGlobalFilterProp} from '@services/utils/Functions.utils';
import {dictionaries} from '@services/mocks/mockData.json';
import {storeState} from '@services/utils/Mst.utils';

import {GlobalDateRangeStore} from './models/GlobalDateRange.store';

const endDate = toISOString(new Date('2023-01-01'));
const startDate = toISOString(new Date('2015-01-01')); //toISOString(getPrevXDate(new Date(endDate), COUNT_DAYS_BEFORE));

const GlobalFiltersStore = types.compose(
  Store,
  types
    .model('GlobalFiltersStore', {
      startDate: types.optional(types.string, startDate),
      endDate: types.optional(types.string, endDate),
      dataSources: types.frozen<ISelectOptions[]>(
        mapToSelectOptionsWithId(dictionaries.dataSources)
      ),
      targetDrugs: types.frozen<ISelectOptions[]>(mapToSelectOptions(dictionaries.targetDrugs)),
      drugClasses: types.frozen<ISelectOptions[]>([]),
      request: types.optional(Request, {}),
      cumulativeDateRange: types.optional(GlobalDateRangeStore, {
        dateFrom: startDate,
        dateTo: endDate
      }),
      modal: types.optional(Modal, {})
    })
    .actions((self) => ({
      updateStartDate: (date: string) => {
        self.startDate = date;
      },
      updateEndDate: (date: string) => {
        self.endDate = date;
      },
      updateGlobalFilterOptions: (filterName: GlobalFiltersNames, data: CheckedDataType) => {
        const [key, checked] = data;

        // @ts-ignore
        const updatedSelectOptions: ISelectOptions[] = self[filterName].map((option) => {
          return option.dataKey === key
            ? {...option, isVisible: checked}
            : {...option, disableCheckbox: false};
        });

        const checkedFilters: ISelectOptions[] = updatedSelectOptions.filter(
          ({isVisible, disableCheckbox}) => isVisible && !disableCheckbox
        );

        if (checkedFilters.length === 1) {
          // @ts-ignore
          self[filterName] = updatedSelectOptions.map((option) => {
            return option.dataKey === checkedFilters[0].dataKey
              ? {...option, disableCheckbox: true}
              : option;
          });
        } else {
          // @ts-ignore
          self[filterName] = updatedSelectOptions;
        }
      },
      updateGlobalFilterRadioOptions: (filterName: GlobalFiltersNames, name: string) => {
        self[filterName] = self[filterName].map((option) => {
          return option.name === name
            ? {...option, isVisible: true}
            : {...option, isVisible: false, disableCheckbox: false};
        });
      },
      selectAllGlobalFilterOptions: (filterName: GlobalFiltersNames, isVisible: boolean) => {
        if (isVisible) {
          // @ts-ignore
          self[filterName] = self[filterName].map((option) => ({
            ...option,
            isVisible,
            disableCheckbox: false
          }));
        } else {
          // @ts-ignore
          self[filterName] = self[filterName].map((option, idx) => {
            return idx === 0
              ? {
                  ...option,
                  isVisible: true,
                  disableCheckbox: true
                }
              : {
                  ...option,
                  isVisible,
                  disableCheckbox: false
                };
          });
        }
      },
      clearAll: () => {
        self.startDate = startDate;
        self.endDate = endDate;
      }
    }))
    .actions((self) => {
      return {
        afterCreate() {
          storeState(self, ['startDate', 'endDate']);
        },
        openConfirmation(): void {
          self.modal.open();
        },
        closeConfirmation(): void {
          self.modal.close();
        }
      };
    })
    .views((self) => ({
      get isFetching(): boolean {
        return self.request.isPending;
      },
      get selectedGlobalFiltersParams(): GlobalDates {
        return {
          dateFrom: self.startDate,
          dateTo: self.endDate,
          // ...getGlobalFilterProp(self.targetDrugs, GlobalFiltersNames.TargetDrugs),
          ...getGlobalFilterProp(self.dataSources, GlobalFiltersNames.DataSources)
        };
      }
    }))
);

export interface IGlobalFiltersStore extends Instance<typeof GlobalFiltersStore> {}

export {GlobalFiltersStore};
