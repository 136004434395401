import {IPageParams} from '@models/Page/Page.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getRootStore} from '@services/utils/Mst.utils';
import {IGlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {types, flow, Instance} from 'mobx-state-tree';
import {Store} from '@services/models/base/Store';
import {Request} from '@services/models/base/Request';

// import {mockStudiesData} from './mockStudies.json';

export interface IStudies {
  name: string;
  receiveDate: string;
}

const INITIAL_STATE: IPageParams<IStudies> = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const Studies = types.compose(
  Store,
  types
    .model('Studies', {
      data: types.frozen<IPageParams<IStudies>>(INITIAL_STATE),
      request: types.optional(Request, {})
    })
    .actions((self) => ({
      load: flow(function* () {
        const selectedGlobalFilters: IGlobalFiltersStore = getRootStore(self).globalFiltersStore;
        const medDRASelectedParams: {[key: string]: string} =
          getRootStore(self).medDRAFiltersStore.medDRASelectedFilters.selectedParamsWithSemicolons;

        const response = yield self.request.send(getApiUrl(ApiVersions.v11, 'studies.filter'), {
          method: 'get',
          params: {
            ...selectedGlobalFilters.selectedGlobalFiltersParams,
            ...medDRASelectedParams
          }
        });
        if (response) {
          const {content, ...pagination} = response;
          // const updatedContent = [...content, ...mockStudiesData];
          const updatedContent = [...content];
          self.data = {
            content: updatedContent,
            ...pagination
          };
        }
      })
    }))
    .views((self) => ({
      get fetchStudiesData() {
        return self.load;
      },
      get isFetching(): boolean {
        return self.request.isPending;
      },
      get isEmptyStudies(): boolean {
        return self.request.isDone && !self.data?.content?.length;
      }
    }))
);

export interface IStudiesModel extends Instance<typeof Studies> {}

export {Studies};
