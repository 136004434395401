import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@services/hooks/useStore';
import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {getFormattedReportClasses} from '@services/utils/ChartHelpers/BarChart.helpers';

import CaseVolumeChart from './CaseVolumeChart';

const CaseVolumeChartContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {reportClasses: selectedReportClasses} = selectedFiltersStore;
  const {timeData, caseVolumeChartData, splitByClasses, caseStatData} = caseStatStore;

  const chartData = {timeData, caseVolumeChartData, splitByClasses};
  const {reportClasses} = caseStatData;

  const formattedReportClasses: IFilterDataItem[] = getFormattedReportClasses({
    reportClasses,
    splitByClasses,
    selectedReportClasses
  });

  return <CaseVolumeChart chartData={chartData} reportClasses={formattedReportClasses} />;
};

export default observer(CaseVolumeChartContainer);
