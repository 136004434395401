import React, {memo} from 'react';

import {DetailCaseCardContainer, DetailCaseChartsContainer} from './components';

interface Props {}

const DetailPage: React.FC<Props> = () => {
  return (
    <div className="page-content">
      <DetailCaseCardContainer />
      <DetailCaseChartsContainer />
    </div>
  );
};

export default memo(DetailPage);
