import React, {forwardRef, useState} from 'react';
import {ITab} from '@models/Tabs/Tabs.types';
import cn from 'classnames';

import s from './Tabs.module.css';

interface IProps {
  tabs: ITab[];
  classNameContainer?: string;
  classNameHeader?: string;
  classNameWrapper?: string;
  setSelectedTabProps?: (tab: ITab) => void;
  isHiddenTabNames?: boolean;
}

const TabsComponent: React.FC<IProps> = forwardRef<HTMLDivElement, IProps>(
  (
    {
      tabs,
      classNameContainer,
      classNameHeader,
      classNameWrapper,
      setSelectedTabProps,
      isHiddenTabNames = false
    },
    ref
  ) => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return (
      <div className={cn(s.wrapper, classNameWrapper)}>
        <div className={cn(s.header, classNameHeader, {[s.headerInactive]: isHiddenTabNames})}>
          {!isHiddenTabNames &&
            tabs.map((tab, idx) => (
              <span
                key={idx}
                className={cn(s.tab, {
                  [s.tabActive]: tab.name === selectedTab.name
                })}
                onClick={() => {
                  setSelectedTab(tab);
                  if (setSelectedTabProps) setSelectedTabProps(tab);
                }}
              >
                {tab.name}
              </span>
            ))}
        </div>

        <div className={cn(s.container, classNameContainer)} ref={ref}>
          {React.createElement(selectedTab.component)}
        </div>
      </div>
    );
  }
);

export default TabsComponent;
