import React from 'react';

export const SearchIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(-8 -8)"
    >
      <g stroke="currentColor" strokeWidth="1.5" transform="translate(8 8)">
        <circle cx="10.5" cy="10.5" r="6" />
        <path d="M15 15L19 19" />
      </g>
    </g>
  </svg>
);
