import React from 'react';

export const ProductsIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="red" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <filter id="prefixProducts">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.192157 0 0 0 0 0.196078 0 0 0 0 0.211765 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefixProducts)">
      <g transform="translate(2 2)">
        <path
          fill="#313236"
          d="M9.5 11.5H16c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5v-8z"
          opacity=".502"
        />
        <path
          stroke="#313236"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 0c3.866 0 7 3.134 7 7 0 1.953-.8 3.718-2.089 4.988-.567-.312-1.219-.488-1.911-.488h0l-8.361.001C6.616 10.284 6 8.714 6 7c0-3.866 3.134-7 7-7zM2.5 15c0-.828.672-1.5 1.5-1.5h2.5"
        />
        <rect
          width="20"
          height="8"
          y="11.5"
          stroke="#313236"
          strokeLinecap="round"
          strokeLinejoin="round"
          rx="4"
        />
        <path
          stroke="#313236"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 3.5L10.5 9.5M9.5 11.5L9.5 19.5"
        />
      </g>
    </g>
  </svg>
);
