import {Store} from '@models/base/Store';
import {IFilterCodeData, SelectedUserFilters} from '@models/CaseStat/CaseStat.types';
import {IDropdownFilterOptions} from '@models/Dropdown/DropdownFilter';
import {getFilterProp} from '@services/utils/Functions.utils';
import {Instance, types} from 'mobx-state-tree';

const SelectedUserFiltersStore = types.compose(
  Store,
  types
    .model('SelectedUserFiltersStore', {
      reportClasses: types.frozen<IFilterCodeData[]>([]),
      ageGroups: types.frozen<IFilterCodeData[]>([]),
      countries: types.frozen<IFilterCodeData[]>([]),
      sexes: types.frozen<IFilterCodeData[]>([]),
      medHistories: types.frozen<IFilterCodeData[]>([]),
      conmeds: types.frozen<IFilterCodeData[]>([]),
      conmedsDrugClass: types.frozen<IFilterCodeData[]>([]),
      outcomes: types.frozen<IFilterCodeData[]>([]),
      serious: types.frozen<IFilterCodeData[]>([]),
      severity: types.frozen<IFilterCodeData[]>([]),
      listedness: types.frozen<IFilterCodeData[]>([])
    })
    .actions((self) => ({
      setUserFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => {
        self[filterName] = filterData.map(({name, code}) => ({
          name: name?.toLowerCase(),
          code
        }));
      },
      clearUserFilter: (filterName: SelectedUserFilters, filterData: string | number) => {
        self[filterName] = self[filterName].filter(({name, code}) =>
          typeof filterData === 'string'
            ? name?.toLowerCase() !== filterData?.toLowerCase()
            : code !== filterData
        );
      },
      clearAllUserFilters: (filterName: SelectedUserFilters) => {
        self[filterName] = [];
      }
    }))
    .views((self) => ({
      get selectedFiltersParams(): {[key: string]: string} {
        return {
          ...getFilterProp(self.reportClasses, SelectedUserFilters.ReportClasses),
          ...getFilterProp(self.ageGroups, SelectedUserFilters.AgeGroups),
          ...getFilterProp(self.sexes, SelectedUserFilters.Sexes),
          ...getFilterProp(self.countries, SelectedUserFilters.Countries),
          ...getFilterProp(self.medHistories, SelectedUserFilters.MedHistories),
          ...getFilterProp(self.conmeds, SelectedUserFilters.Conmeds),
          ...getFilterProp(self.conmedsDrugClass, SelectedUserFilters.ConmedsDrugClass),
          ...getFilterProp(self.outcomes, SelectedUserFilters.Outcomes),
          ...getFilterProp(self.serious, SelectedUserFilters.Seriousness),
          ...getFilterProp(self.severity, SelectedUserFilters.Severity),
          ...getFilterProp(self.listedness, SelectedUserFilters.Listedness)
        };
      },
      get selectedFiltersChipsOptions(): IDropdownFilterOptions<SelectedUserFilters>[] {
        return Object.values(SelectedUserFilters).reduce<
          IDropdownFilterOptions<SelectedUserFilters>[]
        >((acc, filterName) => {
          acc.push({
            category: filterName,
            // TODO
            selectedFilters: self[filterName].map(({name}) => name)
          });
          return acc;
        }, []);
      },
      get selectedFiltersCount(): number {
        return Object.values(SelectedUserFilters).reduce<number>((acc, filterName) => {
          acc += self[filterName].length;
          return acc;
        }, 0);
      }
    }))
);

export interface ISelectedUserFiltersStore extends Instance<typeof SelectedUserFiltersStore> {}

export {SelectedUserFiltersStore};
