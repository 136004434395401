import {SelectedUserFilters} from '@models/CaseStat/CaseStat.types';
import {
  genderBoxShadowCollection,
  genderColorCollection
} from '@services/constants/chart.constants';
import {useStore} from '@services/hooks/useStore';
import {integerWithSpaces, sumOfCases} from '@services/utils/Functions.utils';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {getChartOptions, PieChart} from 'src/components';

const DemographicsPieChartContainer: React.FC<{className: string}> = ({className}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();

  const {sexes: selectedGenders, setUserFilter} = selectedFiltersStore;
  const chartData = caseStatStore.caseStatData.sex;

  const selectedGendersCodes = selectedGenders.map(({code = 3}) => code?.toString());
  return (
    <PieChart
      className={className}
      chartData={chartData}
      selectedFilters={selectedGenders}
      setFilter={setUserFilter}
      filterName={SelectedUserFilters.Sexes}
      colorCollection={genderColorCollection}
      boxShadowCollection={genderBoxShadowCollection}
      options={getChartOptions({
        chartData,
        sumOfCases: integerWithSpaces(
          sumOfCases({chartData, selectedFilters: selectedGendersCodes})
        ),
        selectedFilters: selectedGenders,
        colorCollection: genderColorCollection,
        filterName: SelectedUserFilters.Sexes
      })}
    />
  );
};

export default observer(DemographicsPieChartContainer);
