import {SeriesPieType} from '@models/Chart/PieChart.types';
import {colorGray400, colorGray600} from '@services/constants/chart.constants';
import echarts from 'echarts/lib/echarts';

const colorGray = '#eff1f3';

const defaultRichSettings = {
  a: {
    color: colorGray600,
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: 45
  },
  b: {
    color: colorGray400,
    fontSize: 14,
    lineHeight: 18
  }
};

const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  show: false,
  trigger: 'item',
  formatter: '{a} <br/>{b}: {c} ({d}%)'
};

export const DEFAULT_LEGEND_OPTIONS: echarts.EChartOption.Legend = {
  show: false,
  orient: 'horizontal',
  align: 'auto',
  bottom: 0,
  top: '80%',
  textStyle: {
    fontWeight: 'bold',
    lineHeight: 14,
    color: colorGray400
  }
};

export const DEFAULT_SERIES_OPTIONS: SeriesPieType[] = [
  {
    name: 'Pie chart',
    type: 'pie',
    radius: ['54%', '60%'],
    center: ['50%%', '40%'],
    startAngle: 0,
    endAngle: 359.9,
    zlevel: 1,
    label: {
      show: false,
      position: 'center',
      backgroundColor: 'white',
      width: 130,
      verticalAlign: 'bottom',
      formatter: (params: echarts.EChartOption.Tooltip.Format) => {
        return `{a|${params.data.value[1]}%}\n{b|Cases with\n${params.data.name}}`;
      },
      rich: defaultRichSettings
    },
    minAngle: 5,
    hoverOffset: 4,
    avoidLabelOverlap: false,
    legendHoverLink: true,
    hoverAnimation: true,
    selectedMode: false,
    showBackground: true,
    markArea: {
      silent: false
    },
    emphasis: {
      label: {
        show: true,
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    labelLine: {
      show: false
    },
    data: []
  },
  {
    name: 'gray chart inside',
    type: 'pie',
    radius: ['45%', '54%'],
    center: ['50%%', '40%'],
    legendHoverLink: false,
    hoverAnimation: false,
    silent: true,
    startAngle: 0,
    endAngle: 359.9,
    zlevel: 2, // to over shadow inside
    label: {
      show: false
    },
    data: [
      {
        name: '',
        itemStyle: {
          color: colorGray
        },
        value: 0
      }
    ]
  },
  {
    name: 'general text inside chart',
    type: 'pie',
    radius: ['45%', '54%'],
    center: ['50%%', '40%'],
    legendHoverLink: false,
    hoverAnimation: false,
    silent: true,
    startAngle: 0,
    endAngle: 359.9,
    zlevel: 0,
    label: {
      show: false
    },
    data: [
      {
        itemStyle: {
          color: colorGray
        },
        label: {
          show: true,
          position: 'center',
          formatter: () => {
            return '{a|0}\n{b|Cases}';
          },
          rich: defaultRichSettings
        }
      }
    ]
  }
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  legend: DEFAULT_LEGEND_OPTIONS,
  // @ts-ignore
  series: DEFAULT_SERIES_OPTIONS,
  animation: false
};
