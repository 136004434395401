import format from 'date-fns/format';
import {EChartAggLevel} from '@models/CaseStat/CaseStat.types';

interface RangeDatesProps {
  startDate: Date;
  endDate: Date;
  aggLevel?: EChartAggLevel;
}

interface LocaleDateStringProps extends RangeDatesProps {
  locales?: string;
  options?: Intl.DateTimeFormatOptions;
}

export const getRangeDates = ({startDate, endDate, aggLevel}: RangeDatesProps): Date[] => {
  const dates = [];

  let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

  while (currentDate <= endDate) {
    dates.push(currentDate);

    let level = [0, 0, 0];
    switch (aggLevel) {
      case EChartAggLevel.Year: {
        level = [1, 0, 0];
        break;
      }
      case EChartAggLevel.Month: {
        level = [0, 1, 0];
        break;
      }
      default: {
        level = [0, 0, 1];
      }
    }

    currentDate = new Date(
      currentDate.getFullYear() + level[0],
      currentDate.getMonth() + level[1],
      currentDate.getDate() + level[2]
    );
  }
  return dates;
};

export const getLocalesDates = ({
  startDate,
  endDate,
  locales = 'en-GB',
  options = {day: 'numeric', month: 'short', year: '2-digit'},
  aggLevel
}: LocaleDateStringProps): string[] => {
  const dates: Date[] = getRangeDates({startDate, endDate, aggLevel});
  return dates.map((date) => date.toLocaleDateString(locales, options));
};

export const isDatesEqual = ({firstDate, secondDate}: {firstDate: Date; secondDate: Date}) => {
  return firstDate.toDateString() === secondDate.toDateString();
};

export const getPrevDate = (date: Date): Date => {
  const prevDate = date;
  prevDate.setDate(prevDate.getDate() - 1);
  return prevDate;
};

export const getPrevXDate = (date: Date, x: number): Date => {
  const prevDate = date;
  prevDate.setDate(prevDate.getDate() - x);
  return prevDate;
};

export const getPlusFifteenDays = (date: Date): Date => {
  const nextDate = date;
  nextDate.setDate(nextDate.getDate() + 15);
  return nextDate;
};

export const getTimeOfDateString = (date: string) => new Date(date).getTime();

export const isDateExists = (date: string | null): date is string => {
  return date !== null && date !== undefined;
};

export const toISOString = (date: Date): string => date.toISOString().split('T')[0];

export const formatDate = (date: Date, pattern = 'd MMM yyyy') => {
  return format(date, pattern);
};
