export const findAndShowElement = (textElement: HTMLElement) => {
  const parent = textElement.offsetParent;
  if (!parent) return;
  if (
    textElement.getBoundingClientRect().top - 50 < parent?.getBoundingClientRect().top &&
    parent?.scrollTop > 0
  ) {
    const top =
      parent?.scrollTop -
      (parent?.getBoundingClientRect().top - textElement.getBoundingClientRect().top + 150);
    parent.scrollTo({top, left: 0, behavior: 'smooth'});
  } else if (
    textElement.getBoundingClientRect().top + 50 >
    parent?.getBoundingClientRect().height + parent?.getBoundingClientRect().top
  ) {
    const top =
      textElement.getBoundingClientRect().top -
      (parent?.getBoundingClientRect().top + parent?.getBoundingClientRect().height) +
      150;
    parent.scrollTo({top, left: 0, behavior: 'smooth'});
  }
};
