import React, {ButtonHTMLAttributes, ReactElement} from 'react';
import cn from 'classnames';

import {ArrowDownIcon} from '../../../static/icons';

import s from './ContainedButton.module.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  Icon?: React.FC<{className?: string}>;
  title?: string;
  active?: boolean;
  count?: number;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dropDownIcon?: boolean;
  children?: React.ReactNode;
}

const ContainedButton: React.FC<Props> = ({
  Icon,
  title,
  active,
  count,
  onClick,
  disabled,
  className,
  dropDownIcon = false,
  children
}): ReactElement => (
  <button
    className={cn(s.btn, className, {
      [s.btnActive]: active,
      [s.disabled]: disabled
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {Icon && <Icon />}
    {children && children}
    {title && <span className={s.title}>{title}</span>}
    {count !== undefined && count > 0 && <span className={s.count}>{count}</span>}
    {dropDownIcon && <ArrowDownIcon className={s.dropIcon} />}
  </button>
);

export default ContainedButton;
