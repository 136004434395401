/**
 * JavaScript Adapter
 * https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
 *
 * react-keycloak
 * https://github.com/react-keycloak/react-keycloak/tree/master/packages/web
 */

import KeycloakJs, {KeycloakConfig} from 'keycloak-js';
import {appConstants} from '@config/appConstants';

const keycloakConfig: KeycloakConfig = {
  url: appConstants.keycloakUrl,
  realm: appConstants.keycloakRealm,
  clientId: appConstants.keycloakClient
};

const keycloak: KeycloakJs = new KeycloakJs(keycloakConfig);

export {keycloak};
