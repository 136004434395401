import React from 'react';

export const AdverseEventIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <filter id="prefixEvent">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.192157 0 0 0 0 0.196078 0 0 0 0 0.211765 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      filter="url(#prefixEvent)"
    >
      <g stroke="#313236" transform="translate(1 1)">
        <circle cx="11" cy="11" r="11" />
        <path
          fill="#313236"
          fillOpacity=".5"
          d="M11 2.5c4.694 0 8.5 3.806 8.5 8.5s-3.806 8.5-8.5 8.5-8.5-3.806-8.5-8.5S6.306 2.5 11 2.5zm1.5 11h-3v3h3v-3zm0-8h-3v6h3v-6z"
        />
      </g>
    </g>
  </svg>
);
