import {useStore} from '@services/hooks/useStore';
import echarts from 'echarts/lib/echarts';
import {observer} from 'mobx-react-lite';
import React from 'react';
import s from 'src/components/Report/Report.module.css';
import {EmptyData} from 'src/components/UIKit';

import {getChartOptions} from './chartOptions';
import TimeToOnsetBoxPlot from './TimeToOnsetBoxPlot';

const TimeToOnsetBoxPlotContainer: React.FC<{}> = () => {
  const {
    reportsStore: {boxPlotData, isFetching}
  } = useStore();

  const chartOptions: echarts.EChartOption = getChartOptions({options: boxPlotData});

  if (boxPlotData && !boxPlotData?.length && !isFetching) {
    return (
      <div className={s.emptyDataWrapper}>
        <EmptyData text="Data is empty" />
      </div>
    );
  }

  return <TimeToOnsetBoxPlot isFetching={isFetching} getChartOptions={chartOptions} />;
};

export default observer(TimeToOnsetBoxPlotContainer);
