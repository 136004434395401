import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import CaseProperties from './CaseProperties';

interface Props {
  withoutScroll?: boolean;
}

const CasePropertiesContainer: React.FC<Props> = ({withoutScroll}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  const {outcomes: selectedOutcome, setUserFilter, clearUserFilter} = selectedFiltersStore;

  return (
    <CaseProperties
      outcomesData={caseStatData.outcomes}
      selectedOutcomes={selectedOutcome}
      setUserFilter={setUserFilter}
      clearUserFilter={clearUserFilter}
      withoutScroll={withoutScroll}
    />
  );
};

export default observer(CasePropertiesContainer);
