import React, {useRef, useState} from 'react';
import {UserFiltersNames} from '@models/CaseStat/CaseStat.types';
import {IDropdownFilterProps} from '@models/Dropdown/DropdownFilter';
import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';
import {useTranslation} from 'react-i18next';
import {FiltersIcon} from 'src/static/icons';
import {ClickOutside} from 'src/components/ClickOutside';
import {Button, ContainedButton, EmptyData, Loading} from 'src/components/UIKit';

import {SelectedOptions} from '../SelectedOptions';

import s from './DropdownFilter.module.css';

interface IProps extends IDropdownFilterProps {
  count?: number;
  disabled?: boolean;
  onClearAll?: () => void;
}

const DropdownFilter: React.FC<IProps> = ({
  count,
  userFiltersOptions,
  clearUserFilter,
  clearAllUserFilters,
  medDraOptions,
  clearMedDRAFilter,
  clearAllMedDRAFilters,
  isUserFetching,
  isMedDraFetching,
  disabled,
  onClearAll
}) => {
  const [isListVisible, toggleListVisible] = useState(false);
  const selectRef: React.MutableRefObject<null> = useRef(null);

  const userOptionsEmpty = userFiltersOptions.every((o) => o.selectedFilters.length === 0);
  const medDraOptionsEmpty = medDraOptions.every((o) => o.selectedFilters.length === 0);

  const isFiltersSelectedEmpty: boolean =
    (!!userFiltersOptions.length && !userOptionsEmpty) ||
    (!!medDraOptions.length && !medDraOptionsEmpty);

  const handleClickOutside = () => {
    if (isListVisible) {
      toggleListVisible(false);
    }
  };

  const {t} = useTranslation();

  const MedDRASelectedFiltersNames: Record<EMedDraType, string> = {
    [EMedDraType.BodySystems]: t(`medDRAFilters.${EMedDraType.BodySystems}`),
    [EMedDraType.HLGT]: t(`medDRAFilters.${EMedDraType.HLGT}`),
    [EMedDraType.HLT]: t(`medDRAFilters.${EMedDraType.HLT}`),
    [EMedDraType.PT]: t(`medDRAFilters.${EMedDraType.PT}`),
    [EMedDraType.SMQ]: t(`medDRAFilters.${EMedDraType.SMQ}`)
  };

  return (
    <div className={s.selectContainer}>
      <ContainedButton
        Icon={FiltersIcon}
        title={t('filters')}
        className={s.containedButton}
        count={count}
        onClick={() => toggleListVisible(!isListVisible)}
        disabled={isUserFetching || isMedDraFetching || disabled}
        active
      />
      {isListVisible && (
        <ClickOutside handleClickOutside={handleClickOutside}>
          <div className={s.selectListContainer} ref={selectRef}>
            <Button onClick={onClearAll} className={s.selectListClear} size="small" variant="basic">
              <span className={s.selectListClearText}>{t('clearAll')}</span>
            </Button>
            {isFiltersSelectedEmpty ? (
              <div className={s.selectList}>
                <SelectedOptions
                  options={medDraOptions}
                  selectedFiltersNames={MedDRASelectedFiltersNames}
                  clearAllFilters={clearAllMedDRAFilters}
                  clearFilter={clearMedDRAFilter}
                />
                <SelectedOptions
                  options={userFiltersOptions}
                  selectedFiltersNames={UserFiltersNames}
                  clearAllFilters={clearAllUserFilters}
                  clearFilter={clearUserFilter}
                />
                {(isUserFetching || isMedDraFetching) && <Loading />}
              </div>
            ) : (
              <EmptyData text="No filter selected" />
            )}
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default DropdownFilter;
