import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import {FilterTitle} from 'src/components/UIKit';
import {PaperWidget} from 'src/components';

import {StudyDrugFilterList} from './StudyDrugFilterList';
import s from './StudyDrugFiltersCard.module.css';
import StudyDrugEmptyPage from './StudyDrugEmptyPage/StudyDrugEmptyPage';

const StudyDrugFiltersCard = () => {
  const {t} = useTranslation();
  const {studyDrugFilterStore} = useStore();
  const {setModalOpen, getShowWidgets: showWidgets} = studyDrugFilterStore;

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <PaperWidget
        wrapperClassName={s.paperWidgetWrapper}
        contentClassName={s.paperWidgetContent}
        button
        onClick={handleOpen}
        buttonText="Edit"
      >
        <FilterTitle title={t('studiesAndDrugs')} />
        {showWidgets ? <StudyDrugFilterList /> : <StudyDrugEmptyPage onClick={setModalOpen} />}
      </PaperWidget>
    </>
  );
};

export default observer(StudyDrugFiltersCard);
