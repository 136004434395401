import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@services/hooks/useStore';
import {history} from '@services/router/RouterHistory';

import DetailPage from './DetailPage';

const DetailPageContainer = () => {
  const {caseDetailStore} = useStore();

  const {pathname} = history.location;
  const id = pathname.slice(1).split('/')[1];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    caseDetailStore.fetchCaseDetailData(id);

    return () => {
      caseDetailStore.resetStore();
    };
  }, [caseDetailStore, id]);

  return <DetailPage />;
};

export default observer(DetailPageContainer);
