import React, {FC, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import cn from 'classnames';

import {ITextElement} from '../../models/Demo.types';
import {CloseIcon} from '../../../static/icons';
import {findAndShowElement} from '../../utils/LayoutFind.utils';

import s from './TextCustomSelection.module.css';

export interface IProps {
  textElements: ITextElement[];
  onRemoveEntities: (index: number) => void;
  currentHoverElement?: number;
  setCurrentHoverElement: (value: number | undefined) => void;
  findAndShowElementIndex?: number;
  unsetFindAndShowElement: (value: number | undefined) => void;
  setShowElement: (value: number | undefined) => void;
}

const TextCustomSelection: FC<IProps> = ({
  textElements = [],
  onRemoveEntities,
  currentHoverElement,
  setCurrentHoverElement,
  findAndShowElementIndex,
  unsetFindAndShowElement,
  setShowElement
}) => {
  const mainRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (findAndShowElementIndex === undefined) return;
    const textElement = document.getElementById(`rightSide${findAndShowElementIndex}`);
    if (textElement) {
      findAndShowElement(textElement);
      unsetFindAndShowElement(undefined);
    }
  }, [findAndShowElementIndex, unsetFindAndShowElement]);

  return (
    <div className={s.mainWrapper} ref={mainRef}>
      <div className={s.textWrapper} ref={textRef}>
        {textElements.map((el: ITextElement, index) => {
          if (el.label === null) {
            return <span key={index}>{el.text}</span>;
          } else {
            return (
              <span
                key={index}
                id={`rightSide${el.index}`}
                className={cn(s.selection, s[`selection${el.labelIndex}`], {
                  [s.hover]: currentHoverElement === el.index
                })}
                onMouseEnter={() => setCurrentHoverElement(el.index)}
                onMouseLeave={() => setCurrentHoverElement(undefined)}
                onClick={() => setShowElement(el.index)}
                title={el.longForm}
              >
                {el.text}
                <span className={s.closeButton} onClick={() => onRemoveEntities(el.index ?? -1)}>
                  <CloseIcon className={s.closeIcon} />
                </span>
              </span>
            );
          }
        })}
      </div>
    </div>
  );
};

export default observer(TextCustomSelection);
