import {CaseVolumeStatTypes} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import CaseVolumeControls from './CaseVolumeControls';

const CaseVolumeControlsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {activeStatType, setActiveStatType, isDataExists} = caseStatStore;

  const switcherData = {
    statTypes: [CaseVolumeStatTypes.Cumulative, CaseVolumeStatTypes.Individual],
    activeStatType,
    setActiveStatType,
    disabled: !isDataExists
  };

  return <CaseVolumeControls switcherData={switcherData} />;
};

export default observer(CaseVolumeControlsContainer);
