import {types, Instance, cast, flow} from 'mobx-state-tree';
import {Store} from '@services/models/base/Store';
import {Drugs} from '@stores-mobx/StudyDrugFilterStore/model/Drugs';
import {IStudies, Studies} from '@stores-mobx/StudyDrugFilterStore/model/Studies';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {Request} from '@models/base/Request';
import {filterSelectedClasses, onlyUniqueForStringArray} from '@services/utils/Functions.utils';
import {storeState} from '@services/utils/Mst.utils';
import {Modal} from '@services/models/Modal';

export interface IDrugClass {
  drugClassCode: string;
  drugClassId: string;
  drugClassName: string;
}

export interface IDrug {
  name: string;
  drugsCount: number;
  drugRole?: EDrugRole;
  drugClass?: IDrugClass;
  drugClassesArray?: IDrugClass[];
}

export const enum EDrugRole {
  MainDrug = 'Main drug',
  SecondaryDrug = 'Secondary drug',
  ComparatorDrug = 'Comparator drug',
  TargetDrug = 'Target drug'
}

const StudyDrugFilterStore = types.compose(
  Store,
  types
    .model('StudyDrugFilter', {
      studies: types.optional(Studies, {}),
      studiesTempSelect: types.optional(types.array(types.frozen<IStudies>()), []),
      studiesSelected: types.optional(types.array(types.frozen<IStudies>()), []),
      drugs: types.optional(Drugs, {}),
      drugsTempSelect: types.optional(types.array(types.frozen<IDrug>()), []),
      drugsSelected: types.optional(types.array(types.frozen<IDrug>()), []),
      requestDrugClasses: types.optional(Request, {}),
      isModalOpen: false,
      showAll: types.optional(types.boolean, false),
      modal: types.optional(Modal, {})
    })
    .actions((self) => ({
      afterCreate() {
        storeState(self, ['studiesSelected', 'drugsSelected', 'showAll']);
      },
      setModalOpen(value: boolean) {
        self.isModalOpen = value;
      },
      setShowAll(value: boolean) {
        self.showAll = value;
      },
      updateSelected() {
        self.studiesSelected = cast([...self.studiesTempSelect]);
        self.drugsSelected = cast([...self.drugsTempSelect]);
      },
      updateTempSelected() {
        self.studiesTempSelect = cast([...self.studiesSelected]);
        self.drugsTempSelect = cast([...self.drugsSelected]);
      },
      clearTempSelected() {
        self.studiesTempSelect = cast([]);
        self.drugsTempSelect = cast([]);
      },
      setStudiesTempSelect(value: IStudies) {
        if (self.studiesTempSelect?.includes(value)) {
          self.studiesTempSelect = cast(self.studiesTempSelect?.filter((el) => el !== value));
        } else {
          self.studiesTempSelect = cast([...self.studiesTempSelect, value]);
        }
      },
      setDrugsTempSelect: flow(function* (value: IDrug) {
        if (self.drugsTempSelect?.includes(value)) {
          self.drugsTempSelect = cast(self.drugsTempSelect?.filter((el) => el !== value));
        } else {
          const response = yield self.requestDrugClasses.send(
            getApiUrl(ApiVersions.v11, 'drug.classes'),
            {
              method: 'get',
              params: {
                drugName: `${value.name}`
              }
            }
          );
          const drugClassesArray = response ? response : [];
          const newDrag: IDrug = {...value, drugClassesArray};
          self.drugsTempSelect = cast([...self.drugsTempSelect, newDrag]);
        }
      }),
      updateDrugsTempSelectElement(value: IDrug) {
        const index = self.drugsTempSelect.findIndex(
          (drug) => drug.name === value.name && drug.drugRole === value.drugRole
        );
        self.drugsTempSelect[index] = value;
      },
      setDrugsTempSelectElementRoleToMain(value: IDrug) {
        const newArray = self.drugsTempSelect.map((el) => {
          if (el.drugRole === EDrugRole.MainDrug.toString()) {
            return {...el, drugRole: EDrugRole.SecondaryDrug};
          }
          if (el.name === value.name && el.drugRole !== EDrugRole.ComparatorDrug)
            return {...el, drugRole: EDrugRole.MainDrug};
          return el;
        });

        const filterNewArray = newArray.filter(
          (valueArr, index, selfArr) =>
            index ===
            selfArr.findIndex((t) => t.drugRole === valueArr.drugRole && t.name === valueArr.name)
        );
        self.drugsTempSelect = cast(filterNewArray);
      }
    }))
    .actions((self) => {
      return {
        openConfirmation(): void {
          self.modal.open();
        },
        closeConfirmation(): void {
          self.modal.close();
        }
      };
    })
    .views((self) => ({
      get getStudies() {
        return self.studiesSelected;
      },
      get getStudiesNameArray(): string[] {
        return self.studiesSelected.map((el) => el.name);
      },
      get getStudiesComma() {
        return self.studiesSelected?.map((el) => el.name).join(',') ?? '';
      },
      get getStudiesTempSelectComma() {
        return self.studiesTempSelect.map((el) => el.name).join(',');
      },
      get getStudiesDict() {
        return self.studies.data?.content ?? [];
      },
      get getMainDrug() {
        return self.drugsSelected.find((el) => el.drugRole === EDrugRole.MainDrug);
      },
      get getMainDrugName() {
        return self.drugsSelected.find((el) => el.drugRole === EDrugRole.MainDrug)?.name ?? '';
      },
      get getSecondaryDrugs() {
        return self.drugsSelected.filter((el) => el.drugRole === EDrugRole.SecondaryDrug);
      },
      get getTargetDrugs() {
        return (
          self.drugsSelected.filter(
            (el) => el.drugRole !== EDrugRole.ComparatorDrug && el.drugClass
          ) ?? []
        );
      },
      get getComparatorsDrugs() {
        return self.drugsSelected.filter(
          (el) => el.drugRole === EDrugRole.ComparatorDrug && el.drugClass
        );
      },
      get getTargetDrugsWithEmptyClass() {
        return self.drugsSelected.filter((el) => el.drugRole !== EDrugRole.ComparatorDrug) ?? [];
      },
      get getComparatorsDrugsWithEmptyClass() {
        return self.drugsSelected.filter((el) => el.drugRole === EDrugRole.ComparatorDrug);
      },
      get getComparatorsDrugNamesComma() {
        const result = self.drugsSelected
          .filter((el) => el.drugRole === EDrugRole.ComparatorDrug)
          .map((el) => el.name);
        return result.length ? result.join(', ') : '';
      },
      get getComparatorsDrugClassesComma() {
        const result = self.drugsSelected
          .filter((el) => el.drugRole === EDrugRole.ComparatorDrug && !!el.drugClass)
          .map((el) => el.drugClass?.drugClassName)
          .filter(onlyUniqueForStringArray);
        return result ? result.join(', ') : null;
      },
      get getSecondaryDrugNamesComma() {
        const result = self.drugsSelected
          .filter((el) => el.drugRole === EDrugRole.SecondaryDrug)
          .map((el) => el.name);
        return result.length ? result.join(', ') : '';
      },
      get getSecondaryDrugClassesComma() {
        const result = self.drugsSelected
          .filter((el) => el.drugRole === EDrugRole.SecondaryDrug && !!el.drugClass)
          .map((el) => el.drugClass?.drugClassName)
          .filter(onlyUniqueForStringArray);
        return result ? result.join(', ') : null;
      },
      get getIsSomethingSelected() {
        return self.studiesSelected.length > 0 || self.drugsSelected.length > 0;
      },
      get getIsSomethingClassesSelected() {
        return (
          filterSelectedClasses(self.drugsSelected, EDrugRole.MainDrug) &&
          filterSelectedClasses(self.drugsSelected, EDrugRole.ComparatorDrug)
        );
      },
      // selected one or more therapy
      get getReportIsDisabled() {
        const hasDrugClasses =
          self.drugsSelected.length > 0 && self.drugsSelected.findIndex((el) => el.drugClass) > -1;

        return !hasDrugClasses;
      },
      // if it's selected nothing show confirmation
      get getIsTempSelected() {
        return !(self.studiesTempSelect.length === 0 && self.drugsTempSelect.length === 0);
      },
      get getShowWidgets() {
        return self.showAll || self.studiesSelected.length > 0 || self.drugsSelected.length > 0;
      }
    }))
    .views((self) => ({
      get getAllFilters() {
        const mainDrug: string = self.getMainDrugName;
        const secondaryDrugs: string = self.getSecondaryDrugNamesComma;
        const comparatorDrugs: string = self.getComparatorsDrugNamesComma;
        const study: string = self.getStudiesComma;

        return {
          study,
          comparatorDrugs,
          secondaryDrugs,
          mainDrug
        };
      }
    }))
);

export interface IStudyDrugFilterStore extends Instance<typeof StudyDrugFilterStore> {}

export {StudyDrugFilterStore};
