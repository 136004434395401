import React from 'react';
import {useTranslation} from 'react-i18next';
import {FilterGroupTitle} from 'src/components';

import s from '../CaseProperties.module.css';

interface Props {
  unknownOutcomesCount: number;
  unknownSeriousCount: number;
}

const CasePropertiesControls: React.FC<Props> = ({unknownOutcomesCount, unknownSeriousCount}) => {
  const {t} = useTranslation();
  return (
    <div className={s.controlsContainer}>
      <FilterGroupTitle
        className={s.gridFirst}
        title={t('outcomeCaseLevel')}
        subtitle={t('unknownNCases', {n: unknownOutcomesCount})}
      />
      <FilterGroupTitle
        className={s.gridLast}
        title={t('seriousnessCaseLevel')}
        subtitle={t('unknownNCases', {n: unknownSeriousCount})}
      />
    </div>
  );
};

export default CasePropertiesControls;
