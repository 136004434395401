import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {IMedDRAFilterData} from '@models/MedDRAFilters/MedDRAFilters.types';
import {IBoxPlotData, ReportsChartFormattedDataType} from '@models/Reports/Reports.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {
  getTimeline,
  getReportsLabels,
  getTimeToUnsetReportFormattedData,
  reduceBoxPlotOnsetData
} from '@services/utils/Reports.utils';
import {flow, Instance, types} from 'mobx-state-tree';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {appConstants} from '@config/appConstants';
import {downloadBlob} from '@services/utils/Functions.utils';
import {formatObjectNoEmptyFields} from '@services/utils/Data.utils';

const ReportsStore = types.compose(
  Store,
  types
    .model('ReportsStore', {
      boxPlotData: types.frozen<IBoxPlotData[]>([]),
      reportByPtData: types.frozen<IMedDRAFilterData[]>([]),
      timeToOnsetTimeline: types.optional(types.frozen<number[]>(), []),
      dataReport: types.frozen<IParamsPagination[]>([]),
      request: types.optional(Request, {}),
      requestReportByPt: types.optional(Request, {}),
      requestDocGen: types.optional(Request, {}),
      isGenerating: false
    })
    .actions((self) => ({
      loadBoxPlotData: flow(function* (params: object) {
        const response: IBoxPlotData[] = yield self.request.send(
          getApiUrl(ApiVersions.v11, 'case.time.to.onset'),
          {
            method: 'get',
            params: formatObjectNoEmptyFields(params)
          }
        );
        if (response) {
          const data = reduceBoxPlotOnsetData(response);
          self.boxPlotData = data;
          self.timeToOnsetTimeline = getTimeline(data);
        }
      }),
      loadReportByPt: flow(function* (params: object) {
        const response: IMedDRAFilterData[] = yield self.requestReportByPt.send(
          getApiUrl(ApiVersions.v11, 'case.count.by.pt'),
          {
            method: 'get',
            params: formatObjectNoEmptyFields(params)
          }
        );
        if (response) {
          self.reportByPtData = response;
        }
      }),
      generateReport: flow(function* (params: object) {
        self.isGenerating = true;

        const responseData = yield self.requestDocGen.send(appConstants.reportGeneratorUrl, {
          method: 'post',
          responseType: 'blob',
          data: params
        });

        self.isGenerating = false;

        if (!responseData) {
          return;
        }

        downloadBlob(responseData, self.requestDocGen?.fileName || 'report.docx');
      })
    }))
    .views((self) => ({
      get isFetching() {
        return self.request.isPending;
      },
      get isReportByPtFetching() {
        return self.requestReportByPt.isPending;
      },
      get timeToUnsetReportData(): ReportsChartFormattedDataType {
        return getTimeToUnsetReportFormattedData({
          data: self.boxPlotData ?? [],
          timeline: self.timeToOnsetTimeline
        });
      },
      get timeToUnsetLabels(): string[] {
        return getReportsLabels({
          data: self.boxPlotData ?? []
        });
      },
      get reportGenerating(): boolean {
        return self.isGenerating;
      }
    }))
);

export interface IReportsStore extends Instance<typeof ReportsStore> {}

export {ReportsStore};
