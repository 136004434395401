import React, {memo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {EDrugRole, IDrug} from '@stores-mobx/StudyDrugFilterStore';

import {ClickOutside} from '../../../ClickOutside';

import s from './DrugNameList.module.css';

interface Props {
  arrayDrugSearch: IDrug[];
  selectedDrugs: IDrug[];
  setSelectedDrugs: (value: IDrug) => void;
}

const DrugNamesList: React.FC<Props> = ({setSelectedDrugs, arrayDrugSearch, selectedDrugs}) => {
  const [checkedElement, setCheckedElement] = useState<number | null>(null);

  const drugsRoles: EDrugRole[] = [EDrugRole.TargetDrug, EDrugRole.ComparatorDrug];

  const handleClick = (el: number) => {
    if (checkedElement === el) {
      setCheckedElement(null);
    } else {
      setCheckedElement(el);
    }
  };

  const handleClickOutside = () => {
    if (checkedElement !== null) {
      setCheckedElement(null);
    }
  };

  const handleAddDug = (drug: EDrugRole) => {
    if (checkedElement === null || !drug) return;

    const newRole = selectedDrugs.find((el) => el.drugRole === EDrugRole.MainDrug)
      ? EDrugRole.SecondaryDrug
      : EDrugRole.MainDrug;
    const newElement: IDrug = {
      ...arrayDrugSearch[checkedElement],
      drugRole: drug === EDrugRole.ComparatorDrug ? EDrugRole.ComparatorDrug : newRole
    };
    setSelectedDrugs(newElement);
  };

  return (
    <div className={s.rightContentListWrapper}>
      <ul className={s.rightContentList}>
        {arrayDrugSearch?.map((item, key: number) => (
          <ClickOutside
            handleClickOutside={handleClickOutside}
            key={key}
            className={s.clickWrapper}
          >
            <div title={item?.name} className={s.listRight} onClick={() => handleClick(key)}>
              <p className={s.itemRight}>{item?.name}</p>

              <button aria-label="Add drug class" className={s.plus} />

              {checkedElement === key && (
                <div className={s.show}>
                  <div className={s.contentShow}>
                    {drugsRoles.map((drug, keyButton) => (
                      <button
                        key={keyButton}
                        className={s.showButton}
                        onClick={() => handleAddDug(drug)}
                        disabled={
                          (!!selectedDrugs.find((sd) => {
                            return (
                              sd.drugRole?.toString() === EDrugRole.MainDrug &&
                              drug === EDrugRole.TargetDrug
                            );
                          }) &&
                            !!selectedDrugs.find((sd) => {
                              return (
                                sd.drugRole?.toString() === EDrugRole.SecondaryDrug &&
                                drug === EDrugRole.TargetDrug &&
                                sd.name === item.name
                              );
                            })) ||
                          !!selectedDrugs.find((sd) => {
                            return (
                              sd.drugRole?.toString() === EDrugRole.ComparatorDrug &&
                              drug === EDrugRole.ComparatorDrug &&
                              sd.name === item.name
                            );
                          })
                        }
                      >
                        {drug}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </ClickOutside>
        ))}
      </ul>
    </div>
  );
};

export default memo(observer(DrugNamesList));
