import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {history} from '@services/router/RouterHistory';
import '@services/i18n';

import {Loading} from './components';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './static/styles/main.css';

ReactDOM.render(
  // <ReactKeycloakProvider authClient={keycloak}>
  <Router history={history}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Router>,
  // </ReactKeycloakProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
