import {useStore} from '@services/hooks/useStore';
import {getUnknownFilterCount} from '@services/utils/UserFilters.utils';
import {observer} from 'mobx-react-lite';
import React from 'react';

import DemographicsControls from './DemographicsControls';

const DemographicsControlsContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  return (
    <DemographicsControls
      unknownAgeGroupsCount={getUnknownFilterCount(caseStatData.ageGroups)}
      unknownSexCount={getUnknownFilterCount(caseStatData.sex)}
      unknownCountriesCount={getUnknownFilterCount(caseStatData.countries)}
    />
  );
};

export default observer(DemographicsControlsContainer);
