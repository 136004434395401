import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';
import {getReportClassesColorCollection} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import React from 'react';
import {EmptyData} from 'src/components';

import ReportClassControl from './ReportClassControl';
import s from './ReportClasses.module.css';

interface Props {
  reportClassesData: IFilterDataItem[];
  selectedReportClasses: IFilterCodeData[];
  setReportClasses: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearReportClasses: (filterName: SelectedUserFilters, filterData: string | number) => void;
  isFetching: boolean;
}

const ReportClasses: React.FC<Props> = ({
  reportClassesData,
  selectedReportClasses,
  setReportClasses,
  clearReportClasses,
  isFetching
}) => {
  const selectReportClass = (
    reportClassItem: IFilterCodeData,
    isReportClassSelected: boolean
  ): void => {
    if (isReportClassSelected) {
      clearReportClasses(SelectedUserFilters.ReportClasses, reportClassItem?.name);
      return;
    }
    setReportClasses(SelectedUserFilters.ReportClasses, [
      ...selectedReportClasses,
      reportClassItem
    ]);
  };

  if (!reportClassesData.length && !isFetching) {
    return <EmptyData text="No report classes data" className={s.reportClasses} />;
  }

  const reportClassesWithColors = getReportClassesColorCollection(reportClassesData);

  return (
    <div className={s.reportClasses}>
      {reportClassesData.map((reportClassItem, idx) => (
        <ReportClassControl
          key={idx}
          reportClassItem={reportClassItem}
          selectedReportClasses={selectedReportClasses}
          color={reportClassesWithColors[reportClassItem.name]}
          selectReportClass={(isReportClassSelected) =>
            selectReportClass(reportClassItem, isReportClassSelected)
          }
        />
      ))}
    </div>
  );
};

export default ReportClasses;
