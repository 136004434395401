import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IPageParams} from '@models/Page/Page.types';
import {TableHeaderProps} from 'react-virtualized/dist/es/Table';
import {
  AutoSizer,
  Table,
  Column,
  SortDirection,
  SortDirectionType,
  ScrollEventData
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {
  IColumnSettings,
  IRecalculateColumn,
  ISortIndicator,
  ISortProps
} from '@models/Table/Table.types';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {ArrowTopIcon, ArrowDownIcon} from 'src/static/icons';
import {EmptyData, Loading} from 'src/components/UIKit';
import cn from 'classnames';

import s from './Table.module.css';

export interface Props<T> {
  isFetching?: boolean;
  data: IPageParams<T> | null;
  columnSettings: IColumnSettings[];
  sortBy: string;
  sortDirection: SortDirectionType;
  searchValue?: string;
  page: number;
  loadData: (params: IParamsPagination) => void;
  handleRowClick?: (id: string) => void;
}

const initialData = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};

const tableSettings: {[key: string]: number} = {
  height: 520,
  headerHeight: 45,
  rowHeight: 48
};

const rowClassName = ({index}: {index: number}): string => {
  if (index < 0) {
    return s.headerRow;
  } else {
    return index % 2 === 0 ? s.evenRow : s.oddRow;
  }
};

const SortIndicator = ({sortDirection}: ISortIndicator): JSX.Element | null => {
  if (!sortDirection) return null;

  return sortDirection === SortDirection.ASC ? (
    <ArrowTopIcon className={s.icon} />
  ) : (
    <ArrowDownIcon className={s.icon} />
  );
};

const headerRenderer = ({dataKey, sortBy, sortDirection, label, disableSort}: TableHeaderProps) => {
  return (
    <div className={cn(s.cellHeader, {[s.cellHeaderDisabled]: disableSort})}>
      <span>{label}</span>
      {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
    </div>
  );
};

const noRowsRenderer = (loading: boolean = false) => (
  <div className={s.noRows}>{loading ? <Loading /> : <EmptyData text="No cases" />}</div>
);

const recalculateColumnSettingWidth = ({
  tableRefWidth,
  setFormattedColumns,
  columnSettings
}: IRecalculateColumn): void => {
  if (!tableRefWidth) {
    return;
  }
  const width: number = columnSettings!
    .filter((col) => col.isVisible)
    .reduce((acc, item) => {
      acc += item.width;
      return acc;
    }, 0);
  const coefficient: number = tableRefWidth / width;
  const formattedColumns = columnSettings?.map((c) => ({...c, width: c.width * coefficient})) ?? [];
  if (setFormattedColumns) {
    setFormattedColumns(formattedColumns ?? []);
  }
};

const TableComponent: <T>(p: Props<T>) => React.ReactElement<Props<T>> = ({
  isFetching,
  sortBy,
  sortDirection,
  searchValue = '',
  data = initialData,
  columnSettings,
  page,
  loadData,
  handleRowClick
}) => {
  const [formattedColumns, setFormattedColumns] = useState(columnSettings ?? []);
  const [isResized, setIsResized] = useState(false);
  const tableRef: React.MutableRefObject<Table | null> = useRef(null);
  const {t} = useTranslation();

  const tableData = data?.content ?? [];

  const rowCount = useMemo(() => {
    if (page === 0 && isFetching) {
      return 0;
    }
    return formattedColumns.some((c) => c.isVisible) ? tableData.length : 0;
  }, [formattedColumns, isFetching, page, tableData.length]);

  const isDataLoading = page > 0 && isFetching;

  useEffect(() => {
    if (tableRef.current !== null && isResized) {
      recalculateColumnSettingWidth({
        tableRefWidth: tableRef.current.props.width,
        setFormattedColumns,
        columnSettings
      });
    }
  }, [columnSettings, tableData, isResized]);

  const onScroll = ({
    clientHeight,
    scrollHeight,
    scrollTop: scrollTopProp
  }: ScrollEventData): void => {
    const totalRowCount: number = data?.totalElements ?? 0;
    const totalPages: number = data?.totalPages ?? 0;
    const isScrollDown: boolean = clientHeight + scrollTopProp === scrollHeight;
    const isLastPage: boolean = tableData.length < totalRowCount && page < totalPages;
    const isDataLoaded: boolean = !data?.last ?? isLastPage;

    if (isScrollDown && isDataLoaded) {
      loadData({page: page + 1, sortBy, sortDirection, searchValue});
    }
  };

  const onSort = ({sortBy: sortByProp, sortDirection: sortDirectionProp}: ISortProps): void => {
    loadData({page: 0, sortBy: sortByProp, sortDirection: sortDirectionProp, searchValue});
  };

  const isSortDisabled = (disableSort: boolean): boolean => {
    return disableSort || !tableData.length;
  };

  return (
    <div className={s.tableWrapper}>
      <AutoSizer
        disableHeight
        onResize={() => {
          setIsResized(true);
        }}
      >
        {({width}) => (
          <>
            <Table
              ref={tableRef}
              headerClassName={s.headerColumn}
              rowClassName={cn(rowClassName, {[s.headerClickableRow]: !!handleRowClick})}
              width={width}
              height={tableSettings.height}
              headerHeight={tableSettings.headerHeight}
              rowHeight={tableSettings.rowHeight}
              sort={onSort}
              onScroll={onScroll}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowCount={rowCount}
              noRowsRenderer={() => noRowsRenderer(isFetching)}
              onRowClick={({rowData}) => handleRowClick && handleRowClick(rowData.id)}
              rowGetter={({index}) => tableData[index]}
            >
              {formattedColumns.map(
                ({name, dataKey, width: columnWidth, isVisible, disableSort}) =>
                  isVisible && (
                    <Column
                      key={dataKey}
                      label={t(name)}
                      dataKey={dataKey}
                      width={columnWidth}
                      cellRenderer={({cellData}) =>
                        cellData !== null && cellData !== undefined ? cellData : '-'
                      }
                      headerRenderer={headerRenderer}
                      disableSort={isSortDisabled(disableSort)}
                    />
                  )
              )}
            </Table>
            {isDataLoading && <Loading className={s.loading} />}
          </>
        )}
      </AutoSizer>
    </div>
  );
};

export default TableComponent;
