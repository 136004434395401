export const snakeToPascal = (str: string = '') => {
  const arr = str.split('_');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1, arr[i].length);
  }

  arr[0] = arr[0].toLowerCase();
  return arr.join('') || str;
};

export function formatKeys<K, T>(data: K): T {
  const formattedData = {} as T;
  Object.keys(data).forEach((val: string) => {
    // @ts-ignore
    formattedData[snakeToPascal(val) as keyof T] = data[val];
  });

  return formattedData;
}

export function formatObjectNoEmptyFields<T>(params: T) {
  if (typeof params !== 'object') {
    return {} as Partial<T>;
  }

  return Object.fromEntries(Object.entries(params).filter(([_, val]) => !!val)) as Partial<T>;
}
