import {IDatePickerProps} from '@models/DatePicker/DatePicker.types';
import React, {forwardRef} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import 'src/static/styles/react-datepicker-custom.css';
import zh from 'date-fns/locale/zh-CN';
import {i18n} from '@services/i18n';

import {CalendarIcon} from '../../static/icons';

import s from './DatePickerRange.module.css';

const DATE_FORMAT = 'd MMM yyyy';

/** TODO: add locales
 * https://stackoverflow.com/questions/54399084/change-locale-in-react-datepicker/54399915#54399915
 */

interface Props extends IDatePickerProps {}

registerLocale('zh', zh);

const DatePickerRange: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disabled,
  dark
}) => {
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);

  const CustomInput: React.FC<{
    value: Date;
    onClick: () => void;
    className: string;
  }> = forwardRef(({value, onClick, className}, _ref) => {
    return (
      <button
        className={cn(s.datePicker, className)}
        onClick={onClick}
        ref={_ref as React.RefObject<HTMLButtonElement>} // react-datepicker/issues/862
      >
        {value}
      </button>
    );
  });

  const {t} = useTranslation();

  return (
    <div
      className={cn(s.datePickerWrapper, {[s.datePickerWrapperDark]: dark, [s.disabled]: disabled})}
    >
      <CalendarIcon className={cn(s.icon)} />
      <span className={s.timePeriod}>{t('datePicker.header')}</span>
      <DatePicker
        className={s.datePicker}
        selected={formattedStartDate}
        onChange={setStartDate}
        selectsStart
        locale={i18n.languages[0] === 'en' ? 'en' : 'zh'}
        startDate={formattedStartDate}
        endDate={formattedEndDate}
        dateFormat={DATE_FORMAT}
        fixedHeight
        disabled={disabled}
        maxDate={formattedEndDate}
        showYearDropdown
        customInput={
          <CustomInput
            className={s.datePickerStart}
            value={formattedStartDate}
            onClick={() => {}}
          />
        }
      />
      <div className={s.mdash}>&mdash;</div>
      <DatePicker
        className={s.datePicker}
        selected={formattedEndDate}
        onChange={setEndDate}
        selectsEnd
        startDate={formattedStartDate}
        endDate={formattedEndDate}
        dateFormat={DATE_FORMAT}
        locale={i18n.languages[0] === 'en' ? 'en' : 'zh'}
        fixedHeight
        showYearDropdown
        disabled={disabled}
        minDate={formattedStartDate}
        customInput={
          <CustomInput className={s.datePickerEnd} value={formattedEndDate} onClick={() => {}} />
        }
      />
    </div>
  );
};

export default DatePickerRange;
