import {IFilterDataItem} from '@models/CaseStat/CaseStat.types';
import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Report} from '../../../../../components';

import {getChartOptions} from './chartOptions';

interface Props {
  leftOffset?: boolean;
}

const ReportsByOutcomeContainer: React.FC<Props> = ({leftOffset}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {outcomes: selectedOutcomes} = selectedFiltersStore;
  const {caseStatData, isFetching} = caseStatStore;

  const data: IFilterDataItem[] = caseStatData.outcomes;

  const emptyColors = {};
  const formattedOptions = data.filter(({name, code}) => {
    if (selectedOutcomes.length) {
      return selectedOutcomes.findIndex(({code: _code}) => _code === code) > -1;
    }
    return name;
  });

  const chartOptions = getChartOptions({options: formattedOptions, colors: emptyColors});

  const {t} = useTranslation();

  return (
    <Report
      title={t('ReportByOutcome')}
      isFetching={isFetching}
      options={formattedOptions}
      colors={emptyColors}
      getChartOptions={chartOptions}
      isLegendVisible={false}
      contentHeight={400}
      leftOffset={leftOffset}
    />
  );
};

export default observer(ReportsByOutcomeContainer);
