import {observer} from 'mobx-react-lite';
import React from 'react';

import CaseVolume from './CaseVolume';

const CaseVolumeContainer: React.FC<{}> = () => {
  return <CaseVolume />;
};

export default observer(CaseVolumeContainer);
