import {ENotification} from '@models/Notification/Notification.types';
import {Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

export interface INotificationOptionProps {
  type: ENotification;
  code?: number;
  message?: string;
  url?: string;
}

const NotificationOption = types.model('NotificationOptions', {
  id: types.identifier,
  type: types.enumeration(ENotification.Error, Object.values(ENotification)),
  code: types.maybeNull(types.number),
  message: types.optional(types.string, ''),
  url: types.optional(types.string, '')
});

const NotificationStore = types
  .model('NotificationStore', {
    currentOptions: types.optional(types.array(NotificationOption), [])
  })
  .actions((self) => ({
    open(type: ENotification, options: INotificationOptionProps) {
      self.currentOptions.push({
        id: uuidv4(),
        ...options
      });
    },
    close(id: string) {
      const index = self.currentOptions.findIndex((el) => el.id === id);
      self.currentOptions.splice(index, 1);
    }
  }));

export interface INotificationStore extends Instance<typeof NotificationStore> {}
export interface INotificationOption extends Instance<typeof NotificationOption> {}

export {NotificationStore};
