import React from 'react';

export const ExitIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M23.79 6h-9.58C12.99 6 12 6.96 12 8.143v4.286c0 .394.33.714.737.714s.737-.32.737-.714V8.143c0-.395.33-.714.737-.714h9.579c.407 0 .736.32.736.714v15.714c0 .395-.33.714-.736.714h-9.58c-.406 0-.736-.32-.736-.714v-4.286c0-.394-.33-.714-.737-.714s-.737.32-.737.714v4.286c0 1.184.99 2.143 2.21 2.143h9.58c1.22 0 2.21-.96 2.21-2.143V8.143C26 6.959 25.01 6 23.79 6zm-3.504 9.74H8.438l2.352-2.47c.274-.297.266-.772-.017-1.06-.277-.28-.716-.28-.993 0l-3.572 3.75c-.066.07-.118.153-.154.244-.072.183-.072.39 0 .573.036.091.089.174.155.244l3.572 3.75c.274.298.726.306 1.01.018.284-.287.291-.762.017-1.06l-.017-.018-2.353-2.47h11.848c.394 0 .714-.336.714-.75 0-.415-.32-.75-.714-.75z"
    />
  </svg>
);
