import React from 'react';
import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';
import {colorPurple600} from '@services/constants/chart.constants';
import {integerWithSpaces} from '@services/utils/Functions.utils';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {EmptyData, CountLine} from 'src/components/UIKit';

import {getCasesInterval} from './FiltersWithPercentage.helpers';
import s from './FiltersWithPercentage.module.css';

interface Props<T> {
  filterData: IFilterDataItem[];
  selectedFilters: IFilterCodeData[];
  selectFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearFilter: (filterName: SelectedUserFilters, filterData: string | number) => void;
  filterName: SelectedUserFilters;
  controlHeight?: number;
  className?: string;
  splitNumber?: number;
  customLineColor?: (name: string) => string;
  withoutScroll?: boolean;
}

function FiltersWithPercentage<T>({
  filterData,
  selectedFilters,
  selectFilter,
  clearFilter,
  filterName,
  controlHeight,
  splitNumber = 4,
  className,
  customLineColor,
  withoutScroll = false
}: Props<T>) {
  const {t} = useTranslation();

  if (!filterData.length) {
    return (
      <div className={cn(s.wrapper, className)}>
        <EmptyData text="Data is empty" className={s.emptyData} />
      </div>
    );
  }
  const casesInterval = getCasesInterval(filterData, splitNumber);

  const handleFilterSelect = (item: IFilterDataItem, isSelected: boolean) => {
    if (isSelected) {
      clearFilter(filterName, item.name);
      return;
    }
    selectFilter(filterName, [...selectedFilters, item]);
  };

  return (
    <div className={cn(s.wrapper, className)}>
      <div className={cn(s.filterDataContainer, {[s.withoutScroll]: withoutScroll})}>
        {filterData.map(({name, code, count, percent}, idx) => {
          const isFilterSelectedName: boolean =
            selectedFilters.findIndex(({name: _name}) => _name === name) > -1;
          const isFilterSelected = code
            ? selectedFilters.findIndex(({code: _code}) => _code === code) > -1
            : isFilterSelectedName;

          let backgroundColor = colorPurple600;

          if (customLineColor) backgroundColor = customLineColor(name);
          if (isFilterSelected) backgroundColor = 'white';

          return (
            <div
              className={cn(s.filtersWithPercentage, {[s.filterSelected]: isFilterSelected})}
              key={idx}
              style={{height: controlHeight}}
              title={String(count)}
              onClick={() => handleFilterSelect({code, name, count, percent}, isFilterSelected)}
            >
              <h4 title={name}>{name}</h4>
              <CountLine percent={percent} backgroundColor={backgroundColor} />
            </div>
          );
        })}
      </div>
      <div className={s.numberCasesContainer}>
        <span className={s.title}>{t('filtersWithPercentage.title')}</span>
        <div className={s.interval}>
          {casesInterval.map((interval, idx) => (
            <div className={s.intervalPoint} key={idx}>
              <span className={s.intervalText}>{integerWithSpaces(interval)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FiltersWithPercentage;
