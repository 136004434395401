import {ILabsInitialData, ILabsInitialDataValue} from '@models/CaseDetail/CaseDetail.types';
import React from 'react';
import {DEFAULT_SIDEBAR_WIDTH, IChartSettings} from '@models/Chart/chart.types';
import {isLabelsExists} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {useTranslation} from 'react-i18next';
import {ProductsIcon, AdverseEventIcon, LabsIcon} from 'src/static/icons';

import s from './DetailCaseChartsSidebar.module.css';

const sidebarStyle = {
  width: DEFAULT_SIDEBAR_WIDTH,
  minWidth: DEFAULT_SIDEBAR_WIDTH
};

interface Props {
  chartSettings: IChartSettings;
  labs: ILabsInitialData;
}

const LabsSidebar: React.FC<{
  labsName: ILabsInitialDataValue[] | undefined;
}> = ({labsName}) => {
  const isAllResultsEmpty =
    labsName && labsName.length
      ? labsName.every((lab) => !lab.testResult && !lab.highTestRange && !lab.highTestRange)
      : false;

  const generateTable = (tableData: (string | number | null)[]) =>
    tableData.map((tableItem, idx) => <span key={idx}>{tableItem || '-'}</span>);

  if (labsName && !!labsName.length && !isAllResultsEmpty) {
    return (
      <div className={s.labResultsTable}>
        <div className={s.labResults}>
          {generateTable(['Result', 'Unit', 'High range', 'Low range'])}
        </div>
        {labsName.map(({testResult, testUnit, highTestRange, lowTestRange}, labIdx) => {
          if (!testResult && !testUnit && !highTestRange && !lowTestRange) {
            return null;
          }
          return (
            <div className={s.labResults} key={labIdx}>
              {generateTable([testResult, testUnit, highTestRange, lowTestRange])}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const DetailCaseChartsSidebar: React.FC<Props> = ({chartSettings, labs}) => {
  const [firstChartLabelNames, secondChartLabelNames] = [
    [...Object.keys(chartSettings.firstChartLabels)].reverse(),
    [...Object.keys(chartSettings.secondChartLabels)].reverse(),
    chartSettings.thirdChartLabels
  ];

  const {t} = useTranslation();

  return (
    <div className={s.chartSidebar} style={sidebarStyle}>
      {isLabelsExists(firstChartLabelNames) && (
        <>
          <div className={s.chartTitle} style={{top: chartSettings.firstChartTitleTop}}>
            <ProductsIcon className={s.icon} />
            <span>{t('products')}</span>
          </div>
          <div className={s.chartLabels} style={{top: chartSettings.topOffset}}>
            {firstChartLabelNames.map((chartLabel, idx) => (
              <div key={idx} className={s.chartLabel}>
                <span className={s.label} title={chartLabel}>
                  {chartLabel}
                </span>
                {chartSettings.firstChartLabels[chartLabel] && (
                  <span className={s.empty}>{t('noDates')}</span>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {isLabelsExists(secondChartLabelNames) && (
        <>
          <div className={s.chartTitle} style={{top: chartSettings.secondChartTitleTop}}>
            <AdverseEventIcon className={s.icon} />
            <span>Adverse events</span>
          </div>
          <div className={s.chartLabels} style={{top: chartSettings.secondChartTopOffset}}>
            {secondChartLabelNames.map((chartLabel, idx) => (
              <div key={idx} className={s.chartLabel}>
                <span className={s.label} title={chartLabel}>
                  {chartLabel}
                </span>
                {chartSettings.secondChartLabels[chartLabel] && (
                  <span className={s.empty}>{t('noDates')}</span>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {isLabelsExists(chartSettings.thirdChartLabels) && (
        <>
          <div className={s.chartTitle} style={{top: chartSettings.thirdChartTitleTop}}>
            <LabsIcon className={s.icon} />
            <span>Lab profile</span>
          </div>
          {chartSettings.thirdChartLabels.map(({name}, idx) => (
            <div
              key={idx}
              className={s.chartLabelsLab}
              style={{top: chartSettings.thirdChartTopOffsetsCollection[idx]}}
            >
              <p>{name}</p>
              <LabsSidebar labsName={labs[name]} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DetailCaseChartsSidebar;
