import React from 'react';
import {useTranslation} from 'react-i18next';
import {LightButton} from 'src/components';

import {ReviewStatus} from '../../models/Demo.types';

import s from './WorkStatusButtons.module.css';

interface Props {
  workStatus: ReviewStatus;
  setWorkStatus: (periodType: ReviewStatus) => void;
}

const WorkStatusButtons: React.FC<Props> = ({workStatus, setWorkStatus}) => {
  const {t} = useTranslation();
  return (
    <div className={s.scoresButtonsWrapper}>
      {Object.values(ReviewStatus).map((status, idx) => (
        <LightButton
          key={status}
          title={t(`workStatus.${status?.toLowerCase()}`)}
          active={workStatus === status}
          handlerOnClick={() => setWorkStatus(status)}
          isFirst={idx === 0}
          isLast={idx === Object.keys(ReviewStatus).length - 1}
        />
      ))}
    </div>
  );
};

export default WorkStatusButtons;
