import {
  IFilterCodeData,
  IFilterDataItem,
  SelectedUserFilters
} from '@models/CaseStat/CaseStat.types';
import React from 'react';

import {EmptyData} from '../../../../../../components';
import {FiltersWithPercentage} from '../../../../../../components/FiltersWithPercentage';

import s from './MedicalHistory.module.css';
import {MedicalHistoryControlsContainer} from './MedicalHistoryControls';

interface Props {
  medicalHistoryData: IFilterDataItem[];
  selectedMedicalHistory: IFilterCodeData[];
  conmedsData: IFilterDataItem[];
  selectedConmeds: IFilterCodeData[];
  setUserFilter: (filterName: SelectedUserFilters, filterData: IFilterCodeData[]) => void;
  clearUserFilter: (filterName: SelectedUserFilters, filterData: string | number) => void;
  withoutScroll?: boolean;
}

const MedicalHistory: React.FC<Props> = ({
  medicalHistoryData,
  selectedMedicalHistory,
  setUserFilter,
  clearUserFilter,
  conmedsData,
  selectedConmeds,
  withoutScroll
}) => {
  return (
    <>
      <MedicalHistoryControlsContainer />
      {!medicalHistoryData.length && !conmedsData.length ? (
        <EmptyData text="No filter data" className={s.emptyData} />
      ) : (
        <div className={s.filtersContainer}>
          <FiltersWithPercentage
            controlHeight={24}
            className={s.gridFirst}
            filterData={medicalHistoryData}
            selectedFilters={selectedMedicalHistory}
            selectFilter={setUserFilter}
            clearFilter={clearUserFilter}
            filterName={SelectedUserFilters.MedHistories}
            withoutScroll={withoutScroll}
          />
          <FiltersWithPercentage
            controlHeight={24}
            className={s.gridLast}
            filterData={conmedsData}
            selectedFilters={selectedConmeds}
            selectFilter={setUserFilter}
            clearFilter={clearUserFilter}
            filterName={SelectedUserFilters.ConmedsDrugClass}
            withoutScroll={withoutScroll}
          />
        </div>
      )}
    </>
  );
};

export default MedicalHistory;
