import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {ConfirmationDialog} from '@components/ConfirmationDialog';
import {ModalWindow} from '@components/ModalWindow';
import {Button} from '@components/UIKit';
import {storage} from '@services/utils/storage.utils';
import {useStore} from '@services/hooks/useStore';
import s from '@components/Header/Header.module.css';
import {EMedDraType} from '@models/MedDRAFilters/MedDRAFilters.types';

interface IProps {}

const HeaderResetButton: FC<IProps> = () => {
  const {t} = useTranslation();
  const {globalFiltersStore, medDRAFiltersStore} = useStore();
  const {closeConfirmation, openConfirmation, modal} = globalFiltersStore;

  const {
    medDRASelectedFilters: {clearAllMedDRAFilters}
  } = medDRAFiltersStore;

  const onResetAll = () => {
    clearAllMedDRAFilters(EMedDraType.BodySystems);
    clearAllMedDRAFilters(EMedDraType.HLGT);
    clearAllMedDRAFilters(EMedDraType.HLT);
    clearAllMedDRAFilters(EMedDraType.PT);
    clearAllMedDRAFilters(EMedDraType.SMQ);

    storage.clearStorage();
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <>
      <Button onClick={openConfirmation} className={s.resetAllButton} variant="primary">
        {t('resetAll')}
      </Button>
      <ModalWindow onClose={closeConfirmation} className={s.modalConfirmation}>
        {modal.isOpened && (
          <ConfirmationDialog
            title={t('resetAllDialog.title')}
            description={t('resetAllDialog.description')}
            submitButtonText={t('resetAllDialog.button')}
            onCancel={closeConfirmation}
            onSubmit={onResetAll}
          />
        )}
      </ModalWindow>
    </>
  );
};

export default observer(HeaderResetButton);
