import React from 'react';

export const CalendarIcon: React.FC<{className?: string}> = ({className}) => (
  <svg className={className} fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(-8 -8)"
    >
      <g>
        <path
          stroke="currentColor"
          strokeWidth="1.5"
          d="M4.5 5.5L19.5 5.5 19.5 18.5 4.5 18.5zM15.5 4L15.5 7M8.5 4L8.5 7M4.5 9.5L19.5 9.5"
          transform="translate(8 8)"
        />
      </g>
    </g>
  </svg>
);
