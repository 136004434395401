/**
 * [refactoring]
 * it should be properly formatted in the filter itself, we could add getFilterPropArray method
 * currently it affects translations
 */
export const getFilterParamsArray = (paramsString: string, useSemicolon = false): string[] => {
  const separator = useSemicolon ? ';' : ',';
  if (!paramsString) {
    return [];
  }
  return paramsString.split(separator);
};
