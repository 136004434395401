import {Request} from '@models/base/Request';
import {Store} from '@models/base/Store';
import {
  CaseVolumeStatTypes,
  CHART_AGG_LEVEL,
  ICaseStatData,
  ICaseVolumeChartFormattedData,
  IFilterCodeData
} from '@models/CaseStat/CaseStat.types';
import {ApiVersions, getApiUrl} from '@services/utils/Api.utils';
import {getLineChartData} from '@services/utils/ChartHelpers/ChartCommon.helpers';
import {getLocalesDates} from '@services/utils/Date.utils';
import {getRootStore, storeState} from '@services/utils/Mst.utils';
import {
  getFilteredCaseVolumeChartData,
  mapToSeriousNameFormatted,
  sortByUnknownAgeGroups
} from '@services/utils/UserFilters.utils';
import {IGlobalFiltersStore} from '@stores-mobx/GlobalFiltersStore';
import {flow, Instance, types} from 'mobx-state-tree';
import {IParamsPagination} from '@models/Cases/Cases.types';
import {formatObjectNoEmptyFields} from '@services/utils/Data.utils';

const initialCaseStatData: ICaseStatData = {
  reportClasses: [],
  chart: {},
  ageGroups: [],
  countries: [],
  sex: [],
  outcomes: [],
  serious: [],
  medHistories: [],
  conmeds: [],
  conmedsDrugClass: [],
  severity: [],
  listedness: [],
  totalCount: 0
};

const CaseStatStore = types.compose(
  Store,
  types
    .model('CaseStatStore', {
      dataState: types.frozen<IParamsPagination[]>([]),
      activeStatType: types.optional(
        types.enumeration(CaseVolumeStatTypes.Individual, Object.values(CaseVolumeStatTypes)),
        CaseVolumeStatTypes.Individual
      ),
      splitByClasses: types.optional(types.boolean, false),
      caseVolumeChartData: types.frozen<ICaseVolumeChartFormattedData>({}),
      isCaseVolumeChartFetching: types.optional(types.boolean, false),

      caseStatData: types.frozen<ICaseStatData>(initialCaseStatData), // response
      request: types.optional(Request, {})
    })
    .actions((self) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      load: flow(function* (reqParams: object, reportClasses: IFilterCodeData[]) {
        self.isCaseVolumeChartFetching = true;

        const notEmptyParams = formatObjectNoEmptyFields(reqParams);

        const response: ICaseStatData = yield self.request.send(
          getApiUrl(ApiVersions.v11, 'case.stat'),
          {
            method: 'get',
            params: {
              statType: self.activeStatType,
              splitByClasses: self.splitByClasses,
              ...notEmptyParams,
              aggLevel: CHART_AGG_LEVEL
            }
          }
        );
        if (response) {
          self.caseStatData = {
            ...response,
            serious: mapToSeriousNameFormatted(response.serious),
            ageGroups: sortByUnknownAgeGroups(response.ageGroups)
          } as ICaseStatData;

          self.isCaseVolumeChartFetching = true;
          const selectedReportClasses = reportClasses.map(({name}) => name.toLowerCase());
          // show only selected report classes or all
          const filteredChartData = getFilteredCaseVolumeChartData({
            chartData: response.chart,
            splitByClasses: self.splitByClasses,
            // @ts-ignore
            selectedReportClasses
          });
          self.caseVolumeChartData = getLineChartData({
            lineChart: filteredChartData
          });
        }
        self.isCaseVolumeChartFetching = false;
      })
    }))
    .actions((self) => ({
      afterCreate() {
        storeState(self, ['splitByClasses', 'activeStatType']);
      },
      setActiveStatType: (statType: CaseVolumeStatTypes) => {
        self.activeStatType = statType;
        // self.load();
      },
      setSplitByClasses: (splitByClasses: boolean) => {
        self.splitByClasses = splitByClasses;
        // self.load();
      }
    }))
    .views((self) => ({
      get isFetching() {
        return self.request.isPending || self.isCaseVolumeChartFetching;
      },
      get fetchCaseVolumeData() {
        return self.load;
      },
      get timeData(): string[] {
        const globalFilters: IGlobalFiltersStore = getRootStore(self).globalFiltersStore;

        return getLocalesDates({
          startDate: new Date(globalFilters.startDate),
          endDate: new Date(globalFilters.endDate),
          aggLevel: CHART_AGG_LEVEL
        });
      },
      get isDataExists(): boolean {
        return Object.keys(self.caseVolumeChartData).length > 0;
      }
    }))
);

export interface ICaseStatStore extends Instance<typeof CaseStatStore> {}

export {CaseStatStore};
