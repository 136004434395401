import {Genders, IFilterCodeData} from '@models/CaseStat/CaseStat.types';
import {integerWithSpaces} from '@services/utils/Functions.utils';
import cn from 'classnames';
import ReactEcharts from 'echarts-for-react';
import React from 'react';

import s from './PieChart.module.css';
import {PieChartLegendProps} from './PieChart.types';

const chartRefNotNull = (
  chartRef: React.RefObject<ReactEcharts> | null | undefined
): chartRef is React.RefObject<ReactEcharts> => {
  return chartRef !== null && chartRef !== undefined && chartRef.current !== null;
};

const PieChartLegend: React.FC<PieChartLegendProps> = ({
  chartRef,
  chartData,
  selectedFilters,
  filterName,
  setFilter,
  colorCollection,
  boxShadowCollection,
  className
}) => {
  const handleLegend = (item: IFilterCodeData) => {
    if (chartRefNotNull(chartRef)) {
      chartRef.current?.getEchartsInstance().dispatchAction({
        type: 'legendToggleSelect',
        name: item.name
      });

      const index = selectedFilters.findIndex(({code: _code}) => _code === item.code);
      if (index > -1) {
        setFilter(
          filterName,
          selectedFilters.filter((g) => g.code !== item.code)
        );
        return;
      }
      // TODO
      setFilter(filterName, [...selectedFilters, item]);
    }
  };

  const handleMouseEnter = (name: string) => {
    if (chartRefNotNull(chartRef)) {
      chartRef.current?.getEchartsInstance().dispatchAction({
        type: 'highlight',
        name
      });
    }
  };

  const handleMouseLeave = (name: string) => {
    if (chartRefNotNull(chartRef)) {
      chartRef.current?.getEchartsInstance().dispatchAction({
        type: 'downplay',
        name
      });
    }
  };

  return (
    <div className={cn(s.legend, className)}>
      {chartData.map(({name, code, count}, idx) => {
        const index = selectedFilters.findIndex(({code: _code}) => _code === code);
        const isLegendControlSelected = index > -1;
        const legendControlStyle = isLegendControlSelected
          ? {
              backgroundColor: colorCollection[name],
              boxShadow: boxShadowCollection[name]
            }
          : {};
        const legendLineStyle = {
          backgroundColor: !isLegendControlSelected ? colorCollection[name] : 'white'
        };
        return (
          <div
            key={idx}
            className={cn(s.legendControl, {[s.legendControlActive]: isLegendControlSelected})}
            style={legendControlStyle}
            onClick={() => handleLegend({name, code})}
            onMouseEnter={() => handleMouseEnter(name)}
            onMouseLeave={() => handleMouseLeave(name)}
          >
            <div className={s.legendLine} style={legendLineStyle} />
            <div className={s.legendText}>
              <span className={s.legendTextName}>{name === Genders.Unknown ? 'UNK' : name}</span>
              <span className={s.legendTextCount}>{integerWithSpaces(count)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PieChartLegend;
