import {types, getSnapshot, applySnapshot} from 'mobx-state-tree';

const Store = types
  .model('Store', {})
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate() {
        /** save a snapshot after the first initialising */
        initialState = getSnapshot(self);
      },
      resetStore() {
        /** back the initial state */
        applySnapshot(self, initialState);
      }
    };
  })
  .views(() => ({}));

export {Store};
