import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '@services/hooks/useStore';

import DetailCaseChartsLegends from './DetailCaseChartsLegends';

const DetailCaseChartsLegendsContainer: React.FC<{}> = () => {
  const {caseDetailStore} = useStore();
  const {chartSettings} = caseDetailStore;

  return <DetailCaseChartsLegends chartSettings={chartSettings} />;
};

export default observer(DetailCaseChartsLegendsContainer);
