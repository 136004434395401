import React, {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import cn from 'classnames';

import {ITextElement} from '../../models/Demo.types';
import {CloseIcon} from '../../../static/icons';
import {findAndShowElement} from '../../utils/LayoutFind.utils';

import s from './PtReviewList.module.css';

export interface IProps {
  textElements: ITextElement[];
  uniqueLabels: string[];
  onRemoveEntities: (index: number) => void;
  entitiesLabels: Record<string, string>;
  currentHoverElement: number | undefined;
  setCurrentHoverElement: (value: number | undefined) => void;
  findAndShowElementIndex?: number;
  unsetFindAndShowElement: (value: number | undefined) => void;
  setShowElement: (value: number | undefined) => void;
}

const PtReviewList: FC<IProps> = ({
  textElements,
  uniqueLabels,
  onRemoveEntities,
  entitiesLabels,
  currentHoverElement,
  setCurrentHoverElement,
  findAndShowElementIndex,
  unsetFindAndShowElement,
  setShowElement
}) => {
  const getLabelMainIndex = (value: string): number => {
    return Object.values(entitiesLabels).findIndex((el) => el === value);
  };

  uniqueLabels.sort((a, b) => getLabelMainIndex(a) - getLabelMainIndex(b));

  useEffect(() => {
    if (findAndShowElementIndex === undefined) return;
    const textElement = document.getElementById(`leftSide${findAndShowElementIndex}`);
    if (textElement) {
      findAndShowElement(textElement);
      unsetFindAndShowElement(undefined);
    }
  }, [findAndShowElementIndex, unsetFindAndShowElement]);

  return (
    <div className={s.mainWrapper}>
      {uniqueLabels.map((labelEl: string, index) => {
        const elements = textElements.filter((textEl) => textEl.labelText === labelEl);
        return (
          <div className={s.group} key={index}>
            <div className={s.label} title={labelEl}>
              <div className={cn(s.rect, s[`rect${getLabelMainIndex(labelEl)}`])}> </div>
              {labelEl} ({elements.length})
            </div>
            {elements.map((textEl, textIndex) => {
              return (
                <div
                  key={textIndex}
                  id={`leftSide${textEl.index}`}
                  className={cn(s.text, {[s.hover]: currentHoverElement === textEl.index})}
                  onMouseEnter={() => setCurrentHoverElement(textEl.index)}
                  onMouseLeave={() => setCurrentHoverElement(undefined)}
                  onClick={() => setShowElement(textEl.index)}
                >
                  <div className={s.textText}>{textEl.text}</div>
                  <div className={s.prefName}>{textEl.prefName ?? '-'}</div>
                  <div
                    className={s.closeButton}
                    onClick={() => onRemoveEntities(textEl.index ?? -1)}
                  >
                    <CloseIcon className={s.closeIcon} />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default observer(PtReviewList);
