import React from 'react';
import cn from 'classnames';

import {LoadingIcon, PngIcon} from '../../../static/icons';

import {ReactComponent as CsvIcon} from './assets/csv.svg';
import s from './DownloadButton.module.css';

interface Props {
  type?: 'csv' | 'png';
  handlerSaveImage?: () => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  text?: string;
}

const DownloadButton: React.FC<Props> = ({
  type = 'png',
  handlerSaveImage,
  className,
  text = null,
  disabled,
  isLoading = false
}) => {
  const imageIcon =
    type === 'csv' ? <CsvIcon className={s.icon} /> : <PngIcon className={s.icon} />;

  return (
    <button
      className={cn(s.saveImageBtn, {[s.disabled]: disabled, [s.iconSmall]: !text}, className)}
      onClick={handlerSaveImage}
      disabled={disabled || isLoading}
    >
      {isLoading ? <LoadingIcon className={s.loadingIcon} /> : imageIcon}
      {text && <span>{text}</span>}
    </button>
  );
};

export default DownloadButton;
