import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import SplitByClasses from './SplitByClasses';

const SplitByClassesContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore}
  } = useStore();
  const {splitByClasses, setSplitByClasses, isDataExists} = caseStatStore;

  return (
    <SplitByClasses
      splitByClasses={splitByClasses}
      setSplitByClasses={setSplitByClasses}
      disabled={!isDataExists}
    />
  );
};

export default observer(SplitByClassesContainer);
