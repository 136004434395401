import {ISelectOptions} from '@services/models/Dropdown/Dropdown.types';
import {onlyUniqueForStringArray} from '@services/utils/Functions.utils';

import {IDemoListItem, IRank} from '../models/Demo.types';

export const searchedDiseases = (data: IDemoListItem[], searchValue: string) => {
  let searchedData: IDemoListItem[] = data;

  if (searchValue) {
    searchedData = data.filter((item: IDemoListItem) => {
      const {abstract} = item.article_data;
      return abstract.toLocaleLowerCase().includes(searchValue.toLowerCase().trim());
    });
  }

  return searchedData;
};

const replaceSymboles = (str: string) => str?.replaceAll('|', ' ').replaceAll(' ;', ', ');

const rankValue = (rank: IRank[], drugName: string, ptName: string) => {
  return rank.filter(
    ({drug, pt}: {drug: string; pt: string}) =>
      drug.toLocaleLowerCase() === drugName.toLocaleLowerCase() &&
      pt.toLocaleLowerCase() === ptName.toLocaleLowerCase()
  );
};

export const transformData = (data: IDemoListItem[], drugName: string, ptName: string) => {
  const arr = ['To be reviewed', 'Rejected', 'Accepted'];
  return data.map((item: IDemoListItem) => {
    const getValue = arr[Math.floor(Math.random() * arr.length)];
    const getRank =
      typeof item.rank === 'object' ? rankValue(item.rank, drugName, ptName)[0]?.rank : null;
    const result = {
      ...item,
      article_data: {
        ...item.article_data,
        authors: replaceSymboles(item.article_data.authors)
      },
      workStatus: getValue,
      rank: getRank
    };
    return result;
  });
};

export const filteredDiseasesDataByName = (
  data: IDemoListItem[],
  drugName: string,
  ptName: string
) => {
  let diseasesData: IDemoListItem[] = data;

  if (ptName) {
    diseasesData = data.filter(
      ({rank}) => typeof rank === 'object' && rankValue(rank, drugName, ptName).length
    );
  }

  return diseasesData;
};

export const tagFilter = (data: IDemoListItem[], tagList: ISelectOptions[]) => {
  const getIsVisibleItem = tagList.filter(({isVisible}) => isVisible).map((el) => el.name);

  if (getIsVisibleItem.length > 0) {
    return data.filter((item: IDemoListItem) =>
      getIsVisibleItem.some((el) => item?.article_data?.keywords.includes(el))
    );
  }

  return data;
};

export const filteredByWorkStatus = (data: IDemoListItem[], status: string) => {
  if (status === 'All') {
    return data;
  }

  return data.filter((item: IDemoListItem) => {
    return item.workStatus === status.replace(/_/g, ' ');
  });
};

export const objToSelectOptions = (data: IDemoListItem[]) => {
  const tagList: string[] = [];

  data.map((item: IDemoListItem) => {
    return item?.article_data?.keywords.trim() && tagList.push(item.article_data.keywords);
  });

  if (!tagList.length) {
    return [];
  }

  return tagList
    .filter(onlyUniqueForStringArray)
    .map((i) => i.concat(';'))
    .join('')
    .split(';')
    .filter((item) => item.length)
    .map((item: string) => {
      return {
        dataKey: item,
        name: item,
        isVisible: false,
        disableCheckbox: false
      };
    });
};
