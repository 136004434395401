import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import MedicalHistory from './MedicalHistory';

interface Props {
  withoutScroll?: boolean;
}

const MedicalHistoryContainer: React.FC<Props> = ({withoutScroll}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {caseStatData} = caseStatStore;
  const {
    medHistories: selectedMedicalHistory,
    conmedsDrugClass: selectedConmeds,
    setUserFilter,
    clearUserFilter
  } = selectedFiltersStore;

  return (
    <MedicalHistory
      medicalHistoryData={caseStatData.medHistories}
      selectedMedicalHistory={selectedMedicalHistory}
      conmedsData={caseStatData.conmedsDrugClass}
      selectedConmeds={selectedConmeds}
      setUserFilter={setUserFilter}
      clearUserFilter={clearUserFilter}
      withoutScroll={withoutScroll}
    />
  );
};

export default observer(MedicalHistoryContainer);
