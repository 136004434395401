export interface IPageParamsSort {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
}

export interface IPageParams<T> {
  info?: T[];
  content: T[];
  totalPages: number;
  totalElements: number;
  empty?: boolean;
  first?: boolean;
  last: boolean;
  number?: number;
  numberOfElements?: number;
  ArrayDrugs?: [];
  pageable?: {
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: IPageParamsSort;
    unpaged?: boolean;
  };
  size?: number;
  sort?: IPageParamsSort;
}

export const initialPageParam = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  last: true
};
