import {SeriesLineType} from '@models/Chart/chart.types';
import echarts from 'echarts/lib/echarts';
import {
  colorGray,
  colorGray400,
  colorGrayOpacity,
  colorGreen,
  colorGreen100,
  colorGreenOpacity,
  colorWhite,
  DEFAULT_OFFSET,
  tooltipSettings
} from '@services/constants/chart.constants';
import {formatterTooltip} from '@services/utils/ChartHelpers/ChartCommon.helpers';

const defaultSeriesOption = {
  type: 'line',
  symbolSize: 8,
  symbol: 'circle',
  data: [],
  itemStyle: {
    color: colorGreen,
    borderWidth: 4
  },
  showAllSymbol: true
};

export const DEFAULT_LEGEND_OPTIONS: echarts.EChartOption.Legend = {
  show: false,
  orient: 'vertical',
  align: 'left',
  width: 300,
  bottom: 0,
  top: '20px',
  right: 40,
  data: [],
  textStyle: {
    fontWeight: 'bold',
    lineHeight: 14,
    color: colorGray400
  }
};

export const DEFAULT_TOOLTIP_OPTIONS: echarts.EChartOption.Tooltip = {
  position: 'top',
  trigger: 'item',
  formatter: (params) => formatterTooltip({params, width: '60px'}),
  axisPointer: {
    type: 'cross',
    label: {
      show: false
    }
  },
  backgroundColor: tooltipSettings.backgroundColor,
  borderColor: tooltipSettings.borderColor,
  extraCssText: tooltipSettings.extraCssText
};

export const DEFAULT_GRID_OPTIONS: echarts.EChartOption.Grid = {
  left: '40px',
  bottom: 20,
  top: 20,
  right: `${DEFAULT_OFFSET + 40}px`,
  containLabel: true,
  height: 270
};

export const DEFAULT_XAXIS_OPTIONS: echarts.EChartOption.XAxis = {
  type: 'category',
  data: [],
  axisLine: {
    // bottom line x
    show: false,
    lineStyle: {
      color: colorGray
    }
  },
  splitArea: {
    show: true,
    areaStyle: {
      color: [colorGrayOpacity, colorWhite],
      opacity: 0.5
    }
  },
  axisTick: {
    show: false
  },
  offset: 10,
  axisPointer: {
    show: true,
    type: 'line',
    lineStyle: {
      width: tooltipSettings.axisPointerLineStyleWidth,
      type: 'dashed',
      color: tooltipSettings.axisPointerLineStyleColor
    }
  }
};

export const DEFAULT_YAXIS_OPTIONS: echarts.EChartOption.YAxis = {
  type: 'value',
  axisLine: {
    show: false,
    lineStyle: {
      color: colorGray
    }
  },
  axisTick: {
    show: false
  },
  axisPointer: {
    show: false
  }
};

export const DEFAULT_SERIES_OPTIONS: SeriesLineType[] = [
  {
    type: 'bar',
    symbolSize: 8,
    symbol: 'circle',
    data: [],
    lineStyle: {
      color: colorGreen
    },
    itemStyle: {
      color: colorGreen,
      borderWidth: 4
    },
    showAllSymbol: true,
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: colorGreen100 // color at 0% position
          },
          {
            offset: 1,
            color: colorGreenOpacity // color at 100% position
          }
        ]
      }
    }
  },
  {
    ...defaultSeriesOption
  },
  {
    ...defaultSeriesOption
  },
  {
    ...defaultSeriesOption
  },
  {
    ...defaultSeriesOption
  },
  {
    ...defaultSeriesOption
  }
];

export const DEFAULT_CHART_OPTIONS: echarts.EChartOption = {
  tooltip: DEFAULT_TOOLTIP_OPTIONS,
  grid: DEFAULT_GRID_OPTIONS,
  xAxis: DEFAULT_XAXIS_OPTIONS,
  yAxis: DEFAULT_YAXIS_OPTIONS,
  // @ts-ignore
  series: DEFAULT_SERIES_OPTIONS,
  legend: DEFAULT_LEGEND_OPTIONS
};
