export enum CaseVolumeStatTypes {
  Cumulative = 'cumulative',
  Individual = 'individual'
}

export enum Genders {
  Male = 'Male',
  Female = 'Female',
  Unknown = 'Unknown'
}

export enum SelectedUserFilters {
  ReportClasses = 'reportClasses',
  AgeGroups = 'ageGroups',
  Countries = 'countries',
  Sexes = 'sexes',
  MedHistories = 'medHistories',
  Conmeds = 'conmeds',
  ConmedsDrugClass = 'conmedsDrugClass',
  Outcomes = 'outcomes',
  Seriousness = 'serious',
  Severity = 'severity',
  Listedness = 'listedness'
}

export const UserFiltersNames: Record<SelectedUserFilters, string> = {
  [SelectedUserFilters.ReportClasses]: 'Report classes',
  [SelectedUserFilters.AgeGroups]: 'Age groups',
  [SelectedUserFilters.Countries]: 'Countries',
  [SelectedUserFilters.Sexes]: 'Sexes',
  [SelectedUserFilters.MedHistories]: 'Medical Histories',
  [SelectedUserFilters.Conmeds]: 'Conmeds',
  [SelectedUserFilters.ConmedsDrugClass]: 'Conmeds DrugClasses Class',
  [SelectedUserFilters.Outcomes]: 'Outcomes',
  [SelectedUserFilters.Seriousness]: 'Seriousness',
  [SelectedUserFilters.Severity]: 'Severity',
  [SelectedUserFilters.Listedness]: 'Listedness'
};

export const UserFiltersNamesZh: Record<SelectedUserFilters, string> = {
  [SelectedUserFilters.ReportClasses]: '报告分类',
  [SelectedUserFilters.AgeGroups]: '年龄组',
  [SelectedUserFilters.Countries]: '各国',
  [SelectedUserFilters.Sexes]: '性别',
  [SelectedUserFilters.MedHistories]: '医学史',
  [SelectedUserFilters.Conmeds]: '合并用药',
  [SelectedUserFilters.ConmedsDrugClass]: '合并用药',
  [SelectedUserFilters.Outcomes]: '成果',
  [SelectedUserFilters.Seriousness]: '严重性',
  [SelectedUserFilters.Severity]: '严重程度',
  [SelectedUserFilters.Listedness]: '列出'
};

export enum SeriousName {
  Yes = 'Yes',
  No = 'No'
}

export enum SeriousNameFormatted {
  Serious = 'Serious',
  NotSerious = 'Not serious'
}

export interface IFilterDataItem {
  name: string;
  count: number;
  percent: number;
  code?: number;
}

export interface IFilterCodeData {
  code?: number;
  name: string;
}

interface ICaseVolumeChartData {
  date: string;
  count: number;
}

export interface ICaseVolumeChart {
  [key: string]: ICaseVolumeChartData[];
}

export interface ICaseStatData {
  reportClasses: IFilterDataItem[];
  chart: ICaseVolumeChart;
  ageGroups: IFilterDataItem[];
  countries: IFilterDataItem[];
  sex: IFilterDataItem[];
  medHistories: IFilterDataItem[];
  conmeds: IFilterDataItem[];
  conmedsDrugClass: IFilterDataItem[];
  serious: IFilterDataItem[];
  outcomes: IFilterDataItem[];
  severity: IFilterDataItem[];
  listedness: IFilterDataItem[];
  totalCount: number;
}

export interface ICaseVolumeChartFormattedData {
  [key: string]: [number, number, string][];
}

export interface IChartFiltersData {
  timeData: string[];
  caseVolumeChartData: ICaseVolumeChartFormattedData;
  splitByClasses: boolean;
}

export enum EChartAggLevel {
  Year = 'Year',
  Month = 'month',
  Day = 'day'
}
export const CHART_AGG_LEVEL = EChartAggLevel.Day;
