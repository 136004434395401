import {ILineChartFormattedData} from '@models/Chart/LineChart.types';
import echarts from 'echarts/lib/echarts';
import {
  IDrugsInitialData,
  IEventsInitialData,
  IGetLabsChartData,
  ILabsChartLabels,
  ILabsDataWithDates,
  ILabsInitialData,
  ILabsInitialDataValue,
  LabsDataValueWithDatesType
} from '@models/CaseDetail/CaseDetail.types';
import {
  colorMagenta500,
  colorPurple600,
  colorsCollection
} from '@services/constants/chart.constants';
import {
  getPlusFifteenDays,
  getPrevDate,
  getRangeDates,
  getTimeOfDateString,
  isDatesEqual
} from '@services/utils/Date.utils';
import {setToArray} from '@services/utils/Functions.utils';

export function getLabsMinMaxValuesWithInterval(
  lineChartData: ILabsInitialDataValue[]
): Omit<ILabsChartLabels, 'name'> {
  const formattedLineChartData = lineChartData.map((chartData) =>
    chartData.testResult ? chartData.testResult : 0
  );

  const splitNumber = 4;
  const maxValue = lineChartData.length ? Math.max(...formattedLineChartData) : 0;
  const minValue = lineChartData.length ? Math.min(...formattedLineChartData) : 0;

  const maxFloorValue = maxValue - (maxValue % 100) + 100;
  const interval = Math.floor(maxFloorValue / splitNumber);

  return {
    maxFloor: maxFloorValue,
    interval,
    max: maxValue,
    min: minValue
  };
}

export function getLabsChartLabels(labsData: ILabsInitialData): ILabsChartLabels[] {
  if (Object.entries(labsData).length === 0) {
    return [
      {
        name: 'No results',
        max: null,
        maxFloor: 200,
        min: null,
        interval: 25
      }
    ];
  }

  return Object.entries(labsData).map(([key, value]) => {
    const {max, maxFloor, min, interval} = getLabsMinMaxValuesWithInterval(value);
    return {
      name: key,
      max,
      maxFloor,
      min,
      interval
    };
  });
}

export function getLabsChartData({
  labsData,
  startDate,
  endDate
}: IGetLabsChartData): ILineChartFormattedData {
  return Object.values(labsData).reduce<ILineChartFormattedData>((acc, lineChartData, idx) => {
    if (startDate && endDate) {
      const timeDataInterval = getRangeDates({startDate, endDate});

      acc[idx] = lineChartData.map((dataValue) => {
        const {testDate, testResult} = dataValue;
        let {testUnit} = dataValue;
        const timeDataIdx = timeDataInterval.findIndex((date) => {
          return isDatesEqual({firstDate: date, secondDate: new Date(testDate)});
        });
        testUnit = testResult !== null ? testUnit : null;
        return [timeDataIdx, testResult || 0, testUnit];
      });
    }
    return acc;
  }, {});
}

/**
 *
 * @param labsData
 * @param drugsData
 * @param eventsData
 *
 * get all dates from drugs, events and labs
 * and define min and max date or set current and previous dates by default
 */
export function getStartEndDates({
  labsData,
  drugsData,
  eventsData
}: {
  labsData: ILabsDataWithDates;
  drugsData: IDrugsInitialData[];
  eventsData: IEventsInitialData[];
}): {startDate: Date; endDate: Date} {
  const defaultStartDate = getPrevDate(new Date());
  const defaultEndDate = new Date();

  const dates = {startDate: defaultStartDate, endDate: defaultEndDate};
  const labsDataArray = Object.values(labsData);
  const datesCollection: Set<number> = new Set();

  /** all dates from drugs */
  drugsData.forEach(({drugStartDate, drugEndDate}) => {
    if (drugStartDate !== null) {
      datesCollection.add(getTimeOfDateString(drugStartDate));
    }
    if (drugEndDate !== null) {
      datesCollection.add(getTimeOfDateString(drugEndDate));
    }
  });

  /** all dates from events */
  eventsData.forEach(({startDate: eventStartDate, endDate: eventEndDate}) => {
    if (eventStartDate !== null) {
      datesCollection.add(getTimeOfDateString(eventStartDate));
    }
    if (eventEndDate !== null) {
      datesCollection.add(getTimeOfDateString(eventEndDate));
    }
  });

  /** all dates from labs */
  if (labsDataArray.length > 0) {
    labsDataArray.forEach((lab) => {
      if (lab.length > 0) {
        lab.forEach(({testDate}) => {
          datesCollection.add(getTimeOfDateString(testDate));
        });
      }
    });
  }
  /** convert to array after filled Set */
  const datesCollectionArray = setToArray(datesCollection);
  const minDateTime =
    datesCollectionArray.length > 0 ? Math.min(...datesCollectionArray) : defaultStartDate;
  const maxDateTime =
    datesCollectionArray.length > 0 ? Math.max(...datesCollectionArray) : defaultEndDate;

  dates.startDate = new Date(minDateTime);

  if (maxDateTime !== defaultEndDate) {
    dates.endDate = getPlusFifteenDays(new Date(maxDateTime));
  }
  return dates;
}

const isLabsDataValueWithDates = (
  lab: ILabsInitialDataValue
): lab is LabsDataValueWithDatesType => {
  return lab.testDate !== null && lab.testDate !== undefined;
};

export function getLabsOnlyWithDates(labsData: ILabsInitialData): ILabsDataWithDates {
  return Object.entries(labsData).reduce<ILabsDataWithDates>((acc, [key, value]) => {
    acc[key] = value.filter(isLabsDataValueWithDates);
    return acc;
  }, {});
}

function getStepsCollection(maxValue: number): number[] {
  const stepsCollections: number[] = [];

  const maxSegmentsCount = 7;
  const maxInBetweenSegmentsCount = 6;

  let step = Math.floor(maxValue / (maxInBetweenSegmentsCount * 2));
  const levelValue = Math.floor(maxValue / maxInBetweenSegmentsCount);

  for (let i = 0; i < maxSegmentsCount; i++) {
    if (i === 0) {
      stepsCollections.push(0);
    } else {
      stepsCollections.push(step);
    }
    step += levelValue;
  }
  return stepsCollections;
}

function getColorValue({maxValue, currentValue}: {maxValue: number | null; currentValue: number}) {
  let color = colorPurple600;

  const stepsCollections: number[] = getStepsCollection(maxValue || 0);

  stepsCollections.forEach((step, idx, arr) => {
    const isLastIdx = arr.length - 1 === idx;
    const nextValue = arr[idx + 1];

    if (isLastIdx && currentValue >= step) {
      color = colorsCollection[idx];
    }
    if (currentValue >= step && currentValue < nextValue) {
      color = colorsCollection[idx];
    }
  });
  return color;
}

export const getItemStyleColor =
  (i: number, thirdChartLabels: ILabsChartLabels[]) =>
  (params: echarts.EChartOption.Tooltip.Format) => {
    if (Array.isArray(params.value) && params.value.length > 0 && thirdChartLabels.length) {
      const {1: currentValue} = params.value;
      return getColorValue({maxValue: thirdChartLabels[i].max, currentValue});
    }
    return colorMagenta500;
  };
