import {useStore} from '@services/hooks/useStore';
import {observer} from 'mobx-react-lite';
import React from 'react';

import SeverityListedness from './SeverityListedness';

interface Props {
  withoutScroll?: boolean;
}

const SeverityListednessContainer: React.FC<Props> = ({withoutScroll}) => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {caseStatData} = caseStatStore;

  const {
    severity: selectedSeverity,
    listedness: selectedListedness,
    setUserFilter,
    clearUserFilter
  } = selectedFiltersStore;

  return (
    <SeverityListedness
      severityData={caseStatData.severity}
      selectedSeverity={selectedSeverity}
      listednessData={caseStatData.listedness}
      selectedListedness={selectedListedness}
      setUserFilter={setUserFilter}
      clearUserFilter={clearUserFilter}
      withoutScroll={withoutScroll}
    />
  );
};

export default observer(SeverityListednessContainer);
