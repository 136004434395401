import {IDrugsLabels, IEventsLabels, ILabsChartLabels} from '@models/CaseDetail/CaseDetail.types';
import echarts from 'echarts/lib/echarts';

export const DEFAULT_SIDEBAR_WIDTH = 300;
export const DEFAULT_LINE_CHARTS_COUNT = 1;

export interface IChartLabels {
  firstChartLabels?: IDrugsLabels;
  secondChartLabels?: IEventsLabels;
  thirdChartLabels?: ILabsChartLabels[];
}

export interface IChartSettings {
  gridLeftOffset: string;
  rowHeight: number;
  topOffset: number;
  bottomOffset: number;
  lastChartOffset: number;

  firstChartLabels: IDrugsLabels;
  secondChartLabels: IEventsLabels;
  thirdChartLabels: ILabsChartLabels[];

  secondChartTopOffset: number;
  thirdChartTopOffsetsCollection: number[];

  firstChartHeight: number;
  secondChartHeight: number;
  thirdChartDefaultHeight: number;
  thirdChartsHeightSum: number;

  firstChartTitleTop: number;
  secondChartTitleTop: number;
  thirdChartTitleTop: number;
}

// echarts issue #12582 - not described attributes
export type DataZoomType = echarts.EChartOption.DataZoom & {height?: string};

export type MarkLineDataType = {
  coord?: number[];
  lineStyle?: {
    color?: string;
  };
};

export type SeriesMarkLineDataType = [MarkLineDataType, MarkLineDataType][];
type symbolFunction = (value: [number, number, number], params: object) => 'rect' | 'circle';

// echarts has incorrect data type
export type SeriesScatterType = Omit<
  echarts.EChartOption.SeriesScatter,
  'markLine' | 'data' | 'symbol'
> & {
  markLine?: {
    silent?: boolean;
    symbolSize?: number;
    lineStyle?: {
      color?: string;
      width?: number;
      type?: string;
    };
    data?: SeriesMarkLineDataType;
  };
  data?: ([number, number, string | number] | [number, number, number | string, boolean])[];
  symbol?: symbolFunction | string;
};

interface IColorStops {
  offset: number;
  color: string; // [0] - color at 0% position, [1] - color at 100% position
}

export type SeriesLineType = Omit<echarts.EChartOption.SeriesLine, 'areaStyle' | 'data'> & {
  areaStyle?: {
    color?:
      | {
          type?: string;
          x?: number;
          y?: number;
          x2?: number;
          y2?: number;
          colorStops: IColorStops[];
        }
      | string;
  };
  data?: ([number, number] | [number, number, string | null])[];
};

export type SeriesOptionsType = [
  SeriesScatterType,
  SeriesScatterType,
  SeriesScatterType,
  echarts.EChartOption.SeriesLine
];

export type ChartColorCollectionType = {
  [key in string]: string;
};
