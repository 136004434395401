import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '@services/hooks/useStore';

import ReportClasses from './ReportClasses';

const ReportClassesContainer: React.FC<{}> = () => {
  const {
    userFiltersStore: {caseStatStore, selectedFiltersStore}
  } = useStore();
  const {isFetching, caseStatData} = caseStatStore;
  const {
    reportClasses: selectedReportClasses,
    setUserFilter,
    clearUserFilter
  } = selectedFiltersStore;

  return (
    <ReportClasses
      reportClassesData={caseStatData.reportClasses}
      selectedReportClasses={selectedReportClasses}
      setReportClasses={setUserFilter}
      clearReportClasses={clearUserFilter}
      isFetching={isFetching}
    />
  );
};

export default observer(ReportClassesContainer);
