import React, {lazy, Suspense} from 'react';

import MedDRAFiltersCard from './components/MedDRAFiltersCard/MedDRAFiltersCard';
import {StudyDrugFiltersCard} from './components/StudyDrugFiltersCard';
import {StudyDrugEmptyClassModal} from './components/StudyDrugFiltersCard/StudyDrugFilterEmptyPage';
import s from './ResearchPage.module.css';

const ReportTimeToOnsetContainer = lazy(
  () => import('./components/Reports/ReportTimeToOnset/ReportTimeToOnsetContainer')
);
const ReportsByMedicalHistoryContainer = lazy(
  () => import('./components/Reports/ReportsByMedicalHistory/ReportsByMedicalHistoryContainer')
);
const ReportByPTContainer = lazy(
  () => import('./components/Reports/ReportByPT/ReportByPTContainer')
);
const ReportsByOutcomeContainer = lazy(
  () => import('./components/Reports/ReportsByOutcome/ReportsByOutcomeContainer')
);
const ReportByCountriesContainer = lazy(
  () => import('./components/Reports/ReportByCountries/ReportByCountriesContainer')
);
const ReportsByConmedsDrugClassContainer = lazy(
  () => import('./components/Reports/ReportByConmedsDrugClass/ReportsByConmedsDrugClassContainer')
);
const ReportsBySeriousnessContainer = lazy(
  () => import('./components/Reports/ReportsBySeriousness/ReportsBySeriousnessContainer')
);
const ReportByAgeGroupContainer = lazy(
  () => import('./components/Reports/ReportByAgeGroup/ReportByAgeGroupContainer')
);
const ReportBySexContainer = lazy(
  () => import('./components/Reports/ReportBySex/ReportBySexContainer')
);
const ReportClassificationContainer = lazy(
  () => import('./components/Reports/ReportClassification/ReportClassificationContainer')
);
const GroupedTableCasesContainer = lazy(
  () => import('./components/GroupedTableCases/GroupedTableCasesContainer')
);

const TargetVsComparator = lazy(() => import('./components/TargetVsComparator/TargetVsComparator'));
const CasesByStudyContainer = lazy(() => import('./components/CasesByStudy/CasesByStudyContainer'));
const TableCases = lazy(() => import('./components/TableCases/TableCases'));
const ScoresFiltersCard = lazy(() => import('./components/ScoresFiltersCard/ScoresFiltersCard'));
const UserFiltersCard = lazy(() => import('./components/UserFiltersCard/UserFiltersCard'));

interface Props {
  showWidgets?: boolean;
}

const renderLoader = () => <div />;

const ResearchPage: React.FC<Props> = ({showWidgets = false}) => {
  return (
    <div className="page-content">
      <StudyDrugFiltersCard />
      <StudyDrugEmptyClassModal />
      {showWidgets && (
        <>
          <MedDRAFiltersCard />
          <Suspense fallback={renderLoader()}>
            <ScoresFiltersCard />
            <UserFiltersCard />
            <TargetVsComparator />
            <div className={s.cases}>
              <GroupedTableCasesContainer />
              <div className={s.casesColumn}>
                <ReportClassificationContainer />
                <ReportBySexContainer />
              </div>
            </div>
            <CasesByStudyContainer />
            <div className={s.casesColumn}>
              <TableCases />
            </div>
            <div className={s.cases}>
              <ReportByAgeGroupContainer />
              <div className={s.casesColumn}>
                <ReportsBySeriousnessContainer />
              </div>
            </div>
            <div className={s.cases}>
              <ReportsByOutcomeContainer />
              <ReportByCountriesContainer />
              <ReportsByConmedsDrugClassContainer />
            </div>
            <div className={s.cases}>
              <ReportsByMedicalHistoryContainer />
              <ReportByPTContainer />
            </div>
            <div className={s.cases}>
              <ReportTimeToOnsetContainer />
            </div>
          </Suspense>
        </>
      )}
    </div>
  );
};

export default ResearchPage;
