import {IColumnSettings, ICaseByStudySettings, ISArray} from '@models/Table/Table.types';

export const columnSettings: IColumnSettings[] = [
  {
    dataKey: 'reportClassification',
    name: 'widgetCases.columns.reportClassification',
    sortBy: 'reportClassification',
    width: 200,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'study',
    name: 'widgetCases.columns.study',
    sortBy: 'study',
    width: 200,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'caseNumber',
    name: 'widgetCases.columns.caseNumber',
    sortBy: 'caseNumber',
    width: 120,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'country',
    name: 'widgetCases.columns.country',
    sortBy: 'country',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'gender',
    name: 'widgetCases.columns.gender',
    sortBy: 'gender',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'age',
    name: 'widgetCases.columns.age',
    sortBy: 'age',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'patientNumber',
    name: 'widgetCases.columns.patientNumber',
    sortBy: 'patientNumber',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'ctcaeGrade',
    name: 'widgetCases.columns.ctcaeGrade',
    sortBy: 'ctcaeGrade',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  }
];

export const groupedColumnSettings: IColumnSettings[] = [
  {
    dataKey: 'socName',
    name: 'widgetCases.columns.socName',
    sortBy: 'socName',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'ptName',
    name: 'widgetCases.columns.ptName',
    sortBy: 'ptName',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'casesCount',
    name: 'widgetCases.columns.casesCount',
    sortBy: 'casesCount',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'patientYears',
    name: 'widgetCases.columns.patientYears',
    sortBy: 'patientYears',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'initialCases',
    name: 'widgetCases.columns.initialCases',
    sortBy: 'initialCases',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'followUpCases',
    name: 'widgetCases.columns.followUpCases',
    sortBy: 'followUpCases',
    width: 150,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'relatedness',
    name: 'widgetCases.columns.relatedness',
    sortBy: 'relatedness',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'ctcaeGrade',
    name: 'widgetCases.columns.ctcaeGrade',
    sortBy: 'ctcaeGrade',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  }
];

export const caseByStudySettings: ICaseByStudySettings[] = [
  {
    dataKey: 'socName',
    name: 'widgetCases.columns.socName',
    sortBy: 'socName',
    width: 300,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'ptName',
    name: 'widgetCases.columns.ptName',
    sortBy: 'ptName',
    width: 300,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'casesCount',
    name: 'widgetCases.columns.casesCount',
    sortBy: 'casesCount',
    width: 300,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'patientsCount',
    name: 'widgetCases.columns.nOfPatients',
    sortBy: 'patientsCount',
    width: 200,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'study',
    name: 'widgetCases.columns.study',
    sortBy: 'study',
    width: 300,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'relatedness',
    name: 'widgetCases.columns.relatedness',
    sortBy: 'relatedness',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  },
  {
    dataKey: 'ctcaeGrade',
    name: 'widgetCases.columns.ctcaeGrade',
    sortBy: 'ctcaeGrade',
    width: 100,
    isVisible: true,
    disableSort: false,
    disableCheckbox: false
  }
];

export const columnStudy: ISArray[] = [{arrayStudy: []}];
